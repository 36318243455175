<template>
  <div class="page">
    <topheader @goBack="goBack" />

    <div class="classDetails_title">
      <span class="classDetails_title_ttiao">{{ introduce.bd }}</span>
      <span class="recommend" @click="goToRecommend" v-if="enroll || isClassTeacher">
        班圈推荐
        <van-icon name="good-job" />
      </span>
    </div>

    <div class="classDetails_title_big">
      {{ introduce.title }}
      <!-- <span class="graduation_span" v-if="isGraduation">已结业</span> -->
      <span class="graduation_span" v-if="false">已结业</span>
      <div class="graduation" v-if="false">已结业</div>
      <img
        :src="graduationSrc"
        alt
        class="graduation_img"
        v-if="isGraduation"
        @click="goToGraduatePage"
      />
    </div>
    <div class="classDetails_center" v-html="introduce.content || '介绍'"></div>

    <div :style="{height: isGraduation ?  '26vw' : '13vw'}"></div>

    <template v-if="isVailDate">
      <!-- 未加入班圈 -->
      <div class="join" v-if="(!enroll && !isClassTeacher) || (enroll && !isBuy)">
        <div class="join_flex">
          <div>
            <span class="xianjia">￥{{ introduce.price | priceRale }}</span>
            <span class="yuanjia">￥{{ introduce.orprice | priceRale }}</span>
          </div>
          <div class="join_flex_buttom bton" @click="askFirst">加入班圈</div>
        </div>
      </div>
    </template>
    <!-- 已报名付费 或 专家 -->
    <div class="joins" v-if="enter" @click="goToCourseHome">进入班圈</div>
    <!-- 报名有效期内 -->
    <div class="join" v-if="isVailDate && !enter">
      <div class="join_flex">
        <div>
          <span class="xianjia">￥{{ introduce.price | priceRale }}</span>
          <span class="yuanjia">￥{{ introduce.orprice | priceRale }}</span>
        </div>
        <div class="join_flex_buttom bton" @click="askFirst">加入班圈</div>
      </div>
    </div>
    <div class="joins joins-off" v-else-if="!isVailDate && !enter">报名已结束</div>
    <!-- <div class="joins graduated" v-if="isGraduation" @click="goToGraduatePage">查看结业证书</div> -->
  </div>
</template>

<script>
import { getOneClassinfo } from '@/api/classRing'
import Portrait from './widget/portrait'
import { createStuinfo, getAuthority } from '@/api/pay'

export default {
  components: { Portrait },
  data() {
    const userId = this.$store.state.user.userId,
      recommendA = this.$store.state.user.recommendA,
      recommendB = this.$store.state.user.recommendB,
      isTourists = !Boolean(userId),
      userName = this.$store.state.user.name,
      headPortrait = this.$store.state.user.headImg
    return {
      graduationSrc: require('@/assets/images/graduation.png'),
      classId: this.$route.params.classId, // 班圈id
      userId, //用户id
      userName,
      headPortrait,
      introduce: {}, // 课程介绍
      isBuy: false, //已经加入班圈
      recommendA,
      recommendB,
      isTourists, //游客
      isVailDate: false, //报名有效期内
      enroll: false, //已登记
      stuId: '', //学员id
      isClassTeacher: false, //是否当前班圈的老师（课程班主任/导师/辅导员）
      isGraduation: false //是否已结业
    }
  },
  computed: {
    enter() {
      //已报名付费 或 专家
      return (this.enroll && this.isBuy) || this.isClassTeacher
    }
  },
  filters: {
    priceRale(v) {
      try {
        v = Number(v)
        v = Math.floor(v * 100) / 100
      } catch (e) {
        // 不处理
      }
      return [undefined, ''].includes(v) ? '---.--' : v
    }
  },
  methods: {
    // 获取班圈信息
    getClassInfo() {
      // 获取课程简介
      getOneClassinfo(this.classId).then(res => {
        this.introduce = res.data[0]
        this.isClassTeacher = res.isclasstea
        this.isVailDate = this.inVaildDate(this.introduce.bmendtime) //有效期内
        this.enroll = res.stu.length > 0
        this.shareSetting() //设置分享信息

        if (res.stu.length) {
          // 结业状态: 0-未计算结业 1-待结业 2-已结业 3-不及格
          this.isGraduation = res.stu[0].graduation === 2
          //已报名
          this.isBuy = res.stu[0].paystatus === 1 //免费班圈也当是收费了
          this.stuId = res.stu[0].autoid //学员报名id
          this.$store.dispatch('user/setRecommend', {
            type: 'A',
            data: res.stu[0].recommendA
          }) //一级推荐人
          this.$store.dispatch('user/setRecommend', {
            type: 'B',
            data: res.stu[0].recommendB
          }) //二级推荐人
        } else {
          //未报名
          this.setRecommend()
        }
      })
    },
    // 设置推荐信息
    setRecommend() {
      const rec = this.$route.query.state //推荐班圈/推荐人
      if (rec) {
        const recArr = rec.split('_')
        this.$store.dispatch('user/setRecClassId', recArr[0]) //推荐班圈（只有从二维码链接过来才有，且刷新无效）
        this.$store.dispatch('user/setRecommend', {
          type: 'A',
          data: recArr[1] //一级推荐人
        })
        this.recommendA = recArr[1]
        this.$store.dispatch('user/setRecommend', {
          type: 'B',
          data: recArr[2] //二级推荐人
        })
        this.recommendB = recArr[2]
      }
    },
    // 判断有效日期是否大于当天
    inVaildDate(d) {
      let t = new Date(d.replace(/-/g, '/'))
      let dateTime = t.setDate(t.getDate() + 1)
      return new Date() <= dateTime
    },
    // ->马上报名
    goToClassApply() {
      this.$router.push({
        path: '/classApply',
        query: {
          // 价格 现价|原价
          price: this.introduce.price + '|' + this.introduce.orprice,
          className: this.introduce.title,
          stuId: this.stuId,
          classId: this.classId
        }
      })
    },
    // ->班圈推荐
    goToRecommend() {
      this.$router.push({
        path: `/recommend/${this.classId}/${this.userId}`
      })
    },
    // ->进入班圈
    goToCourseHome() {
      this.$router.push({
        path: '/Course_home/',
        query: { classId: this.classId }
      })
    },
    // 查看结业证书
    goToGraduatePage() {
      this.$router.push({
        path: `/graduation/${this.classId}`,
        query: {
          className: this.introduce.title,
          stuId: this.stuId
        }
      })
    },
    askFirst() {
      this.$dialog
        .confirm({
          title: '对不起，班圈仅对学员开放',
          confirmButtonText: '是',
          cancelButtonText: '否',
          confirmButtonColor: '#ff3b30',
          cancelButtonColor: '#007aff',
          message: '现在报名'
        })
        .then(this.goToClassApply)
        .catch(err => {
          // 未注册 或 注册未购买(未加入班圈)
          if (this.isTourists || (!this.isTourists && !this.isBuy)) {
            setTimeout(this.askAgain, 300)
          } else if (false) {
            //收费班圈，报名后支付失败，怎么办???
          }
        })
    },
    askAgain() {
      this.$dialog
        .confirm({
          title: '我想先免费了解一下',
          confirmButtonText: '同意',
          cancelButtonText: '不同意',
          confirmButtonColor: '#ff3b30',
          cancelButtonColor: '#007aff',
          message: '   '
        })
        .then(() => {
          // 弹出注册界面
          if (this.isTourists) {
            // 游客先注册(登录)
            this.$router.push({
              path: '/login/',
              query: {
                redirect: `/Course_home?price=${this.introduce.price}|${this.introduce.orprice}&classId=${this.classId}`, // 价格 现价|原价,班圈id
                apply: true, //需要申请班圈
                classId: this.classId //班圈id
              }
            })
          } else {
            // 先报名再看免费课程
            if (this.stuId) {
              //上次已报名(未支付/免费试学)
              this.goToCourseHome()
            } else {
              // 创建学员报名记录，并完成支付
              createStuinfo(
                this.classId,
                this.userId,
                this.recommendA,
                this.recommendB
              )
                .then(res => {
                  this.goToCourseHome()
                })
                .catch(err => {
                  this.$toast('创建报名记录失败')
                })
            }
          }
        })
    },
    goBack() {
      sessionStorage.removeItem('lastPage') //清除这个参数，避免以后有其他地方影响
      if (this.$route.query.goindex === 'true') {
        this.$router.push('/')
      } else {
        this.$router.back(-1)
      }
    },
    shareSetting() {
      let shareIcon = ''
      if (this.introduce.pic) {
        shareIcon = this.introduce.pic
      } else {
        shareIcon = this.headPortrait
      }
      let params = {
        title: this.introduce.title,
        desc: this.introduce.describe ? this.introduce.describe : `${this.userName} 邀您加入班圈`,
        link: `${location.origin}/classDetails/${this.classId}?state=${
          this.classId
        }_${this.userId}_${this.recommendA ? this.recommendA : ''}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareIcon,
        success: () => {
          console.warn('成功分享：朋友/朋友圈', params)
        },
        fail: e => {
          console.error('分享失败：朋友/朋友圈', e)
        }
      }
      //需在用户可能点击分享按钮前就先调用
      wx.ready(() => {
        wx.updateAppMessageShareData(params) // 分享给朋友
        setTimeout(() => {
          wx.updateTimelineShareData(params) // 分享到朋友圈
        }, 300)
      })
    }
  },
  created() {
    this.$setgoindex()
    getAuthority()
  },
  mounted() {
    this.getClassInfo()
  },
  activated() {
    // ② 解决二维码分享跳转到班圈详情页无法返回到首页的问题
    this.$setgoindex()
    this.getClassInfo() //测试发现 报名页支付成功回到本页面，仍显示未支付状态，故重新请求一次
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
.quxioa {
  width: 50%;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.533vw;
  color: #007aff;
  font-weight: bold;
  border-top: 0.133vw solid #3f3f3f;
  border-right: 0.1vw solid #3f3f3f;
}
.baoming {
  width: 50%;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.533vw;
  color: #007aff;
  font-weight: bold;
  border-top: 0.133vw solid #3f3f3f;
}
.felx_quxiao {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.tooltip_cneter {
  text-align: center;
  font-size: 3.467vw;
  color: #1e1e1e;
  margin: 1vw auto;
}
.tooltip_title {
  font-size: 4.533vw;
  color: #1e1e1e;
  font-weight: bold;
  text-align: center;
  margin-top: 0vw;
}
.tooltip {
  width: 72vw;
  height: 37.333vw;
  background-color: #ffffff;
  border-radius: 3.733vw;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
}
.joins {
  width: 100vw;
  height: 13vw;
  background-image: linear-gradient(90deg, #f0b860 0%, #fddaa4 100%),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.joins-off {
  background: #e7e7e7;
  color: #a4a4a4;
}
.graduated {
  bottom: 13vw;
  border-bottom: 1px solid $border-light;
}
.yuanjia {
  font-size: 2.933vw;
  letter-spacing: -0.133vw;
  color: #bfc2cc;
  margin-left: 1.333vw;
  text-decoration: line-through;
}
.xianjia {
  font-size: 5.067vw;
  font-weight: normal;
  letter-spacing: -2px;
  color: #fe4858;
  font-weight: bold;
}
.join_flex_buttom {
  width: 41.6vw;
  height: 10.133vw;
  background-image: linear-gradient(90deg, #f0b860 0%, #fddaa4 100%),
    linear-gradient(#fe4858, #fe4858);
  background-blend-mode: normal, normal;
  border-radius: 5.067vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.733vw;
  color: #ffffff;
}
.join_flex {
  width: 93.733vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.join {
  width: 100vw;
  height: 14vw;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  border-top: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
}
.classDetails_center {
  width: 93.333vw;
  font-size: 4vw;
  line-height: 6vw;
  color: #303133;
  margin: 0 auto;
}
// .classDetails_center>>>img{
//   width: 100%;
// }
/deep/.classDetails_center {
  img {
    width: 100% !important;
  }
}

/* start - by：yyz */
.classDetails_calssRing_list
  .van-collapse-item
  /deep/.van-collapse-item__wrapper
  .van-collapse-item__content {
  padding-top: 0;
}
/* 去除vant折叠面板的上下底边 */
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: 0 none;
}
.collapse {
  .van-collapse-item {
    /deep/.van-cell {
      padding-left: 0.5em;
      padding-right: 0.5em;
      .van-cell__title {
        span {
          color: #969799;
        }
      }
      .van-icon {
        color: #323233;
      }
    }
    /deep/.van-collapse-item__wrapper {
      .van-collapse-item__content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.classDetails_title_big {
  width: 93.333vw;
  margin: 4.533vw auto;
  font-size: 5.333vw;
  color: #303133;
  font-weight: bold;
  position: relative;
  .graduation_img {
    position: absolute;
    top: -10.667vw;
    left: 18.667vw;
  }
  .graduation_span {
    padding: 0.5em;
    color: $success-color;
  }
  .graduation {
    position: absolute;
    right: 0;
    bottom: -1.6vw;
    border: 2px solid rgba(110, 220, 204, 0.65);
    border-radius: 1.6vw;
    width: 19.8vw;
    text-align: center;
    line-height: 8vw;
    color: rgba(110, 220, 204, 0.65);
    transform: rotateZ(-12deg);
  }
}

.classDetails_title {
  width: 93.333vw;
  margin: 4.533vw auto;
  display: flex;
  align-items: center;
  font-size: 2.933vw;
  color: #909399;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .classDetails_title_ttiao {
    padding-left: 1.4vw;
    position: relative;
  }
  .classDetails_title_ttiao::before {
    content: '';
    display: block;
    @include wh(0.8vw, 100%);
    background-color: #247dff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .recommend {
    // display: flex;
    // align-items: center;
    color: #ff3c1a;
    // color: red;
    font-size: 3.2vw;
    .van-icon {
      // padding-left: 0.667vw;
      // font-size: 3.6vw;
    }
  }
}
</style>
