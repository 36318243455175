<!-- 评论：course_home 专用 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="comment_list" v-for="(item, i) in list" :key="'review' + i">
          <div class="comment_list_content">
            <div class="comment_list_top">
              <div class="comment_list_top_left">
                <Portrait :src="item.headurl" />
              </div>
              <div class="comment_list_top_right">
                <div class="name">
                  <span>
                    {{item.userid == userId ? '我' : item.name}}
                    <van-tag color="#ffe1e1" text-color="#ad0000" v-if="item.is2Top">Top</van-tag>
                  </span>
                  <slot name="nameRight" :data="item" />
                </div>
                <div class="time">
                  {{
                  item.crdate
                  | timeStamp
                  | dateFormat('yyyy/MM/dd hh:mm:ss', true)
                  }}
                </div>
              </div>
              <div></div>
            </div>
            <div class="comment_list_details">
              <template v-if="item.replymode === '文本'">
                <div class="comment_list_text" v-html="translateCommend(item.content)"></div>
              </template>
              <template v-else-if="item.replymode === '语音'">
                <div class="audio_box">
                  <audioBox
                    isBuy
                    ref="audioBox"
                    :mediaId="item.mediaId"
                    :sec="item.sec"
                    :url="item.voiceurl"
                    :createdTime="item.crdate"
                    @beforePlay="beginPlay"
                  />
                </div>
              </template>
              <div class="comment_list_reply">
                <template v-if="isClassTeacher && item.userid != userId">
                  <span @click="eply(item, 'delete')">删除</span>
                  <span v-if="item.replymode !== '语音'">/</span>
                </template>
                <span @click="eply(item)">
                  {{
                  item.userid == userId ? '删除' : item.replymode == '语音'? '' : '回复'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getReviewList } from '@/api/classRing'
import audioBox from '../../../My_personal_center/widget/audioBox'
import Portrait from '@/components/extend/portrait'
import { sort2top } from '@/assets/js/public'
export default {
  name: 'review',
  props: {
    classId: String,
    isBuy: Boolean,
    isClassTeacher: Boolean, //班主任|导师|辅导员
    active: Number, //记录用,页面回退到Course_home时反显[导航栏]
    curLessonIdx: Number //记录用,页面回退到Course_home时反选[当前课程]
  },
  components: { audioBox, Portrait },
  data() {
    let curPhone = this.$store.state.user.tel
    return {
      // page: 1,// 当前页数
      list: [], // 显示数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      curPhone, //当前客户的手机号码
      userId: this.$store.state.user.userId, //用户id
      player: undefined, //上一个/正在播放的录音
      defaultSrc: require('@/assets/images/head_prole.png')
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      // this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad(isLoading) {
      let commonId = 0
      if (this.list.length) {
        // 如果是父组件定时更新评论信息的，需将当前评论最新（大）autoid传会给后台
        let idArr = []
        this.list.forEach(item => idArr.push(item.autoid))
        commonId = Math.max.apply(null, idArr) //取最大的autoid
      }
      getReviewList(this.classId, commonId, isLoading)
        .then(res => {
          if (commonId == 0) {
            this.list = sort2top(res.list)
            this.finished = true
          } else {
            // this.list.push(...res.list)

            let arr = this.list.concat(res.list)
            // this.list = []
            // this.$nextTick(() => {
            this.list = sort2top(arr)
            // })
          }
          this.loading = false
          this.pullRefresh = false
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 供父级定时更新评论信息
    refresh(isLoading) {
      this.onLoad(isLoading)
    },
    // 删除/回复
    eply(info, type) {
      info.type = type
      this.$emit('action', info)
    },
    // 将匹配结果替换表情图片
    emotion(res) {
      let word = decodeURI(res).replace(/\#|\;/gi, '')
      const list = [
        '微笑',
        '撇嘴',
        '色',
        '发呆',
        '得意',
        '流泪',
        '害羞',
        '闭嘴',
        '睡',
        '大哭',
        '尴尬',
        '发怒',
        '调皮',
        '呲牙',
        '惊讶',
        '难过',
        '酷',
        '冷汗',
        '抓狂',
        '吐',
        '偷笑',
        '可爱',
        '白眼',
        '傲慢',
        '饥饿',
        '困',
        '惊恐',
        '流汗',
        '憨笑',
        '大兵',
        '奋斗',
        '咒骂',
        '疑问',
        '嘘',
        '晕',
        '折磨',
        '衰',
        '骷髅',
        '敲打',
        '再见',
        '擦汗',
        '抠鼻',
        '鼓掌',
        '糗大了',
        '坏笑',
        '左哼哼',
        '右哼哼',
        '哈欠',
        '鄙视',
        '委屈',
        '快哭了',
        '阴险',
        '亲亲',
        '吓',
        '可怜',
        '菜刀',
        '西瓜',
        '啤酒',
        '篮球',
        '乒乓',
        '咖啡',
        '饭',
        '猪头',
        '玫瑰',
        '凋谢',
        '示爱',
        '爱心',
        '心碎',
        '蛋糕',
        '闪电',
        '炸弹',
        '刀',
        '足球',
        '瓢虫',
        '便便',
        '月亮',
        '太阳',
        '礼物',
        '拥抱',
        '强',
        '弱',
        '握手',
        '胜利',
        '抱拳',
        '勾引',
        '拳头',
        '差劲',
        '爱你',
        '不',
        '好的',
        '爱情',
        '飞吻',
        '跳跳',
        '发抖',
        '怄火',
        '转圈',
        '磕头',
        '回头',
        '跳绳',
        '挥手',
        '激动',
        '街舞',
        '献吻',
        '左太极',
        '右太极'
      ]
      let index = list.indexOf(word)
      return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif">`
    },
    // 回复评论转换
    translateCommend(t) {
      const txt = t.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, this.emotion),
        txtArr = txt.split('{+_+}'),
        label = []
      if (txtArr.length == 1) {
        return txt
      } else {
        label.push(`<div>${txtArr[0]}</div>`)
        label.push(`<div>${txtArr[1]}</div>`)
        return label.join('')
      }
    },
    // 开始播放前要停用上一个播放的audio
    beginPlay(that) {
      if (!that && this.player) {
        //用于ios终端 停止 播放isplay=false
        this.player.stop()
      } else {
        that.allowToPlay = true //因为多个音频播放,所以需要判断是否允许播放
        if (this.player) {
          if (this.player._uid === that._uid && that.isPlay) {
            that.allowToPlay = false
            that.stop()
          } else if (this.player._uid !== that._uid) {
            this.player.stop()
          }
        }
        this.player = that
      }
    }
  },
  mounted() {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 0 3.2vw;

  /deep/.van-pull-refresh {
    .van-pull-refresh__track {
      .van-list {
        padding-bottom: 13vw;
        .comment_list {
          width: 93.6vw;
          width: 100%;
          padding-bottom: 2.667vw;
          border-bottom: 0.233vw solid #edeff2;

          // .comment_list_content {
          //   .comment_list_top {
          //     .comment_list_top_left {
          //     }
          //     .comment_list_top_right {
          //     }
          //   }
          //   .comment_list_details {
          //     // 文本
          //     .comment_list_text {
          //     }
          //     // 语音
          //     .audio_box {
          //     }
          //     .comment_list_reply {
          //     }
          //   }
          // }

          .comment_list_content {
            .comment_list_top {
              display: flex;
              align-items: center;
              margin-top: 4vw;
              .comment_list_top_left {
                width: 8.8vw;
                height: 8.8vw;
                @include borderRadius(50%);
                overflow: hidden;
                img {
                  @include imgCover;
                }
              }
              .comment_list_top_right {
                margin-left: 2.8vw;
                flex: 1;
                .name {
                  font-size: 3.467vw;
                  color: #303133;
                  font-weight: bold;
                  display: flex;
                  span:first-child {
                    flex: 1;
                    // .van-tag {
                    //   font-size: 3.2vw;
                    // }
                  }
                }
                .time {
                  font-size: 3.2vw;
                  letter-spacing: -0.133vw;
                  color: $font-gray;
                }
              }
            }
            .comment_list_details {
              width: 100%;
              box-sizing: border-box;
              font-size: 3.733vw;
              line-height: 6.4vw;
              color: #303133;
              margin-top: 2.667vw;
              display: -webkit-flex; /* 新版本语法: Chrome 21+ */
              display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
              display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
              display: -moz-box; /* 老版本语法: Firefox (buggy) */
              display: -ms-flexbox; /* 混合版本语法: IE 10 */
              // 文本回复
              // /deep/
              .comment_list_text {
                -webkit-flex: 1; /* Chrome */
                -ms-flex: 1; /* IE 10 */
                flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
                -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
                -moz-box-flex: 1; /* OLD - Firefox 19- */
                word-break: break-word;
                white-space: pre-line; //支持换行/n
                // min-width: 560px;
                // max-width: 630px;
                div:first-child {
                  position: relative;
                  color: $font-gray;
                }
                div:first-child::after {
                  content: '- - - - - - - - - - - - - - - - - - - -';
                  display: block;
                  position: absolute;
                  left: 0;
                  bottom: -0.8em;
                }
                img {
                  @include wh(6.4vw, 6.4vw);
                }
              }
              // 语音回复
              .audio_box {
                flex: 1;
                .x-audio-wrap {
                  border-radius: 0.667vw;
                  margin-left: 1px;
                  .x-sector {
                  }
                  .x-time {
                  }
                }
              }
              .comment_list_reply {
                padding-left: 2vw;
                color: $font-gray;
              }
            }
          }
        }
        .van-list__finished-text {
          line-height: 6.4vw;
        }
      }
    }
  }
}
</style>
