<template>
  <div class="set_homework">
    <topheader>
      <!-- <van-icon name="add-o" slot="rightTools" @click="createHomework" /> -->
      <van-button type="info" size="small" slot="rightTools" @click="createHomework">发布</van-button>
    </topheader>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="question_box"
          v-for="(item, idx) in list"
          :key="idx"
          @click="hwClick($event, item)"
        >
          <div class="question_box_div">
            <div class="question_box_number">{{ (idx + 1) | serial }}</div>
            <div class="question_box_title">{{ item.title }}</div>
            <div class="question_box_newresponse">
              <span>删除</span>
            </div>
          </div>
          <div class="question_box_div">
            <div class="question_box_number"></div>
            <!-- <div class="question_box_desc">{{ item.content }}</div> -->
            <div class="question_box_newresponse"></div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 新增作业弹窗 -->
    <van-dialog
      v-model="showDialog"
      :title="dailogTitle"
      show-cancel-button
      close-on-popstate
      cancel-button-text="取消"
      :confirm-button-text="isNew ? '新增' : '编辑'"
      :showConfirmButton="isNew || !began"
      :beforeClose="checking"
    >
      <van-form ref="form" class="newHomework">
        <!-- 所属班圈/课程信息 -->
        <van-cell-group class="homework_info">
          <van-field
            label="所属班圈:"
            name="className"
            type="textarea"
            rows="1"
            label-width="5em"
            readonly
            clickable
            :value="className"
            autosize
          />
        </van-cell-group>
        <van-cell-group class="homework_info">
          <van-field
            label="所属课程:"
            name="lessonName"
            type="textarea"
            rows="1"
            label-width="5em"
            readonly
            clickable
            :value="lessonName"
            autosize
          />
        </van-cell-group>
        <!-- 日期选择 -->
        <van-cell-group class="homework_time">
          <van-field
            v-model="startTime"
            label="开始时间:"
            name="startTime"
            placeholder="请选择..."
            type="textarea"
            rows="1"
            label-width="5em"
            autosize
            readonly
            disabled
            required
            :rules="[{ required: true, message: '请选择开始时间' }, {validator: beginTimeValid, message: ''}]"
            @click="showStartTimePicker"
          />
        </van-cell-group>
        <van-cell-group class="homework_time">
          <van-field
            v-model="endTime"
            label="结束时间:"
            name="endTime"
            placeholder="请选择..."
            type="textarea"
            rows="1"
            label-width="5em"
            readonly
            autosize
            disabled
            required
            :rules="[{ required: true, message: '请选择结束时间' },{ validator: endTimeValid, message: '开始时间不能大于等于结束时间' }]"
            @click="showEndTimePicker"
          />
        </van-cell-group>
        <!-- 作业信息 -->
        <van-cell-group class="homework_content">
          <van-field
            v-model="title"
            name="title"
            type="textarea"
            label="作业名称:"
            placeholder="请输入..."
            rows="1"
            label-width="5em"
            autosize
            required
            clearable
            :disabled="!(isNew || !began)"
            :rules="[{ required: true, message: '请输入作业名称' }]"
          />
          <van-field
            v-model="content"
            name="content"
            type="textarea"
            label="作业内容:"
            placeholder="请输入..."
            rows="3"
            label-width="5em"
            required
            autosize
            clearable
            :disabled="!(isNew || !began)"
            :rules="[{ required: true, message: '请输入作业内容' }]"
          >
            <template #input v-if="!(isNew || !began)">
              <div class="homework_content_readonly" v-html="content"></div>
            </template>
          </van-field>
        </van-cell-group>
      </van-form>
    </van-dialog>
    <!-- 开始时间选择器 -->
    <van-popup v-model="showStartTime" round position="bottom">
      <van-datetime-picker
        v-model="startT"
        type="date"
        title="选择开始时间"
        :min-date="startMinDate"
        :max-date="startMaxDate"
        @confirm="selectStartPicker"
        @cancel="cancelStartPicker"
      />
    </van-popup>
    <!-- 结束时间选择器 -->
    <van-popup v-model="showEndTime" round position="bottom">
      <van-datetime-picker
        v-model="endT"
        type="date"
        title="选择结束时间"
        :min-date="endMinDate"
        :max-date="endMaxDate"
        @confirm="selectEndPicker"
        @cancel="cancelEndPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import { getHWPlanList } from '@/api/my'
import { editeHWPlaninfo } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,

      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      showDialog: false,

      classId: this.$route.query.classId,
      className: this.$route.query.className,
      lessonId: this.$route.query.lessonId,
      lessonName: this.$route.query.lessonName,
      startMinDate: new Date(),
      // startMaxDate: new Date(this.$route.query.endDate.replace(/\-/g, '/')),
      startMaxDate: undefined,
      endMinDate: new Date(),
      // endMaxDate: new Date(this.$route.query.endDate.replace(/\-/g, '/')),
      endMaxDate: undefined,
      startT: new Date(), //选择器的开始时间
      endT: '', //选择器的结束时间
      startTime: '', //开始时间
      endTime: '', //结束时间
      title: '', //题目
      content: '', //内容
      showStartTime: false, //是否展示开始时间的选择器
      showEndTime: false, //是否展示结束时间的选择器
      isNew: false, //是否新建作业
      curHWInfo: {} //当前选中的作业信息
    }
  },
  filters: {
    serial(i) {
      return i <= 9 ? `0${i}` : i
    }
  },
  computed: {
    dailogTitle() {
      return this.isNew ? '新建作业' : '查看作业信息'
    },
    began() {
      if (this.curHWInfo.bd) {
        return new Date() >= new Date(this.curHWInfo.bd.replace(/\-/g, '/'))
      } else {
        return false
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getHWPlanList(this.lessonId)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 新建作业-跳转页面
    createHomework() {
      this.$router.push({
        path: '/createHomework',
        query: {
          lessonId: this.lessonId,
          className: this.className,
          lessonName: this.lessonName,
          isNew: 1, // 1-新增 0-编辑
          began: this.began ? 1 : 0, // 课程是否已经开始
          beginDate: this.$route.query.beginDate, //
          endDate: this.$route.query.endDate
        }
      })
    },
    // 展示开始时间选择器
    showStartTimePicker() {
      if (this.isNew || !this.began) {
        this.showStartTime = true
        this.startT = new Date(this.startTime.replace(/\-/g, '/'))
      }
    },
    // 展示结束时间选择器
    showEndTimePicker() {
      if (this.isNew || !this.began) {
        this.showEndTime = true
        this.endT = new Date(this.endTime.replace(/\-/g, '/'))
      }
    },
    // 选中开始时间
    selectStartPicker(v) {
      this.startTime = v && v.Format('yyyy-MM-dd')
      this.showStartTime = false
    },
    // 选中结束时间
    selectEndPicker(v) {
      this.endTime = v && v.Format('yyyy-MM-dd')
      this.showEndTime = false
    },
    // 开始时间校验
    beginTimeValid(v) {
      return true
    },
    // 结束时间校验
    endTimeValid(v) {
      // ui框架存在问题：如果第一次校验开始时间大于结束时间【校验不通过】，此时改小开始时间，再提交不会触发结束时间的校验，导致无法提交
      return v > this.startTime
    },
    // 取消开始时间
    cancelStartPicker() {
      this.showStartTime = false
    },
    // 取消结束时间
    cancelEndPicker() {
      this.showEndTime = false
    },
    initForm() {
      this.title = ''
      this.content = ''
      this.startTime = ''
      this.endTime = ''
    },
    // 点击事件
    hwClick(e, info) {
      this.curHWInfo = info
      let tar = e.target
      // 删除作业
      if (
        [...new Set(tar.classList)].includes('question_box_newresponse') ||
        [...new Set(tar.parentNode.classList)].includes(
          'question_box_newresponse'
        )
      ) {
        this.deleteHomework(info)
      } else {
        // 查看作业信息
        // this.showDialog = true
        // this.isNew = false
        // this.title = info.title
        // this.content = info.content
        // this.startTime = info.bd
        // this.endTime = info.ed
        console.log(1111, info)
        // return
        this.$router.push({
          path: '/createHomework',
          query: {
            lessonId: this.lessonId,
            className: this.className,
            lessonName: this.lessonName,
            isNew: 0, // 1-新增 0-编辑
            began: this.began ? 1 : 0, // 课程是否已经开始
            beginDate: this.$route.query.beginDate, //课程开始时间
            endDate: this.$route.query.endDate, //课程结束时间
            // 反显信息
            title: info.title,
            content: info.content,
            startTime: info.bd,
            endTime: info.ed,
            sqnum: info.sqnum,
            autoid: info.autoid
          }
        })
      }
    },
    beforeConfirm() {
      return new Promise((resolve, reject) => {
        const hwStartTime = Date.parse(
          new Date(this.startTime.replace(/\-/g, '/'))
        )
        const hwEndTime = Date.parse(new Date(this.endTime.replace(/\-/g, '/')))
        const lessonStartTime = Date.parse(
          new Date(this.$route.query.beginDate.replace(/\-/g, '/'))
        )
        const lessonEndTime = Date.parse(
          new Date(this.$route.query.endDate.replace(/\-/g, '/'))
        )
        // 结束时间大于课程结束时间
        if (hwEndTime > lessonEndTime) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '作业设置的结束时间大于课程结束时间，确定继续吗？'
            })
            .then(resolve)
            .catch(reject)
        } else if (hwStartTime < lessonStartTime) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '作业设置的开始时间小于课程开始时间，确定继续吗？'
            })
            .then(resolve)
            .catch(reject)
        } else {
          resolve()
        }
      })
    },
    async checking(action, done) {
      if (action === 'confirm') {
        const defaultParams = {
          courseid: this.lessonId,
          title: this.title,
          content: this.content,
          bd: this.startTime,
          ed: this.endTime,
          duration: false
        }
        let params = {}
        try {
          await this.beforeConfirm()
        } catch (e) {
          done(false)
          return
        }
        if (this.isNew) {
          //新增
          params = Object.assign(defaultParams, {
            sqnum: 99, //序号默认99,暂时不让老师更新
            autoid: null,
            type: 9
          })
        } else {
          //编辑
          params = Object.assign(defaultParams, {
            sqnum: this.curHWInfo.sqnum,
            autoid: this.curHWInfo.autoid,
            type: 1
          })
        }
        this.$refs.form
          .validate()
          .then(() => {
            editeHWPlaninfo(params)
              .then(res => {
                done()
                this.initForm()
                this.onRefresh()
                this.$nextTick(() => {
                  this.$toast(this.isNew ? '新建成功' : '编辑成功')
                  this.isNew = false
                })
              })
              .catch(err => {
                done(false)
                this.isNew = false
              })
          })
          .catch(err => {
            done(false)
            this.isNew = false
          })
      } else if (action === 'cancel') {
        done()
        this.initForm()
        setTimeout(() => {
          this.isNew = false
        }, 300)
      }
    },
    deleteHomework(item) {
      if (this.began) {
        const t =
          item.title.length > 10 ? item.title.slice(0, 8) + '...' : item.title
        this.$dialog.alert({
          title: '提示',
          message: `当前作业：“${t}”已开始答题，不支持删除！`
        })
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定要删除“${item.title}”作业吗？`
          })
          .then(() => {
            editeHWPlaninfo({
              courseid: this.lessonId,
              title: item.title, //可不传
              content: item.content, //可不传
              bd: item.bd, //可不传
              ed: item.ed, //可不传
              duration: false, //可不传
              sqnum: null, //可不传
              autoid: item.autoid,
              type: 0
            }).then(() => {
              const group = this.list
              this.list = []
              this.$nextTick(() => {
                this.list = group.filter(it => it.autoid !== item.autoid)
                this.$toast({
                  message: '删除成功',
                  position: 'bottom'
                })
              })
            })
          })
      }
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.set_homework {
  @include isTop;
  @include wh(100vw, 100vh);
  background: #fff;
  .top_header {
    .right {
      font-size: 5.6vw;
      .van-button {
        min-width: 4em;
        padding: 0;
      }
    }
  }
  .question_box {
    padding: 3.467vw 3.2vw 5.333vw 3.2vw;
    border-bottom: 1px solid $border-light;
    .question_box_div {
      display: flex;
      align-items: center;
      .question_box_number {
        width: 8.667vw;
        color: #bfc2cc;
      }
      .question_box_title {
        font-size: 4vw;
        flex: 1;
      }
      .question_box_desc {
        font-size: 3.2vw;
        flex: 1;
        color: $font-gray;
        @include dot;
      }
      .question_box_newresponse {
        font-size: 2.667vw;
        color: #bfc2cc;
        text-align: right;
        padding-left: 20px;
      }
      // .dot {
      //   position: relative;
      //   display: inline-block;
      // }
      // .dot::after {
      //   content: '';
      //   display: block;
      //   @include wh(1.333vw, 1.333vw);
      //   @include borderRadius(50%);
      //   @include center;
      //   left: -2.667vw;
      //   background: $warn-color;
      // }
    }
    .question_box_div:nth-child(2) {
      padding-top: 2.933vw;
    }
  }
  // 新增作业弹窗
  .newHomework {
    padding-left: 3.2vw;
    padding-right: 3.2vw;
    max-height: 80vw;
    overflow-y: scroll;
    /deep/.van-cell-group {
      padding-top: 2.667vw;
      .van-cell {
        margin: 0;
        font-size: 3.8vw;
      }
      .van-cell::after {
        border-bottom: 0 none;
      }
    }
    .van-cell-group::after {
      border-bottom: 0 none;
    }
    .homework_info,
    .homework_time,
    .homework_content {
      /deep/.van-cell {
        padding-left: 0.5em;
        box-sizing: border-box;
        .van-field__value {
          .van-field__body {
            .van-field__control {
              .homework_content_readonly {
                color: $font-gray;
                p {
                  color: inherit !important;
                  width: 100%;
                  img {
                    @include imgCover;
                  }
                }
                img {
                  @include imgCover;
                }
              }
            }
          }
        }
      }
      .van-cell::before {
        left: 0;
      }
      /deep/.van-cell:nth-child(2) {
        .van-cell__value {
          box-shadow: $box-shadow;
          padding: 0.5em;
        }
      }
    }
  }
}
</style>
