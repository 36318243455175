<template>
  <!-- 班圈所有考试的平均分列表 -->
  <div class="page">
    <topheader />

    <van-pull-refresh success-text="刷新成功" v-model="pullRefresh" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="average_box"
          v-for="(item, index) in list"
          :key="index"
          @click="goToDetail(item)"
        >
          <div class="average_header">
            <div class="average_img">
              <img :src="item.pic" alt />
            </div>
            <div class="average_title" v-text="item.classname"></div>
          </div>
          <div class="average_score">
            <div class="dot green"></div>
            <div class="content">
              <span>平均分数：</span>
              <span class="block green" v-text="`${item.exscoreavg.toFixed(1)}分`"></span>
            </div>
          </div>
          <div class="average_rank">
            <div class="dot blue"></div>
            <div class="content">
              <span>平均名次：</span>
              <p>
                <span class="block blue" v-text="`NO.${item.rank}`"></span>
                <span class="date" v-text="item.crdate"></span>
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyClassAvgList } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyClassAvgList(this.userId)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    goToDetail(item) {
      // console.log('item', item)
      this.$router.push({
        path: `/scoreList/${item.stuid}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$green: #00bf79;
$blue: #237efc;
$per: 750/1080; //ui图给了1080P的 所以这里简单做个转换
.page {
  @include isTop;
  background: $bg-light;
  padding: 13.333vw * $per + 4.8vw * $per 3.2vw * $per 0 3.2vw * $per;
  .average_box {
    // @include wh(100%, );
    min-height: 46.667vw * $per;
    @include borderRadius(1.333vw * $per);
    box-sizing: border-box;
    background: #fff;
    padding: 3.333vw * $per 7.2vw * $per 5.867vw * $per 4.267vw * $per;
    margin-top: 4.8vw * $per;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .average_header {
      display: flex;
      align-items: center;
      .average_img {
        @include wh(12.533vw * $per, 12.533vw * $per);
        box-sizing: border-box;
        border: 0.998px solid #ccc;
        img {
          @include wh(100%, 100%);
          object-fit: cover;
        }
      }
      .average_title {
        flex: 1;
        @include font(5.333vw * $per, 1.2em);
        padding-left: 2.667vw * $per;
      }
    }

    .average_score,
    .average_rank {
      display: flex;
      align-items: center;
      .content {
        margin-left: 2.667vw * $per;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 4.8vw * $per;
        span {
        }
        p {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // span
          span.date {
            color: $font-gray;
            font-size: 3.467vw * $per;
          }
        }
      }
    }
    .average_score {
      .content {
        justify-content: normal;
      }
    }
  }
  //
  .block {
    border: 0.998px solid $border-gray;
    @include borderRadius(2.133vw * $per);
    @include wh(18.667vw * $per, 7.467vw * $per);
    @include font(4.8vw * $per, 7.467vw * $per);
    text-align: center;
  }
  // 点
  .dot {
    @include wh(12.533vw * $per, auto);
    position: relative;
  }
  .dot::after {
    content: '';
    display: block;
    @include wh(2.667vw * $per, 2.667vw * $per);
    @include borderRadius(50%);
    @include center;
  }
  // 绿色
  .green {
    border-color: $green;
    &::after {
      background-color: $green;
    }
  }
  // 蓝色
  .blue {
    border-color: $blue;
    &::after {
      background-color: $blue;
    }
  }
}
</style>
