<template>
  <div class="attention_page">
    <topheader />
    <van-tabs
      swipeable
      sticky
      offset-top
      title-active-color="#247dff"
      title-inactive-color="black"
      line-width="4.533vw"
      :color="'#247dff'"
      v-model="active"
    >
      <van-tab title="关注的同学">
        <Contacts ref="student" type="0" @onload="getList" />
      </van-tab>
      <van-tab title="关注的老师">
        <Contacts ref="teacher" type="1" @onload="getList" @click="goToExpertDetails" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import { getMyTeacherList } from '../../api/my'
import { getMyFollow } from '@/api/my'
import Contacts from '../widget/contacts'
export default {
  components: { Contacts },
  data() {
    return {
      userId: this.$store.state.user.userId,
      active: 0
    }
  },
  methods: {
    getList(page) {
      const params = {
        userid: this.userId,
        type: this.active,
        curpage: page
      }
      getMyFollow(params)
        .then(res => {
          if (this.active === 0) {
            this.$refs.student.setList(res.list)
            this.$refs.student.success(res)
          } else if (this.active === 1) {
            this.$refs.teacher.setList(res.list)
            this.$refs.teacher.success(res)
          }
        })
        .catch(err => {
          if (this.active === 0) {
            this.$refs.student.failure()
          } else if (this.active === 1) {
            this.$refs.teacher.failure()
          }
        })
    },
    goToExpertDetails(info) {
      this.$router.push({
        path: '/expertDetails/' + info.teaid,
        query: {
          expertUserId: info.userid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.attention_page {
  @include isTop;
  .attention {
    width: 90%;
    height: 23.733vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attention_left {
      @include wh(15.733vw, 15.733vw);
      @include borderRadius(50%);
      background-color: #c6c8cb;
      overflow: hidden;
      box-shadow: $box-shadow;
      img {
        @include imgCover;
      }
    }
    .attention_right {
      height: 23.733vw;
      width: 71.6vw;
      border-bottom: 0.133vw solid #edeff2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .attention_right_span {
        color: #303133;
      }
      .attention_right_daoshi {
        color: #bfc2cc;
        font-size: 3.6vw;
      }
    }
  }
}
</style>
