<template>
  <div class="ask_page">
    <topheader />
    <div class="ask_home">
      <div class="ask_home_flex">
        <div class="ask_home_flex_left">
          <img v-if="info.headurl" :src="info.headurl" alt />
          <img v-else src="@/assets/images/head_prole.png" alt />
        </div>
        <div style="display: flex;">
          <div>
            <div class="teacher_name">{{ info.name }}</div>
            <div :class="`${details ? 'people-show' : 'people'}`">{{ info.describe }}</div>
          </div>
          <div
            style="display: flex;flex-direction: column;justify-content: space-between;margin-left: 8vw"
          >
            <div
              :class="{
                ask_home_flex_right: !info.isfollow,
                ask_home_flex_rights: info.isfollow,
              }"
              @click="changeFocus"
              v-text="info.isfollow ? '已收听' : '收听'"
            ></div>
            <div
              v-if="isShowDetails"
              style="color: #ff8c40;font-size: 3.467vw;"
              @click="showDetails"
            >{{ details ? "收起" : "详情" }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ask_home_clum">
      <van-tabs
        v-model="active"
        color="#247dff"
        line-width="5.6vw"
        animated
        sticky
        offset-top="100"
      >
        <van-tab title="简介">
          <div class="teacher_introduction">
            <p v-html="info.content"></p>
          </div>
        </van-tab>
        <van-tab title="听答">
          <QuestionList :expertId="expertId" :teacher="info" ref="answer" />
        </van-tab>
        <van-tab title="听课">
          <CourseList :expertId="expertId" :expertName="info.name" />
        </van-tab>
      </van-tabs>
    </div>
    <div class="btn" :class="{hide: active === 0}" v-if="active === 1">
      <span class="tiwen" @click="goToQuiz">我来提问</span>
    </div>
  </div>
</template>

<script>
import CourseList from './widget/courseList'
import QuestionList from './widget/questionList'
import { getOneTeacherinfo, changeFocus } from '../../api/questionsAndAnswers'

export default {
  components: {
    CourseList,
    QuestionList
  },
  data() {
    return {
      expertId: this.$route.params.expertId, // 专家Id
      expertUserId: this.$route.query.expertUserId, // 专家的用户Id
      userId: this.$store.state.user.userId, // 用户Id
      info: {}, // 专家信息
      coursevalue: '',
      active: 0,
      details: false, // 显示详情
      brief: '@todo' //导师简介
    }
  },
  computed: {
    isShowDetails() {
      if (this.info.content) {
        if (this.info.content.length > 40) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    init() {
      getOneTeacherinfo(this.userId, this.expertId).then(res => {
        this.info = res.data[0]
      })
    },
    //详情
    showDetails() {
      this.details = !this.details
    },
    ReplyDetails() {
      this.$router.push({
        path: '/replyList'
      })
    },
    // ->提问
    goToQuiz() {
      this.$router.push({
        path: '/quiz',
        query: {
          expertId: this.expertId,
          expertName: this.info.name
        }
      })
    },
    // 加注/取关
    changeFocus() {
      let type = this.info.isfollow ? 0 : 1
      changeFocus(this.userId, this.expertUserId, type).then(res => {
        this.info.isfollow = !this.info.isfollow
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ask_page {
  width: 100%;
  height: 100vh;
  background-color: #f5f7fa;
  @include isTop;
  /deep/.teacher_introduction {
    width: calc(100% - 6.4vw);
    @include borderRadius(0.667vw);
    @include font(3.2vw, 4.8vw);
    padding: 3.2vw 2.4vw;
    background: #fff;
    color: $font-gray;
    margin: 4.4vw auto;
    box-sizing: border-box;
    overflow-wrap: break-word;
    // display: flex;
    p {
      // flex: 1;
      // text-indent: 2em;
      img {
        width: 100% !important;
      }
    }
    div {
      // width: 26.667vw;
      img {
        @include imgCover;
      }
    }
  }
  .ask_home_clum {
    /deep/.van-tabs {
      div {
        .van-sticky--fixed {
          top: 13.333vw !important;
        }
      }
    }
  }
}

.people {
  width: 50vw;
  font-size: 3.467vw;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.people-show {
  width: 50vw;
  font-size: 3.467vw;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.teacher_name {
  font-size: 3.733vw;
  color: #303133;
}
.ask_home_flex_right {
  width: 16vw;
  height: 8vw;
  background-color: #ffffff;
  border-radius: 4vw;
  border: solid 0.267vw #247dff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.467vw;
  color: #247dff;
}
.ask_home_flex_rights {
  width: 16vw;
  height: 8vw;
  background-color: #f5f7fa;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.467vw;
  color: #303133;
}
.ask_home_flex_left {
  width: 15.467vw;
  height: 15.467vw;
  background-color: #c6c8cb;
  border-radius: 100%;
}
.ask_home_flex_left > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.ask_home_flex {
  width: 93.733vw;
  margin: 5vw auto;
  display: flex;
  justify-content: space-between;
}
.ask_home {
  width: 100vw;
  display: flex;
  align-items: center;
  margin-top: 2vw;
  padding-bottom: 2vw;
  background-color: #ffffff;
}
.btn {
  width: 100%;
  height: 15.2vw;
  position: fixed;
  bottom: 0;
  transition: all 300ms;
  background: #fff;
  border-top: 1px solid $border-light;
  .tiwen {
    width: 92vw;
    height: 12.5vw;
    background-color: #247dff;
    border-radius: 6.267vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8vw;
    letter-spacing: 0.267vw;
    color: #ffffff;
    @include center;
  }
}
.hide {
  transform: translateX(100vw);
}
</style>
