<!-- 资料：班圈详情>>公告下面有个班圈资料 -->
<template>
  <div class="page">
    <topheader @beforeBack="beforeBack" />
    <Material :classId="classId" ref="material"/>
  </div>
</template>

<script>
import Material from '@/components/discover/widget/material'
import eventBus from '@/util/eventBus'
export default {
  data() {
    return {
      classId: this.$route.params.classId,
      list: []
    }
  },
  components: { Material },
  methods: {
    beforeBack() {
      eventBus.$emit('back2CourseHome', {
        active: this.$route.query.active,
        curLessonIdx: this.$route.query.curLessonIdx
      })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
</style>
