<template>
  <div class="page">
    <div class="succeed_img">
      <img src="@/assets/images/succend.png" alt />
    </div>
    <div class="reminder">
      恭喜你<span v-text="type == '1' ? '报名' : '打赏'"></span>成功
    </div>
    <div class="beizhu">课程名称: {{className}}</div>

    <template v-if="type == '1'">
      <!-- <router-link :to="'/classDetails/' + classId"> -->
        <div class="check" @click="goToClassDetails">查看班圈主页</div>
      <!-- </router-link> -->
      <div class="back" @click="backToMy">返回个人中心</div>
    </template>

    <template v-else-if="type == '2'">
      <div class="check" @click="back">返回</div>
      <div class="back" @click="goToBill">账单明细</div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classId: "", //班圈id
      className: "",//班圈名称
      type: this.$route.params.type //展示类型 1-报名 2-打赏
    };
  },
  methods: {
    backToMy() {
      // this.$router.go(-3);
      //@todo 个人中心怎么调到支付页面??? 这点跟路由跳转有关
      this.$router.push("/my");
    },
    back() {
      this.$router.go(-1);
    },
    // 支付成功，返回班圈简介页
    goToClassDetails() {
      this.$router.go(-3);
    },
    goToBill() {
      this.$toast("@todo");
    }
  },
  mounted() {
    if (this.type == "1") {
      this.classId = this.$route.query.classId;
      this.className = this.$route.query.className;
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  .succeed_img {
    width: 41.067vw;
    height: 38.933vw;
    margin: 20vw auto;
    margin-top: 20vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .reminder {
    font-size: 5.333vw;
    line-height: 6.133vw;
    color: #303133;
    text-align: center;
  }
  .beizhu {
    font-size: 3.733vw;
    color: #909399;
    text-align: center;
    padding-top: 2vw;
  }

  .check {
    width: 92vw;
    height: 12.533vw;
    background-color: #247dff;
    border-radius: 1.333vw;
    margin: 6.667vw auto;
    font-size: 4.8vw;
    letter-spacing: 0.267vw;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back {
    font-size: 4.8vw;
    width: 92vw;
    height: 12.533vw;
    letter-spacing: 0.267vw;
    color: #303133;
    text-align: center;
    border: solid 0.233vw #edeff2;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
