<template>
  <div>
    <van-nav-bar
      left-text=" "
      right-text="确认修改"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="onClickRight"
    />
    <div class="confirm_title">
      <van-field v-model="title" placeholder="输入作业标题" />
    </div>
    <div class="confirm_details">
      <textarea placeholder="对问题补充说明" v-model="value"></textarea>
    </div>
  </div>
</template>

<script>
import {modifyWork} from "../../api/my";

export default {
  data() {
    return {
      id:this.$route.params.id,
      title: this.$route.query.title,
      value: this.$route.query.value,
    };
  },
  methods: {
    // 确认修改
    onClickRight() {
      modifyWork(this.id,this.title,this.value).then(() => {
        this.$toast('修改成功');
        setTimeout(() => {
          this.$toast().clear();
          this.$router.go(-1);
        },1500)
      }).catch(() => {this.$toast('修改失败');})
    }
  }
};
</script>

<style scoped>
textarea {
  width: 93.6vw;
  height: 50vw;
  border: none;
}
textarea::-webkit-input-placeholder {
  font-size: 4.267vw;
  color: #bfc2cc;
}
.confirm_details {
  margin-top: 4vw;
  margin-left: 3.467vw;
}
.van-cell {
  font-size: 5.067vw;
  color: #909399;
  padding: 0;
}
.confirm_title {
  width: 93.6vw;
  height: 15.467vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
  display: flex;
  align-items: center;
}
</style>
