<template>
  <div class="page">
    <topheader/>
    <div class="succeed_img">
      <img src="@/assets/images/succend.png" alt />
    </div>
    <div class="reminder">评分成功</div>
    <!-- <router-link to="/homework_result"> -->
      <div class="check" @click="$router.back()">查看我的评分</div>
    <!-- </router-link> -->
    <div class="back" @click="back">返回{{text}}列表</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: this.$route.params.type
    };
  },
  computed:{
    text() {
      return this.type === '1' ? '作业' : '考试';
    }
  },
  methods: {
    back() {
      // this.$router.go(-4);
      this.$router.go(-3);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
.back {
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #303133;
  text-align: center;
}
.check {
  width: 92vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 1.333vw;
  margin: 6.667vw auto;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reminder {
  font-size: 5.333vw;
  line-height: 6.133vw;
  color: #303133;
  text-align: center;
}
.succeed_img img {
  display: block;
  width: 100%;
  height: 100%;
}
.succeed_img {
  width: 41.067vw;
  height: 38.933vw;
  margin: 20vw auto;
  margin-top: 20vw;
}
</style>