<template>
  <!-- 作废处理 -->
  <div class="page">
    <topheader />
    <!-- 回答列表 -->
    <div class="answer_list" v-for="(item, idx) in list" :key="idx">
      <!-- 头部 -->
      <div class="answer_header">
        <div class="teacher_avatar">
          <img :src="item.teacher_headimg" alt />
        </div>
        <div class="teacher_name" v-text="item.teacher_name"></div>
        <!-- 疑问: 时间逻辑是否要做成 跟朋友圈效果一样??? -->
        <div class="teacher_time" v-text="item.answer_time"></div>
      </div>
      <!-- 回复内容 -->
      <div class="answer_desc">
        <ul class="answer_desc_ul">
          <li v-for="(ans, i) in item.teacher_answer" :key="'answer' + i" class="answer_desc_li">
            <template v-if="ans.type === 1">
              <!-- 语音 -->
              <div class="answer" v-text="`回复 ${item.to}:`" v-if="i === 0"></div>

              <div class="voice" @touchstart="playRecord" @touchend="stopRecord">
                <span>
                  {{ans.len}}
                  <i>s</i>
                </span>
                <van-icon name="music" />
              </div>
            </template>
            <template v-if="ans.type === 2">
              <!-- 文字 -->
              <div
                class="word"
                :class="{bgWhite: i===0}"
                v-text="`${i===0 ? `回复 ${item.to}: `: ''}${ans.desc}`"
              ></div>
            </template>
          </li>
        </ul>
      </div>
      <!-- 提问内容 -->
      <div class="question_box" v-text="`${item.to}的提问: ${item.question}`"></div>
    </div>
  </div>
</template>

<script>
const mockData = [
  {
    teacher_name: '钟智老师',
    teacher_headimg:
      'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585796221680&di=4abbe756dd93096f14a6d2e88a29ca0c&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201603%2F20%2F20160320153219_B4RrP.jpeg',
    answer_time: Date.now() - 32000,
    question:
      '时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘',
    teacher_answer: [
      {
        type: 2, // 1-语音 2-文字
        url: '',
        desc:
          '苏东坡福克斯打破富士康的富婆看看迫使对方,什么的,水平的佛山,的破发,吗破开瓶费么物品媒婆分为满分破名为没人陪我',
        len: ''
      }
    ],
    to: '陆霏霏'
  },
  {
    teacher_name: '钟智老师',
    teacher_headimg:
      'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585796221680&di=4abbe756dd93096f14a6d2e88a29ca0c&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201603%2F20%2F20160320153219_B4RrP.jpeg',
    answer_time: Date.now() - 32000,
    question:
      '时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘',
    teacher_answer: [
      {
        type: 1, // 1-语音 2-文字
        url:
          'https://m10.music.126.net/20200401183835/daa77eb82863bab0a317588142e1ebd5/yyaac/obj/wonDkMOGw6XDiTHCmMOi/1946196570/b846/6caf/3b33/f9500b0acf817e1dc83c03a8613c685e.m4a',
        desc: '',
        len: '32'
      }
    ],
    to: '陆霏霏'
  },
  {
    teacher_name: '钟智老师',
    teacher_headimg:
      'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585796221680&di=4abbe756dd93096f14a6d2e88a29ca0c&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201603%2F20%2F20160320153219_B4RrP.jpeg',
    answer_time: Date.now() - 32000,
    question:
      '时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘',
    teacher_answer: [
      {
        type: 1, // 1-语音 2-文字
        url:
          'https://m10.music.126.net/20200401183835/daa77eb82863bab0a317588142e1ebd5/yyaac/obj/wonDkMOGw6XDiTHCmMOi/1946196570/b846/6caf/3b33/f9500b0acf817e1dc83c03a8613c685e.m4a',
        desc: '',
        len: '32'
      },
      {
        type: 2, // 1-语音 2-文字
        url: '',
        desc:
          '苏东坡福克斯打破富士康的富婆看看迫使对方,什么的,水平的佛山,的破发,吗破开瓶费么物品媒婆分为满分破名为没人陪我',
        len: ''
      }
    ],
    to: '陆霏霏'
  },
  {
    teacher_name: '钟智老师',
    teacher_headimg:
      'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585796221680&di=4abbe756dd93096f14a6d2e88a29ca0c&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201603%2F20%2F20160320153219_B4RrP.jpeg',
    answer_time: Date.now() - 32000,
    question:
      '时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘时间大宋i大沙漠i按时的的农舍大阿宋帝阿萨是大盘',
    teacher_answer: [
      {
        type: 2, // 1-语音 2-文字
        url: '',
        desc:
          '苏东坡福克斯打破富士康的富婆看看迫使对方,什么的,水平的佛山,的破发,吗破开瓶费么物品媒婆分为满分破名为没人陪我',
        len: ''
      },
      {
        type: 1, // 1-语音 2-文字
        url:
          'https://m10.music.126.net/20200401183835/daa77eb82863bab0a317588142e1ebd5/yyaac/obj/wonDkMOGw6XDiTHCmMOi/1946196570/b846/6caf/3b33/f9500b0acf817e1dc83c03a8613c685e.m4a',
        desc: '',
        len: '32'
      }
    ],
    to: '陆霏霏'
  }
]
export default {
  data() {
    return {
      list: []
    }
  },
  methods: {
    playRecord() {
      console.error('录音播放功能@todo')
      this.$toast('录音播放功能@todo')
    },
    stopRecord() {
      console.error('录音停止功能@todo')
      this.$toast('录音停止功能@todo')
    }
  },
  mounted() {
    window.T = {}
    T.vm = this
    this.list = mockData
  }
}
</script>
<style lang="scss" scoped>
$bgc: #f5f7fa;
@mixin font22 {
  font-size: 2.933vw;
}
@mixin boxlist {
  padding-left: 2.667vw;
  padding-right: 3.333vw;
  background: $bgc;
}
.page {
  @include isTop;

  //
  .answer_list {
    padding: 2.667vw 3.2vw 5.333vw 3.2vw;
    margin-top: 2.667vw;
    position: relative;
    .answer_header {
      font-size: 3.2vw;
      display: flex;
      align-items: center;
      .teacher_avatar {
        @include wh(8.667vw, 8.667vw);
        @include borderRadius(50%);
        overflow: hidden;
        img {
          @include wh(100%, 100%);
          object-fit: cover;
        }
      }
      .teacher_name {
        margin-left: 2.4vw;
        color: #474f66;
        flex: 1;
      }
      .teacher_time {
        color: $font-gray;
      }
    }
    .answer_desc {
      padding-left: 11.067vw;
      .answer_desc_ul {
        .answer_desc_li {
          margin-top: 1.333vw;
          .answer,
          .word {
            //第一个语音回复的要+ 回复who
            margin-top: 1.067vw;
            @include font(3.467vw, 6.133vw);
          }
          // .answer {width: 30%;}
          .voice {
            width: 30%;
            @include boxlist;
            height: 10.4vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include borderRadius(1.333vw);
            min-width: 10.667vw;
            margin-top: 0.667vw;
            border-bottom-left-radius: 0;
            span {
              @include font22;
              i {
                font-size: 2.133vw;
                font-style: normal;
              }
            }
            .van-icon {
            }
          }
          .word {
            background: $bgc;
            padding: 2.133vw 2.667vw;
            @include font(3.733vw, 6.4vw);
          }
          .bgWhite {
            background: #fff;
          }
        }
      }
    }
    .question_box {
      @include boxlist;
      margin-top: 1.333vw;
      margin-left: 11.067vw;
      @include font22;
      color: #606266;
      @include font(3.467vw, 6.133vw);
      padding: 2.133vw 2.667vw;
    }
  }
  .answer_list::after {
    content: '';
    display: block;
    width: calc(100% - 6.4vw);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $font-light;
  }
}
</style>