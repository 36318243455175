<template>
  <div class="main">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="加载失败，点击重新加载"
    >
      <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
        <div class="practiceTests" v-for="(item, i) in list" :key="'practiceTest' + i">
          <div class="bigbox">
            <div class="tobecompleted" @click="goToQuestionDetail(item)">
              <div class="tobecompleted_title">
                <span class="tag" v-text="item.coursename">专项突破 巩固夯基</span>
                <span class="deadline" v-if="item.ed">
                  {{
                  item.ed | timeStamp | dateFormat('yyyy-MM-dd hh:mm')
                  }}
                  截止答题
                </span>
              </div>

              <div class="tobecompleted_msg">
                <div class="tobecompleted_msg_title">
                  <span>{{ item.title }}</span>
                  <span>{{ item.panum }}人已练习</span>
                </div>
                <div class="tobecompleted_msg_box">
                  <div class="tobecompleted_msg_left">
                    <p class="tobecompleted_msg_headline">
                      <!-- <span v-text="item.content">多加联系,知识点轻松牢记</span> -->
                    </p>
                    <div
                      class="tobecompleted_teacher"
                    >{{ item.classownername2 || item.classownername1 }}导师</div>
                  </div>

                  <div class="tobecompleted_msg_right">
                    <template>
                      <div v-if="item.asnum !== item.qscount && !item.isend" class="peugeot">待完成</div>
                      <div
                        v-if="item.asnum == item.qscount && !item.isend"
                        class="peugeot peugeot_submit"
                      >待提交</div>
                      <div v-else-if="item.isend" class="peugeot peugeot_on">已完成</div>
                    </template>
                    <div class="right_peugeot">
                      <span class="peugeot_star">{{ item.asnum }}</span>
                      <span class="peugeot_star_two">/{{ item.qscount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </van-list>
  </div>
</template>

<script>
import { createHWkRecord, createExamRecord } from '@/api/classRing'

export default {
  name: 'testRecordToBeCompleted',
  props: {
    type: String //调用渠道（homework-作业 exam-考试）
  },
  data() {
    return {
      list: [],
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      userId: this.$store.state.user.userId
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
      this.onLoad()
    },
    // 父级设置子级的list
    setList(data) {
      this.list = data
    },
    // 调用父级方法获取数据
    onLoad() {
      this.$emit('onload')
    },
    // 父级调用子级成功回调
    success() {
      this.loading = false
      this.pullRefresh = false
      this.finished = true
    },
    // 父级调用子级失败回调
    failure() {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // ->测试练习做题页面
    goToQuestionDetail(info) {
      const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))
      let id = undefined //作业/考试 id
      switch (this.type) {
        case 'homework':
          id = info.hwplanid
          break //作业id
        case 'exam':
          id = info.explanid
          break //考试id
      }
      console.log('题目信息=', JSON.parse(JSON.stringify(info)))

      let params = {
        id: id, // 作业/考试 id
        recordid: info.recordid, //记录id(关系id)
        lastqsid: info.lastqsid, //上次提交答题id
        total: info.qscount, //试题总数
        title: info.title, //标题
        isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
        overdue: overdue ? 1 : 0, //是否过期
        headmasterId: info.classowneruserid1, //班主任userid
        supervisorId: info.classowneruserid2 //导师userid
      }
      // 逾期
      if (overdue) {
        if (info.lastqsid == 0) params.lastqsid = info.firsitdetailid
        if (this.type == 'homework') {
          params.beginTime = info.bd //作业开始时间
          params.endTime = info.ed //作业结束时间
          params.lessonName = info.coursename
          if (info.isend) params.lastqsid = info.firsitdetailid
          this.$router.push({ path: '/homeworkQuestionDetail2', query: params })
        } else if (this.type === 'exam') {
          if (info.isend) params.lastqsid = info.firsitdetailid
          this.$router.push({ path: '/examQuestionDetail2', query: params })
        }
      } else {
        // 有效期内
        if (this.type === 'homework') {
          params.beginTime = info.bd //作业开始时间
          params.endTime = info.ed //作业结束时间
          params.lessonName = info.coursename
          if (info.recordid && info.lastqsid !== 0) {
            // 有创建答题记录的
            this.$router.push({
              path: '/homeworkQuestionDetail',
              query: params
            })
          } else {
            // 没创建答题记录
            createHWkRecord(id, info.stuid).then(res => {
              this.$router.push({
                path: '/homeworkQuestionDetail',
                query: Object.assign(params, {
                  recordid: res.recordid, //记录id(关系id)
                  lastqsid: res.lastqsid //上次答题id
                })
              })
            })
          }
        } else if (this.type === 'exam') {
          if (info.recordid && info.lastqsid !== 0) {
            // 有创建答题记录的
            this.$router.push({ path: '/examQuestionDetail', query: params })
          } else {
            // 没创建答题记录
            createExamRecord(id, info.stuid).then(res => {
              this.$router.push({
                path: '/examQuestionDetail',
                query: Object.assign(params, {
                  recordid: res.recordid, //记录id(关系id)
                  lastqsid: res.lastqsid //上次答题id
                })
              })
            })
          }
        }
      }
    }
  },
  mounted() {
    this.onRefresh()
  }
}
</script>

<style lang="scss" scoped>
.van-pull-refresh {
  box-sizing: border-box;
  padding-bottom: 1px;
}
.practiceTests {
  width: 93.067vw;
  margin: 0 auto;
  .bigbox {
    .tobecompleted {
      width: 100%;
      box-sizing: border-box;
      height: 31.2vw;
      background-color: #ffffff;
      border-radius: 1vw;
      border: solid 1px $font-light;
      margin: 2.667vw auto;
      // 作业/考试 标题
      .tobecompleted_title {
        font-size: 2.933vw;
        padding-right: 3vw;
        color: #303133;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tag {
          min-width: 30.933vw;
          max-width: 50%;
          @include dot;
          height: 5.333vw;
          border-radius: 0.8vw 0vw 2.667vw 0vw;
          background-color: #ffcd05;
          text-align: center;
          line-height: 5.333vw;
        }
        .deadline {
          color: $font-gray;
        }
      }
      // 大盒子
      .tobecompleted_msg {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 0 3vw;
        padding-bottom: 4vw;
        box-sizing: border-box;
        height: calc(100% - 5.333vw);
        // height: 31.2vw - 5.333vw;

        .tobecompleted_msg_title {
          font-size: 4.533vw;
          line-height: 6.533vw;
          color: #303133;
          padding-top: 3vw;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          span:first-child {
            flex: 1;
          }
          span:last-child {
            float: right;
            display: block;
            color: #909399;
            font-size: 3.2vw;
          }
        }

        .tobecompleted_msg_box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .tobecompleted_msg_left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tobecompleted_msg_headline {
              font-size: 3.2vw;
              color: #303133;
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .tobecompleted_teacher {
              width: 21.333vw;
              height: 5.333vw;
              line-height: 5.6vw;
              text-align: center;
              background-color: #ffffff;
              border-radius: 2.667vw;
              border: solid 0.267vw #bfc2cc;
              font-size: 2.933vw;
              color: #909399;
              @include dot;
            }
          }
          .tobecompleted_msg_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 100%;
            .peugeot {
              width: 13.333vw;
              height: 5.333vw;
              background-image: linear-gradient(90deg, #37c1ff 0%, #137bfe 100%),
                linear-gradient(#a2a6a7, #a2a6a7);
              background-blend-mode: normal, normal;
              border-radius: 0vw 2.667vw 0vw 2.667vw;
              font-size: 2.933vw;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .peugeot_on {
              background-image: linear-gradient(
                90deg,
                #17b3c1 0%,
                #47d6b6 100%
              );
            }
            .peugeot_submit {
              background-image: linear-gradient(
                90deg,
                #c55252 0%,
                #f00a0a 100%
              );
            }
            .right_peugeot {
              margin-left: 11vw;
              .peugeot_star {
                width: 8.933vw;
                height: 4.533vw;
                font-size: 5.6vw;
                color: #247dff;
              }
              .peugeot_star_two {
                font-size: 3.467vw;
                color: #303133;
              }
            }
          }
        }
      }
    }
  }
}
</style>
