<template>
  <!-- 打赏 -->
  <div class="payment">
    <div class="course_gratuity">
      <div class="course_gratuity_header">本课程老师</div>
      <van-card
        v-for="(teach, index) in teachers"
        :key="'teacher' + index"
        :title="teach.name"
        :desc="teach.role"
        :thumb="teach.thumb"
        :class="{ active: gratuityTeachers.includes(teach.userId) }"
        @click="chooseTeacher(teach)"
      >
        <template #tags>
          <van-icon v-if="gratuityTeachers.includes(teach.userId)" name="passed" color="'#237efe'"></van-icon>
          <div class="circle" v-else>
            <i></i>
          </div>
        </template>
      </van-card>
    </div>
    <div class="divider"></div>
    <!-- 支付 -->
    <div class="pay">
      <div class="payment-header">
        <span>打赏金额</span>
        <span>注：每位老师一次性打赏最多200元</span>
      </div>
      <ul class="payment-connent">
        <li
          v-for="(item, index) in priceGroup"
          :key="index"
          :class="{ active: item == price }"
          @click="clickPrice(item)"
        >
          <span>{{ item }}元</span>
        </li>
        <li :class="{ active: customize }" @click="showCustomize">
          <span>自定义</span>
        </li>
        <transition name="fade">
          <div class="money_number" v-if="customize">
            <span class="symbol">￥</span>
            <span class="money_number_yur">
              <van-field
                v-model="priceNumber"
                onfocus="this.blur()"
                placeholder="0.00"
                @touchstart.stop="showKeyboard = true"
              />
              <van-number-keyboard
                theme="custom"
                extra-key="."
                :show="showKeyboard"
                close-button-text="完成"
                @blur="showKeyboard = false"
                @input="onClickInputKeyBoard"
                @delete="onDeleteKeyBoard"
              ></van-number-keyboard>
            </span>
          </div>
        </transition>
      </ul>
      <div class="payment-totel">
        <span>当前共打赏{{ total }}元</span>
      </div>
      <div class="payment-submit">
        <div
          class="withdraw"
          :class="{
            opacity5: !gratuity || !gratuityTeachers.length,
          }"
          @click="pay"
        >微信支付</div>
      </div>
      <div class="holder" v-show="showKeyboard"></div>
    </div>
  </div>
</template>

<script>
import { wxPayOrderDS } from '@/api/pay'
export default {
  props: {
    lesson: Object //课程信息
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      gratuityTeachers: [], //指定打赏的老师列表
      priceGroup: [1, 3, 5, 8, 18, 28, 38], //预置打赏金额
      price: 0, //打赏金额
      priceNumber: '', //自定义打赏金额
      customize: false, //是否选中自定义打赏金额
      showKeyboard: false //是否显示数字键盘
    }
  },
  watch: {
    priceNumber(n, o) {
      if (n) this.price = 0 //清除选中的打赏金额
    },
    showKeyboard(n) {
      if (n) {
        setTimeout(() => {
          const popup = document.getElementsByClassName('van-popup')[0]
          popup.scrollTop = 1000
        }, 300)
      }
    }
  },
  computed: {
    teachers() {
      let res = []
      if (this.lesson) {
        for (let key in this.lesson) {
          switch (key) {
            case 'classowneruserid1': {
              res.push({
                name: this.lesson['classownername1'],
                expertId: this.lesson['classownerid1'],
                userId: this.lesson['classowneruserid1'],
                role: '班主任',
                thumb: this.lesson['classownerheadurl'] || 'xxx'
              })
              break
            }
            case 'classowneruserid2': {
              if (
                this.lesson.classowneruserid2 &&
                this.lesson.classowneruserid2 !== this.lesson.classowneruserid1
              ) {
                res.push({
                  name: this.lesson['classownername2'],
                  expertId: this.lesson['classownerid2'],
                  userId: this.lesson['classowneruserid2'],
                  role: '导师',
                  thumb: this.lesson['classownerheadur2'] || 'xxx'
                })
              }
              break
            }
            case 'classowneruserid3': {
              if (
                this.lesson.classowneruserid3 &&
                this.lesson.classowneruserid3 !==
                  this.lesson.classowneruserid1 &&
                this.lesson.classowneruserid3 !== this.lesson.classowneruserid2
              ) {
                res.push({
                  name: this.lesson['classownername3'],
                  expertId: this.lesson['classownerid3'],
                  userId: this.lesson['classowneruserid3'],
                  role: '辅导员',
                  thumb: this.lesson['classownerheadur3'] || 'xxx'
                })
              }
              break
            }
          }
        }
      }
      return res
    },
    gratuity() {
      return this.price || this.priceNumber
    },
    total() {
      return (this.gratuity * this.gratuityTeachers.length)
        .toString()
        .match(/^\d*(\.?\d{0,2})/g)[0]
    }
  },
  methods: {
    // 参考：https://www.jianshu.com/p/8d71c6f14ee4
    /**
     * 模拟键盘点击数字时触发的函数
     */
    onClickInputKeyBoard(value) {
      this.priceNumber += value
      // 限制只能输入一个小数点及两位小数
      this.priceNumber =
        this.priceNumber.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null
      if (this.priceNumber > 200) {
        this.priceNumber = this.priceNumber.slice(0, -1)
      }
    },
    /**
     * 模拟键盘删除时触发的函数
     */
    onDeleteKeyBoard() {
      let flag = true
      if (flag) {
        // 删除掉字符串最后一个
        this.priceNumber = this.priceNumber.substring(
          0,
          this.priceNumber.length - 1
        )
        if (this.priceNumber.length === 0) {
          flag = false
          return false
        }
      }
    },
    // 选择要打赏的导师
    chooseTeacher(teacher) {
      if (this.gratuityTeachers.includes(teacher.userId)) {
        let list = this.gratuityTeachers.filter(item => item != teacher.userId)
        this.gratuityTeachers = list
      } else {
        this.gratuityTeachers.push(teacher.userId)
      }
    },
    // 选择打赏金额
    clickPrice(item) {
      this.customize = false
      this.priceNumber = null
      if (this.price != item) {
        this.price = item
      } else {
        this.price = undefined
      }
    },
    // 选中自定义金额
    showCustomize() {
      this.customize = !this.customize
      this.price = 0
    },
    // 重置
    reset() {
      this.price = 0
      this.priceNumber = null
      this.customize = false
      this.gratuityTeachers = []
    },
    // 支付
    pay() {
      if (this.gratuityTeachers.length === 0) {
        this.$toast('请选择导师')
        return
      } else if (!this.gratuity) {
        this.$toast('请输入金额')
        return
      } else {
        const params = {
          classid: this.lesson.classid,
          courseid: this.lesson.autoid, // 课程id
          userid: this.userId,
          teastr: this.gratuityTeachers.join(','),
          unitprice: this.gratuity
        }
        // 打赏支付接口
        wxPayOrderDS(params).then(res => {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {
              appId: res.appId, // 公众号名称，由商户传入
              timeStamp: res.timeStamp, // 时间戳，自1970年以来的秒数
              nonceStr: res.nonceStr, // 随机串
              package: res.packageValue,
              paySign: res.paySign, // 微信签名
              signType: 'MD5' // 微信签名方式
            },
            res => {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                this.$emit('rewarded', {
                  gratuityTeachers: this.gratuityTeachers,
                  price: this.gratuity
                })
                this.reset()
              } else {
                //支付失败
                this.$toast('支付失败')
              }
            }
          )
        })
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.payment {
  // 打赏 - 界面
  .course_gratuity {
    .course_gratuity_header {
      padding: 1em 7.2vw;
    }
    .van-card {
      margin-top: 0;
      padding: 3vw 7.2vw;
      background-color: #fff;
      .van-card__header {
        .van-card__thumb {
          width: 15vw;
          height: 15vw;
          border-radius: 50%;
          overflow: hidden;
        }
        .van-card__content {
          min-height: auto;
          border-bottom: 1px solid rgba(104, 104, 104, 0.1);
          div {
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 4vw;
            .van-card__title {
              margin-right: 2vw;
            }
            .van-card__desc {
              font-size: 3.5vw;
            }
            .van-icon {
              flex: 1;
              text-align: right;
              font-size: 5vw;
              padding-right: 2vw;
            }
            // 未选中时的圈圈
            .circle {
              flex: 1;
              padding-right: 2vw;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              i {
                @include wh(3.8vw, 3.8vw);
                margin-right: 1px;
                display: inline-block;
                border-radius: 50%;
                border: 1px solid $font-light;
              }
            }
          }
        }
      }
    }
    .van-card:last-child {
      .van-card__header {
        .van-card__content {
          // border: 0 none;
        }
      }
    }
    .van-card.active {
      color: $active_color;
      .van-card__desc {
        color: $active_color;
      }
    }
  }
  .divider {
    //分割线
    width: 100%;
    height: 1vw;
    background: #f5f8fa;
  }
  // 支付
  .pay {
    box-sizing: border-box;
    .payment-header {
      padding: 3.2vw 7.2vw;
      font-size: 4.2vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:nth-child(2) {
        color: $font-gray;
        font-size: 3.467vw;
      }
    }
    .payment-connent {
      padding: 0 7.2vw;
      display: flex;
      flex-wrap: wrap;
      li {
        box-sizing: border-box;
        width: 25%;
        margin-bottom: 1.333vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.333vw;
        span {
          @include borderRadius(1.333vw);
          border: 1px solid $border-gray;
          text-align: center;
          padding: 1.333vw 0;
          width: 17.333vw;
        }
      }
      .active {
        color: $active-color;
        span {
          border-color: $active-color;
        }
      }
      .money_number {
        margin-top: 3.667vw;
        width: 100%;
        border-bottom: 1px solid $border-gray;
        display: flex;
        span:first-child {
          padding-right: 1.333vw;
          margin-top: 2vw;
        }
        .symbol {
          font-size: 6.133vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 3.733vw;
          color: #303133;
          margin-top: 3vw;
        }
        .money_number_yur {
          flex: 1;
          height: 12.667vw;
          font-size: 30px;
          padding-left: 10px;
          color: #333;
          /deep/.van-cell {
            .van-cell__value {
              .van-field__body {
                input {
                  font-size: 6.133vw;
                }
              }
            }
          }
        }
      }
      // 自定义打赏 动画
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
    }
    .payment-totel {
      padding: 1.333vw 7.2vw;
      font-size: 3.467vw;
      color: $font-gray;
      text-align: right;
    }
    .payment-submit {
      padding: 3vw 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .withdraw {
        width: 89.333vw;
        height: 12.533vw;
        background-color: #247dff;
        border-radius: 1.333vw;
        font-size: 4.8vw;
        letter-spacing: 0.267vw;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .opacity5 {
        color: $active-color;
        > span {
          border-color: $active-color;
        }
      }
    }
    .holder {
      // height: 28.8vw;
      height: 38vw;
    }
  }
}
</style>
