<template>
  <!-- 专家栏目 >>> 作业√ || 考试√  -->
  <div class="page">
    <topheader :title="titleText" />

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 简答题列表 -->
        <div class="question_box" v-for="(item, idx) in list" :key="idx" @click="goToDetail(item)">
          <div class="question_box_div">
            <div class="question_box_number">{{ (idx + 1) | serial }}</div>
            <div class="question_box_title">{{ item.title }}</div>
            <div class="question_box_newresponse">
              <span
                :class="{ dot: item.cancheckhwnum && type == '1' || item.cancheckexnum && type == '2' }"
                v-show="item.cancheckhwnum && type == '1' || item.cancheckexnum && type == '2' "
              >{{ item.cancheckhwnum || item.cancheckexnum }}条回答</span>
            </div>
          </div>
          <div class="question_box_div">
            <div class="question_box_number"></div>
            <div class="question_box_desc">{{ item.planname }}</div>
            <div class="question_box_newresponse"></div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getTeaCanCheckHWQSList, getTeaCanCheckEXQSList } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      lessonId: this.$route.query.lessonId,
      page: 1, // 当前页数
      type: this.$route.params.type, // 1-作业 2-考试
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  filters: {
    serial(i) {
      return i <= 9 ? `0${i}` : i
    }
  },
  computed: {
    titleText() {
      return this.type === '1' ? '作业简答题' : '考试简答题'
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      this.getData()
        .then(res => {
          this.list.push(...res.list)
          this.success(res)
        })
        .catch(this.failure)
    },
    success(res) {
      this.loading = false
      this.pullRefresh = false
      this.finished = true
    },
    failure(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    getData() {
      switch (this.type) {
        case '1': //作业
          return getTeaCanCheckHWQSList(this.lessonId, this.userId)
        case '2': //考试
          return getTeaCanCheckEXQSList(this.lessonId, this.userId)
      }
    },
    // 查看学生答题列表
    goToDetail(item) {
      this.$router.push({
        path: `/experts_answerList/${this.type}`,
        query: {
          planId: this.type == '1' ? item.hwplanid : item.explanid, //作业/考试 id
          questionId: item.questionid // 问题 id
        }
      })
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  .question_box {
    padding: 3.467vw 0 5.333vw 0;
    border-bottom: 1px solid $border-light;
    .question_box_div {
      display: flex;
      .question_box_number {
        width: 8.667vw;
        color: #bfc2cc;
      }
      .question_box_title {
        font-size: 4vw;
        flex: 1;
      }
      .question_box_desc {
        font-size: 3.2vw;
        flex: 1;
        color: $font-gray;
        @include dot;
      }
      .question_box_newresponse {
        font-size: 2.667vw;
        // color: $font-light;
        color: #bfc2cc;
        // width: 21vw;
        text-align: right;
        padding-left: 20px;
      }
      .dot {
        position: relative;
        display: inline-block;
      }
      .dot::after {
        content: '';
        display: block;
        @include wh(1.333vw, 1.333vw);
        @include borderRadius(50%);
        @include center;
        left: -2.667vw;
        background: $warn-color;
      }
    }
    .question_box_div:nth-child(2) {
      padding-top: 2.933vw;
    }
  }
}
</style>
