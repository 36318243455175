<template>
  <div class="My">
    <!-- 顶头部分 -->
    <div class="my_head">
      <div class="info_message">
        <div class="head_portrait">
          <!-- <img v-if="headImg" :src="headImg" alt />
          <img v-else src="@/assets/images/head_prole.png" alt />-->
          <Portrait :src="headImg" />
          <input
            v-if="isLogin"
            class="update-head-img"
            type="file"
            accept="image/*"
            @change="uploadHeadImg"
          />
        </div>
        <div class="register_time" v-if="info.createtime" @click="clickFn">
          <span>已注册{{ info.createtime | timeStamp | dimDD }}天</span>
          <img src="@/assets/images/resister.png" alt />
        </div>
        <div class="info_sed">
          <router-link to="/login" v-if="!isLogin">马上登录</router-link>
          <div>{{ name || tel }}</div>
        </div>
        <div class="figure">
          <div class="attention" @click="skip('answer')">
            <span class="number">{{isLogin ? info.questionCount : '--'}}</span>
            <span class="attention_size">问答</span>
          </div>
          <div class="attention" @click="skip('Classring')">
            <span class="number">{{isLogin ? info.classCount : '--'}}</span>
            <span class="attention_size">班圈</span>
          </div>
          <div class="attention" @click="skip('attention')">
            <span class="number">{{isLogin ? info.focusCount : '--'}}</span>
            <span class="attention_size">关注</span>
          </div>
          <div class="attention" @click="skip('fans')">
            <span class="number">{{isLogin ? info.fansCount : '--'}}</span>
            <span class="attention_size">粉丝</span>
          </div>
        </div>
      </div>
    </div>

    <cell-group :list="baseTools" />
    <cell-group :list="expertsTools" class="mt16" v-if="isExpert" />
    <cell-group :list="publicTools" class="mt16" />

    <!-- 注册&&登陆 -->
    <div class="register bton" v-if="!isLogin" @click="register">注册</div>
    <div class="Login_immediately bton" v-if="!isLogin" @click="login" style="margin-top: 0">立即登录</div>
    <div class="Login_immediately bton" v-if="isLogin" @click="logout">退出登录</div>
    <div class="bottom-placeholder"></div>
  </div>
</template>

<script>
import { updateUserInfo, getMyNum } from '../api/my'
import qiniuUpload from '../api/qiniuUpload'
import { pictureRotationCorrection } from '../api/image'
import Portrait from '@/components/extend/portrait'

export default {
  name: 'My',
  props: {
    msg: String
  },
  components: { Portrait },
  data() {
    return {
      baseTools: [
        {
          icon: require('@/assets/images/record.png'),
          label: '我的成长',
          route: '/myGrow',
          value: ''
        },
        {
          icon: require('@/assets/images/Mymoney.png'),
          label: '我的钱包',
          route: '/my_wallet',
          value: ''
        },
        {
          icon: require('@/assets/images/testNumber.png'),
          label: '我的推荐',
          route: '/recommendation',
          value: ''
        }
      ],
      expertsTools: [
        {
          icon: require('@/assets/images/experts.png'),
          label: '专家栏目',
          route: '/experts_tools',
          value: ''
        }
      ],
      publicTools: [
        {
          icon: require('@/assets/images/key.png'),
          label: '我的设置',
          route: '/setting_center',
          value: ''
        }
      ]
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    },
    userId() {
      return this.$store.getters.userId
    },
    name() {
      return this.$store.getters.name
    },
    headImg() {
      return this.$store.getters.headImg
    },
    tel() {
      return this.$store.getters.tel
    },
    isExpert() {
      return this.$store.getters.isExpert
    },
    info() {
      return this.$store.getters.personalCenterInfo
    } // 个人中心信息
  },
  filters: {
    // 计算相差天数
    dimDD(time) {
      const nowTime = parseInt(new Date().getTime() / 1000, 10)
      const diffTime = nowTime - time / 1000
      return parseInt(diffTime / (60 * 60 * 24), 10)
    }
  },
  methods: {
    init() {
      if (this.userId) {
        getMyNum(this.userId).then(res => {
          this.$store.dispatch('user/setPersonalHeaderInfo', {
            focusCount: res.follownum,
            fansCount: res.fansnum,
            classCount: res.classnum,
            questionCount: res.faqnum
          })
        })
      }
    },
    // 上传头像
    async uploadHeadImg(e) {
      let file = e.target.files[0]
      pictureRotationCorrection(
        file, //文件
        _file => {
          // 完成后回调
          qiniuUpload(_file, url => {
            updateUserInfo({
              type: 3,
              autoid: this.userId,
              headurl: url
            }).then(res => {
              // 保存数据
              this.$store
                .dispatch('user/loginByOpenId')
                .then(() => {
                  this.$toast('头像已更换')
                })
                .catch(err => {
                  console.error(err)
                  this.$toast('刷新用户信息失败,请手动刷新!')
                })
            })
          })
        }
      )
    },
    // 注册
    register() {
      this.$router.push('/register')
    },
    // 登录
    login() {
      this.$router.push({
        path: '/login'
      })
    },
    // 退出登录
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login?type=logout')
      })
    },
    // 页面跳转
    skip(str) {
      switch (str) {
        case 'answer':
          this.$router.push('answer')
          break
        case 'Classring':
          this.$router.push('Classring')
          break
        case 'attention':
          this.$router.push('attention')
          break
        case 'fans':
          this.$router.push('fans')
          break
      }
    },
    // 测试专用，用完请删
    clickFn() {
      // this.$router.push('/test')
      // location.href = location.origin + '/live.html'
      // location.href = 'https://jiebian.life/web/h5/github/recordapp.aspx'
      // this.$router.push({
      //   path: `/graduation/77`,
      //   query: {
      //     className: '假期留一个班圈给开发用哈'
      //   }
      // })
    }
  },
  mounted() {},
  activated() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.My {
  width: 100%;
  height: 100%;
  background-color: $bg-gray;
  // 顶头
  .my_head {
    width: 100%;
    height: 63.333vw;
    display: flex;
    background: url('../assets/images/headback.png');
    background-size: 100% 100%;
    .info_message {
      margin: 0 auto;
      margin-top: 18vw;
      width: 93.6vw;
      height: 45.6vw;
      background-color: #ffffff;
      box-shadow: 0vw 1.067vw 4vw 0vw rgba(237, 239, 242, 0.4);
      border-radius: 2.133vw;
      position: relative;
      .head_portrait {
        width: 20.8vw;
        height: 20.8vw;
        background-color: #ffffff;
        border: solid 1.067vw #ffffff;
        border-radius: 50%;
        position: absolute;
        top: -11.467vw;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .update-head-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
      .register_time {
        width: 26.667vw;
        height: 9.333vw;
        background-image: linear-gradient(90deg, #ffd016 0%, #ffb10b 100%),
          linear-gradient(#009944, #009944);
        background-blend-mode: normal, normal;
        border-radius: 4.667vw 0vw 0vw 4.667vw;
        float: right;
        margin-top: 6.2vw;
        position: absolute;
        right: 0;
        span {
          position: absolute;
          font-size: 3.733vw;
          color: #ffffff;
          margin-left: 4vw;
          margin-top: 2vw;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .info_sed {
        font-size: 4.267vw;
        color: #303133;
        margin-top: 16.4vw;
        text-align: center;
        a {
          color: #303133;
        }
      }
      .figure {
        width: 68.667vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 6.733vw;
        .attention {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .number {
            font-size: 4.533vw;
            line-height: 5.2vw;
            color: #303133;
            font-weight: bold;
          }
          .attention_size {
            font-size: 3.467vw;
            color: #909399;
            margin-top: 2vw;
          }
        }
      }
    }
  }
}

.mt16 {
  margin-top: 2.133vw;
}
.register {
  font-size: 4.267vw;
  color: #989898;
  width: 100vw;
  height: 11.733vw;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8.533vw;
}
// .My_function a {
//   color: #303133;
// }
// .my_courses_right_tech_left {
//   display: flex;
//   align-items: center;
//   margin-right: 3.067vw;
//   font-size: 2.933vw;
//   color: #bfc2cc;
// }
// .my_courses_right_tech {
//   display: flex;
//   align-items: center;
// }
// .red_dot {
//   display: flex;
//   width: 1.6vw;
//   height: 1.6vw;
//   margin-right: 0.8vw;
//   border-radius: 100%;
//   background-color: #ff3b30;
// }
.Login_immediately {
  font-size: 4.267vw;
  letter-spacing: -0.133vw;
  color: #ff3b30;
  width: 100vw;
  height: 11.733vw;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8.533vw;
}
// .my_courses_left_index {
//   width: 8vw;
// }
// .key_img {
//   width: 5.867vw;
//   height: 5.867vw;
// }
// .information {
//   margin-top: 2.133vw;
//   background-color: #ffffff;
// }
// .information a {
//   color: #000000;
// }
// .record_img {
//   width: 4.667vw;
//   height: 5.867vw;
// }
// .Mycourse_img {
//   width: 5.733vw;
//   height: 5.867vw;
// }
// .Mymoney_img {
//   width: 4.8vw;
//   height: 5.867vw;
// }
// .my_courses_right {
//   width: 1.333vw;
//   height: 2.4vw;
// }

// .my_courses_left {
//   display: flex;
//   align-items: center;
// }
// .My_function {
//   width: 100%;
//   background-color: #ffffff;
//   padding-top: 5vw;
// }
// .my_courses {
//   width: 90vw;
//   height: 13vw;
//   padding: 0 5vw;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
</style>
