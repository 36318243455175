<template>
  <div class="page">
    <topheader />
    <div style="height:2.667vw"></div>
    <div class="money_panel">
      <div class="money_panel_xihua">
        <p class="money_panel_title">
          提现金额
          <!-- <span>（至少大于1元）</span> -->
        </p>
        <div class="money_number">
          <!-- <span class="symbol">￥</span>
          <span class="money_number_yur">
            <input type="number" v-model.number="numberPrice" placeholder="0.00" />
          </span> -->
          <div class="money_number">
            <span class="symbol">￥</span>
            <span class="money_number_yur">
              <van-field
                v-model="numberPrice"
                onfocus="this.blur()"
                placeholder="0.00"
                @touchstart.stop="showKeyboard = true"
              />
              <van-number-keyboard
                theme="custom"
                extra-key="."
                :show="showKeyboard"
                close-button-text="完成"
                @blur="showKeyboard = false"
                @input="onClickInputKeyBoard"
                @delete="onDeleteKeyBoard"
              ></van-number-keyboard>
            </span>
          </div>
        </div>
      </div>
      <div class="hint">
        <span class="hint_title">当前可提现{{ balance.toFixed(2) }}元</span>
        <span class="hint_ti" @click="numberPrice = balance">全部提现</span>
      </div>
    </div>
    <div class="withdraw" :class="{ dis: !canCashout }" @click="withdrawCash">
      <!-- <van-loading type="spinner" v-show="loading" /> -->
      提现至微信
    </div>
    <div class="bill">
      <router-link to="/my_wallet_detaciis">账单明细</router-link>
    </div>

    <div class="tip">
      <p>
        温馨提醒：<br />
            1.提现金额必须大于等于1元；<br />
            2.提现额限是1~100000元；<br />
            3.每天只能提现10次。
      </p>
    </div>
  </div>
</template>

<script>
import { getMyBalance, cashout } from '@/api/my'
// import {wxPayOrderBM} from '@/api/pay';
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      numberPrice: '', // 想要提现的钱
      balance: 0, // 余额
      showKeyboard: false, // 是否显示数字控件
      // loading: false
    }
  },
  computed: {
    canCashout() { // 是否可以提现
      return this.balance > 0 && this.numberPrice <= this.balance && this.numberPrice >= 1
    }
  },
  methods: {
    init() {
      getMyBalance(this.userId)
        .then(res => {
          // if (res.balance) {
          //   // this.balance = Math.floor(Number(res.data.balance)*100)/100;
          // }
          this.balance = res.balance
        })
        .catch(() => {
          this.$toast('余额获取失败')
        })
    },
    // 提现
    withdrawCash() {
      // this.$toast('@todo, 模拟提现成功')
      if (this.canCashout) {
        // this.loading = true
        cashout({
          userid: this.userId,
          chargemoney: this.numberPrice
        }).then(res => {
          this.$router.push({ path: '/moneySucceed/' })
        }).finally(res => {
          // this.loading = false
        })
      } else {
        console.error('**不满足提现条件**')
      }
    },
    /**
     * 模拟键盘点击数字时触发的函数
     */
    onClickInputKeyBoard(value) {
      this.numberPrice += value
      // 限制只能输入一个小数点及两位小数
      this.numberPrice =
        this.numberPrice.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null
      if (this.numberPrice > this.balance) {
        this.numberPrice = this.numberPrice.slice(0, -1)
      }
    },
    /**
     * 模拟键盘删除时触发的函数
     */
    onDeleteKeyBoard() {
      let flag = true
      if (flag) {
        // 删除掉字符串最后一个
        this.numberPrice = this.numberPrice.substring(
          0,
          this.numberPrice.length - 1
        )
        if (this.numberPrice.length === 0) {
          flag = false
          return false
        }
      }
    },
  },
  filters: {},
  mounted() {
    this.init()
    window.T = {}
    T.vm = this
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
.withdraw a {
  color: #ffffff;
}
.bill a {
  color: #576b95;
}
.bill {
  line-height: 3.733vw;
  color: #576b95;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 75vw;
  display: flex;
  justify-content: center;
}
.withdraw {
  width: 89.333vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 55vw;
  margin: 6.667vw auto;
  // .van-loading {
  //   width: 4.8vw;
  //   height: 4.8vw;
  //   margin-right: 2vw;
  // }
}
.dis {
  background: #ddd;
}
.tip {
  width: 89.333vw;
  margin: 5vw auto;
  font-size: 2.6vw;
  line-height: 5.2vw;
  color: #909399;
}
.hint_ti {
  margin-left: 2.667vw;
  font-size: 3.467vw;
  color: #576b95;
}
.money_number_yur input {
  border: none;
  width: 75vw;
}
.hint {
  width: 85.6vw;
  margin: 0 auto;
  margin-top: 2.2vw;
}
.hint_title {
  font-size: 3.467vw;
  line-height: 3.733vw;
  color: #909399;
}
.symbol {
  font-size: 6.133vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3.733vw;
  color: #303133;
  margin-top: 3vw;
}
.money_number {
  margin-top: 3.667vw;
  display: flex;
}
input::-webkit-input-placeholder {
  font-size: 9.6vw;
  letter-spacing: -0.533vw;
  color: #303133;
}
.money_panel_title {
  padding-top: 5.333vw;
}
.money_panel_xihua {
  height: 30.533vw;
  width: 85.6vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
}
.money_number_yur {
  font-size: 9.6vw;
  letter-spacing: -0.533vw;
  color: #303133;
  margin-left: 2vw;
  .van-cell {
    width: 100%;
    font-size: 6.133vw;
  }
  .van-field::after {
    border-color: transparent;
  }
}
.money_panel {
  width: 93.6vw;
  height: 41.067vw;
  background-color: #ffffff;
  margin: 0 auto;
}
.page {
  width: 100%;
  height: 100vh;
  background-color: #f5f7fa;
}
</style>
