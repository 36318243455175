<template>
  <!-- 老师发起签到 -->
  <div
    id="moveDiv"
    class="xuanfu"
    :style="{
      'background': canSign ? 'transparent' : ''
    }"
    @mousedown="down($event)"
    @touchstart="down($event)"
    @mousemove="move($event)"
    @touchmove="move($event)"
    @mouseup="end($event)"
    @touchend="end($event)"
  >
    <transition name="fade" mode="out-in">
      <!-- 签到按钮 -->
      <div class="sign_box" v-if="canSign" key="sign" @click="action">
        <!-- 老师 -->
        <div class="tea_sign">
          <img :src="bg" class="tea_sign_img" />
          <i>
            发起
            <span class="large">签</span>
            <span>到</span>
          </i>
        </div>
      </div>
      <!-- 倒计时 -->
      <div class="content" v-else key="countdown">
        <span>发起倒计时&nbsp;</span>
        <span>{{`${time.hour}:${time.minute}:${time.second}`}}</span>
      </div>
    </transition>

    <van-dialog
      v-model="showDialog"
      title="设置学员签到时限"
      show-cancel-button
      class="dialog_box"
      :before-close="teaInitiate"
    >
      <van-cell-group>

        <van-row>
          <van-col span="8">
            <van-field
              label="时限"
              required
            />
          </van-col>
          <van-col span="8">
            <van-field
              v-model="signIntervalMinute"
              placeholder="请输入..."
              type="digit"
            >
              <template #button>
                <span>分</span>
              </template>
            </van-field>
          </van-col>
          <van-col span="8">
            <van-field
              v-model="signIntervalSecond"
              placeholder="请输入..."
              type="digit"
            >
              <template #button>
                <span>秒</span>
              </template>
            </van-field>
          </van-col>
        </van-row>

        <!-- <van-field name="stepper" label="学员签到时限：" label-width="7em">
          <template #input>
            <van-stepper
              v-model="signInterval"
              theme="round"
              button-size="22"
              onfocus="this.blur()"
              required
              @touchstart.stop="showKeyboard = true"
            />
          </template>
          <template #button>
            <span>分钟</span>
          </template>
        </van-field> -->
      </van-cell-group>
    </van-dialog>
    <!-- 数字输入控件 -->
    <!-- <van-number-keyboard
      class="number_keyboard"
      theme="custom"
      close-button-text="完成"
      :show="showKeyboard"
      @blur="showKeyboard = false"
      @input="onClickInputKeyBoard"
      @delete="onDeleteKeyBoard"
    ></van-number-keyboard> -->
  </div>
</template>

<script>
import { createStarSign, createSignal } from '@/api/classRing'
import { formatSeconds } from '@/assets/js/public'
// 烟花
// http://v.bootstrapmb.com/2019/4/6rbr4432/
// http://www.bootstrapmb.com/item/4432
export default {
  props: {
    //倒计时(秒)
    countDown: {
      type: Number,
      default: 0
    },
    lessonId: [Number, String], //课程id
    signId: [Number, String] //学员签到id
    // isTeach: Boolean //班主任/导师
  },
  data() {
    return {
      flags: false,
      position: { x: 0, y: 0 },
      nx: '',
      ny: '',
      dx: '',
      dy: '',
      xPum: '',
      yPum: '',
      // --------------------------------------
      // --------------------------------------
      userId: this.$store.state.user.userId,
      canSign: false, //是否允许发起签到/学员签到
      bg: require('@/assets/images/sign.png'), //签到背景图
      second: 0, //剩余(秒)
      countdownTimer: null, //倒计时定时器
      time: {
        second: '00',
        minute: '00',
        hour: '00'
      },
      showDialog: false, //老师发起签到提示框
      signInterval: '1', //学员签到时限，默认1分钟
      signIntervalMinute: 1,//学员签到时限，默认60秒
      signIntervalSecond: 0, //学员签到时限，默认60秒
      showKeyboard: false
    }
  },
  watch: {
    countDown: {
      handler(nl, ol) {
        // console.log(1111, { nl, ol })
        // 如果旧值为0，新增大于0 表示需要倒计时
        if (nl > 0 && !ol) {
          this.second = nl
          this.canSign = false
          this.setCountdown()
        } else if (nl === 0) {
          this.canSign = true
        }
      },
      immediate: true
    }
  },
  methods: {
    // 实现移动端拖拽
    down(event) {
      this.flags = true
      var touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.position.x = touch.clientX
      this.position.y = touch.clientY
      this.dx = moveDiv.offsetLeft
      this.dy = moveDiv.offsetTop
    },
    move() {
      if (this.flags) {
        var touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        this.nx = touch.clientX - this.position.x
        this.ny = touch.clientY - this.position.y
        this.xPum = this.dx + this.nx
        this.yPum = this.dy + this.ny
        moveDiv.style.left = this.xPum + 'px'
        moveDiv.style.top = this.yPum + 'px'
        //阻止页面的滑动默认事件
        document.addEventListener(
          'touchmove',
          function() {
            // 1.2 如果碰到滑动问题，请注意是否获取到 touchmove
            event.preventDefault() //jq 阻止冒泡事件
            // event.stopPropagation(); // 如果没有引入jq 就用 stopPropagation()
          },
          false
        )
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false
    },
    // //模拟键盘点击数字时触发的函数
    // onClickInputKeyBoard(value) {
    //   this.signInterval += value
    //   // 限制只能输入一个小数点及两位小数
    //   this.signInterval =
    //     this.signInterval.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null
    //   // if (this.signInterval > 60) {
    //   //   this.signInterval = this.signInterval.slice(0, -1)
    //   // }
    // },
    // //模拟键盘删除时触发的函数
    // onDeleteKeyBoard() {
    //   let flag = true
    //   if (flag) {
    //     // 删除掉字符串最后一个
    //     this.signInterval = this.signInterval.substring(
    //       0,
    //       this.signInterval.length - 1
    //     )
    //     if (this.signInterval.length === 0) {
    //       flag = false
    //       return false
    //     }
    //   }
    // },
    // 设置 倒计时
    setCountdown() {
      // 还在有效期内，可进行倒计时
      this.countdownTimer = setInterval(() => {
        if (this.second) {
          this.time = formatSeconds(this.second)
          this.second--
        } else {
          this.canSign = true
          this.cleanCountdownTimer()
        }
      }, 1000)
    },
    // 清除倒计时定时器
    cleanCountdownTimer() {
      clearInterval(this.countdownTimer)
      this.countdownTimer = null
    },
    // 发起 / 签到
    action() {
      this.showDialog = true
    },
    // 老师发起签到
    teaInitiate(action, done) {
      if (action === 'confirm') {
        if (this.signIntervalMinute || this.signIntervalSecond) {
          // 学员签到时限有值，且不为0
          const min = Number(this.signIntervalMinute) || 0 // 分钟
          const sec = Number(this.signIntervalSecond) || 0 // 秒
            createStarSign({
              courseid: this.lessonId,
              userid: this.userId,
              signinterval: min*60 + sec
            })
              .then(() => {
                done()
                // 隐藏
                this.$emit('after', 1) // 发起 成功后通知父组件隐藏签到
              })
              .catch(err => {
                done(false)
              })
        } else {
          // 没有输入时间限制
          done(false)
        }
      } else if (action === 'cancel') {
        done()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/#moveDiv {
  .van-overlay {
  }
}
.xuanfu {
  padding: 1.5vw;
  z-index: 999;
  position: fixed;
  top: 120vw;
  left: 74vw;
  border-radius: 0.8vw;
  background-color: rgba(0, 0, 0, 0.55);
  @include font(4.2vw, 4.2vw);
  .content {
    color: #ff8b07;
  }
  // 签到盒子
  .sign_box {
    color: #ff8b07;
    // 老师发起签到盒子
    .tea_sign {
      width: 24vw;
      position: relative;
      .tea_sign_img {
        @include imgCover;
      }
      i {
        position: absolute;
        left: 0;
        bottom: 2vw;
        right: 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding-right: 1.2vw;
        span {
          color: #ffe607;
        }
        span.large {
          padding-left: 0.6vw;
          font-size: 5.8vw;
        }
      }
    }
  }
  //
  /deep/.dialog_box {
    z-index: 9999 !important; //默认2034+
    top: 93.333vw;
    top: 89.333vw;
    .van-dialog__header {
      padding-top: 0;
      line-height: 44px;
    }
    .van-dialog__content {
      .van-cell-group {
        .van-row {
          .van-col {
            text-align: center;
            .van-cell {
              .van-cell__value {
                .van-field__body {
                  .van-field__control {
                    text-align: center;
                  }
                }
              }
            }
          }
          .van-col:first-child {

          }
        }
      }
    }
    .van-dialog__header {}
  }
  //
  .van-number-keyboard {
    z-index: 5000; //其他都在2000+
  }
  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
  .fade-enter-active {
    transition: all 0.1s ease;
  }
  .fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter,
  .fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
