import request from '@/util/request'
import { getOpenId } from "@/util/auth";

// 支付相关

/* ---------------- 二次开发：start(by:yyz) ---------------- */


// 获取JSSDK秘钥（微信授权参数）
function getAuthorityParams(str) {
  let url = '';
  let ua = navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod/.test(ua)) {
    url = location.href.split('#')[0];
  } else {
    url = location.href;
  }
  url = str ? str : url
  return request.get('/Server/GetJSSDK', { url });
}

// window.getAuthorityParams = getAuthorityParams;

// 获取微信授权
export function getAuthority() {
  getAuthorityParams().then(res => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      // debug: true,
      appId: res.appId,             // 必填，公众号的唯一标识
      timestamp: res.timestamp,     // 必填，生成签名的时间戳
      nonceStr: res.nonceStr,       // 必填，生成签名的随机串
      signature: res.signature,     // 必填，签名
      signType: "MD5",              // 微信签名方式
      jsApiList: [                  // 必填，需要使用的JS接口列表
        'startRecord',    //开始录音接口
        'stopRecord',     //停止录音接口
        'playVoice',      //播放语音接口
        'pauseVoice',     //暂停播放接口
        'stopVoice',      //停止语音接口
        'onVoicePlayEnd', //监听语音播放完毕接口
        'uploadVoice',  //上传语音接口
        'downloadVoice',//下载语音接口
        'updateAppMessageShareData',//自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        'updateTimelineShareData',  //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
      ]
    });
  })
}

// 创建学员报名记录
export function createStuinfo(classid, userid, recommendA, recommendB) {
  return request.post('/Server/CreateStuinfo', {
    data: {
      classid,
      userid,
      recommendA,
      recommendB
    }
  })
}

// 获取微信支付参数(购买班圈)
export function wxPayOrderBM(stuid) {
  return request.post('/WxPay/WxPayOrderBM', {
    data: {
      openid: getOpenId(),
      stuid
    }
  })
}

// 获取微信支付参数（购买班主任问答语音）
export function wxPayOrderWD(userid, replyid) {
  return request.post('/WxPay/WxPayOrderWD', {
    data: {
      openid: getOpenId(),
      userid,
      replyid,
    }
  })
}

// 打赏支付接口
export function wxPayOrderDS(params) {
  const { classid, courseid, userid, teastr, unitprice } = params
  return request.post("/WxPay/WxPayOrderDS", {
    data: {
      openid: getOpenId(),
      classid,
      courseid,
      userid,
      teastr,
      unitprice
    }
  })
}
/* ---------------- 二次开发：end(by:yyz)   ---------------- */