<template>
  <!-- 专家栏目 -->
  <div class="page">
    <topheader />
    <cell-group :list="tools" class="mt16" />
  </div>
</template>
<script>
import { getTeaCanCheckHWTotal, getTeaCanCheckEXTotal } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      tools: [
        {
          icon: require('@/assets/images/student_course.png'),
          label: '课程',
          route: '/experts_course',
          value: ''
        },
        {
          icon: require('@/assets/images/homework.png'),
          label: '作业',
          // route: '/experts_questionList/1',
          route: '/course_list/2',
          value: ''
        },
        {
          icon: require('@/assets/images/student_exam.png'),
          label: '考试',
          // route: '/experts_questionList/2',
          route: '/course_list/3',
          value: '',
          valueDot: false
        },
        {
          icon: require('@/assets/images/notice.png'),
          label: '公告',
          route: '/course_list/4',
          value: ''
        },
        {
          icon: require('@/assets/images/student.png'),
          label: '学员信息',
          route: '/course_list/5',
          value: ''
        },
        {
          icon: require('@/assets/images/checkin.png'),
          label: '考勤记录',
          route: '/course_list/6',
          value: ''
        },
        {
          icon: require('@/assets/images/couers.png'),
          label: '设置作业',
          route: '/course_list/7',
          value: ''
        },
        {
          // icon: require('@/assets/images/classNews.png'),
          icon: 'bell',
          color: '#FE525F',
          type: 'iconfont',
          label: '发布消息',
          route: '/course_list/8',
          value: ''
        }
      ]
    }
  },
  methods: {
    // 获取作业量
    getHWnews() {
      getTeaCanCheckHWTotal(this.userId).then(res => {
        this.tools.forEach(item => {
          if (item.label == '作业') {
            item.value = `${res.cancheckhwnum}条新回答`
            item.valueDot = res.cancheckhwnum > 0
          }
        })
      })
    },
    // 获取考试量
    getExnews() {
      getTeaCanCheckEXTotal(this.userId).then(res => {
        this.tools.forEach(item => {
          if (item.label == '考试') {
            item.value = `${res.cancheckexnum}条新回答`
            item.valueDot = res.cancheckexnum > 0
          }
        })
      })
    }
  },
  mounted() {
    this.getHWnews()
    this.getExnews()
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
}
</style>
