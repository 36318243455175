<template>
  <div class="page">
    <topheader />
    <div class="succeed_img">
      <img src="@/assets/images/succend.png" alt />
    </div>
    <div class="reminder">提现成功</div>
    <router-link to="/my_wallet_detaciis">
      <div class="check">查看账单明细</div>
    </router-link>
    <div class="back" @click="back">返回我的钱包</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
/* .back {
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #303133;
  text-align: center;
  border: 1px solid rgba(104,104,104,0.1)
} */
.page {
  // @include isTop;
}
.check, .back {
  width: 92vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 1.333vw;
  margin: 6.667vw auto;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back {
  color: #303133;
  background: #FFF;
  border: 1px solid rgba(104,104,104,.1);
}
.reminder {
  font-size: 5.333vw;
  line-height: 6.133vw;
  color: #303133;
  text-align: center;
}
.succeed_img img {
  display: block;
  width: 100%;
  height: 100%;
}
.succeed_img {
  width: 41.067vw;
  height: 38.933vw;
  margin: 20vw auto;
  margin-top: 20vw;
}
</style>