<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div style="height:2.667vw"></div>
        <div
          class="ask_home_clum_list"
          v-for="(item,i) in list"
          :key="'course'+i"
          @click="goToClassDetails(item.autoid)"
        >
          <div class="ask_home_clum_list_left">
            <img :src="item.pic" alt />
          </div>
          <div class="ask_home_clum_list_right">
            <div class="neme">{{item.title}}</div>
            <div class="clum_list_right_name">
              <div>{{expertName}}</div>
              <div>{{item.crdate | timeStamp | dateFormat('yyyy/MM/dd')}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getTeaClassList } from '../../../api/questionsAndAnswers'

export default {
  name: 'courseList',
  props: {
    expertId: String,
    expertName: String
  },
  data() {
    return {
      page: 1, // 当前页数
      list: [], // 显示数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getTeaClassList(this.expertId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->课程简介
    goToClassDetails(classId) {
      this.$router.push('/classDetails/' + classId)
    }
  }
}
</script>

<style scoped>
.ask_home_clum_list_right {
  margin-left: 3vw;
  width: 50vw;
  height: 24.267vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.neme {
  font-size: 4.267vw;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clum_list_right_name {
  font-size: 2.933vw;
  color: #909399;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ask_home_clum_list_left {
  width: 36.533vw;
  height: 24.267vw;
  background-color: #c6c8cb;
  border-radius: 4%;
  margin-left: 2vw;
}
.ask_home_clum_list_left > img {
  width: 100%;
  height: 100%;
  border-radius: 4%;
  object-fit: cover;
}

.ask_home_clum_list {
  width: 93.6vw;
  height: 26.933vw;
  background-color: #ffffff;
  border-radius: 0.8vw;
  margin: 0 auto;
  margin-top: 2.667vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
