<template>
  <div class="contact">
    <div class="connection_list" v-for="(item,i) in list" :key="'connection'+i" @click="act(item)">
      <div class="connection_list_left">
        <img v-if="item.headurl" :src="item.headurl" alt />
        <img v-else :src="defaultSrc" alt />
      </div>
      <div class="connection_list_right">
        <!-- 人脉 用到 -->
        <template v-if="item.classname">
          <div class="peopleInfo">
            <span class="name" v-text="item.name"></span>
            <span class="job">
              <b>{{item.company || '公司名称'}}</b>-
              <em>{{item.job || '职位'}}</em>
            </span>
          </div>
          <div class="class_info">
            <div class="class_name">
              <label for>最近报名：</label>
              <span v-text="item.classname"></span>
            </div>
            <div class="join_time" v-if="item.crdate">
              <label for>报名时间：</label>
              <span>{{item.crdate | timeStamp | dateFormat('yyyy/MM/dd')}}</span>
            </div>
          </div>
        </template>
        <!-- 关注 用到 -->
        <template v-else>
          <div class="peopleInfo" :class="{isfollow: !item.classname}">
            <span class="name" v-text="item.name"></span>
            <span>{{item.crdate | timeStamp | dateFormat('yyyy/MM/dd')}}</span>
          </div>
          <div class="class_info" :class="{isfollow: !item.classname}">
            <span class="job">
              <b>{{item.company || '公司名称'}}</b>-
              <em>{{item.job || '职位'}}</em>
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: Array
  },
  methods: {
    act(info) {
      this.$emit('click', info)
    }
  },
  data() {
    return {
      defaultSrc: require('@/assets/images/head_prole.png')
    }
  }
}
</script>
<style lang="scss" scoped>
.contact {
  .connection_list {
    width: 93.733vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .connection_list_left {
      @include wh(13.333vw, 13.333vw);
      @include borderRadius(50%);
      overflow: hidden;
      img {
        @include imgCover;
      }
    }
    .connection_list_right {
      margin-left: 3.6vw;
      min-height: 20vw;
      box-sizing: border-box;
      border-bottom: 0.233vw solid #edeff2;
      width: 80%;
      padding: 2vw 0;
      .peopleInfo {
        font-size: 3.733vw;
        color: #303133;
        .name {
          // text-shadow: 2px 1px 4px #aaa;
        }
        .job {
          float: right;
          font-size: 3.2vw;
          em {
            color: $active-color;
            font-style: normal;
          }
        }
      }
      .class_info {
        width: 100%;
        font-size: 3.2vw;
        padding-top: 1.067vw;
        color: #909399;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .class_name,
        .join_time {
          display: flex;
          line-height: 4.8vw;
          label {
            width: 5em;
          }
          span {
            flex: 1;
          }
        }
      }

      // 关注 用到时的样式
      .peopleInfo.isfollow {
        display: flex;
        justify-content: space-between;
        span:nth-child(2) {
          color: $font-gray;
        }
      }
      .class_info.isfollow {
        em {
          font-style: normal;
        }
      }
    }
  }
}
</style>