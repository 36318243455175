<template>
  <div class="page">
    <topheader :title="title" />
    <!-- 题号 -->
    <div class="question_header">
      <div class="question_number">
        <!-- 得分状况 -->
        <div class="score_box" v-text="`得分：${QSInfo.acscore}/${question.score}`"></div>
        <span class="premise">{{ questionIdx }}</span>
        <span class="topic">/{{ total }}题</span>
      </div>
    </div>
    <!-- 问题描述 -->
    <div class="question_box" id="question">
      <!-- 题型：题目 -->
      <div class="question_desc">
        <div class="question_type" v-text="questionTypeDesc"></div>
        <div class="question_title">{{ question.title }}</div>
      </div>
      <!-- 选择题 type: 1-->
      <div class="question_choice" v-if="questionType === 1 || questionType === 2">
        <div
          class="answer"
          :class="{
            'pitch': isPitchOn(item),
            'finish_right':item.iscorrect,
            'finish_wrong':isPitchOn(item) && !item.iscorrect
          }"
          v-for="(item, i) in QSInfo.optioninfo"
          :key="i"
        >
          <!-- <span v-text="`${item.optionid}.`"></span> -->
          <span v-text="item.optionname"></span>
          <template>
            <van-icon name="success" v-if="item.iscorrect" />
            <van-icon name="cross" v-else-if="isPitchOn(item) && !item.iscorrect" />
          </template>
        </div>
      </div>
      <!-- 填空题 type: 3-->
      <ul class="question_gap_filling" v-if="questionType === 3">
        <li v-for="(item, idx) in QSInfo.optioninfo" :key="idx">
          <label>填空题{{ idx + 1 }}</label>
          <div class="question_gap_filling_answer">
            <van-field
              v-model="temporary[idx]"
              rows="1"
              type="textarea"
              autosize
              placeholder="请输答案..."
              :class="{ 
                'active': answering === idx,
                'tk_right':tkIsRight(item.optionname,temporary[idx]),
                'tk_wrong':!tkIsRight(item.optionname,temporary[idx]),
              }"
              :right-icon="rightIcon(item.optionname,temporary[idx])"
              disabled
              :error-message="''"
              @focus="answering = idx"
              @blur="answering = undefined"
            />
          </div>
        </li>
      </ul>
      <!-- 简答题 type: 4-->
      <div class="question_short_answer" v-if="questionType === 4">
        <van-field
          autosize
          v-model="temporary[0].content"
          rows="4"
          type="textarea"
          placeholder="请输入答案..."
          disabled
        >
          <div slot="right-icon">
            <van-icon name="upgrade" />
          </div>
        </van-field>

        <!-- 上传文件列表 -->
        <Files :list="filesList" ref="files" openFile isdelete />
      </div>
    </div>

    <!-- 操作按钮 -->
    <div class="buttons">
      <template>
        <template v-if="total == 1">
          <van-button block type="primary" color="#247dff" @click="checkResult">查看结果</van-button>
        </template>

        <van-button
          v-else-if="QSInfo.pretdetailid"
          block
          plain
          type="primary"
          color="#247dff"
          @click="last"
        >上一题</van-button>
        <van-button
          v-else-if="QSInfo.nextdetailid || questionIdx == 1 && total > 1"
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>
      </template>

      <template>
        <div v-show="total == 1 || (total > 1 && questionIdx == 1)" class="empty"></div>
        <van-button
          v-if="
            total > 1 &&
            QSInfo.hasnext &&
            questionIdx != 1 &&
            questionIdx != total
          "
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>
        <template v-else-if="total != 1 && total == questionIdx">
          <van-button block type="primary" color="#247dff" @click="checkResult">查看结果</van-button>
        </template>
      </template>
    </div>

    <!-- 查看分数 -->
    <van-overlay :show="showGrade">
      <div class="wrapper">
        <div class="box">
          <template v-if="!result.isend && !result.ischeck && overdue">
            <p class="title">查询结果</p>
            <p class="grade" style="margin-top: 19vw;">答题结束,暂无成绩</p>
          </template>
          <template v-else>
            <p class="title">答卷提交成功</p>
            <p class="grade" style="margin-top: 19vw;">
              <template v-if="result.ischeck && result.score !== null">本次考试{{result.score}}分</template>
              <template v-else>等待老师阅卷</template>
            </p>
          </template>
          <div class="button" @click="$router.go(-1)">返回上一页</div>
          <div class="back" @click="$router.push('/')">返回首页</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getExamRecord, stuGetEXQSInfoFromDetail } from '@/api/classRing'
export default {
  name: 'examQuastionDetail2',
  data() {
    return {
      temporary: [], // 临时答案区
      showGrade: false, // 显示成绩
      answering: undefined, //填空题是否正在答题
      filesList: [], //上传文件列表
      id: this.$route.query.id, // 考试/作业 id
      recordid: this.$route.query.recordid, //学员记录id
      lastqsid: this.$route.query.lastqsid, //上一次提交答题id（要提交答案的答题id）
      total: this.$route.query.total, //试题总数
      questionIdx: 0, //当前答题下标
      title: this.$route.query.title, //动态标题（作业/考试 名称）
      overdue: this.$route.query.overdue, //答题是否已结束
      QSInfo: {}, //整个题目信息
      question: {}, //题目信息
      result: {} // 查询结果
    }
  },
  computed: {
    // 答题类型
    questionType() {
      return this.question.mode
    },
    // 答题类型 文字描述
    questionTypeDesc() {
      switch (this.question.mode) {
        case 1:
          return '单选题'
          break
        case 2:
          return '多选题'
          break
        case 3:
          return '填空题'
          break
        case 4:
          return '简答题'
          break
      }
    }
  },
  methods: {
    // 获取答题信息
    getExamQuestion(explanid, recordid, detailid) {
      return stuGetEXQSInfoFromDetail({ explanid, recordid, detailid }).then(
        res => {
          const mode = res.questioninfo[0].mode
          const answer = res.answer
          const option = res.optioninfo
          this.question = res.questioninfo[0]
          this.temporary = this.getAnswerList(mode, answer, option) //答案（临时区）
          this.QSInfo = res
          this.questionIdx = res.rows //题目序号
          this.filesList = res.file //附件
          return res //返回数据到其他地方引用
        }
      )
    },
    /**
     * @desc 转换答案格式
     * @params
     *     mode    Number 答题类型 1-单选 2-多选 3-填空 4-简答
     *     answer  String 数据库返回的答案字符串
     *     option  Array  接口返回的选项
     * @return Array
     */
    getAnswerList(mode, answer, option) {
      let list = []
      if (answer) {
        //后台返回的字符串格式拼接的答案内容
        // 单  选：1_答案A(前面的数字是optionid)
        // 多选题：1_答案A|2_答案B|C_答案C(前面的数字是optionid)
        // 填空题：1_空1内容|2_空2内容|C_空3内容(前面的数字是optionid)
        // 简答题：0_答案(简答没有选项，所以保存为0)
        answer.split('|').forEach(op => {
          const optId = op.split('_')[0]
          const content = op.split('_')[1]
          if ([1, 2].includes(mode)) {
            //选择题
            const opt = option.find(item => item.optionid == optId) //预防后台修改选项，之前的答案找不到
            if (opt) {
              list.push(opt)
            }
          } else if ([3].includes(mode)) {
            //填空题
            list.push(content)
          } else if ([4].includes(mode)) {
            // 简答题
            list.push({
              optionid: 0,
              content
            })
          }
        })
      } else {
        if (mode === 4) {
          //简答题
          list.push({
            optionid: 0,
            content: ''
          })
        }
      }
      return list
    },
    // （选择题）判断是否被选中
    isPitchOn(opt) {
      return this.temporary.includes(opt)
    },
    // 将答案(临时区)转换成接口指定格式数据
    changeAnswerType(mode) {
      let list = []
      if ([1, 2].includes(mode)) {
        //选择题
        this.temporary.forEach(item => {
          list.push({
            optionid: item.optionid,
            content: item.optionname
          })
        })
      } else if (mode === 3) {
        //填空题
        this.temporary.forEach((item, idx) => {
          list.push({ optionid: idx, content: item.trim() })
        })
      } else if (mode === 4) {
        //简答题
        list = this.temporary
      }
      return list
    },
    // 下一题
    next() {
      this.getExamQuestion(this.id, this.recordid, this.QSInfo.nextdetailid)
    },
    // 上一题
    last() {
      this.getExamQuestion(this.id, this.recordid, this.QSInfo.pretdetailid)
    },
    // 查看答题结果
    checkResult() {
      getExamRecord(this.recordid).then(res => {
        this.result = res.data[0]
        this.showGrade = true
      })
    },
    // 填空题右边图标
    rightIcon(str, answer) {
      if (this.forbid2Change) {
        return this.tkIsRight(str, answer) ? 'success' : 'cross'
      } else {
        return ''
      }
    },
    // 判断填空题答案是否正确
    tkIsRight(str = '', answer = '') {
      return str.split('|').includes(answer)
    }
  },
  mounted() {
    this.getExamQuestion(this.id, this.recordid, this.lastqsid)
    this.$store.dispatch('user/forbid2Change', this.forbid2Change)
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  position: relative;
  // 题号（序号+倒计时）
  .question_header {
    .question_number {
      position: relative;
      height: 14vw;
      line-height: 14vw;
      text-align: center;
      width: 100%;
      font-size: 4vw;
      .premise {
        color: $active-color;
      }
      .topic {
        color: #303133;
      }
      // 已提交的题目，查看得分情况 || 倒计时 || 上传附件 按钮
      .score_box,
      // .score_time,
      .uploadFileBtn {
        position: absolute;
        top: 50%;
        right: 3vw;
        transform: translateY(-50%);
        color: $active-color;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          padding-left: 1vw;
        }
      }
      // 已提交的题目，查看得分情况 | 上传附件 按钮
      .score_box,
      .uploadFileBtn {
        right: auto;
        left: 3vw;
        font-size: 3.2vw;
        color: $font-gray;
      }
      // 上传附件 按钮
      .uploadFileBtn {
        color: #fff;
      }
    }
  }
  // 答卷
  .question_box {
    min-height: calc(100% - 13.333vw - 14vw - 32vw);
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;
    // 题目
    .question_desc {
      width: 93.6vw;
      margin: 7.2vw auto;
      display: flex;
      .question_type {
        width: 13.333vw;
        height: 5.333vw;
        background-color: #ffffff;
        border-radius: 2.667vw;
        border: solid 0.267vw #bfc2cc;
        font-size: 2.933vw;
        color: #909399;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .question_title {
        margin-left: 2vw;
        font-size: 4vw;
        color: #303133;
        flex: 1;
        p {
          width: 100%;
        }
        img {
          width: 100%;
        }
        a {
          text-decoration: none;
        }
      }
    }
    // 选择题
    .question_choice {
      width: 93.6vw;
      margin: 0 auto;
      .answer {
        width: 93.6vw;
        min-height: 11.733vw;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 1.067vw;
        border: solid 0.267vw #edeff2;
        margin: 0 auto;
        margin-top: 4vw;
        display: flex;
        align-items: center;

        font-size: 3.733vw;
        letter-spacing: -0.133vw;
        color: #303133;
        span {
          padding-left: 5.333vw;
        }
        span:nth-child(2) {
          flex: 1;
        }
        i {
          padding: 0 1vw;
          font-size: 1.5em;
        }
      }
      .pitch {
        border: solid 0.267vw #247dff;
      }
      .finish_right {
        i {
          color: $success-color;
        }
      }
      .finish_wrong {
        i {
          color: $warn-color;
        }
      }
    }
    // 填空题
    .question_gap_filling {
      padding: 0 3.2vw;
      li {
        @include fja(space-between, center);
        margin-bottom: 3.2vw;
        label {
          @include wh(13.333vw, 100%);
          @include font(3.2vw);
        }
        .question_gap_filling_answer {
          flex: 1;
          margin-left: 2.133vw;
          .van-cell {
            @include borderRadius(1vw);
            min-width: 36.667vw;
            width: auto;
            display: inline-block;
            border: 1px solid #ccc;
            overflow: hidden;
            input:disabled {
              background: $bg-light;
            }
          }
          .active {
            border-color: $active-color;
          }
          /deep/.tk_right {
            .van-cell__value {
              .van-field__body {
                .van-field__right-icon {
                  .van-icon {
                    color: $success-color;
                  }
                }
              }
            }
          }
          /deep/.tk_wrong {
            .van-cell__value {
              .van-field__body {
                .van-field__right-icon {
                  .van-icon {
                    color: $warn-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    // 简答题
    .question_short_answer {
      padding: 0 3vw;
      .van-field {
        border: 1px solid $border-gray;
        @include borderRadius(1vw);
        padding-right: 2.667vw;
        /deep/.van-cell__value {
          .van-field__body {
            align-items: flex-end;
            textarea {
            }
            .van-field__right-icon {
              font-size: 2vw;
            }
          }
        }
      }
      // .main {
      //   .datum {
      //     margin: 0;
      //   }
      // }
    }
  }
  // 操作按钮
  .buttons {
    width: 100%;
    padding: 2vw 3vw 5vw 3vw;
    box-sizing: border-box;
    .van-button {
      margin-bottom: 3vw;
      border-radius: 1vw;
    }
    .van-button:last-child {
      margin-bottom: 0;
    }
    .empty {
      height: 11.733vw;
    }
  }
  // 上传进度环
  .van-dialog {
    /deep/ .van-dialog__content {
      text-align: center;
      padding: 1em;
      .van-circle {
        display: inline-block;
      }
    }
  }
}

// 答题分数
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 84.533vw;
    height: 88.133vw;
    background-image: url('~@/assets/images/grade.jpg');
    background-size: 100%;
    border-radius: 6.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 10vw;
      font-size: 7.467vw;
      color: #fffefb;
    }
    .grade {
      font-size: 4.533vw;
      color: #303133;
      span {
        color: #247dff;
      }
    }
    .button {
      margin-top: 8vw;
      width: 63.867vw;
      height: 11.2vw;
      background-image: linear-gradient(90deg, #37c1ff 0%, #137bfe 100%);
      border-radius: 5.6vw;
      font-size: 4.267vw;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // .bton {}
    .back {
      margin-top: 6vw;
      font-size: 4.267vw;
      color: #249cff;
    }
  }
}
// vue 手机键盘把底部按钮顶上去 ??? 参考https://segmentfault.com/a/1190000014228563
</style>
