<template>
  <div class="quiz_page">
    <topheader />
    <div class="quiz_head">
      <div class="quiz_head_title">
        向
        <span>{{ expertName }}</span> 的提问
      </div>
    </div>
    <div class="quiz_huifu">
      <div class="miaoshu">
        <van-field v-model="message" rows="6" type="textarea" placeholder="请告诉我们你的疑问" />
      </div>
    </div>
    <div :class="`release bton ${content ? 'op' : ''}`" @click="release">发布</div>
  </div>
</template>

<script>
import { createFAQ } from '../../api/questionsAndAnswers'

export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      expertId: this.$route.query.expertId,
      expertName: this.$route.query.expertName,
      message: ''
    }
  },
  computed: {
    content() {
      return this.message.trim()
    }
  },
  methods: {
    release() {
      if (this.content) {
        this.$dialog
          .confirm({
            title: '提示',
            message: '确定发布提问？'
          })
          .then(() => {
            createFAQ(this.userId, this.expertId, this.content)
              .then(res => {
                this.$router.push({ path: '/issueSucceed/' + res.autoid }) //返回 问题id
              })
              .catch(err => {
                this.$toast('发布失败')
              })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.op {
  opacity: 1 !important;
}
.release {
  width: 92vw;
  height: 12.533vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #247dff;
  border-radius: 1.333vw;
  opacity: 0.4;
  margin: 8vw auto;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
}
.quiz_huifu {
  width: 100vw;
  height: 40.8vw;
  background-color: #ffffff;
}
.quiz_page {
  @include isTop;
  width: 100vw;
  height: 100vh;
  background-color: #f5f7fa;
}
.quiz_head_title span {
  font-size: 3.733vw;
  color: #303133;
}
.quiz_head_title {
  text-indent: 3.6vw;
  font-size: 3.733vw;
  color: #909399;
}
.quiz_head {
  width: 100vw;
  height: 13.333vw;
  border-bottom: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
</style>
