import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/My.vue'
import navigationBottom from '../components/navigationBottom.vue'
import register from '../components/enter/register.vue'
import login from '../components/enter/login.vue'
import forget from '../components/enter/forget.vue'

// vue-router报错Uncaught (in promise)及解决方法
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }



import test_result from '../components/My_personal_center/test_result.vue'
import my_wallet from '../components/My_personal_center/my_wallet.vue'
import moneySucceed from '../components/succeed/moneySucceed.vue'
import my_wallet_detaciis from '../components/My_personal_center/my_wallet_detaciis.vue'
import personalInfo from '../components/My_personal_center/mySetting/personalInfo.vue'

// import publication_add from '../components/My_personal_center/publication_add.vue'
import publication_update from '../components/My_personal_center/publication_update.vue'
import homework_comment from '../components/My_personal_center/homework_comment.vue'
import homework_list from '../components/My_personal_center/homework_list.vue'
import homework_comment_list from '../components/My_personal_center/homework_comment_list.vue'
import homework_result from '../components/My_personal_center/homework_result.vue'
import homework_input from '../components/My_personal_center/homework_input.vue'
import homeworkSucceed from '../components/succeed/homeworkSucceed.vue'
import doubt_list from '../components/My_personal_center/doubt_list.vue'
import doubt_explain from '../components/My_personal_center/doubt_explain.vue'


import classSucceed from '../components/succeed/classSucceed.vue'
import classSearch from '../components/ClassRing/classSearch.vue'

// import uploadSucceed from '../components/succeed/uploadSucceed.vue'

// import uploadFile from '../components/My_personal_center/uploadFile.vue'




import attendance_records from '../components/My_personal_center/attendance_records.vue'
import testScoreDetail from '../components/My_personal_center/testScoreDetail.vue'
import recommendation from '../components/My_personal_center/recommendation.vue'
import team_recommend from '../components/My_personal_center/team_recommend.vue'
import teacher_response from '../components/My_personal_center/teacher_response.vue'
import setting_center from '../components/My_personal_center/setting_center.vue'


// 我的>>>消息
import answer from '../components/My_personal_center/userMessage/answer.vue'
import attention from '../components/My_personal_center/userMessage/attention.vue'
import Classring from '../components/My_personal_center/userMessage/Classring.vue'
import fans from '../components/My_personal_center/userMessage/fans.vue'


// 我的>>>我的成长
// import my_grow from '../components/My_personal_center/my_growth.vue'
import myGrow from '../components/My_personal_center/myGrow/index'
import my_studyplan from '../components/My_personal_center/myGrow/my_studyplan'
import my_class from '../components/My_personal_center/myGrow/my_class.vue'
import myHomework from '../components/My_personal_center/myGrow/myHomework.vue'
import myExam from '../components/My_personal_center/myGrow/myExam.vue'
import myScore from '../components/My_personal_center/myGrow/my_score.vue'
import my_attendance from '../components/My_personal_center/myGrow/my_attendance.vue'
import scoreList from '../components/My_personal_center/myGrow/scoreList.vue'


// 我的>>>专家栏目
import course_list from '../components/My_personal_center/course_list.vue'
import experts_tools from '../components/My_personal_center/experts_tools/index.vue'
import experts_course from '../components/My_personal_center/experts_tools/experts_course.vue'
import experts_questionList from '../components/My_personal_center/experts_tools/experts_questionList.vue'
import experts_answerList from '../components/My_personal_center/experts_tools/experts_answerList.vue'
import experts_grading from '../components/My_personal_center/experts_tools/experts_grading.vue'
import experts_announcement from '../components/My_personal_center/experts_tools/experts_announcement.vue'
import createAnnouncement from '../components/My_personal_center/experts_tools/createAnnouncement.vue'
import attendance_detail from '../components/My_personal_center/experts_tools/attendance_detail'
import student_list from '../components/My_personal_center/experts_tools/student_list'
import student_score_list from '../components/My_personal_center/experts_tools/student_score_list'
import student_score_status from '../components/My_personal_center/experts_tools/student_score_status'
import set_homework from '../components/My_personal_center/experts_tools/set_homework.vue'
import createHomework from '../components/My_personal_center/experts_tools/createHomework.vue'
import class_news from '../components/My_personal_center/experts_tools/class_news.vue'
import create_news from '../components/My_personal_center/experts_tools/create_news.vue'


// 班圈
import classDetails from '../components/ClassRing/classDetails.vue'
import classApply from '../components/ClassRing/classApply.vue'
import Course_home from '../components/ClassRing/Course_home.vue'
import contactGroup from '../components/ClassRing/contactGroup.vue'
import materialGroup from '../components/ClassRing/materialGroup.vue'
import announcementDetails from '../components/ClassRing/announcementDetails'
import homeworkQuestionDetail from '../components/My_personal_center/homeworkQuestionDetail.vue'
import homeworkQuestionDetail2 from '../components/My_personal_center/homeworkQuestionDetail2.vue'
import examQuestionDetail from '../components/My_personal_center/examQuestionDetail.vue'
import examQuestionDetail2 from '../components/My_personal_center/examQuestionDetail2.vue'
import tagGroup from '@/components/ClassRing/tagGroup'
import editTag from '@/components/ClassRing/tagGroup/editTag'
import graduation from '@/components/ClassRing/graduation'



// 问答
import reply_list from '../components/ClassRing/reply_list.vue'
import discover_details from '../components/discover/discover_details.vue'
import expertDetails from '../components/asking/expertDetails.vue'
import replyList from '../components/asking/replyList.vue'
import quiz from '../components/asking/quiz.vue'
import issueSucceed from '../components/asking/issueSucceed.vue'

// 测试专用，用完请删
import test from '@/components/extend/test'

Vue.use(VueRouter)

const routes = [
  /**
   * meta: {
   *   caption: ''          当前页面标题名称
   *   keepAlive: true    缓存当前页面
   *   needLogin: true    需要登录才能访问的页面
   * }
   */
  {
    path: '/HelloWorld',
    name: 'HelloWorld',
    component: HelloWorld
  },
  /**
   * 主页 -----------------------------------------------------------------------------
   */
  {
    path: '/',
    name: 'navigationBottom',
    component: () => import('@/components/navigationBottom'),
    redirect: '/class_ring',
    meta: { title: '和英商学', keepAlive: true, },
    children: [
      // 班圈
      {
        path: 'class_ring',
        name: 'ClassRing',
        component: () => import('@/components/Class_ring'),
        meta: { caption: '商学班圈', keepAlive: true, }
      },
      // 问答
      {
        path: 'ask_home',
        name: 'AskHome',
        component: () => import('@/components/ask_home'),
        meta: { keepAlive: true, }
      },
      // 发现
      {
        path: 'discover_home',
        name: 'DiscoverHome',
        component: () => import('@/components/discover_home'),
        meta: { keepAlive: true, needLogin: true, }
      },
      // 我的
      {
        path: 'my',
        name: 'My',
        component: () => import('@/components/My'),
        meta: { keepAlive: true, }
      },
    ]
  },
  /**
   * 登录注册相关页面 -----------------------------------------------------------------------------
   */
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('@/components/enter/register'),
    meta: {
      caption: "注册"
    }
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/enter/login'),
    meta: {
      caption: "登录"
    }
  },
  // 忘记密码
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/components/enter/forget'),
    meta: {
      caption: "忘记密码"
    }
  },
  // 修改密码
  {
    path: '/change',
    name: 'change',
    component: () => import('@/components/enter/change'),
    meta: {
      caption: "修改密码",
      needLogin: true,
    }
  },
  /**
   * 班圈 --------------------------------------------------------------------------------------
   */
  // 班级推荐
  {
    path: '/recommend/:classId/:userId',
    name: 'recommend',
    component: () => import('@/components/ClassRing/recommend'),
  },
  // 搜索
  {
    path: '/classSearch',
    name: 'classSearch',
    component: classSearch,
  },
  // 课程简介
  {
    path: '/classDetails/:classId',
    name: 'classDetails',
    component: () => import('@/components/ClassRing/classDetails'),
    meta: {
      caption: "班圈详情"
    }
  },
  // 报名信息
  {
    path: '/classApply',
    name: 'classApply',
    component: () => import('@/components/ClassRing/classApply'),
    meta: {
      caption: "马上报名",
      needLogin: true,
    }
  },
  // 视频播放页面
  {
    path: '/Course_home',
    name: 'Course_home',
    component: () => import('@/components/ClassRing/Course_home'),
    meta: {
      caption: "课程详情",
      needLogin: true,
      // keepAlive: true
    }
  },
  // 课堂作业
  {
    path: '/reply_list/:id',
    name: 'reply_list',
    component: reply_list,
  },
  // 上传答案
  {
    path: '/upload_answer',
    name: 'UploadAnswer',
    component: () => import('@/components/ClassRing/uploadAnswer')
  },

  {// 测试练习做题页面
    path: '/homeworkQuestionDetail',
    name: 'homeworkQuestionDetail',
    component: homeworkQuestionDetail,
    meta: {
      caption: "答题详情"
    }
  },
  {// 测试练习做题页面 (逾期学生查看答题用)
    path: '/homeworkQuestionDetail2',
    name: 'homeworkQuestionDetail2',
    component: homeworkQuestionDetail2,
    meta: {
      caption: "答题详情"
    }
  },
  {//考试答题页面
    path: '/examQuestionDetail',
    name: 'examQuestionDetail',
    component: examQuestionDetail,
    meta: {
      caption: "答题详情",
      needLogin: true
    }
  },
  {//考试答题页面2 (逾期学生查看答题用)
    path: '/examQuestionDetail2',
    name: 'examQuestionDetail2',
    component: examQuestionDetail2,
    meta: {
      caption: "答题详情",
      needLogin: true
    }
  },
  {
    path: '/tagGroup',
    name: 'tagGroup',
    component: tagGroup,
    meta: {
      caption: "所有分组"
    }
  },
  {
    path: '/editTag',
    name: 'editTag',
    component: editTag,
    meta: {
      caption: "编辑标签"
    }
  },
  /**
   * 问答 --------------------------------------------------------------------------------------
   */
  // 专家详情
  {
    path: '/expertDetails/:expertId',
    name: 'expertDetails',
    component: expertDetails,
    meta: {
      caption: "导师主页",
      needLogin: true,
    }
  },
  // 回复详情
  {
    path: '/replyList',
    name: 'replyList',
    component: replyList,
    meta: {
      caption: "回复"
    }
  },
  // 提问
  {
    path: '/quiz',
    name: 'quiz',
    component: quiz,
    meta: {
      caption: "提问"
    }
  },
  // 发布成功
  {
    path: '/issueSucceed/:id',
    name: 'issueSucceed',
    component: issueSucceed,
    meta: {
      caption: "发布成功"
    }
  },
  /**
   * 发现 --------------------------------------------------------------------------------------
   */
  // 案列详情
  {
    path: '/discover_details/:caseId',
    name: 'discover_details',
    component: discover_details,
    meta: {
      caption: "案例详情"
    }
  },
  /**
   * 我的 --------------------------------------------------------------------------------------
   */
  // 关注
  {
    path: '/attention',
    name: 'attention',
    component: attention,
    meta: {
      caption: '关注',
      needLogin: true,
    }
  },
  // 粉丝
  {
    path: '/fans',
    name: 'fans',
    // component: () => import('@/components/My_personal_center/fans'),
    component: fans,
    meta: { caption: '粉丝', needLogin: true }
  },
  // 课程记录
  {
    path: '/Classring',
    name: 'Classring',
    component: Classring,
    meta: {
      caption: '班圈',
      needLogin: true,
    }
  },
  // 回答
  {
    path: '/answer',
    name: 'answer',
    component: answer,
    meta: {
      caption: '问答',
      needLogin: true,
    }
  },
  // 个人信息
  {
    path: '/personalInfo',
    name: 'personalInfo',
    component: personalInfo,
    meta: {
      caption: "个人信息",
      needLogin: true,
    }
  },
  // 我的课程
  {
    path: '/my_class',
    name: 'my_class',
    component: my_class,
    meta: {
      caption: '我的班圈',
      needLogin: true,
    }
  },
  // 测试记录 改成 我的考试
  {
    path: '/myExam',
    name: 'myExam',
    component: myExam,
    meta: {
      caption: '我的考试',
      // needLogin: true,
    }
  },
  // 设置作业
  {
    path: '/set_homework',
    name: 'set_homework',
    component: set_homework,
    meta: {
      caption: "设置作业",
    }
  },

  // 设置作业(作业列表)
  {
    path: '/createHomework',
    name: 'createHomework',
    component: createHomework,
    meta: {
      caption: "发布作业"
    }
  },
  // // 发布作业
  // {
  //   path: '/publication_add/:id',
  //   name: 'publication_add',
  //   component: publication_add,
  //   meta: {
  //     caption: "发布作业"
  //   }
  // },
  // 修改作业
  {
    path: '/publication_update/:id',
    name: 'publication_update',
    component: publication_update,
    meta: {
      caption: "修改作业"
    }
  },
  // 作业点评
  {
    path: '/homework_comment',
    name: 'homework_comment',
    component: homework_comment,
    meta: {
      caption: "作业点评",
      needLogin: true,
    }
  },
  // 作业点评(列表)
  {
    path: '/homework_list/:id',
    name: 'homework_list',
    component: homework_list,
    meta: {
      caption: "作业列表"
    }
  },
  // 作业点评(详情)
  {
    path: '/homework_comment_list/:id',
    name: 'homework_comment_list',
    component: homework_comment_list,
    meta: {
      caption: "作业点评"
    }
  },
  // 点评(详情)
  {
    path: '/homework_result/:id',
    name: 'homework_result',
    component: homework_result,
    meta: {
      caption: "点评"
    }
  },
  // 点评
  {
    path: '/homework_input/:id',
    name: 'homework_input',
    component: homework_input,
    meta: {
      caption: "点评"
    }
  },
  // 解疑答惑(列表)
  {
    path: '/doubt_list',
    name: 'doubt_list',
    component: doubt_list,
    meta: {
      caption: "解疑答惑",
    }
  },
  // 解疑答惑(具体内容)
  {
    path: '/doubt_explain/:id',
    name: 'doubt_explain',
    component: doubt_explain,
    meta: {
      caption: "解疑答惑"
    }
  },
  // 测试分数(我的成绩)
  {
    path: '/scoreList/:stuId',
    name: 'scoreList',
    component: scoreList,
    meta: {
      caption: "成绩列表",
      needLogin: true,
    }
  },
  // 测试分数(查看详情)
  {
    path: '/test_result/:id',
    name: 'test_result',
    component: test_result,
    meta: {
      caption: "测试分数"
    }
  },
  // 我的钱包
  {
    path: '/my_wallet',
    name: 'my_wallet',
    component: my_wallet,
    meta: {
      caption: "我的钱包",
      needLogin: true,
    }
  },
  // 我的钱包(账单明细)
  {
    path: '/my_wallet_detaciis',
    name: 'my_wallet_detaciis',
    component: my_wallet_detaciis,
    meta: {
      caption: "账单明细"
    }
  },
  /**
   * END ------------------------------------------------------------------------------------------
   */





  // 未使用部分
  {
    path: '/moneySucceed',
    name: 'moneySucceed',
    component: moneySucceed,
    meta: {
      caption: "提现结果"
    }
  },
  {// 点评成功 --> 老师给简答题评分
    path: '/homeworkSucceed/:type',// 1-作业 2-考试
    name: 'homeworkSucceed',
    component: homeworkSucceed,
    meta: {
      caption: "评分成功"
    }
  },
  {
    path: '/classSucceed/:type',//type:展示类型 1-报名 2-打赏
    name: 'classSucceed',
    component: classSucceed,
    meta: {
      caption: "支付结果"
    }
  },
  // {
  //   path: '/uploadSucceed',
  //   name: 'uploadSucceed',
  //   component: uploadSucceed,
  // },


  /**
   * start - 新增 ------------------------------------------------------------------------------------------
   */
  {
    path: '/announcementDetails/:announcementId',
    name: 'announcementDetails',
    component: announcementDetails,
    meta: {
      caption: '公告详情'
    }
  },/* {
    path: '/uploadFile/',
    name: 'uploadFile',
    component: uploadFile,
    meta: {
      caption: '上传附件'
    }
  },*/ {
    path: '/contactGroup/:classId',
    name: 'contactGroup',
    component: contactGroup,
    meta: {
      caption: '班圈名单'
    }
  }, {
    path: '/materialGroup/:classId',
    name: 'materialGroup',
    component: materialGroup,
    meta: {
      caption: '班圈资料'
    }
  }, {
    path: '/myGrow',
    name: 'myGrow',
    component: myGrow,
    meta: {
      caption: '我的成长测试',
    }
  }, {//type  我的成长: 1-我的计划 2-我的考勤  ...  专家栏目: 3-考勤记录 4-学员信息 5-作业 6-考试
    path: '/course_list/:type',
    name: 'course_list',
    component: course_list,
    meta: {
      caption: '课程'
    }
  }, {
    path: '/my_studyplan',
    name: 'my_studyplan',
    component: my_studyplan,
    meta: {
      caption: '学习计划'
    }
  }, {
    path: '/myHomework',
    name: 'myHomework',
    component: myHomework,
    meta: {
      caption: '我的作业'
    }
  }, {// 班圈所有考试的平均分列表
    path: '/myScore',
    name: 'myScore',
    component: myScore,
    meta: {
      caption: '我的成绩',
      needLogin: true
    }
  }, {// 我的考勤
    path: '/my_attendance',
    name: 'my_attendance',
    component: my_attendance,
    meta: {
      caption: '我的考勤'
    }
  }, {// 考勤列表
    path: '/attendance_records/:type',// 1-我的考勤>>>课程>>>记录   2-专家栏目>>>考勤记录>>>课程>>>考勤详情>>>考勤明细
    name: 'attendance_records',
    component: attendance_records,
    meta: {
      caption: '考勤列表'
    }
  }, {// 考试分数
    path: '/testScoreDetail',
    name: 'testScoreDetail',
    component: testScoreDetail,
    meta: {
      caption: '考试分数'
    }
  }, {// 我的推荐
    path: '/recommendation',
    name: 'recommendation',
    component: recommendation,
    meta: {
      caption: '我的推荐'
    }
  }, {// 团队推荐
    path: '/team_recommend',
    name: 'team_recommend',
    component: team_recommend,
    meta: {
      caption: '团队推荐'
    }
  }, {// 回答
    path: '/teacher_response',
    name: 'teacher_response',
    component: teacher_response,
    meta: {
      caption: '回答'
    }
  }, {
    path: '/setting_center',
    name: 'setting_center',
    component: setting_center,
    meta: {
      caption: '我的设置'
    }
  }, {
    path: '/experts_tools',
    name: 'experts_tools',
    component: experts_tools,
    meta: {
      caption: '专家栏目'
    }
  }, {
    path: '/experts_course',
    name: 'experts_course',
    component: experts_course,
    meta: {
      caption: '专家课程'
    }
  }, {
    path: '/experts_questionList/:type',//type: 1-作业 2-考试
    name: 'experts_questionList',
    component: experts_questionList,
    meta: {
      caption: '简答题',// 1-作业 2-考试
    }
  }, {
    path: '/experts_answerList/:type',//type: 1-作业 2-考试
    name: 'experts_answerList',
    component: experts_answerList,
    meta: {
      caption: '简答题回答'
    }
  }, {
    path: '/experts_grading/:type',//type: 1-作业 2-考试
    name: 'experts_grading',
    component: experts_grading,
    meta: {
      caption: '简答题评分'
    }
  }, {//专家栏目 >>> 点击:公告列表
    path: '/experts_announcement',
    name: 'experts_announcement',
    component: experts_announcement,
    meta: {
      caption: '公告列表'
    }
  }, {//专家栏目 >>> 点击:公告列表 >>> 新增公告
    path: '/createAnnouncement',
    name: 'createAnnouncement',
    component: createAnnouncement,
    meta: {
      caption: '发布公告'
    }
  }, {//专家栏目 >>> 点击:公告 >>> 选择后 >>> 考勤详情
    path: '/attendance_detail',
    name: 'attendance_detail',
    component: attendance_detail,
    meta: {
      caption: '考勤详情'
    }
  }, {//专家栏目 >>> 点击:学员信息 >>> 课程列表(选择后) >>>
    path: '/student_list',
    name: 'student_list',
    component: student_list,
    meta: {
      caption: '学员列表'
    }
  }, {//专家栏目 >>> 点击:学员信息 >>> 课程列表(选择后) >>> 查看详情
    path: '/student_score_list/:id',
    name: 'student_score_list',
    component: student_score_list,
    meta: {
      caption: '详细信息'
    }
  }, {//专家栏目 >>> 点击:发布消息 >>> 班圈列表(选择后) >>> 消息列表
    path: '/class_news/:classId',
    name: 'class_news',
    component: class_news,
    meta: {
      caption: '消息列表'
    }
  }, {//专家栏目 >>> 点击:发布消息 >>> 班圈列表(选择后) >>> 消息列表 >>> 新增班圈消息
    path: '/create_news/:classId',
    name: 'create_news',
    component: create_news,
    meta: {
      caption: '发布班圈消息'
    }
  },
  {//学员结业状态
    path: '/student_score_status/:stuId',
    name: 'student_score_status',
    component: student_score_status,
    meta: {
      caption: '状态'
    }
  },
  {
    path: '/graduation/:classId',
    name: 'graduation',
    component: graduation,
    meta: {
      caption: '结业证书'
    }
  },

  {//测试专用，用完请删
    path: '/test',
    name: 'test',
    component: test,
    meta: {
      caption: '测试专用，用完请删'
    }
  },


  /**
   * END - 新增 ------------------------------------------------------------------------------------------
   */


  // 404
  {
    path: '*',
    name: '404NotFound',
    component: () => import('@/components/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
