<template>
  <div>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="comment_box"
          v-for="(item,i) in list"
          :key="'homeworkCommentList'+i"
          @click="Lookanswer"
        >
          <div class="head_portrait">
            <img :src="item.headimg" alt />
          </div>
          <div class="head_portrait_center">
            <div class="head_portrait_center_name">
              <div class="name">
                {{item.name}}
                <span class="gestyle" v-if="item.point">已回复</span>
              </div>
              <div class="time">{{item.replytime | dateFormat('yyyy/MM/dd hh:mm:ss')}}</div>
            </div>
            <div class="head_portrait_center_flex">
              <div class="head_portrait_center_img" v-for="(img,i) in item.reply_pic.split('|')">
                <img :src="img" alt />
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getWorkRecordList } from '../../api/my'

export default {
  data() {
    return {
      workId: this.$route.params.id,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getWorkRecordList(this.workId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.data.list)
          this.page += 1
          this.pullRefresh = false
          if (res.data.current_page >= res.data.total_page) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    Lookanswer() {
      this.$router.push('/homework_result/' + this.workId)
    }
  }
}
</script>

<style scoped>
.gestyle {
  width: 11.467vw;
  height: 4.533vw;
  background-color: #ffffff;
  border-radius: 0.533vw;
  border: solid 0.233vw #ff8c40;
  font-size: 2.933vw;
  color: #ff8c40;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2.667vw;
}
.time {
  font-size: 3.2vw;
  letter-spacing: -0.133vw;
  color: #909399;
}
.name {
  font-size: 3.467vw;
  color: #474f66;
  display: flex;
}
.head_portrait_center_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head_portrait_center_flex {
  display: flex;
  flex-wrap: wrap;
  width: 82.667vw;
  margin-top: 5.333vw;
}
.head_portrait_center_img {
  width: 14.667vw;
  height: 14.667vw;
  background-color: #c6c8cb;
  margin-right: 1.8vw;
  margin-bottom: 1.8vw;
}
.head_portrait_center_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.head_portrait_center {
  margin-left: 2.4vw;
  margin-top: 5.333vw;
}
.head_portrait {
  width: 8.8vw;
  height: 8.8vw;
  border-radius: 100%;
  background-color: #c6c8cb;
  margin-top: 2.667vw;
}
.head_portrait > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.comment_box {
  width: 93.6vw;
  padding-bottom: 3vw;
  margin: 0 auto;
  border-bottom: 1px solid #edeff2;
  display: flex;
}
</style>
