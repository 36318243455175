<template>
  <!-- 学员列表 -->
  <div class="page">
    <topheader :title="`${className}学员`" class="theme_color_blue" />
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 学员列表 -->
        <div class="student_list" v-for="(item, idx) in list" :key="idx">
          <div class="student_list_left">
            <img :src="item.headurl" v-if="item.headurl" />
            <img :src="defaultSrc" v-else />
          </div>
          <div class="student_list_right">
            <div class="student_right_1st">
              <div class="student_name">
                {{item.name}}
                <div class="student_modify" @click="goToPersonal(item)">
                  <!-- <van-icon name="edit" /> -->
                </div>
              </div>
              <div class="study_status" @click="goToStatus(item)">
                <img :src="getStatusImg(item.graduation)" v-if="item.graduation != 0" />
                <span v-else>未计算结业</span>
              </div>
            </div>
            <div class="student_right_2nd">
              <div class="student_zhichen" v-text="`${item.company} - ${item.job}`"></div>
              <div class="student_detail" @click="goToDetail(item)">查看详情</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getTeaClassPeopleList } from '@/api/my'
export default {
  data() {
    return {
      list: [],
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      defaultSrc: require('@/assets/images/head_prole.png'),
      className: this.$route.query.className, //班圈名称
      classId: this.$route.query.classId //班圈id
    }
  },
  methods: {
    //   获取 结业 图标
    getStatusImg(state) {
      // 0未计算结业，待结业，已结业，不及格
      switch (state) {
        case 0: //未计算结业
          return '' //require('')
        case 1:
          return require('@/assets/icon/not-graduation.png')
          break //待结业
        case 2:
          return require('@/assets/icon/has-graduation.png')
          break //已结业
        case 3:
          return require('@/assets/icon/not-pass.png')
          break //不合格
      }
    },
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getTeaClassPeopleList(this.classId)
        .then(res => {
          this.list = res.list
          this.success(res)
        })
        .catch(this.fail)
    },
    // 成功回调
    success(res) {
      this.loading = false
      this.page += 1
      this.pullRefresh = false
      this.finished = true
    },
    // 失败回调
    fail(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // 状态(待结业 已结业 不及格)
    goToStatus(item) {
      console.error('结业状况', JSON.parse(JSON.stringify(item)))
      this.$router.push({
        path: `/student_score_status/${item.stuid}`
      })
    },
    // 跳转到【修改学员信息】
    goToPersonal(item) {
      return '暂不开放老师修改学员信息'
      // this.$router.push({
      //   path: '/personalInfo',
      //   query: {
      //     studentUserId: item.userid
      //   }
      // })
    },
    // 跳转到【成绩详情列表】
    goToDetail(item) {
      this.$router.push({
        path: `/student_score_list/${item.stuid}`,
        query: {
          classId: this.classId,
          stuId: item.stuid,
          stuUserId: item.userid //学员userid
        }
      })
    }
  },
  mounted() {
    // window.T = {}
    // T.vm = this
    // this.list = mockData
  }
}
</script>

<style lang="scss" scoped>
@mixin flexsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page {
  @include isTop;
  .theme_color_blue {
    color: $active_color;
  }
  .student_list {
    padding: 2.4vw 3.2vw 4vw 3.2vw;
    display: flex;
    align-items: center;
    position: relative;
    .student_list_left {
      @include wh(15.733vw, 15.733vw);
      @include borderRadius(50%);
      overflow: hidden;
      img {
        @include imgCover;
      }
    }
    .student_list_right {
      flex: 1;
      margin-left: 3.733vw;
      font-size: 3.467vw;
      .student_right_1st {
        @include flexsb;
        .student_name {
          display: flex;
          align-items: center;
          .student_modify {
            margin-left: 1.867vw;
            @include wh(3.333vw, 3.333vw);
            .van-icon {
              color: #ccc;
            }
          }
        }
        .study_status {
          @include wh(16vw, 5.333vw);
          img {
            @include imgCover;
          }
          span {
            color: $font-gray;
            @include font(3.2vw, 5.333vw);
          }
        }
      }
      .student_right_2nd {
        @include flexsb;
        font-size: 3.2vw;
        padding-top: 2.667vw;
        .student_zhichen {
          color: $font-gray;
        }
        .student_detail {
          color: #535353;
        }
      }
    }
  }
  .student_list::after {
    content: '';
    width: calc(100% - 15.733vw - 3.733vw - 6.4vw);
    border-bottom: 1px solid $border-light;
    position: absolute;
    bottom: 0;
    right: 3.2vw;
  }
}
</style>
