<template>
  <div class="set_homework">
    <div style="height: 2.667vw;"></div>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="classring_list"
          @click="publishjob(item.lesson_id)"
          v-for="(item,i) in list"
          :key="'homeworkComment'+i"
        >
          <div class="classring_list_left">
            <img :src="item.cover" alt />
          </div>
          <div class="classring_list_right">
            <p class="classring_list_title">{{item.lesson_title}}</p>
            <div class="classring_list_right_bottom">
              <span class="classring_list_time">
                <span class="not" v-if="item.new!==0"></span>
                {{item.new}}条新回答
              </span>
            </div>
          </div>
        </div>

        <!-- 课程列表: 请做到的时候考虑一下是否可以用这个组件 -->
        <!-- <courseList :list="list" @click="goToClassDetails"/> -->
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { getCourseRecord } from '@/api/my'
import courseList from '@/components/extend/courseList'

export default {
  components: { courseList },
  data() {
    return {
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      return
      getCourseRecord(this.page, 3)
        .then(res => {
          this.loading = false
          this.list.push(...res.data.list)
          this.page += 1
          this.pullRefresh = false
          if (res.data.current_page >= res.data.total_page) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    publishjob(id) {
      this.$router.push('/homework_list/' + id)
    }
  }
}
</script>

<style scoped>
.not {
  display: block;
  width: 1.7vw;
  height: 1.7vw;
  background-color: #ff3b30;
  border-radius: 100%;
  margin-right: 0.8vw;
}

.classring_list_right_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.set_homework {
  height: 100vh;
  background-color: #f5f7fa;
}

.classring_list_time {
  font-size: 2.933vw;
  color: #909399;
  display: block;
  display: flex;
  align-items: center;
}

.classring_list_title {
  font-size: 4.267vw;
  line-height: 6.4vw;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.classring_list_right {
  width: 53vw;
  height: 22.133vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.classring_list_left {
  width: 32.333vw;
  height: 22.133vw;
  background-color: blue;
  border-radius: 1.5vw;
  margin-left: 1.2vw;
}
.classring_list_left > img {
  width: 100%;
  height: 100%;
  border-radius: 1.5vw;
  object-fit: cover;
}

.classring_list {
  width: 90%;
  height: 28.267vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edeff2;
  background-color: #ffffff;
}
</style>
