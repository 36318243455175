<!-- 资料 -->
<template>
  <div class="main">
    <div
      class="datum"
      :class="{bottomBorder:bottomBorder}"
      v-for="(item,i) in list"
      :key="'material'+i"
    >
      <div class="datum_left" @click="open(item)">
        <img src="@/assets/icon/jpg.png" v-if="['jpg','jpeg'].includes(item.fileext)" />
        <img src="@/assets/icon/png.png" v-if="['png'].includes(item.fileext)" />
        <img src="@/assets/icon/txt.png" v-if="['txt'].includes(item.fileext)" />
        <img src="@/assets/icon/word.png" v-if="['doc','docx'].includes(item.fileext)" />
        <img src="@/assets/icon/excel.png" v-if="['xls','xlsx'].includes(item.fileext)" />
        <img src="@/assets/icon/ppt.png" v-if="['ppt','pptx'].includes(item.fileext)" />
        <img src="@/assets/icon/pdf.png" v-if="['pdf'].includes(item.fileext)" />
      </div>
      <div class="datum_right">
        <div class="datum_right_name" :class="{hasDeleteBtn: isdelete}">
          <span @click="open(item)">{{item.title || '没有名字'}}</span>
          <span class="deleteF" v-if="isdelete" @click="deleteFile(item)">删除</span>
        </div>
        <div class="rq" @click="open(item)">
          <span v-if="item.crdate">{{item.crdate | timeStamp | dateFormat('yyyy/MM/dd hh:mm:ss')}}</span>
          <span v-if="item.lag" v-text="`${item.lag}M`"></span>
          <!-- <span v-else v-text="`-- M`"></span> -->
        </div>
      </div>
      <div v-if="dot" class="dot" @click="actDot">···</div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'material',
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      // A-资料(material.vue) B-上传新资料(uploadFile.vue)
      type: String,
      default: 'A'
    },
    dot: Boolean, //是否展示右边···
    bottomBorder: Boolean, //底边边框
    openFile: Boolean, //是否打开文档
    isdelete: Boolean //是否删除
  },
  methods: {
    // 打开文档
    open(info) {
      if (this.openFile) {
        // 图片
        const img = ['jpg', 'jpge', 'png']
        // 微软办公三剑客
        const wep = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
        // pdf
        const pdf = ['pdf']
        if (img.indexOf(info.fileext) !== -1) {
          this.imagePreview = ImagePreview({
            images: [info.url],
            showIndex: false,
            closeable: true
          })
        }
        if (wep.indexOf(info.fileext) !== -1) {
          window.location.href =
            'https://view.officeapps.live.com/op/view.aspx?src=' + info.url
        }
        if (pdf.indexOf(info.fileext) !== -1) {
          window.location.href = info.url
        }
      }
    },
    deleteFile(item) {
      this.$emit('delete', item)
    },
    actDot(info) {
      this.$toast('@toAsk 代确认具体功能')
      this.$emit('actDot', info)
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.datum {
  margin: 0 4.533vw;
  width: calc(100% - 9.067vw);
  height: 16vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .datum_left {
    width: 9.333vw;
    height: 11.2vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .datum_right {
    box-sizing: border-box;
    padding-left: 4vw;
    flex: 1;
    .datum_right_name {
      font-size: 4.2vw;
      color: #303133;
      word-break: break-word;
    }
    .hasDeleteBtn {
      padding-right: 2em;
      position: relative;
      .deleteF {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 1vw;
        color: $font-gray;
        font-size: 0.2vw;
      }
    }
    .rq {
      font-size: 2.933vw;
      color: $font-gray;
      display: flex;
      justify-content: space-between;
      margin-top: 1.5vw;
    }
  }
  .dot {
    padding-left: 2vw;
    font-weight: 600;
    color: $font-gray;
  }
}
.bottomBorder {
  border-bottom: 1px solid $font-light;
}
</style>
