<template>
  <div class="search_page">
    <div class="lessonList" v-for="(item, i) in list" :key="item.title + i" @click="act(item)">
      <div class="lesson_left">
        <img v-if="item.pic" :src="item.pic" />
        <video v-else :src="item.videourl || item.liveurl"></video>
      </div>
      <div class="lesson_right">
        <div class="lesson_name">
          <van-tag plain :type="item.showmode == '直播' ? 'warning' : 'success'">{{item.showmode}}</van-tag>
          <!-- item.showmode == "录播" ? "辅导" : "预习" -->
          <span v-text="item.title"></span>
        </div>
        <div class="lesson_connent" v-text="item.content"></div>
        <div class="lesson_teacher">
          <span class="teacher_name" v-text="item.classownername1"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    act(info) {
      this.$emit('click', info)
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.search_page {
  // 课程样式
  .lessonList {
    padding: 0 3.2vw 3.2vw 3.2vw;
    // padding: 0 0 3.2vw 0;
    height: 24.933vw;
    background: #fff;
    box-sizing: border-box;
    @include borderRadius(1.067vw);
    .lesson_left {
      @include wh(33.33vw, 22.133vw);
      box-shadow: $box-shadow;
      border-radius: 6%;
      overflow: hidden;
      float: left;
      background: black;
      img {
        @include imgCover;
      }
      video {
        @include wh(100%, 100%);
      }
    }
    .lesson_right {
      padding-left: 2vw;
      height: 22.133vw;
      font-size: 2.9vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .lesson_name {
        font-size: 4.2vw;
        @include dot2;
        .van-tag {
          margin-right: 0.667vw;
          margin-bottom: 1px;
        }
      }
      .lesson_connent {
        flex: 1;
        padding-top: 1px;
        @include dot;
      }
      .lesson_teacher {
        span {
          min-width: 4em;
          text-align: center;
          display: inline-block;
          padding: 0.667vw 1.333vw;
          border: 1px solid $border-gray;
          border-radius: 1em;
          color: $font-gray;
        }
      }
    }
  }
}
</style>
