<template>
  <!-- 考勤列表 -->
  <div class="page">
    <topheader :title="titleText" />
    <div class="attendance_title">
      <span>考勤次数</span>
      <span>考勤时间</span>
    </div>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="attendance_box" v-for="(item, i) in list" :key="'classring' + i">
          <van-icon name="checked" />
          <p>
            <span class="attendance_box_time">第{{i+1}}次</span>
            <span class="attendance_box_date">{{item.crdate}}</span>
          </p>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMySignList } from '@/api/my'
export default {
  data() {
    return {
      type: this.$route.params.type, //来源
      studentUserId: this.$route.query.studentUserId,
      lessonId: this.$route.query.lessonId,

      studentId: '', //学生id
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  computed: {
    titleText() {
      // 1-我的考勤>>>课程>>>记录
      // 2-专家栏目>>>考勤记录>>>课程>>>考勤详情>>>考勤明细
      switch (this.type) {
        // case '1':
        //   return '考勤列表'
        //   break
        case '2':
          return '考勤明细'
          break
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMySignList(this.studentUserId, this.lessonId)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  font-size: 3.2vw;
  .attendance_title {
    height: 9.867vw;
    background: $bg-light;
    color: $font-gray;
    padding: 0 3.2vw 0 6.667vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
    }
  }
  .van-pull-refresh {
    height: calc(100% - 9.867vw);
    overflow-y: scroll;
  }
  .attendance_box {
    padding: 0 3.2vw;
    position: relative;
    height: 12.267vw;
    background: #fff;
    line-height: 12.267vw;
    font-size: 3.2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .van-icon {
      font-size: 4.533vw;
      padding-left: 3.2vw;
      color: #00d2b2;
    }
    p {
      flex: 1;
      .attendance_box_time {
        margin-left: 4.8vw;
      }
      .attendance_box_date {
        float: right;
      }
    }
  }
  .attendance_box::after {
    content: '';
    display: block;
    width: calc(100% - 6.4vw);
    border-bottom: 1px solid $bg-gray;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
