<template>
  <!-- 专家栏目>>>点击:公告>>>公告列表 -->
  <div class="page">
    <topheader>
      <!-- <van-icon name="add-o" slot="rightTools" @click="createNewAccounce" /> -->
      <van-button type="info" size="small" slot="rightTools" @click="createNewAccounce">发布</van-button>
    </topheader>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 公告列表 -->
        <Affiche
          canDelete
          :list="list"
          :can2Top="can2Top"
          @showmore="goToDetail"
          @deleteFn="deleteAnnouncement"
          @toTop="toTop"
        />
      </van-list>
    </van-pull-refresh>

    <!-- 新增公告 -->
    <!-- <van-dialog
      v-model="ceateNew"
      title="新建公告"
      show-cancel-button
      cancel-button-text="取消"
      confirm-button-text="新增"
      :beforeClose="checking"
    >
      <div class="newAnnoundance">
        <van-field
          v-model="newAnnouncement.title"
          rows="1"
          autosize
          type="textarea"
          placeholder="公告标题"
          show-word-limit
          class="announdance_title"
        />
        <van-field
          v-model="newAnnouncement.describe"
          rows="2"
          autosize
          type="textarea"
          placeholder="公告描述"
          show-word-limit
          class="announdance_desc"
        />
        <div class="announdance_cover">
          <p class="announdance_cover_title">
            <span>封面图片</span>
          </p>
          <div class="announdance_cover_image">
            <template v-if="coverImage">
              <img :src="coverImage" alt />
              <van-icon name="clear" @click="coverImage = undefined" />
            </template>
            <van-icon name="photograph" v-else />
            <input class="update-img" type="file" accept="image/*" @change="uploadCoverImg" />
          </div>
        </div>
        <div class="announdance_connect">
          <div class="announdance_connect_header">
            <span>公告内容（1文字+1图片）</span>
          </div>
          <van-field
            v-model="newAnnouncement.contentText"
            rows="2"
            autosize
            type="textarea"
            placeholder="公告文字内容"
            show-word-limit
            class="announdance_content_text"
          />
          <div class="announdance_content_image">
            <template v-if="contentImage">
              <img :src="contentImage" alt />
              <van-icon name="clear" @click="contentImage = undefined" />
            </template>
            <van-icon name="photograph" v-else />
            <input class="update-img" type="file" accept="image/*" @change="uploadContentImg" />
          </div>
        </div>
        <div class="announdance_author">
          <van-field v-model="newAnnouncement.author" placeholder="请输入..." label="作者:" />
        </div>
      </div>
    </van-dialog>-->
    <div class="spinner_box" v-if="spinnerLoading">
      <van-loading type="spinner" color="#1989fa" />
    </div>
  </div>
</template>
<script>
import { getNoticeList, toTopNotice, getCourseList } from '@/api/classRing'
import { editeNoticeinfo } from '@/api/my'
import { sort2top } from '@/assets/js/public'

import Affiche from '@/components/ClassRing/widget/affiche'
export default {
  components: { Affiche },
  data() {
    return {
      page: 1, // 当前页数
      userId: this.$store.state.user.userId,
      classId: this.$route.query.classId,
      className: this.$route.query.className, //建议放出来页面给用户看
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      spinnerLoading: false,
      newAnnouncement: {
        classid: this.$route.query.classId,
        type: 9, //新建
        sqnum: 99, //序号
        autoid: '', //公告记录id(新增不用)
        title: '', //标题
        pic: '', //封面图
        describe: '', //描述
        author: this.$store.state.user.name, //作者
        contentText: '', //文字内容
        contentImage: '', //图片内容
        content: '' // contentText + contentImage
      },
      can2Top: false //是否有置顶权限
    }
  },
  filters: {
    serial(i) {
      return i <= 9 ? `0${i}` : i
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getNoticeList(this.classId, this.page)
        .then(res => {
          this.loading = false
          this.pullRefresh = false
          let arr = this.list.concat(res.list)
          this.list = []
          this.$nextTick(() => {
            this.list = sort2top(arr) //优先选出置顶的数据，其余按时间排列
          })
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 新增公告
    createNewAccounce() {
      this.coverImage = undefined //封面图片（新增-暂存）
      this.contentImage = undefined //内容图片（新增-暂存）
      this.$router.push({
        path: '/createAnnouncement',
        query: {
          classId: this.classId,
          className: this.className
        }
      })
    },
    // 删除公告
    deleteAnnouncement(item) {
      let params = {
        classid: this.classId,
        title: '',
        content: '',
        describe: '',
        author: this.$store.state.user.name,
        sqnum: '',
        pic: '',
        autoid: item.autoid,
        type: 0
      }
      let announceName = ''
      if (item.title.length <= 8) {
        announceName = item.title
      } else {
        announceName = item.title.slice(0, 8) + '...'
      }
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要删除“${announceName}”吗？`
        })
        .then(() => {
          editeNoticeinfo(params).then(res => {
            this.list = this.list.filter(it => it.autoid != item.autoid)
            this.$toast({
              message: '删除成功',
              position: 'bottom'
            })
          })
        })
    },
    // 查看公告列表
    goToDetail(item) {
      this.$router.push({
        path: `/announcementDetails/${item.autoid}`
      })
    },
    // 公告置顶
    toTop(id) {
      toTopNotice(id).then(res => {
        this.$toast({
          message: '置顶成功',
          position: 'bottom'
        })
      })
      this.onRefresh()
    }
  },
  created() {
    getCourseList(this.classId).then(res => {
      let headMasterUserArr = []
      res.list.forEach(item => {
        headMasterUserArr.push(item.classowneruserid1)
      })
      headMasterUserArr = [...new Set(headMasterUserArr)]
      this.can2Top = headMasterUserArr.some(it => it == this.userId)
    })
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  .top_header {
    .right {
      // font-size: 5.6vw;
      .van-button {
        min-width: 4em;
        padding: 0;
      }
    }
  }
  // 新增蒙层
  .spinner_box {
    @include wh(100vw, 100vh);
    @include center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .van-loading--spinner {
      @include center;
    }
  }
}
// 弹窗蒙层
/deep/.van-overlay {
  @include wh(100vw, 100vh);
}
</style>
