<template>
  <!-- 考勤详情 -->
  <div class="page">
    <topheader />
    <div class="student_attendance_title">学员考勤表</div>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 考勤表 -->
        <div class="attendance_list" v-for="(item, idx) in list" :key="idx">
          <div class="student_info">
            <div class="avatar">
              <img :src="item.headurl" v-if="item.headurl" />
              <img :src="defaultSrc" v-else />
            </div>
            <div class="student_name">{{item.name}}</div>
          </div>
          <div class="attendance_record" v-if="mode == '直播'">已考勤{{item.signnum}}次</div>
          <div class="student_score">
            <template v-if="mode == '直播'">
              <span>播放时长</span>
              <span>{{formatSeconds(item.learntime)}}</span>
            </template>
            <span v-else class="tar">播放时长 {{formatSeconds(item.learntime)}}</span>
          </div>
          <div class="student_detail" @click="goTodetail(item)" v-if="mode == '直播'">详情</div>
        </div>
        <!-- 空 -->
        <p class="empty" v-if="list.length === 0">暂无数据</p>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getTeaSignCount } from '@/api/my'
import { formatSeconds } from '@/assets/js/public'
export default {
  data() {
    return {
      classId: this.$route.query.classId,
      lessonId: this.$route.query.lessonId,
      mode: this.$route.query.mode, //视频类型 [直播, 录播]
      page: 1, // 当前页数
      list: [], // 显示的数据 todo 数据请求未处理的
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      defaultSrc: require('@/assets/images/head_prole.png'),
      type: this.$route.params.type // 1-我的计划 2-我的考勤 3-考勤记录
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getTeaSignCount(this.classId, this.lessonId)
        .then(res => {
          this.success(res)
          this.list.push(...res.list)
        })
        .catch(this.fail)
    },
    // 成功回调
    success(res) {
      this.loading = false
      this.pullRefresh = false
      this.finished = true
    },
    // 失败回调
    fail(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // 考勤明细
    goTodetail(item) {
      this.$router.push({
        path: '/attendance_records/2',
        query: {
          studentUserId: item.userid,
          lessonId: this.lessonId
        }
      })
    },
    formatSeconds(v) {
      let time = formatSeconds(v)
      const { day, hour, minute, second } = time
      if (Number(day)) return `${day}天${hour}时${minute}分${second}秒`
      if (hour) return `${hour}时${minute}分${second}秒`
      if (minute) return `${minute}分${second}秒`
      return `${second}秒`
    }
  },
  mounted() {
    window.T = {}
    T.vm = this
  }
}
</script>

<style lang="scss" scoped>
$per: 750/1080;
.page {
  @include isTop;
  .student_attendance_title {
    @include font(5.333vw * $per, 14vw * $per);
    background: $active-color;
    text-align: center;
    color: #fff;
  }
  .attendance_list {
    height: 29.867vw * $per;
    padding: 0 4.8vw * $per;
    font-size: 4.533vw * $per;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .student_info {
      flex: 1;
      display: flex;
      align-items: center;
      .avatar {
        @include wh(16.8vw * $per, 16.8vw * $per);
        @include borderRadius(50%);
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .student_name {
        margin-left: 1.333vw * $per;
      }
    }
    .attendance_record {
      flex: 1;
      text-align: center;
    }
    .student_score {
      flex: 1;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      span {
        display: block;
        // @include wh(16.8vw * $per, 6.667vw * $per);
        // @include borderRadius(3.333vw * $per);
        // @include font(3.467vw * $per, 6.667vw * $per);
        @include font(3.2vw, 3.6vw);
        // border: 1px solid #1c805e;
        text-align: center;
        color: #1c805e;
      }
      .tar {
        text-align: right;
      }
    }
    .student_detail {
      padding-right: 5.333vw * $per;
      position: relative;
    }
    .student_detail::after {
      content: '';
      display: block;
      @include wh(2.667vw * $per, 2.667vw * $per);
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      position: absolute;
      right: 1.333vw * $per;
      top: 55%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  .attendance_list::after {
    content: '';
    display: block;
    width: 93.333vw;
    border-bottom: 1px solid $border-gray;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .empty {
    text-align: center;
    @include font(3.6vw, 8.2vw);
    color: $font-gray;
  }
}
</style>
