<template>
  <div class="page">
    <topheader />
    <div class="recommend_header">
      <ul class="recommend_header_box">
        <li
          v-for="(item, index) in ['推荐总数', '个人推荐', '团队推荐']"
          :key="index"
          v-text="item"
          @click="goToTeamRecommend(index)"
        ></li>
      </ul>
      <ul class="recommend_header_box people">
        <li v-text="recommend.total + '人'"></li>
        <li v-text="recommend.person + '人'"></li>
        <li v-text="recommend.team + '人'" @click="goToTeamRecommend(2)"></li>
      </ul>
      <ul class="recommend_header_box record">
        <li>推荐提成记录</li>
      </ul>
    </div>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        finished-text="没有更多了"
        error-text="加载失败，点击重新加载"
        :finished="finished"
        :error.sync="error"
        @load="onLoad"
      >
        <!-- 推荐列表 -->
        <div class="recommend_list" v-for="(item, idx) in list" :key="idx">
          <div class="recommend_list_left">
            <div class="recommend_list_lesson" v-text="item.classname"></div>
            <div class="recommend_list_user" v-text="item.name"></div>
          </div>
          <div class="recommend_list_right">
            <div class="recommend_list_money" v-text="item.acmoney"></div>
            <div class="recommend_list_date">{{item.crdate}}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyRecommend } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      // page: 1, // 当前页数
      list: [],
      recommend: {
        total: 0,
        person: 0,
        team: 0
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyRecommend(this.userId)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.recommend.total = res.tjnum1
          this.recommend.person = res.tjnum2
          this.recommend.team = res.tjnum3
          this.pullRefresh = false
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    goToTeamRecommend(idx) {
      if (idx === 2) {
        //团队推荐
        this.$router.push({
          path: '/team_recommend'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin flex_sb {
  display: flex;
  justify-content: space-between;
}
.page {
  @include isTop;
  .recommend_header {
    .recommend_header_box {
      @include wh(100%, 10.133vw);
      padding: 0 4vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $active-color;
      color: #fff;
      font-size: 4vw;
      text-align: center;
      li:not(:nth-child(2)) {
        width: 22.933vw;
      }
      li:nth-child(2) {
        flex: 1;
      }
    }
    .people {
      background: #fff;
      color: $font-black;
      height: 12.533vw;
    }
    .record {
      height: 12vw;
      background: #f6f6f6;
      color: $active-color;
      li {
        flex: 1;
      }
    }
  }

  .recommend_list {
    @include wh(100%, 24.533vw);
    box-sizing: border-box;
    padding: 5.333vw 4vw;
    position: relative;
    @include flex_sb;
    .recommend_list_left {
      flex: 1;
      @include flex_sb;
      flex-direction: column;
      .recommend_list_lesson {
      }
      .recommend_list_user {
      }
    }
    .recommend_list_right {
      @include flex_sb;
      flex-direction: column;
      .recommend_list_money {
        text-align: right;
        color: $warn-color;
      }
      .recommend_list_date {
        color: $font-gray;
        font-size: 2.667vw;
      }
    }
  }
  .recommend_list::after {
    content: '';
    display: block;
    width: calc(100% - 8vw);
    border-bottom: 1px solid $border-gray;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}
</style>
