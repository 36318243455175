import { getOpenId, setOpenId } from "@/util/auth";
import axios from 'axios'
import { getAuthorityParams } from "@/api/pay";
import request from '@/util/request'

// 初始化

const APP_ID = 'wxa3cbbb87523bdf64';

// 获取query(计算一次就失效)
function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

function getopenid() {
  return new Promise((resolve, reject) => {
    setOpenId('111')
    resolve('111')
    // axios.get('https://heying.hyclound.cn:8015/Server/GetOpenid?code=' + getQueryVariable('code'))
    //   .then(res => {
    //     setOpenId(res.data);
    //     resolve(res.data);
    //   }).catch(err => {
    //     reject(err);
    //   })
  })
}

export function init(callback) {
  // 获取openId
  callback()
  // const hasOpenId = getOpenId();
  // if (hasOpenId) { // 有openId
  //   callback();
  // } else { // 没有openId
  //   const url = window.location.href;
  //   if (getQueryVariable('code')) { // 有code参数
  //     getopenid().then(openId => {
  //       if (openId) {
  //         callback();
  //       } else {
  //         const redirect = url.replace(/(\?code=)[\da-zA-Z]+&state=STATE/ig, '')
  //         window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  //       }
  //     }).catch((err => {
  //       console.log('获取openId失败')
  //     }))
  //   } else { // 没有code参数
  //     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  //   }
  // }
}
