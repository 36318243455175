<!-- 课程详情 >>> 选择:公告 -->
<template>
  <div class="box">
    <!-- 学员列表 & 班级资料 -->
    <ul class="baseInfoBox">
      <li v-for="(item, index) in baseInfoList" :key="index" @click="goToDetail(item)">
        <div class="baseInfoBox_left">
          <img :src="item.resource" />
        </div>
        <div class="baseInfoBox_right">
          <p class="file_name" v-text="item.title"></p>
          <p class="file_create_date">
            {{item.createtime | dateFormat('yyyy/MM/dd hh:mm:ss')}}
            <span class="file_show_more">查看</span>
          </p>
        </div>
      </li>
    </ul>
    <!-- 公告详情 -->
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 列表 -->
        <Affiche
          :list="list"
          @showmore="goToAnnouncementDetail"
          :can2Top="headMasterUserId == userId"
          @toTop="toTop"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getNoticeList, toTopNotice } from '@/api/classRing'
import Affiche from '@/components/ClassRing/widget/affiche'
import { sort2top } from '@/assets/js/public'
export default {
  props: {
    classId: [Number, String], // 班圈分类id
    // active: Number,
    // curLessonIdx: Number,
    // headmasterId: Number,//班主任用户id
    headMasterUserId: Number, //班主任userid
    supervisorId: Number, //导师  用户id
    assistantUserId: Number //辅导员userid
  },
  components: { Affiche },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      baseInfoList: [
        //学员列表 & 班级资料
        {
          title: '班圈名单',
          type: 1,
          resource: require('@/assets/icon/student_list.png'),
          createtime: 1582250760
        },
        {
          title: '班圈资料',
          type: 2,
          resource: require('@/assets/icon/class_info.png'),
          createtime: 1582250760
        }
      ]
    }
  },
  computed: {
    isTeacher() {
      return (
        this.userId == this.headmasterId || this.userId == this.supervisorId
      )
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      this.getAnnouncements()
    },
    // 获取公告
    getAnnouncements() {
      getNoticeList(this.classId, this.page)
        .then(res => {
          this.loading = false
          // this.list.push(...res.list)
          let arr = this.list.concat(res.list)
          this.list = sort2top(arr)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->课程介绍
    goToAnnouncementDetail(info) {
      this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
      this.$router.push({
        path: '/announcementDetails/' + info.autoid, //公告id
        query: {
          active: this.active,
          curLessonIdx: this.curLessonIdx
        }
      })
    },
    goToDetail(info) {
      this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
      if (info.type === 1) {
        //班圈名单
        this.$router.push({
          path: `/contactGroup/${this.classId}`,
          query: {
            active: this.active,
            curLessonIdx: this.curLessonIdx
            // headMasterUserId: this.headMasterUserId,
            // supervisorId: this.supervisorId,
            // assistantUserId: this.assistantUserId
          }
        })
      } else if (info.type === 2) {
        //班圈资料
        this.$router.push({
          path: `/materialGroup/${this.classId}`,
          query: {
            active: this.active,
            curLessonIdx: this.curLessonIdx
          }
        })
      }
    },
    toTop(id) {
      toTopNotice(id).then(res => {
        this.$toast({
          message: '置顶成功',
          position: 'bottom'
        })
      })
      this.onRefresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 0 3.2vw;
  .baseInfoBox {
    padding-top: 2.667vw;
    li {
      width: 100%;
      padding: 3.333vw 0;
      padding-right: 0;
      display: flex;
      justify-content: space-between;
      .baseInfoBox_left {
        @include wh(6.133vw, 6.933vw);
        img {
          @include wh(100%, 100%);
          object-fit: cover;
        }
      }
      .baseInfoBox_right {
        flex: 1;
        margin-left: 1.333vw;
        color: #303133;
        .file_name {
          font-size: 4.2vw;
        }
        .file_create_date {
          padding-top: 1.867vw;
          font-size: 2.9vw;
          color: $font-gray;
          span {
            float: right;
          }
        }
      }
    }
  }
}
</style>
