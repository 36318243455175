<template>
  <div v-if="testList.length" class="page">
    <topheader @beforeBack="beforeBack" />
    <!-- <div class="choice_question">
      <div class="last_question" v-if="testIndex !== 0" @click="last">
        上一题
      </div>
      <div class="last_question-off" v-else></div>
      <div>
        <span class="premise">{{ testIndex + 1 }}</span>
        <span class="topic">/{{ testList.length }}题</span>
      </div>
      <div
        class="Next_question"
        v-if="testList.length !== testIndex + 1"
        @click="next"
      >
        下一题
      </div>
      <div class="Next_question" v-else @click="back">返回</div>
    </div>-->

    <div class="question_header">
      <div class="question_number">
        <span class="premise">{{ testIndex + 1 }}</span>
        <span class="topic">/{{ testList.length }}题</span>
        <!-- 若是考试才有计时器 -->
        <div class="score_time">
          <!-- <van-icon name="clock-o" />
          <span v-text="countdown"></span>-->
        </div>
      </div>
    </div>

    <div class="topic_select">
      <div class="topic_select_box" v-if="testList[testIndex].type === 1">单选题</div>
      <div class="topic_select_box" v-if="testList[testIndex].type === 2">多选题</div>
      <div
        class="topic_select_title"
      >{{ testList[testIndex] ? testList[testIndex].examination_name : "" }}</div>
    </div>
    <div class="answer_list">
      <div
        :class="
          `answer ${
            item.is_correct === 1
              ? 'pitch'
              : judgeAnswer(item)
              ? 'incorrect'
              : ''
          }`
        "
        v-for="(item, i) in testList[testIndex].answer"
        :key="'testResult' + i"
      >
        <div>
          <p>{{ `${item.answer_name}.` }}</p>
          <p style="margin-left: 1vw;">{{ `${item.answer_content}` }}</p>
        </div>
        <img src="@/assets/images/dui.png" class="yes" v-if="item.is_correct === 1" />
        <img src="@/assets/images/cuo.png" class="no" v-if="judgeAnswer(item)" />
      </div>
    </div>

    <div class="buttons">
      <template>
        <van-button
          v-if="testIndex + 1 === 1 && testIndex + 1 !== testList.length"
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>

        <van-button
          v-else-if="testIndex + 1 !== 1 && testList.length !== 1"
          block
          plain
          type="primary"
          color="#247dff"
          @click="last"
        >上一题</van-button>
      </template>
      <template>
        <div v-show="testIndex + 1 === 1 && testList.length !== 1" class="empty"></div>

        <van-button
          v-if="testIndex + 1 !== 1 && testIndex + 1 !== testList.length"
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>
      </template>
    </div>
  </div>
</template>

<script>
// import { getProblemSets } from '@/api/classRing'
import eventBus from '@/util/eventBus'
const mockData = {
  status: '200',
  data: {
    list: [
      {
        examination_id: 16,
        examination_name: '1+1=？',
        type: 1,
        no: 1,
        test_id: 11,
        worth: 10,
        user_answer: null,
        answer: [
          {
            id: 28,
            answer_name: 'A',
            no: 1,
            answer_content: '2',
            is_correct: 1,
            examination_id: 16
          },
          {
            id: 29,
            answer_name: 'B',
            no: 2,
            answer_content: '3',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 30,
            answer_name: 'C',
            no: 3,
            answer_content: '0',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 31,
            answer_name: 'D',
            no: 4,
            answer_content: '5',
            is_correct: 0,
            examination_id: 16
          }
        ]
      },
      {
        examination_id: 16,
        examination_name: '1+1=？',
        type: 1,
        no: 1,
        test_id: 11,
        worth: 10,
        user_answer: null,
        answer: [
          {
            id: 28,
            answer_name: 'A',
            no: 1,
            answer_content: '2',
            is_correct: 1,
            examination_id: 16
          },
          {
            id: 29,
            answer_name: 'B',
            no: 2,
            answer_content: '3',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 30,
            answer_name: 'C',
            no: 3,
            answer_content: '0',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 31,
            answer_name: 'D',
            no: 4,
            answer_content: '5',
            is_correct: 0,
            examination_id: 16
          }
        ]
      },
      {
        examination_id: 16,
        examination_name: '1+1=？',
        type: 1,
        no: 1,
        test_id: 11,
        worth: 10,
        user_answer: null,
        answer: [
          {
            id: 28,
            answer_name: 'A',
            no: 1,
            answer_content: '2',
            is_correct: 1,
            examination_id: 16
          },
          {
            id: 29,
            answer_name: 'B',
            no: 2,
            answer_content: '3',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 30,
            answer_name: 'C',
            no: 3,
            answer_content: '0',
            is_correct: 0,
            examination_id: 16
          },
          {
            id: 31,
            answer_name: 'D',
            no: 4,
            answer_content: '5',
            is_correct: 0,
            examination_id: 16
          }
        ]
      }
    ],
    isDone: 1,
    total: 1
  }
}
export default {
  data() {
    return {
      testId: this.$route.params.id, // 测试题组编号
      testList: [], // 题组列表
      testIndex: 0, // 当前题组下标
      rightAnswers: [], // 正确答案
      myAnswer: [], // 你的回答
      bg: true
    }
  },
  methods: {
    init() {
      return
      getProblemSets(this.testId).then(res => {
        // this.testList = res.data.list;
        this.getMyAnswer()
        this.getRightAnswers()
      })
    },
    // 获取我的回答
    getMyAnswer() {
      for (let i in this.testList) {
        if (this.testList[i].type === 2) {
          // 多选
          if (this.testList[i].user_answer) {
            // 有答案
            this.myAnswer.push(this.testList[i].user_answer.split(','))
          } else {
            // 没有答案
            this.myAnswer.push([])
          }
        } else {
          // 单选
          this.myAnswer.push(this.testList[i].user_answer)
        }
      }
    },
    // 获取正确答案
    getRightAnswers() {
      for (let i in this.testList) {
        let correct = [] // 正确答案
        let answerList = this.testList[i].answer // 答案组
        for (let j in answerList) {
          if (answerList[j].is_correct === 1) {
            // 是正确答案
            correct.push(answerList[j].answer_name)
          }
        }
        this.rightAnswers.push(correct)
      }
    },
    // 判断答案对错
    judgeAnswer(item) {
      let userAnswer = this.testList[this.testIndex].user_answer
      if (this.testList[this.testIndex].type === 1) {
        // 单选
        if (item.answer_name === userAnswer) {
          if (item.is_correct === 1) {
            return false
          } else {
            return true
          }
        }
      } else {
        // 多选
        if (userAnswer.split(',').includes(item.answer_name)) {
          if (item.is_correct === 1) {
            return false
          } else {
            return true
          }
        }
      }
    },
    // 下一题
    next() {
      this.testIndex += 1
    },
    // 上一题
    last() {
      this.testIndex -= 1
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 返回前传参
    beforeBack() {
      // 返回 Course_home 页，需要反显课程序号 + tab下标
      eventBus.$emit('courseHome', {
        active: this.$route.query.active,
        curLessonIdx: this.$route.query.curLessonIdx
      })
    }
  },
  mounted() {
    this.init()
    // 测试专用，用完请删
    this.testList = mockData.data.list
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  // 题号（序号+倒计时）
  .question_header {
    .question_number {
      position: relative;
      height: 14vw;
      line-height: 14vw;
      text-align: center;
      width: 100%;
      .premise {
        font-size: 4vw;
        color: $active-color;
      }
      .topic {
        font-size: 4vw;
        font-weight: normal;
        color: #303133;
      }
      // 倒计时
      .score_time {
        position: absolute;
        top: 50%;
        right: 3vw;
        transform: translateY(-50%);
        color: $active-color;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          padding-left: 1vw;
        }
      }
    }
  }
  .answer_list {
    min-height: calc(100% - 13.333vw - 14vw - 42vw);
  }
  // 操作按钮
  .buttons {
    width: 100%;
    padding: 0 3vw 5vw 3vw;
    box-sizing: border-box;
    .van-button {
      margin-bottom: 3vw;
      border-radius: 1vw;
    }
    .van-button:last-child {
      margin-bottom: 0;
    }
    .empty {
      height: 11.733vw;
    }
  }
}
.no {
  width: 4.133vw;
  height: 4vw;
}
.yes {
  width: 5.333vw;
  height: 4vw;
}
.pitch {
  border: solid 1px #18a071 !important;
  background-color: #fafefd !important;
}
.incorrect {
  border: solid 1px #ff392d !important;
  background-color: #fffbfb;
}
.answer {
  width: 93.6vw;
  height: 11.733vw;
  box-sizing: border-box;
  padding: 0 2.8vw;
  background-color: #ffffff;
  border-radius: 1.067vw;
  border: solid 0.267vw #edeff2;
  margin: 4vw auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3.733vw;
  color: #303133;
}
.answer > div {
  width: 75vw;
  display: flex;
}
.answer_list {
  width: 93.6vw;
  margin: 0 auto;
}
.topic_select_title {
  margin-left: 2vw;
  font-size: 4vw;
  letter-spacing: -0.133vw;
  color: #303133;
  flex: 1;
}
.topic_select_box {
  width: 13.333vw;
  height: 5.333vw;
  background-color: #ffffff;
  border-radius: 2.667vw;
  border: solid 0.267vw #bfc2cc;
  font-size: 2.933vw;
  color: #909399;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topic_select {
  width: 93.6vw;
  margin: 7.2vw auto;
  display: flex;
}
.topic {
  font-size: 4vw;
  font-weight: normal;
  letter-spacing: -0.133vw;
  color: #303133;
}
.premise {
  font-size: 4vw;
  letter-spacing: -0.133vw;
  color: #247dff;
}
.choice_question {
  width: 93.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 6vw;
}
.Next_question {
  width: 16vw;
  height: 8vw;
  background-color: #247dff;
  border-radius: 1.067vw;
  border: solid 1px #247dff;
  font-size: 3.467vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.last_question {
  width: 16vw;
  height: 8vw;
  border-radius: 1.067vw;
  font-size: 3.467vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #247dff;
  color: #247dff;
}
.last_question-off {
  width: 16vw;
  height: 8vw;
}
</style>
