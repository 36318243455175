<template>
  <!-- 公告列表 -->
  <div class="affiche">
    <div
      class="list"
      v-for="(item, i) in list"
      :key="'CurriculumTable' + i"
      v-show="item.isactive"
      @click="showmore(item, $event)"
    >
      <div class="list_left">
        <img :src="item.pic" alt />
      </div>

      <div class="list_right">
        <div class="list_right_title">
          <van-tag color="#ffe1e1" text-color="#ad0000" v-if="item.is2Top">Top</van-tag>
          {{item.title}}
        </div>
        <div class="list_right_dec">
          <p v-text="item.describe"></p>
        </div>
        <div class="list_right_time">
          {{ item.crdate | timeStamp | dateFormat("yyyy/MM/dd") }}
          <div class="btn">
            <!-- <span class="toTop" v-if="can2Top && i !== 0">置顶</span>
            <span v-if="can2Top && i !== 0 && canDelete">&nbsp;/&nbsp;</span>
            <span class="deleteBtn" v-if="canDelete">删除</span>
            <span class="list_right_more" v-if="!((can2Top && i !== 0) || canDelete)">查看</span>-->

            <van-button
              type="info"
              plain
              hairline
              size="mini"
              v-if="can2Top && i !== 0"
              class="toTop"
            >置顶</van-button>
            <van-button
              type="default"
              plain
              hairline
              size="mini"
              v-if="canDelete"
              class="deleteBtn"
            >删除</van-button>
            <span class="list_right_more" v-if="!((can2Top && i !== 0) || canDelete)">查看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    can2Top: Boolean,
    canDelete: Boolean
  },
  data() {
    return {}
  },
  methods: {
    showmore(item, e) {
      let cla = e.target.classList
      if ([...new Set(cla)].includes('toTop')) {
        this.toTop(item.autoid)
        return
      } else if ([...new Set(cla)].includes('deleteBtn')) {
        this.deleteBtn(item)
      } else {
        if (this.$listeners.showmore) {
          this.$emit('showmore', item) // 公告id
        } else {
          return
        }
      }
    },
    toTop(id) {
      this.$emit('toTop', id)
    },
    deleteBtn(info) {
      this.$emit('deleteFn', info)
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.affiche {
  .list {
    width: 93.6vw;
    height: 28.8vw;
    margin: 0 auto;
    border-bottom: 0.233vw solid #edeff2;
    display: flex;
    align-items: center;
    .list_left {
      width: 33.333vw;
      height: 22.133vw;
      border-radius: 6%;
      background-color: #c6c8cb;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6%;
        object-fit: cover;
      }
    }
    .list_right {
      width: 93.6vw - 33.333vw - 2vw;
      margin-left: 2vw;
      height: 80%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      color: $font-gray;
      .list_right_title {
        font-size: 4.2vw;
        color: #303133;
        @include dot;
      }
      .list_right_dec {
        flex: 1;
        font-size: 2.9vw;
        line-height: 5vw;
        padding: 1vw 0;
        p {
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .list_right_time {
        // font-size: 2.9vw;
        @include font(2.9vw, 4.2vw);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          // .deleteBtn,
          // .list_right_more,
          // .toTop {
          // }
          font-size: 3.2vw;
        }
      }
    }
  }
}
</style>
