<!-- 我的关注 老师/同学 -->
<template>
  <div class="main">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="加载失败，点击重新加载"
    >
      <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
        <ContactList :list="list" @click="action" />
      </van-pull-refresh>
    </van-list>
  </div>
</template>

<script>
import ContactList from '@/components/extend/contactList'
export default {
  props: {
    type: String //调用渠道（homework-作业 exam-考试）
  },
  components: { ContactList },
  data() {
    return {
      list: [],
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      page: 1
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
      this.onLoad()
    },
    // 父级设置子级的list
    setList(data) {
      this.list = data
    },
    // 调用父级方法获取数据
    onLoad() {
      this.$emit('onload', this.page)
    },
    // 父级调用子级成功回调
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 >= res.sum) {
        this.finished = true
      } else {
        this.page++
      }
    },
    // 父级调用子级失败回调
    failure() {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    action(info) {
      this.$emit('click', info)
    }
  },
  mounted() {
    this.onRefresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
