<template>
  <div class="page">
    <topheader />
    <van-tabs
      v-model="active"
      line-width="14vw"
      title-inactive-color="#247dff"
      title-active-color="#247dff"
      color="#247dff"
      animated
      swipeable
      sticky
      :offset-top="50"
      @change="getData"
    >
      <van-tab :title="tab" v-for="(tab,i) in tabsTitle" :key="'tab'+i">
        <div
          class="score_list"
          v-for="(item, idx) in list"
          :key="idx"
          @click="goToQuestionDetail($event, item)"
        >
          <!-- 考试记录 -->
          <template v-if="active === 0">
            <div class="score_left" v-text="getIdx(idx)"></div>
            <div class="score_center">
              <div class="score_title" v-text="item.planname"></div>
              <div class="score_desc" v-html="item.content"></div>
            </div>
            <div class="score_right">
              <span v-text="getScore(item.score)"></span>
              <van-tag plain v-if="!item.isbegin" type="danger">未答题</van-tag>
              <van-tag plain v-else-if="!item.isend" color="#7232dd">未提交</van-tag>
              <van-tag plain v-else-if="!item.ischeck" type="warning">待评阅</van-tag>
              <van-tag plain v-else-if="item.score != null" type="success">已打分</van-tag>
            </div>
          </template>

          <!-- 作业记录 -->
          <template v-else-if="active === 1">
            <div class="score_left" v-text="getIdx(idx)"></div>
            <div class="score_center">
              <div class="score_title homework">
                <span v-text="item.planname"></span>
                <!-- <span>
                  <van-tag plain v-if="!item.isbegin" type="danger">未答题</van-tag>
                  <van-tag plain v-else-if="!item.isend" color="#7232dd">未提交</van-tag>
                  <van-tag plain v-else-if="!item.ischeck" type="warning">待评阅</van-tag>
                  <van-tag plain v-else-if="item.score != null" type="success">已打分</van-tag>
                </span>-->
                <van-tag
                  class="reminder"
                  v-if="!item.isbegin || !item.isend"
                  @click="reminder(item)"
                >通知一下</van-tag>
              </div>
            </div>
            <div class="score_right">
              <span v-text="getScore(item.score)"></span>
              <span>
                <van-tag plain v-if="!item.isbegin" type="danger">未答题</van-tag>
                <van-tag plain v-else-if="!item.isend" color="#7232dd">未提交</van-tag>
                <van-tag plain v-else-if="!item.ischeck" type="warning">待评阅</van-tag>
                <van-tag plain v-else-if="item.score != null" type="success">已打分</van-tag>
              </span>
            </div>
          </template>
        </div>
      </van-tab>
    </van-tabs>
    <!-- 提醒学员 -->
    <van-dialog
      v-model="show"
      title="提醒学员"
      show-cancel-button
      cancel-button-text="取消"
      confirm-button-text="通知"
      :beforeClose="checking"
    >
      <van-form ref="form" class="reminder_box">
        <van-field
          v-model="reminderInfo.title"
          label="通知内容"
          rows="1"
          autosize
          type="textarea"
          placeholder="请输入..."
          show-word-limit
          class="announdance_title"
        />
        <van-field
          v-model="reminderInfo.content"
          label="通知备注"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入..."
          show-word-limit
          class="announdance_desc"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {
  teaGetStuHWRecordList,
  teaGetStuEXRecordList,
  createNewMsg //消息提醒
} from '@/api/my'
export default {
  data() {
    // 如果是 专家 从 班圈名单 跳转过来
    const t = this.$route.query.active // 0-作业 1-考试
    let active = 0
    if (!isNaN(t)) {
      active = t == 0 ? 1 : 0
    }
    return {
      tabsTitle: ['考试记录', '作业记录'],
      active,
      userId: this.$store.state.user.userId,
      stuId: this.$route.params.id, //学员id
      classId: this.$route.query.classId,
      list: [],
      homeworkList: [],
      examList: [],
      show: false, //是否展示发送消息提示编辑框
      reminderInfo: {
        userid: this.$store.state.user.userId,
        classid: this.$route.query.classId,
        title: '',
        content: '',
        useridlist: this.$route.query.stuUserId,
        groupid: ''
      }
    }
  },
  watch: {
    active(n, o) {}
  },
  methods: {
    getIdx(idx) {
      let i = idx + 1
      return i <= 9 ? '0' + i : i
    },
    getScore(score) {
      return ['', undefined, null].includes(score) ? '---' : `${score}分`
    },
    getData(n) {
      switch (n) {
        case 0: //考试
          if (!this.examList.length) {
            teaGetStuEXRecordList(this.stuId).then(res => {
              this.examList = res.list
              this.list = this.examList
            })
          } else {
            this.list = this.examList
          }
          break
        case 1: //作业
          if (!this.homeworkList.length) {
            teaGetStuHWRecordList(this.stuId).then(res => {
              this.homeworkList = res.list
              this.list = this.homeworkList
            })
          } else {
            this.list = this.homeworkList
          }
          break
      }
    },
    goToQuestionDetail(e, item) {
      if ([...new Set(e.target.classList)].includes('reminder')) {
        return
      }
      if (item.isbegin) {
        const id = this.active === 1 ? item.hwplanid : item.explanid
        const path =
          this.active === 1 ? '/homeworkQuestionDetail' : '/examQuestionDetail'

        let query = {
          id: id, //作业/考试id
          recordid: item.recordid, //记录id(关系id)
          lastqsid: item.lastqsid, //上次提交答题id
          total: item.qscount, //试题总数
          title: item.planname, //标题
          isend: 1, //已经提交 (班主任可查看到已交卷的学员答题)
          overdue: false, //不区分过期
          headmasterId: this.userId, //班主任userid
          isClassTeacher: true,
          studentName: item.name //学员姓名
        }
        // 作业不一样哦
        if (this.active === 1) {
          query.beginTime = item.bd //作业开始时间
          query.endTime = item.ed //作业结束时间
          query.lessonName = item.coursename
        }
        this.$router.push({
          path,
          query
        })
      } else {
        this.$toast('该学员还未开始作答')
      }
    },
    // 通知学员
    reminder(info) {
      this.show = true
      let msg = ''
      if (!info.isbegin) msg = '未开始答题'
      if (!info.isend) msg = '未提交'
      this.reminderInfo.title = `你正在学习的《${info.coursename}》课程中，还有《${info.planname}》${msg}。`
      this.reminderInfo.content = '请及时处理。'
    },
    checking(action, done) {
      if (action == 'confirm') {
        createNewMsg(this.reminderInfo)
          .then(res => {
            this.$toast({
              message: '发送成功',
              position: 'bottom',
              duration: 3000
            })
            // init
            this.reminderInfo.title = ''
            this.reminderInfo.content = ''
          })
          .finally(() => {
            done()
          })
      } else if (action == 'cancel') {
        done()
      }
    }
  },
  mounted() {
    this.getData(this.active)
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  /deep/.van-tabs {
    > div {
      .van-sticky {
        top: 13.333vw !important;
      }
    }
  }
  .score_list {
    padding: 6.4vw 6vw 5.067vw 2.133vw;
    display: flex;
    position: relative;
    .score_left {
      width: 8.8vw;
      padding-left: 2.667vw;
      font-size: 2.933vw;
      color: $font-gray;
    }
    .score_center {
      flex: 1;
      // padding-left: 2.667vw;
      display: flex;
      flex-direction: column;
      @include dot;
      .score_title {
        @include dot;
        font-size: 4vw;
      }
      .score_title.homework {
        overflow: auto;
        white-space: normal;
        text-overflow: clip;
        .reminder {
          margin-left: 0.5em;
        }
      }
      .score_desc {
        @include dot;
        font-size: 3.2vw;
        color: $font-gray;
        padding-top: 2.933vw;
        padding-right: 0.5em;
      }
    }
    .score_right {
      width: 13.333vw;
      font-size: 4vw;
      color: #07a4a6;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .no-begin {
      }
      .van-tag {
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  // 底边
  .score_list::after {
    content: '';
    width: calc(100% - 2.133vw);
    border-bottom: 1px solid $border-gray;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
