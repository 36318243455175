<!-- 考试分数： 作废???? -->
<template>
  <div class="page">
    <topheader />

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="score" @click="test_score(item.test_id)" v-for="(item,i) in list" :key="i">
          <div class="score_1st">
            <div>
              <span class="score_title_bunmer">{{i<9?'0'+(i+1):i+1}}</span>
              <span class="score_title">{{item.title}}</span>
              <div class="score_right">{{item.point}}分</div>
            </div>
          </div>

          <div class="score_2nd">练习时间：20分钟32秒</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import {getTestScores} from '@/api/my';
const mockData = [
  {
    point: '90',
    test_id: '10',
    test_rank: '10',
    title: '测试项目一，用完请删'
  },
  {
    point: '68',
    test_id: '10',
    test_rank: '10',
    title: '测试项目二，用完请删'
  },
  {
    point: '31',
    test_id: '10',
    test_rank: '10',
    title: '测试项目三，用完请删'
  }
]
export default {
  data() {
    return {
      page: 1, // 当前页数
      list: [], // 显示的数据
      titles: ['考试名称', '排名', '分数'], //标题
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      return
      getTestScores(this.page)
        .then(res => {
          this.loading = false
          // this.list.push(...res.data.list);
          this.list.push(...mockData)
          this.page += 1
          this.pullRefresh = false
          if (res.data.current_page >= res.data.total_page) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->分数详情
    test_score(testId) {
      this.$router.push('/test_result/' + testId)
    }
  },
  mounted() {
    window.T = {}
    T.vm = this
    this.list = mockData
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  .score {
    padding: 2vw 3vw;
    .score_1st {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .score_title_bunmer {
          font-size: 3.733vw;
          color: #909399;
          width: 6vw;
        }
        .score_title {
          font-size: 4.267vw;
          color: #303133;
          flex: 1;
          padding-left: 2vw;
        }
        .score_right {
          height: 5.333vw;
          width: 13.333vw;
          background-color: #fafefd;
          border-radius: 2.667vw;
          border: solid 0.267vw #18a071;
          font-size: 2.933vw;
          color: #18a071;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .score_2nd {
      margin-left: 6vw;
      padding: 2vw;
      padding-right: 13.333vw;
      font-size: 3.733vw;
      color: #909399;
      border-bottom: 1px solid $font-light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .score:last-child {
    .score_2nd {
      border: 0 none;
    }
  }
}
</style>
