<template>
  <div class="page">
    <topheader />
    <van-tabs
      swipeable
      sticky
      title-active-color="#247dff"
      title-inactive-color="black"
      line-width="4.533vw"
      color="#247dff"
      offset-top="50"
      v-model="active"
    >
      <van-tab title="待完成">
        <TestRecordToBeCompleted ref="finishRecord" type="homework" @onload="getList" />
      </van-tab>
      <van-tab title="已完成">
        <TestRecordToBeCompleted ref="notFinishRecord" type="homework" @onload="getList" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import TestRecordToBeCompleted from '../widget/testRecord_ToBeCompleted'
import { getMyHWList } from '@/api/my'
export default {
  name: 'myHomework',
  components: { TestRecordToBeCompleted },
  data() {
    return {
      active: 0,
      userId: this.$store.state.user.userId
    }
  },
  methods: {
    getList() {
      getMyHWList(this.userId, this.active)
        .then(res => {
          if (this.active === 0) {
            this.$refs.finishRecord.setList(res.list)
            this.$refs.finishRecord.success()
          } else if (this.active === 1) {
            this.$refs.notFinishRecord.setList(res.list)
            this.$refs.notFinishRecord.success()
          }
        })
        .catch(err => {
          if (this.active === 0) {
            this.$refs.finishRecord.failure()
          } else if (this.active === 1) {
            this.$refs.notFinishRecord.failure()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  /deep/.van-tabs {
    > div {
      .van-sticky--fixed {
        top: 13.333vw !important;
      }
    }
  }
}
</style>
