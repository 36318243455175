<!-- 测试分数记录（作业分数-详细） -->
<template>
  <div class="page">
    <topheader />
    <ul class="score_title">
      <li v-for="(item, idx) in titles" :key="idx" v-text="item"></li>
    </ul>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="score_box"
          v-for="(item, idx) in list"
          :key="idx"
          @click="goToQuestionDetail(item)"
        >
          <div class="score_box_name width50%" v-text="item.title"></div>
          <div class="score_box_rank">
            <span class="block blue" v-text="`NO.${item.rank}`"></span>
          </div>
          <div class="score_box_score">
            <span class="block green" v-text="`${item.score}分`"></span>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyStuAvgList } from '@/api/my'
import { createExamRecord } from '@/api/classRing'

export default {
  data() {
    return {
      stuId: this.$route.params.stuId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      titles: ['考试名称', '排名', '分数'], //标题
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyStuAvgList(this.stuId)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->分数详情
    test_score(testId) {
      this.$router.push('/test_result/' + testId)
    },
    // 考试分数
    goToDetail(item) {
      return
      this.$router.push({
        path: '/testScoreDetail'
      })
    },
    goToQuestionDetail(info) {
      console.log(123, JSON.parse(JSON.stringify(info)))
      // this.$toast('调试中')
      // this.$dialog.confirm({
      //   title: '提示',
      //   message: "是否跳转到考试分数( •̀ ω •́ )y"
      // }).then(this.goToDetail)
      // return
      // const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))

      //学员(有参与过答题)
      if (info.recordid && info.lastqsid) {
        // 平时查看作业
        this.$router.push({
          path: '/examQuestionDetail',
          query: {
            id: info.explanid, //考试id
            recordid: info.recordid, //记录id(关系id)
            lastqsid: info.lastqsid, //上次提交答题id
            total: info.qscount, //试题总数
            title: info.title, //标题
            isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
            overdue: 0 //是否过期
          }
        })
      } else {
        // 创建答题记录（首次查看考试）
        createExamRecord(info.explanid, info.stuId).then(res => {
          this.$router.push({
            path: '/examQuestionDetail',
            query: {
              id: info.explanid, //作业id
              recordid: res.recordid, //记录id(关系id)
              lastqsid: res.lastqsid, //上次答题id
              total: info.qscount, //试题总数
              finishCont: info.asnum, //答题数
              title: info.title, //标题
              isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
              overdue: 0 //未过期
            }
          })
        })
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
// 二次开发样式
$green: #00bf79;
$blue: #237efc;
.page {
  @include isTop;
  .score_title,
  .score_box {
    @include wh(100%, 9.867vw);
    @include font(4vw, 9.867vw);
    background-color: $active-color;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li:first-child,
    div:first-child {
      width: 40%;
      padding-left: 4vw;
    }
    li:not(:first-child),
    div:not(:first-child) {
      flex: 1;
      text-align: center;
    }
  }
  .score_box {
    @include wh(100%, 20vw);
    @include font(4vw, 1em);
    background: #fff;
    color: $font-black;
    position: relative;
    .score_box_rank,
    .score_box_score {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.933vw;
      .block {
        @include wh(12.8vw, 5.067vw);
        @include borderRadius(1.333vw);
        display: block;
        border: 1px solid #ccc;
        line-height: 5.067vw;
      }
      .green {
        border-color: $green;
        color: $green;
      }
      .blue {
        border-color: $blue;
        color: $blue;
      }
    }
  }
  .score_box::after {
    content: '';
    display: block;
    width: calc(100% - 5.333vw);
    border-bottom: 1px solid $font-light;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  // 设置高度
  .van-pull-refresh {
    height: calc(100% - 9.867vw);
    overflow: scroll;
  }
}
</style>
