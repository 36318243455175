<template>
  <div class="page">
    <topheader :title="!isNew ? '查看消息详情' : undefined" />
    <div class="info_box">
      <div class="class_name">
        <span>所属班圈：</span>
        <span v-text="className"></span>
      </div>
      <van-form ref="form">
        <van-field
          v-model="theme"
          type="textarea"
          label="主题:"
          label-width="5em"
          placeholder="输入文本..."
          rows="1"
          autosize
          required
          clearable
          :disabled="!isNew"
          :rules="[{ required: true, message: '请输入具体的消息内容' }]"
        />
        <van-field
          v-model="content"
          type="textarea"
          label="具体内容:"
          label-width="5em"
          placeholder="输入文本..."
          rows="2"
          autosize
          required
          clearable
          :disabled="!isNew"
          :rules="[{ required: true, message: '请输入具体的消息内容' }]"
        />
        <div class="kind">
          <span>成员</span>
          <template v-if="isNew">
            <span v-if="canDelete" @click="canDelete = false">关闭删除功能</span>
          </template>
          <template v-else-if="!isNew && createdTime">
            <span>创建时间：{{createdTime}}</span>
          </template>
        </div>
        <!-- 成员列表 -->
        <div class="contacts_box">
          <ul class="contacts_ul">
            <li
              class="contacts_li"
              v-for="item in chooseContacts"
              :key="item.userid"
              @click="delMember(item)"
            >
              <div class="contacts_info" :class="{canDelete}">
                <van-icon name="clear" v-if="canDelete" />
                <van-image :src="item.headurl" cover />
                <p class="contacts_name" v-text="item.name"></p>
              </div>
            </li>
            <!-- 添加 -->
            <li class="contacts_li" v-show="!canDelete" v-if="isNew">
              <div class="contacts_info" @click="addBtnClick">
                <div class="add_contacts">
                  <van-icon name="plus" />
                </div>
                <p class="contacts_add" v-text="'add'"></p>
              </div>
            </li>
            <!-- 删除 -->
            <li class="contacts_li" v-show="!canDelete" v-if="isNew" @click="subBtnClick">
              <div class="contacts_info" @click="showDeleteBtn">
                <div class="sub_contacts">—</div>
                <p class="contacts_sub" v-text="'sub'"></p>
              </div>
            </li>
            <li class="contacts_li_default" v-if="canDelete" @click="canDelete = false">
              <div class="contacts_info">
                <div class="sub_contacts"></div>
                <p class="contacts_sub"></p>
              </div>
            </li>
          </ul>
        </div>
      </van-form>
    </div>
    <!-- 选择联系人 -->
    <van-popup
      v-model="showPopup"
      position="bottom"
      close-icon-position="top-right"
      :style="{ height: 'calc(100% - 13.333vw)', width: '100%' }"
    >
      <div class="selectContact_box">
        <div class="selectContact_title">
          <div class="btn">
            <van-button type="primary" :disabled="openGroupState" @click="choosedContact">确定</van-button>
          </div>
          <div class="txt">
            <van-dropdown-menu :overlay="true">
              <van-dropdown-item
                title="分组"
                ref="item"
                @open="beforeChooseGroup"
                @closed="openGroupState = false"
              >
                <van-cell-group>
                  <van-cell
                    v-for="(it, i) in students"
                    :key="it.name + i"
                    :title="it.name"
                    :class="{active: groupIds.includes(it.groupid)}"
                    @click="chooseGroup(it)"
                  >
                    <template #right-icon>
                      <van-icon
                        name="success"
                        style="line-height: inherit;"
                        v-if="groupIds.includes(it.groupid)"
                      />
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-dropdown-item>
            </van-dropdown-menu>
            <van-icon name="arrow" @click="showPopup = false" />
          </div>
        </div>
        <SelectContact :list="childs" ref="selectContact" keyword="userid" />
      </div>
    </van-popup>
    <!-- 提交按钮 -->
    <div class="submit_btn">
      <van-button
        type="primary"
        block
        color="#247dff"
        :disabled="!theme || !content || !chooseContacts.length || !isNew || canDelete"
        @click="submit"
      >立即发送</van-button>
    </div>
  </div>
</template>
<script>
import { deleteMsg, getTeaMsgDetail, createNewMsg } from '@/api/my'
import { getClassPeopleInfo } from '@/api/classRing'
import SelectContact from '@/components/extend/selectContact'
export default {
  components: { SelectContact },
  data() {
    // const content = this.$route.query.content || ''
    // const theme = this.$route.query.title || ''
    // const createdTime = this.$route.query.createdTime || ''
    return {
      isNew: this.$route.query.msgId ? false : true, //1-查看 0-新建
      msgId: this.$route.query.msgId,
      userId: this.$store.state.user.userId,
      className: this.$route.query.className,
      classId: this.$route.params.classId,
      createdTime: '', //历史消息的创建时间
      theme: '',
      content: '',
      canDelete: false,
      childs: [], //原有学员名单列表
      students: [], //分组的成员
      chooseContacts: [], //已选中的成员名单
      showPopup: false,
      groupIds: [],
      openGroupState: false // false-关闭 true-打开
    }
  },
  // watch: {
  //   groupIds(n) {
  //     let idxs = []
  //     this.students.forEach(item => idxs.push(item.groupid))
  //     if (n.includes(0)) {

  //     }
  //   }
  // },
  methods: {
    init() {
      getClassPeopleInfo(this.userId, this.classId).then(res => {
        this.headMasters = this.teaAddGroupInfo(res.teacher1, {
          groupid: -1,
          name: '班主任'
        })
        this.supervisors = this.teaAddGroupInfo(res.teacher2, {
          groupid: -2,
          name: '导师'
        })
        this.assistants = this.teaAddGroupInfo(res.teacher3, {
          groupid: -3,
          name: '辅导员'
        })
        //原有学员名单列表
        this.childs = [].concat(
          res.stu,
          this.headMasters,
          this.supervisors,
          this.assistants
        )

        this.students = this.blockSort(
          this.childs,
          res.group.concat([
            {
              groupid: -1,
              name: '班主任'
            },
            {
              groupid: -2,
              name: '导师'
            },
            {
              groupid: -3,
              name: '辅导员'
            }
          ])
        )

        // this.childs = res.stu
        // this.students = this.blockSort(res.stu, res.group)
      })

      // 查看消息详情
      if (this.msgId) {
        getTeaMsgDetail({ autoid: this.msgId }).then(res => {
          // console.log(222, res)
          const info = res.info[0]
          this.theme = info.title
          this.content = info.content
          this.createdTime = info.crdate
          this.chooseContacts = res.user //成员列表
        })
      }
    },
    // 处理老师数据，加上分组信息
    teaAddGroupInfo(childs, info = {}) {
      let res = []
      childs.forEach(item => {
        let obj = {
          groupname: info.name,
          groupid: info.groupid
        }
        res.push(Object.assign(item, obj))
      })
      return res
    },
    // 处理成员分组
    blockSort(childs, arr = []) {
      let group = arr
      group.forEach(item => {
        item.active = false
        item.list = childs.filter(it => it.groupid === item.groupid)
      })
      // 添加全选组
      group.unshift({
        groupid: 0,
        name: '全选',
        hwscoresum: null,// 暂无作用
        exscoresum: null,// 暂无作用
        active: false,
        list: childs
      })
      const noGroup = childs.filter(it => it.groupid === null)
      if (noGroup.length) {
        let hwscoresum = 0
        let exscoresum = 0
        noGroup.forEach(el => {
          hwscoresum += el.hwscoresum ? el.hwscoresum : 0
          exscoresum += el.exscoresum ? el.exscoresum : 0
        })
        group.push({
          groupid: -4,
          name: '未分组',
          hwscoresum,// 暂无作用
          exscoresum,// 暂无作用
          active: false,
          list: noGroup
        })
      }
      return group.filter(c => c.list.length)
    },
    // 选中所有成员的分组要默认勾选，否则不勾选【如果state存在，则先用】
    beforeChooseGroup(state) {
      let activeGroup = [],
        filterGroup = [],
        blockSort = this.students,
        chooseMember = this.$refs.selectContact.checked //已经选中的成员
      blockSort.forEach(s => {
        let arr = [] //当前分组的所有学员id
        s.list.forEach(u => {
          arr.push(u.userid)
        })
        arr.forEach(st => {
          if (!chooseMember.includes(st)) {
            filterGroup.push(s.groupid)
            return false
          }
        })
      })
      this.students.forEach(s => {
        if (![...new Set(filterGroup)].includes(s.groupid)) {
          activeGroup.push(s.groupid)
        }
      })
      this.groupIds = activeGroup
      // 其它地方引用的
      if (typeof state == 'boolean') {
        this.openGroupState = state
      } else {
        this.openGroupState = true
      }
    },
    // 选择分组成员
    chooseGroup(item) {
      let arr = this.groupIds
      let checkedArr = this.$refs.selectContact.checked
      if (arr.includes(item.groupid)) {
        this.groupIds = arr.filter(el => el != item.groupid)
        // 批量删除分组成员
        let member = []
        item.list.forEach(it => member.push(it.userid))
        this.$refs.selectContact.checked = checkedArr.filter(
          stu => !member.includes(stu)
        )
      } else {
        this.groupIds.push(item.groupid) //分组
        // 批量添加分组成员
        let member = []
        item.list.forEach(it => member.push(it.userid))
        this.$refs.selectContact.checked = checkedArr.concat(member)
      }
      // 点击 全选 要特殊处理
      let idxs = [] // 分组所有id
      this.students.forEach(item => {
        idxs.push(item.groupid)
      })
      if (item.groupid === 0 && item.name === "全选") {
        if (this.groupIds.includes(0)) {
          this.groupIds = idxs
        } else {
          this.groupIds = []
        }
      } else {
        // 已满足全选要求
        const all = idxs.sort((a, b) => a - b).filter(item => item != 0) // 所有分组[除：0-全选]
        const cur = [].concat(this.groupIds).sort((a, b) => a - b) // 当前选中分组
        console.log(1111, all, cur)
        if (all.join(',') === cur.join(',')) {
          this.groupIds = idxs
        } else {
          this.groupIds = this.groupIds.filter(item => item !== 0)
        }
      }
    },
    addBtnClick() {
      this.showPopup = true
      let group = []
      this.$nextTick(() => {
        this.chooseContacts.forEach(item => {
          group.push(item.userid)
        })
        this.$refs.selectContact.checked = group
      })
    },
    subBtnClick() {
      this.canDelete = true
    },
    choosedContact() {
      this.chooseContacts = this.$refs.selectContact.checkedGroup
      this.beforeChooseGroup(false) //重新计算是否存在分组（方便创建消息的时候传值）
      setTimeout(() => {
        this.showPopup = false
      }, 300)
    },
    showDeleteBtn() {},
    delMember(item) {
      if (this.canDelete) {
        const arr = this.chooseContacts
        this.chooseContacts = arr.filter(it => it.userid != item.userid)
      } else {
        return
      }
    },
    submit() {
      let memberArr = [],
        members = '',
        groups = ''
      this.chooseContacts.forEach(item => memberArr.push(item.userid))
      members = memberArr.join()
      groups = this.groupIds.join()
      // 创建消息
      createNewMsg({
        userid: this.userId,
        classid: this.classId,
        title: this.theme,
        content: this.content,
        useridlist: members,
        groupid: groups
      }).then(res => {
        this.$dialog
          .confirm({
            title: '提示',
            message: '推送成功',
            confirmButtonText: '继续创建消息',
            cancelButtonText: '返回上一页'
          })
          .then(() => {
            // 清空数据
            this.theme = ''
            this.content = ''
            this.chooseContacts = []
            this.$refs.selectContact.checked = []
          })
          .catch(() => {
            this.$router.back()
          })
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  .info_box {
    height: calc(100% - 13.333vw);
    overflow-y: scroll;
    .class_name {
      background: $bg-gray;
      @include font(3.2vw, 6.4vw);
      padding: 0 2.667vw;
      span:first-child {
        color: $active-color;
      }
      span:nth-child(2) {
        color: black;
      }
    }
    .kind {
      box-sizing: border-box;
      @include wh(100%, 8.8vw);
      @include font(2.667vw, 8.8vw);
      padding: 0 4vw;
      color: $font-gray;
      background: #ededed;
      span:nth-child(2) {
        float: right;
        color: #f95350;
      }
    }
    // 成员列表
    .contacts_box {
      padding: 1.333vw 2.4vw;
      box-sizing: border-box;
      border-bottom: 1px solid $border-light;
      .contacts_ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .contacts_li {
          width: 20%;
          // padding-top: 1.6vw;
          margin-bottom: 3.467vw;
          .contacts_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /deep/.van-image {
              @include wh(13.6vw, 13.6vw);
              @include borderRadius(1.333vw);
              overflow: hidden;
              img {
                @include imgCover;
              }
            }
            .contacts_name {
              @include font(3.2vw, 6.133vw);
              @include dot;
              color: $font-gray;
            }
          }
          // 增加
          // 减去
          .add_contacts,
          .sub_contacts {
            @include wh(13vw, 13vw);
            @include borderRadius(1.333vw);
            border: 0.3vw dashed $border-gray;
            margin-bottom: 0 none;
            text-align: center;
            font-size: 5.6vw;
            color: $font-gray;
            line-height: 13vw;
            .van-icon {
              line-height: 13.2vw;
            }
          }
          .contacts_add,
          .contacts_sub {
            @include font(3.2vw, 6.133vw);
            color: #fff;
            color: transparent;
          }
          // 允许删除成员
          .canDelete {
            position: relative;
            .van-icon-clear {
              position: absolute;
              top: -1vw;
              right: 1.6vw;
              font-size: 1.6vw;
              z-index: 1;
              color: #f95350;
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        // 占位元素 用于事件触发
        .contacts_li_default {
          flex: 1;
          // height: 19.867vw;
          // margin-bottom: 3.467vw;
          .contacts_info {
            .sub_contacts {
              @include wh(13.2vw, 13.2vw);
            }
            .contacts_sub {
              height: 6.133vw;
            }
          }
        }
      }
    }
    // 删除标签
    .delete_btn {
      width: calc(100% - 6.4vw);
      @include borderRadius(1.333vw);
      margin: 11.2vw 3.2vw 0 3.2vw;
    }
  }

  // proup弹出层
  .selectContact_box {
    height: 100%;
    .selectContact_title {
      font-size: 3.2vw;
      height: 9.6vw;
      padding: 0 3.2vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-light;
      .btn {
        button {
          @include font(3.2vw, 3.8vw);
          height: 6.4vw;
          margin-left: 1.333vw;
        }
      }
      .txt {
        height: 100%;
        display: flex;
        align-items: center;
        // 分组列表
        /deep/.van-dropdown-menu {
          height: 100%;
          margin-right: 3.2vw;
          .van-dropdown-menu__item {
            .van-dropdown-menu__title {
              @include font(3.2vw, 3.2vw);
            }
          }
        }
        .active {
          color: $active-color;
        }
        .groups {
        }
        .van-icon {
          padding-left: 1.333vw;
          font-size: 3.8vw;
        }
      }
    }
    .selectContact {
      height: calc(100% - 9.6vw - 0.267vw);
      overflow-y: hidden;
    }
  }
  // 提交按钮
  .submit_btn {
    height: 13.333vw;
    padding: 0 3.2vw;
    position: relative;
    border-top: 1px solid $border-light;
    box-sizing: border-box;
    .van-button {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>