import Cookies from 'js-cookie'

// cookies

const OpenIdKey = 'OpenId';
// const LastPage = 'LastPage';

export function getOpenId() {
  if (process.env.NODE_ENV === 'production') {
    return '22222'
    // return Cookies.get(OpenIdKey)
  } else {
    return '22222'// 18825046808/123456(杨工)
    return Cookies.get(OpenIdKey)
  }
  return Cookies.get(OpenIdKey)
  // return 'oxfLdjpW1i3Rs_KHK0xiE9JFg4b8'// 18825046808/123456(杨工)
  // return 'oxfLdjvjx0KIAsKH-mDST_LE_vgc'// 18825046806/123456(赖工)
}

export function setOpenId(openId) {
  return Cookies.set(OpenIdKey, openId)
}

export function removeOpenId() {
  return Cookies.remove(OpenIdKey)
}

// export function setLastPage(page) {
//   return Cookies.set(LastPage, page)
// }
// export function getLastPage() {
//   return Cookies.get(LastPage)
// }