<template>
  <div class="page">
    <topheader />
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        finished-text="没有更多了"
        error-text="加载失败，点击重新加载"
        :finished="finished"
        :error.sync="error"
        @load="onLoad"
      >
        <!-- 推荐列表 -->
        <div class="recommend_group" v-for="(item, idx) in list" :key="idx">
          <div class="recommend_group_img">
            <img :src="item.img" alt />
          </div>
          <div class="recommend_list">
            <div class="recommend_list_left">
              <div class="recommend_list_person" v-text="item.name"></div>
              <div class="recommend_list_lesson" v-text="item.classname"></div>
              <div
                class="recommend_list_time"
                v-text="item.date"
              >{{item.date | dateFormat('yyyy-MM-dd')}}</div>
            </div>
            <div class="recommend_list_right">
              <div class="recommend_list_money" v-text="item.acmoney"></div>
              <div class="recommend_list_referees" v-text="`推荐人 ${item.recommendAname}`"></div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getTeamRecommend } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      page: 1, // 当前页数
      list: [],
      recommend: {
        total: 4,
        person: 2,
        team: 2
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getTeamRecommend(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          // this.list.push(...mockData); //测试专用,用完请删
          this.pullRefresh = false
          if (res.list.length < 10) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@mixin flex_sb {
  display: flex;
  justify-content: space-between;
}
.page {
  @include isTop;

  .recommend_group {
    @include flex_sb;
    height: 20vw;
    align-items: center;
    .recommend_group_img {
      @include wh(13.333vw, 13.333vw);
      margin-left: 3.333vw;
      background-image: url('../../assets/images/low.png');
      background-size: cover; //@todo
      img {
        @include wh(100%, 100%);
        object-fit: cover;
      }
    }

    .recommend_list {
      @include flex_sb;
      flex: 1;
      box-sizing: border-box;
      padding: 3.333vw 5.333vw 3.333vw 2.667vw;
      border-bottom: 0.998px solid $border-gray;
      .recommend_list_left {
        flex: 1;
        @include flex_sb;
        flex-direction: column;
        .recommend_list_person {
          font-size: 3.733vw;
        }
        .recommend_list_lesson {
          font-size: 3.2vw;
        }
        .recommend_list_time {
          font-size: 2.133vw;
          color: $font-gray;
        }
      }
      .recommend_list_right {
        @include flex_sb;
        flex-direction: column;
        .recommend_list_money {
          text-align: right;
          font-size: 3.733vw;
          color: $warn-color;
        }
        .recommend_list_referees {
          color: $font-gray;
          font-size: 2.133vw;
        }
      }
    }
  }
}
</style>
