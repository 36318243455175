<template>
  <div v-if="info">
    <div class="result_theme">
      <div class="result_theme_top">
        <div class="result_theme_top_left">Q</div>
        <div class="result_theme_top_right">
          <div class="result_theme_top_title">{{info.work_name}}</div>
          <div class="result_theme_top_center">{{info.work_content}}</div>
        </div>
      </div>
    </div>
    <div class="result_theme">
      <div class="result_theme_top_leftA">A</div>
      <div class="result_theme_bottom">
        <div class="result_theme_top_touxiang">
          <img :src="info.student_headimg" alt="">
        </div>
        <div class="result_theme_top_right">
          <div class="name">{{info.student_name}}</div>
          <div class="time">{{info.replytime | dateFormat('yyyy/MM/dd', true)}}</div>
          <div class="result_theme_top_right_img">
            <div class="right_img_flex"
                 v-for="(img,i) in info.reply_pic.split('|')"
            >
              <img :src="img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graybar"></div>
    <!-- 未评价 -->
    <div class="teacher_comments" v-if="info.point===null">
      <div class="teacher_comments_title">导师评价</div>
      <div class="xiao">学生等着您点评呢</div>
    </div>
    <!-- 已评价 -->
    <div class="teacher_commentsif" v-if="info.point!==null">
      <div class="teacher_comments_title">导师评价</div>
      <div style="display:flex;margin-top: 5.333vw;">
        <div class="result_theme_top_touxiang">
          <img :src="info.teacher_headimg" alt="">
        </div>
        <div class="result_theme_top_right">
          <div class="name">{{info.teacher_name}}</div>
          <div class="time">{{info.commenttime | dateFormat('yyyy/MM/dd',true)}}</div>
          <div
            class="result_theme_tetx"
          >{{info.comment}}</div>
          <div class="daoshui">
            <span class="prefix">导师评分：</span>
            <span class="soucur">{{info.point.split('.')[0]}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="remark_sed" @click="remarkSed" v-if="info.point===null">点评答案</div>
  </div>
</template>

<script>
import {getWorkInfo} from "../../api/classRing";

export default {
  data() {
    return {
      workId:this.$route.params.id,
      info:null,
    };
  },
  methods: {
    init() {
      getWorkInfo(this.workId).then(res => {
        this.info = res.data;
      })
    },
    remarkSed() {
      this.$router.push("/homework_input/"+this.info.word_record_id+"?name="+this.info.student_name);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped>
.remark_sed {
  width: 92vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 6.267vw;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8vw;
}
.soucur {
  font-size: 5.6vw;
  letter-spacing: -0.133vw;
  color: #1fb9bf;
}
.prefix {
  font-size: 3.2vw;
  letter-spacing: -0.133vw;
  color: #909399;
}
.daoshui {
  float: right;
}
.result_theme_tetx {
  font-size: 3.733vw;
  line-height: 6.4vw;
  color: #303133;
  width: 81.733vw;
  margin-top: 4vw;
}
.xiao {
  font-size: 3.733vw;
  color: #bfc2cc;
  text-align: center;
  margin-top: 13.333vw;
}
.teacher_comments_title {
  padding-top: 4vw;
}
.teacher_comments {
  width: 93.6vw;
  height: 32vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
}
.teacher_commentsif {
  width: 93.6vw;
  margin: 0 auto;
}
.graybar {
  width: 100vw;
  height: 2.133vw;
  background-color: #f5f7fa;
  margin-top: 5.333vw;
}
.name {
  font-size: 3.467vw;
  color: #303133;
}
.time {
  font-size: 3.2vw;
  letter-spacing: -0.133vw;
  color: #909399;
  margin-top: 2.667vw;
}
.result_theme_top_touxiang {
  width: 8.8vw;
  height: 8.8vw;
  background-color: #f0b860;
  border-radius: 100%;
}
.result_theme_top_touxiang>img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.right_img_flex {
  width: 14.667vw;
  height: 14.667vw;
  background: #c6c8cb;
  margin-right: 1.65vw;
  margin-bottom: 1.8vw;
}
.right_img_flex>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.result_theme_top_right_img {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4.533vw;
}
.result_theme_top_center {
  margin-top: 2.933vw;
  font-size: 3.467vw;
  color: #909399;
}
.result_theme_top_title {
  font-size: 4.267vw;
  color: #303133;
}
.result_theme_top_right {
  box-sizing: border-box;
  padding-left: 3vw;
  flex: 1;
}
.result_theme_top_left {
  width: 7.333vw;
  height: 4vw;
  background-image: linear-gradient(-55deg, #f0b860 0%, #ffe7c1 100%),
    linear-gradient(#ffcd05, #ffcd05);
  background-blend-mode: normal, normal;
  border-radius: 0vw 1.067vw 1.067vw 1.067vw;
  font-size: 3.467vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.result_theme_top_leftA {
  width: 7.333vw;
  height: 4vw;
  background-image: linear-gradient(-55deg, #f0b860 0%, #ffe7c1 100%),
    linear-gradient(#ffcd05, #ffcd05);
  background-blend-mode: normal, normal;
  border-radius: 0vw 1.067vw 1.067vw 1.067vw;
  font-size: 3.467vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.733vw;
  margin-left: 3.2vw;
}
.result_theme_top {
  width: 93.6vw;
  padding-bottom: 4vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
  display: flex;
  margin-top: 4vw;
}
.result_theme_bottom {
  width: 93.6vw;
  margin: 0 auto;
  display: flex;
  margin-top: 4vw;
}
</style>
