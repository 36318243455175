<template>
  <div class="page">
    <topheader />
    <div class="tips">
      <span>所属班圈：</span>
      {{className}}
    </div>
    <!-- 分数列表 -->
    <div
      class="score_list"
      v-for="(item, idx) of scoreList"
      :class="{noBottomMargin: idx==scoreList.length - 1}"
      :key="idx"
    >
      <div class="score_header">
        <div class="score_header_left" v-text="`${item.title}占比${item.content.weight}%`"></div>
        <div class="score_header_right" v-text="`${item.content.score}分`"></div>
      </div>
      <ul class="score_body">
        <li v-for="(sc, i) in item.content.list" :key="'score' + i">
          <div class="index" v-text="i + 1"></div>
          <div class="title" v-text="sc.title"></div>
          <div class="score" v-text="`${sc.score}分`"></div>
        </li>
      </ul>
    </div>

    <!-- 汇总分数 -->
    <div class="score_summary" v-text="`汇总分数：${summary}`"></div>

    <!-- 最终分数 -->
    <div class="score_atlast">
      最终分数：
      <input type="number" v-model="lastScore" placeholder="请输入..." />
    </div>

    <!-- 提交按钮 -->
    <van-button
      type="primary"
      block
      class="btn"
      loading-text="正在提交..."
      :loading="loading"
      :disabled="loading"
      @click="submit"
    >确定</van-button>
    <SuccessDialog
      ref="successDialog"
      :show="show"
      title="打分成功"
      cancelText="返回上一页"
      confirmText="停留当前页"
      @confirm="successCallback"
    />
  </div>
</template>

<script>
import { getStuGraduationInfo, graduationGrade } from '@/api/my'
import SuccessDialog from '@/components/extend/successDialog'
export default {
  components: { SuccessDialog },
  data() {
    return {
      stuId: this.$route.params.stuId,
      className: '', //班圈名称
      summary: 80, //汇总分数
      lastScore: '', //最终分数
      scoreId: undefined,
      loading: false,
      show: false,
      scoreList: [
        { title: '作业', content: [] },
        { title: '考试', content: [] },
        { title: '考试', content: [] }
      ]
    }
  },
  methods: {
    init() {
      getStuGraduationInfo({ stuid: this.stuId }).then(res => {
        let master = res.master[0]
        this.className = master.classname
        this.scoreId = master.autoid
        this.summary = master.calscore
        this.lastScore = master.teascore
        this.scoreList = [
          {
            title: '作业',
            content: {
              score: master.hwscore,
              weight: master.hwweight,
              list: res.hw
            }
          },
          {
            title: '考试',
            content: {
              score: master.exscore,
              weight: master.exweight,
              list: res.hw
            }
          },
          {
            title: '考勤',
            content: {
              score: master.kqscore,
              weight: master.kqweight,
              list: res.kq
            }
          }
        ]
      })
    },
    submit() {
      graduationGrade({
        autoid: this.scoreId,
        score: this.lastScore,
        stuid: this.stuId
      })
        .then(res => {
          this.show = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    successCallback() {
      this.show = false
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  .tips {
    background: $bg-gray;
    color: $active-color;
    @include font(3.2vw, 6.4vw);
    padding: 0 3.2vw;
    color: $font-gray;
    span {
      color: $active-color;
    }
  }
  .score_list {
    width: calc(100% - 7.467vw);
    margin: 4.267vw auto;
    @include borderRadius(1.333vw);
    box-shadow: 0 0 2.667vw rgba(0, 0, 0, 0.05);
    .score_header {
      background: $active-color;
      border-top-left-radius: 1.333vw;
      border-top-right-radius: 1.333vw;
      color: #fff;
      padding: 0 8vw;
      @include font(4.267vw, 9.6vw);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .score_header_left {
      }
      .score_header_right {
      }
    }
    .score_body {
      padding: 0 8vw 5.333vw 8vw;
      border-bottom-left-radius: 1.333vw;
      border-bottom-right-radius: 1.333vw;
      li {
        padding-top: 4vw;
        display: flex;
        align-items: center;
        .index {
          @include font(2.4vw, 4vw);
          position: relative;
        }
        .index::after {
          content: '';
          display: block;
          @include wh(4vw, 4vw);
          @include center;
          @include borderRadius(50%);
          border: 1px solid #eee;
        }
        .title {
          padding: 0 18px;
          flex: 1;
        }
        .score {
        }
      }
    }
  }
  .score_list.noBottomMargin {
    margin-bottom: 0;
  }
  .score_summary {
    padding: 0 8vw;
    // @include font(4vw, 14.667vw);
    @include font(4vw, 18.133vw);
    position: relative;
    color: $font-gray;
  }
  .score_atlast {
    @include font(4vw, 18.133vw);
    padding: 0 8vw;
    display: flex;
    position: relative;
    input {
      flex: 1;
      border: 0 none;
    }
  }
  .score_atlast::after,
  .score_summary::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid $border-light;
    position: absolute;
    bottom: 0;
    left: -3.733vw;
  }
  .btn {
    width: calc(100% - 16vw);
    margin: 4vw auto;
    @include borderRadius(1.333vw);
    background: rgb(26, 173, 25);
  }
}
</style>
