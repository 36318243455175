<template>
  <div class="page">
    <topheader>
      <!-- <van-icon name="add-o" slot="rightTools" @click="createNew" /> -->
      <van-button type="info" size="small" slot="rightTools" @click="createNew">发布</van-button>
    </topheader>
    <van-search v-model="key" show-action label="消息" placeholder="请输入搜索关键词" @search="onRefresh">
      <template #action>
        <div @click="onRefresh">搜索</div>
      </template>
    </van-search>
    <div class="class_name">
      <span>所属班圈：</span>
      <span v-text="className"></span>
    </div>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <ul class="news_list">
          <li v-for="(item, idx) in list" :key="item.title + idx" @click="goToDetail(item, $event)">
            <div class="news_left">
              <span class="news_idx" v-text="idx > 9 ? idx+1 : '0'+(idx+1)"></span>
            </div>
            <div class="news_center">
              <div class="news_content" v-text="item.title"></div>
              <span class="news_create_date" v-text="item.crdate"></span>
            </div>
            <div class="news_right">
              <span @click="deleteNew(item)">删除</span>
            </div>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
    <!-- 按钮 -->
    <!-- <div class="btn">
      <van-button type="primary" block color="#247dff" @click="createNew">新增</van-button>
    </div>-->
  </div>
</template>
<script>
import { getTeaMsgList, deleteMsg } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      classId: this.$route.params.classId,
      className: this.$route.query.className,
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      page: 1,
      showDialog: false,
      list: [],
      key: ''
    }
  },
  methods: {
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true
      this.$nextTick(() => {
        this.finished = false
      })
    },
    onLoad() {
      getTeaMsgList({
        keyword: this.key,
        userid: this.userId,
        curpage: this.page,
        classid: this.classId
      })
        .then(res => {
          this.loading = false
          this.pullRefresh = false
          this.list.push(...res.list)
          this.finished = true
          if (this.page * 10 > res.sum) {
            this.finished = true
          } else {
            this.page++
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    createNew() {
      this.$router.push({
        path: `/create_news/${this.classId}`,
        query: {
          className: this.className
        }
      })
    },
    deleteNew(info) {
      const t =
        info.title.length > 12 ? info.title.slice(0, 12) + '...' : info.title
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要删除“${t}”班圈消息？`
        })
        .then(() => {
          deleteMsg({ autoid: info.autoid }).then(res => {
            this.$toast({
              message: '删除成功',
              position: 'bottom'
            })
            let arr = this.list
            this.list = []
            this.$nextTick(() => {
              this.list = arr.filter(item => item.autoid !== info.autoid)
            })
          })
        })
    },
    goToDetail(info, e) {
      const childNodeClassList = [...new Set(e.target.classList)],
        parentNodeClassList = [...new Set(e.target.parentNode.classList)]
      if (
        childNodeClassList.includes('news_right') ||
        parentNodeClassList.includes('news_right')
      ) {
        return '删除功能，由其他方法完成'
      } else {
        // this.$dialog.confirm({
        //   title: '提示',
        //   message: `确定要查看`
        // })
        console.log(info)
        this.$router.push({
          path: `/create_news/${this.classId}`,
          query: {
            className: this.className,
            // title: info.title, //@toCheck
            // createdTime: info.crdate, //@toCheck
            // content: info.content, //@toCheck
            member: info.stuid, //学员列表，逗号拼接的字符串
            msgId: info.autoid
          }
        })
      }
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  .top_header {
    .right {
      font-size: 5.6vw;
      .van-button {
        min-width: 4em;
        padding: 0;
      }
    }
  }
  .van-search {
    height: 13.333vw;
    .van-search__content {
      .van-search__label {
        @include font(3.733vw, 9.067vw);
      }
      /deep/.van-cell {
        @include font(3.733vw, 6.4vw);
        padding: 0.667vw 1.067vw 0.667vw 0;
        .van-field__left-icon {
          .van-icon-search {
            font-size: 4.267vw;
          }
        }
      }
    }
    .van-search__action {
      @include font(3.733vw, 9.067vw);
    }
  }
  .class_name {
    background: $bg-gray;
    @include font(3.2vw, 6.4vw);
    padding: 0 2.667vw;
    span:first-child {
      color: $active-color;
    }
    span:nth-child(2) {
      color: black;
    }
  }
  /deep/.van-pull-refresh {
    height: calc(100% - 6.4vw - 13.333vw - 13.3333vw);
    overflow: scroll;
    .van-pull-refresh__track {
      .van-list {
        .news_list {
          padding: 0 3.2vw;
          li {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-light;
            color: $font-gray;
            padding: 2vw 0;
            font-size: 3.2vw;
            display: flex;
            .news_left {
              .news_idx {
                padding-right: 1.6vw;
              }
            }
            .news_center {
              flex: 1;
              width: calc(100% - 1.6vw - 6em);
              .news_content {
                @include dot;
                @include font(3.8vw, 6.2vw);
                color: $font-color;
              }
              .news_create_date {
                // padding-top: 1.6vw;
              }
            }
            .news_right {
              width: 4em;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .btn {
    height: 13.333vw;
    padding: 0 3.2vw;
    position: relative;
    border-top: 1px solid $border-light;
    box-sizing: border-box;
    .van-button {
      // box-sizing: border-box;
      // width: 100%;
      // position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>