<template>
  <!-- 成功提示框 -->
  <van-overlay :show="show">
    <div class="wrapper">
      <div class="tip_title" v-text="title"></div>
      <div class="box">
        <img :src="defaultSrc" />
      </div>
      <div class="btns">
        <div class="btn_cancel" v-if="cancelBtn" @click="cancel">{{cancelText}}</div>
        <div class="btn_confirm" v-if="confirmBtn" @click="confirm">{{confirmText}}</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '成功提示'
    },
    cancelBtn: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmBtn: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    show: Boolean
  },
  data() {
    return {
      defaultSrc: require('@/assets/images/succend.png')
    }
  },
  methods: {
    cancel() {
      if (this.$listeners.cancel) {
        this.$emit('cancel')
      } else {
        this.$router.back()
      }
    },
    confirm() {
      if (this.$listeners.confirm) {
        this.$emit('confirm')
      } else {
      }
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  @include center;
  border-radius: 1.6vw;
  width: 80vw;
  background: #fff;
  border: 1px solid $border-light;
  box-shadow: $box-shadow;
  .tip_title {
    @include font(4.2vw, 12vw);
    text-align: center;
    border-bottom: 0.133vw solid $border-light;
    color: #333;
  }
  .box {
    padding: 1.6vw;
    height: 60vw;
    box-sizing: border-box;
    img {
      @include wh(100%, 100%);
      object-fit: contain;
    }
  }
  .btns {
    @include font(4vw, 10vw);
    border-top: 0.133vw solid $border-light;
    display: flex;
    justify-content: space-between;
    text-align: center;
    .btn_cancel {
      flex: 1;
      color: $font-gray;
    }
    .btn_confirm {
      flex: 1;
      color: $active-color;
    }
    div:nth-child(2) {
      position: relative;
    }
    div:nth-child(2)::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      background: $bg-light;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
</style>