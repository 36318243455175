<template>
  <div class="ap">
    <div class="comment_input_head">
      <span class="comment_input_head_size">
        点评
        <span class="name">{{name}}</span> 的回答
      </span>
    </div>
    <div class="comment_input_center">
      <van-field v-model="message" rows="12" autosize type="textarea" placeholder="请输入您的点评" />
    </div>
    <div class="pinfen">
      <div class="pinfenName">评分</div>
      <div class="pinfenName_shuru">
        <input v-model="point" type="text" class="right_input" placeholder="请输入分数" />
      </div>
    </div>
    <div :class="`dianping ${on?'dianping-on':''}`" @click="comment">点评</div>
  </div>
</template>

<script>
import {commentWork} from "../../api/my";

export default {
  data() {
    return {
      name:this.$route.query.name,
      workRecordId:this.$route.params.id,
      message:'',
      point:'',
      on:false,
    };
  },
  computed:{
    all() {
      const {message,point} = this;
      return {message,point};
    }
  },
  watch:{
    all() {
      if (this.message!==''&&this.point!=='') {
        this.on = true
      } else {this.on = false}

    }
  },
  methods: {
    comment() {
      if (this.on) {
        commentWork(this.workRecordId,this.message,this.point).then(() => {
          this.$toast('点评成功');
          setTimeout(() => {
            this.$toast().clear();
            this.$router.go(-1);
          },1000)
        }).catch(() => {this.$toast('点评失败')})
        // this.$router.push("/homeworkSucceed");
      }
    }
  }
};
</script>

<style scoped>
.dianping {
  width: 92vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  margin: 8vw auto;
}
.dianping-on{opacity: 1;}
.right_input {
  text-align: right !important;
  border: none;
  margin-right: 3.333vw;
}
input::-webkit-input-placeholder {
  font-size: 4.267vw;
  color: #bfc2cc;
}

.pinfenName {
  font-size: 4.267vw;
  color: #303133;
  padding-left: 3.333vw;
}
.pinfen {
  width: 100vw;
  height: 11.733vw;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ap {
  width: 100%;
  height: 100vh;
  background-color: #f5f7fa;
}

.comment_input_center {
  height: 40.667vw;
  overflow: hidden;
}
.name {
  font-size: 3.733vw;
  color: #303133;
}
.comment_input_head_size {
  font-size: 3.733vw;
  line-height: 6.4vw;
  color: #909399;
  text-indent: 3.333vw;
}
.comment_input_head {
  width: 100%;
  height: 13.333vw;
  border-bottom: 0.133vw solid #edeff2;
  display: flex;
  background-color: #ffffff;
  align-items: center;
}
</style>
