<template>
  <!-- 头像 -->
  <img :src="realSrc" />
</template>
<script>
export default {
  props: {
    src: String
  },
  data() {
    return {
      realSrc: '',
      defaultSrc: require('@/assets/images/head_prole.png')
    }
  },
  watch: {
    src: {
      handler(n) {
        this.getRealSrc(n)
      },
      immediate: true
    }
  },
  methods: {
    getRealSrc(n) {
      let img = new Image()
      img.src = n
      img.onload = () => {
        this.realSrc = n
      }
      img.onerror = () => {
        this.realSrc = this.defaultSrc
      }
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped></style>