// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
// 开启difference:true可进行时差显示(24小时内)
Date.prototype.Format = function(fmt,difference=false) {
  if (difference) { // 开启差时计算
    let newDate = new Date().getTime(); // 当前时间戳
    const sd = 60; // 秒差
    const md = 60*60; // 分钟差
    const hs = 24*60*60; // 小时差
    const td = (newDate - this.getTime())/1000; // 时间差(秒)
    if (td<1) {
      return '刚刚';
    } else if (td < sd) {
      return parseInt(td,10)+'秒前';
    } else if (td < md) {
      return parseInt(td/60,10)+'分钟前';
    } else if (td < hs) {
      return parseInt(td/(60*60),10)+'小时前';
    }
  }

  let o = {
    "M+" : this.getMonth()+1,                 //月份
    "d+" : this.getDate(),                    //日
    "h+" : this.getHours(),                   //小时
    "m+" : this.getMinutes(),                 //分
    "s+" : this.getSeconds(),                 //秒
    "q+" : Math.floor((this.getMonth()+3)/3), //季度
    "S"  : this.getMilliseconds()             //毫秒
  };
  if(/(y+)/.test(fmt))
    fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
  for(let k in o)
    if(new RegExp("("+ k +")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
  return fmt;
}
console.log('%c%s', 'padding: 2px 4px;color: white;border-radius: 4px;background-color: #FA8BFF;background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);', '趣果科技，您的“IT技术合伙人”');
