<template>
  <div>
    <div class="register_title">忘记登录密码?</div>
    <div class="register_title_welcome">填写信息重置密码</div>
    <div class="input_box">
      <div class="input_box_xia">
        <van-field v-model="tel" type="tel" placeholder="手机号码"/>
      </div>
      <div class="input_box_xia">
        <van-field v-model="code" type="tel" placeholder="验证码"/>
        <div class="code_gain" v-if="!showGetCode||countDown!==0">{{countDown===0?'获取验证码':`${countDown}秒重新获取`}}</div>
        <div class="code_gain code_gain-on bton" v-if="showGetCode&&countDown===0" @click="getCode">获取验证码</div>
      </div>
      <div class="input_box_xia">
        <van-field v-model="pwd" type="password" placeholder="设置新6~8位登录密码"/>
      </div>
      <div class="input_box_xia">
        <van-field v-model="pwds" type="password" placeholder="重复新密码"/>
      </div>
    </div>
    <div :class="`Sed bton ${all?'op':''}`" @click="register_click">确认</div>
  </div>
</template>

<script>
  import {telTest} from "@/util/verify";
  import {getPIN, resetPwd} from "@/api/register";

  export default {
    name: "register",
    data() {
      return {
        tel: "",
        code: "",
        pwd: "",
        pwds: "",
        showGetCode:false, // 显示获取验证码
        countDown: 0, // 验证码倒计时
        key: '', // 验证码绑定的key
      };
    },
    computed:{
      all() {
        return this.tel && this.code && this.pwd && this.pwds;
      },
    },
    watch: {
      tel(n) {
        this.showGetCode = telTest(n);
      }
    },
    methods: {
      // 获取验证码
      getCode() {
        getPIN(this.tel, 2).then(res => {
          this.key = res.code;
          this.countDown = 59;
          let t = setInterval(() => {
            if (this.countDown>0) {
              this.countDown--;
            } else {
              clearInterval(t);
            }
          },1000)
        })
      },
      register_click() {
        if (!this.all) return;
        if (!telTest(this.tel)) {
          this.$toast("请输入正确的手机号码");
          return;
        }
        if (this.code != this.key) {
          this.$toast("验证码有误");
          return;
        }
        // 密码位数验证
        if (this.pwd.length<6||this.pwd.length>8) {
          this.$toast('密码请在6-8位间');return;
        }
        // 两次密码验证
        if (this.pwd!==this.pwds) {
          this.$toast('两次密码不等');return;
        }
        resetPwd(this.tel, this.pwd).then(res => {
          this.$toast('注册成功');
          setTimeout(() => {
            this.$toast().clear();
            this.$router.go(-1);
          },500)
        })
      }
    }
  };
</script>

<style scoped>
  .op {
    opacity: 1 !important;
  }

  .Sed {
    width: 84vw;
    height: 12.533vw;
    margin: 0 auto;
    margin-top: 17.333vw;
    background-color: #247dff;
    border-radius: 6.267vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8vw;
    letter-spacing: 0.267vw;
    color: #ffffff;
    opacity: 0.4;
  }

  .code_gain {
    width: 35vw;
    height: 8vw;
    background-color: #edeff2;
    border-radius: 4vw;
    font-size: 3.467vw;
    color: #909399;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code_gain-on{
    background-color: #008eed;
    color: #ffffff;
  }

  .input_box_xia {
    height: 11.333vw;
    border-bottom: 0.2vw solid #edeff2;
  }

  .input_box_xia {
    margin-top: 8vw;
    display: flex;
    justify-content: space-between;
  }

  .van-field__control,
  .van-cell {
    margin: 0;
    padding: 0;
    font-size: 4vw;
    color: #bfc2cc;
  }

  .input_box {
    width: 84vw;
    margin: 0 auto;
    margin-top: 13.333vw;
  }

  .register_title {
    font-size: 6.667vw;
    letter-spacing: 0.133vw;
    color: #303133;
    margin-left: 8vw;
    margin-top: 10.667vw;
  }

  .register_title_welcome {
    font-size: 3.733vw;
    letter-spacing: -0.133vw;
    color: #909399;
    margin-left: 8vw;
    margin-top: 5.067vw;
  }
</style>
