<template>
  <div class="top_header" :class="className()">
    <i class="back" v-if="back" :style="backStyle" :class="backClass" @click="goBack"></i>
    {{this.realTitle}}
    <div class="left">
      <slot name="leftTools"></slot>
    </div>

    <div class="right">
      <slot name="rightTools"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    title: String, // 标题
    back: {
      // 是否展示返回键
      type: Boolean,
      default: true
    },
    isTop: {
      // 是否固定在页面顶部
      type: Boolean,
      default: true
    },
    lucency: Boolean, // 是否要透明背景
    borderNot: Boolean, // 底边框是否透明
    backWhite: Boolean // 返回键白色
  },
  computed: {
    realTitle() {
      return this.title === undefined ? this.$route.meta.caption : this.title
    },
    backStyle() {
      return {
        // color: this.backColor,
      }
    },
    backClass() {
      return {
        backWhite: this.backWhite
      }
    }
  },
  methods: {
    className() {
      return {
        fixedTop: this.isTop,
        lucency: this.lucency,
        borderNot: this.lucency || this.borderNot
      }
    },
    async goBack() {
      this.$emit('beforeBack')
      if (this.$listeners['goBack']) {
        this.$emit('goBack')
      } else {
        // ③ 解决二维码分享跳转到班圈详情页无法返回到首页的问题
        if (this.$route.query.goindex === 'true') {
          this.$router.push('/')
        } else {
          await this.$router.back(-1)
        }
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.top_header {
  @include font(4vw, 13.333vw);
  @include wh(100%, 13.333vw);
  @include dot;
  text-align: center;
  position: relative;
  color: $font-color;
  background: #ffffff;
  border-bottom: 0.133vw solid $font-light;
  padding: 0 13.333vw;
  box-sizing: border-box;
  .back {
    display: block;
    @include wh(13.333vw, 13.333vw);
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  .back::after {
    content: '';
    display: block;
    width: 2.667vw;
    height: 2.667vw;
    border-top: 0.133vw solid $font-black;
    border-left: 0.133vw solid $font-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .backWhite::after {
    border-color: #fff;
  }

  div.left,
  div.right {
    height: 100%;
    position: absolute;
    top: 0;
  }
  div.left {
    left: 0;
  }
  div.right {
    right: 0;
    @include wh(13.333vw, 13.333vw);
  }
}
.fixedTop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.lucency {
  background: transparent;
}
.borderNot {
  border: 0 none;
}
</style>
