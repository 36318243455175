<!-- 问答 >>> 老师/学生 回复提问 -->
<template>
  <div class="main">
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="加载失败，点击重新加载"
    >
      <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
        <div class="comment_list" v-for="(item, i) in list" :key="'review' + i">
          <div class="comment_list_content">
            <div class="comment_list_top">
              <div class="comment_list_top_left">
                <img v-if="item.headurl" :src="item.headurl" alt />
                <img v-else src="@/assets/images/head_prole.png" alt />
              </div>
              <div class="comment_list_top_right">
                <div class="name">
                  <span v-text="item.userid == userId ? '我' : item.name"></span>
                  <van-tag color="#ffe1e1" text-color="#ad0000" v-if="item.is2Top">Top</van-tag>
                  <slot name="nameRight" :data="item"></slot>
                </div>
                <div class="time">
                  {{
                  item.crdate
                  | timeStamp
                  | dateFormat("yyyy/MM/dd hh:mm:ss", true)
                  }}
                </div>
              </div>
            </div>

            <div class="comment_list_details">
              <template v-if="item.replymode === '文本'">
                <div class="comment_list_text" v-html="translateCommend(item.content)"></div>
              </template>

              <template v-else-if="item.replymode === '语音'">
                <div class="audio_box">
                  <audioBox
                    ref="audioBox"
                    :url="item.voiceurl"
                    :isBuy="item.ispay"
                    :price="price"
                    :id="item.autoid"
                    :createdTime="item.crdate"
                    :mediaId="item.mediaId"
                    :sec="item.sec"
                    @beforePlay="beginPlay"
                  />
                  <!-- <van-tag>{{item.ispay ? '已购买': '未购买'}}</van-tag> -->
                </div>
              </template>
              <div
                class="comment_list_reply"
                @click="eply(item)"
                v-if="item.userid != userId && item.replymode === '文本'"
              >回复</div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </van-list>
  </div>
</template>

<script>
import { getReviewList } from '@/api/classRing'
import audioBox from '@/components/My_personal_center/widget/audioBox'

export default {
  name: 'review',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    price: [Number, String]
  },
  components: { audioBox },
  data() {
    return {
      pullRefresh: false, // 下拉刷新中
      finished: true, // 全部加载完成
      error: false, // 加载出现错误
      userId: this.$store.state.user.userId, //用户id
      player: undefined //上一个/正在播放的录音
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.$emit('refresh')
    },
    // 删除/回复
    eply(info) {
      this.$emit('action', info)
    },
    // 将匹配结果替换表情图片
    emotion(res) {
      let word = decodeURI(res).replace(/\#|\;/gi, '')
      const list = [
        '微笑',
        '撇嘴',
        '色',
        '发呆',
        '得意',
        '流泪',
        '害羞',
        '闭嘴',
        '睡',
        '大哭',
        '尴尬',
        '发怒',
        '调皮',
        '呲牙',
        '惊讶',
        '难过',
        '酷',
        '冷汗',
        '抓狂',
        '吐',
        '偷笑',
        '可爱',
        '白眼',
        '傲慢',
        '饥饿',
        '困',
        '惊恐',
        '流汗',
        '憨笑',
        '大兵',
        '奋斗',
        '咒骂',
        '疑问',
        '嘘',
        '晕',
        '折磨',
        '衰',
        '骷髅',
        '敲打',
        '再见',
        '擦汗',
        '抠鼻',
        '鼓掌',
        '糗大了',
        '坏笑',
        '左哼哼',
        '右哼哼',
        '哈欠',
        '鄙视',
        '委屈',
        '快哭了',
        '阴险',
        '亲亲',
        '吓',
        '可怜',
        '菜刀',
        '西瓜',
        '啤酒',
        '篮球',
        '乒乓',
        '咖啡',
        '饭',
        '猪头',
        '玫瑰',
        '凋谢',
        '示爱',
        '爱心',
        '心碎',
        '蛋糕',
        '闪电',
        '炸弹',
        '刀',
        '足球',
        '瓢虫',
        '便便',
        '月亮',
        '太阳',
        '礼物',
        '拥抱',
        '强',
        '弱',
        '握手',
        '胜利',
        '抱拳',
        '勾引',
        '拳头',
        '差劲',
        '爱你',
        '不',
        '好的',
        '爱情',
        '飞吻',
        '跳跳',
        '发抖',
        '怄火',
        '转圈',
        '磕头',
        '回头',
        '跳绳',
        '挥手',
        '激动',
        '街舞',
        '献吻',
        '左太极',
        '右太极'
      ]
      let index = list.indexOf(word)
      return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif">`
    },
    translateCommend(t) {
      const txt = t.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, this.emotion),
        txtArr = txt.split('{+_+}'),
        label = []
      if (txtArr.length == 1) {
        return txt
      } else {
        label.push(`<div>${txtArr[0]}</div>`)
        label.push(`<div>${txtArr[1]}</div>`)
        return label.join('')
      }
    },
    // 开始播放前要停用上一个播放的audio
    beginPlay(that) {
      that.allowToPlay = true //因为多个音频播放,所以需要判断是否允许播放
      if (this.player) {
        if (this.player._uid === that._uid && that.isPlay) {
          that.allowToPlay = false
          this.player.stop()
        } else if (this.player._uid !== that._uid) {
          this.player.stop()
        }
      }
      this.player = that
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.comment_list {
  width: 93.6vw;
  // min-height: 25.733vw;
  padding-bottom: 2.667vw;
  border-bottom: 0.233vw solid #edeff2;
  .comment_list_content {
    .comment_list_top {
      display: flex;
      align-items: center;
      margin-top: 4vw;
      .comment_list_top_left {
        width: 8.8vw;
        height: 8.8vw;
        @include borderRadius(50%);
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .comment_list_top_right {
        margin-left: 2.8vw;
        flex: 1;
        .name {
          font-size: 3.467vw;
          color: #303133;
          font-weight: bold;
          display: flex;
          span:first-child {
            flex: 1;
          }
        }
        .time {
          font-size: 3.2vw;
          letter-spacing: -0.133vw;
          color: $font-gray;
        }
      }
    }
    .comment_list_details {
      font-size: 3.733vw;
      line-height: 6.4vw;
      color: #303133;
      margin-top: 2.667vw;
      display: flex;
      /deep/.comment_list_text {
        flex: 1;
        word-break: break-word;
        white-space: pre-line; //支持换行/n
        div:first-child {
          position: relative;
          color: $font-gray;
        }
        div:first-child::after {
          content: '- - - - - - - - - - - - - - - - - - - -';
          display: block;
          position: absolute;
          left: 0;
          bottom: -0.8em;
        }
        img {
          @include wh(6.4vw, 6.4vw);
        }
      }
      // 语音
      .audio_box {
        flex: 1;
        .x-audio-wrap {
          // border-top-left-radius: 1.333vw;
          // border-bottom-left-radius: 1.333vw;
          border-radius: 0.667vw;
          margin-left: 1px;
          .x-sector {
          }
          .x-time {
          }
        }
      }
      .comment_list_reply {
        padding-left: 2vw;
        color: $font-gray;
      }
    }
  }
}
</style>
