<!-- 学员列表 引用了 -->
<template>
  <div class="stu_list">
    <ul class="name_list" v-if="list.length > 0">
      <li class="name_list_li" v-for="(item, idx) in list" :key="idx">
        <div class="name_list_li_avatar">
          <!-- <img :src="getPortrait(item.headurl)" v-if="item.headurl" />
          <img src="@/assets/images/head_prole.png" v-else />-->
          <Portrait :src="item.headurl" ref="portrait" />
        </div>
        <div class="name_list_li_rightBox">
          <div class="username">
            <span v-text="item.name"></span>

            <div class="follow_teacher" v-if="groupname === 'students' && userId != item.userid">
              <span
                class="follow"
                :class="{active: item.islike}"
                v-if="userId !== item.userid"
                @click="follow(item)"
              >
                <van-icon name="plus" v-if="!item.islike"></van-icon>
                {{item.islike? '已关注' : '关注'}}
              </span>
            </div>
          </div>

          <!-- 学员 -->
          <div class="operation">
            <div
              class="operation_score"
              v-if="groupname === 'students'"
              @click="scoreAct(type, item)"
            >
              <span class="orange" v-if="type === 0">
                <i>作</i>
                <b v-text="item.hwscoresum"></b>
              </span>
              <span class="blue" v-if="type === 1">
                <i>考</i>
                <b v-text="item.exscoresum"></b>
              </span>
            </div>

            <div class="average" v-else></div>

            <div class="operation_follow" v-if="groupname !== 'students' && userId != item.userid">
              <span
                class="follow"
                :class="{active: item.islike}"
                v-if="userId !== item.userid"
                @click="follow(item)"
              >
                <van-icon name="plus" v-if="!item.islike"></van-icon>
                {{item.islike? '已关注' : '关注'}}
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="no-data">查无数据</div>
  </div>
</template>

<script>
import Portrait from '@/components/extend/portrait'
export default {
  props: {
    list: Array,
    active: Number, //查询成绩的类型 0-作业 1-考试
    groupname: String //分组类型  headMaster-班主任 supervisor-导师 assistant-辅导员 students-学员
  },
  components: {
    Portrait
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      type: 0 // 0-作业 1-考试
    }
  },
  watch: {
    active: {
      handler(v) {
        this.type = v
      },
      immediate: true
    }
  },
  methods: {
    follow(info) {
      this.$emit('follow', { info, groupname: this.groupname })
    },
    scoreAct(t, info) {
      this.$emit('scoreAct', { type: t, info })
    }
  },
  mounted() {
    // console.log(this)
  }
}
</script>

<style lang="scss" scoped>
@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stu_list {
  // 名单列表
  .name_list {
    .name_list_li {
      @include wh(100%, 13.867vw);
      box-sizing: border-box;
      padding: 0 4vw;
      display: flex;
      align-items: center;
      // 头像
      .name_list_li_avatar {
        @include wh(9.6vw, 9.6vw);
        @include borderRadius(0.667vw);
        overflow: hidden;
        img {
          @include wh(100%, 100%);
          object-fit: cover;
        }
      }
      // 姓名+关注+分数
      .name_list_li_rightBox {
        @include font(4vw, 13.867vw);
        width: calc(100% - 9.6vw);
        border-bottom: 0.133vw solid $font-light;
        box-sizing: border-box;
        padding-left: 4.267vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .username {
          width: calc(100% - 3em);
          // flex: 1;
          display: flex;
          align-items: center;
          > span {
            min-width: 4em;
            max-width: calc(100% - 13.333vw);
            @include dot;
          }
          .follow_teacher {
            margin-left: 2px;
            .follow {
              width: 13.333vw;
              @include font(2.667vw, 4.8vw);
              text-align: center;
              color: $font-gray;
              @include borderRadius(3.067vw);
              border: 1px solid $border-light;
              @include flexCenter();
            }
            .active {
              border-color: $active-color;
              color: $active-color;
            }
          }
        }
        .operation {
          // flex: 1;
          width: 3em;
          display: flex;
          // 非学员才有占位
          .average {
            flex: 1;
          }
          .operation_follow {
            line-height: normal;
            text-align: right;
            .follow {
              display: inline-block;
              width: 13.333vw;
              @include font(2.667vw, 4.8vw);
              @include borderRadius(3.067vw);
              text-align: center;
              border: 1px solid $border-light;
              color: $font-gray;
              @include flexCenter();
            }
            .active {
              border-color: $active-color;
              color: $active-color;
            }
          }
          // 总分数 | 占位标签
          .operation_score {
            width: 100%;
            text-align: right;
            span {
              display: inline-block;
              @include font(3.2vw, 3.2vw);
              color: $font-gray;
              display: flex;
              justify-content: space-between;
              align-items: center;
              i {
                font-style: normal;
                @include wh(4.6vw, 4.6vw);
                line-height: 4.6vw;
                text-align: center;
                border-radius: 50%;
                color: #fff;
              }
              b {
                font-weight: normal;
              }
            }
            .orange {
              i {
                background: #ff976a;
              }
              b {
                color: #ff976a;
              }
            }
            .blue {
              i {
                background: #1989fa;
              }
              b {
                color: #1989fa;
              }
            }
          }
        }
      }
    }
    .name_list_li:last-child {
      border: 0 none;
    }
  }
  .no-data {
    @include font(3.2vw, 9.6vw);
    text-align: center;
    color: $font-gray;
  }
}
</style>
