<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive===true" ref="router-view" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive===true" ref="router-view" />
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      setTimeout(() => {
        window.T = {}
        T.vm = this.$refs['router-view']
      }, 500)
    }
  }
}
</script>

<style lang="scss">
@import './assets/font/font.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  touch-action: pan-y;
}
/*
  * 全局公用属性
  */
/*底部占位符*/
.bottom-placeholder {
  height: 50px;
}
/*完全居中*/
.ct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ct-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*按钮效果(颜射变深一点)*/
.bton {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.bton::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #000;
  border: inherit;
  border-color: #000;
  border-radius: inherit;
  transform: translate(-50%, -50%);
  opacity: 0;
  content: ' ';
  transition: all 300ms;
}
.bton:active:before {
  opacity: 0.2;
}
</style>
