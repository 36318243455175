import { render, staticRenderFns } from "./testScoreDetail.vue?vue&type=template&id=9566628c&scoped=true&"
import script from "./testScoreDetail.vue?vue&type=script&lang=js&"
export * from "./testScoreDetail.vue?vue&type=script&lang=js&"
import style0 from "./testScoreDetail.vue?vue&type=style&index=0&id=9566628c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9566628c",
  null
  
)

export default component.exports