<template>
  <div class="page">
    <topheader />
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="classring_list"
          v-for="(item,i) in list"
          :key="'classring'+i"
          @click="goToClassDetails(item.autoid)"
        >
          <div class="classring_list_left">
            <img :src="item.pic" alt />
          </div>
          <div class="classring_list_right">
            <span class="classring_list_title">{{item.title}}</span>
            <span class="classring_list_time">{{item.crdate}}</span>
          </div>
        </div>

        <!-- 课程列表: 请做到的时候考虑一下是否可以用这个组件 -->
        <!-- <courseList :list="list" @click="goToClassDetails"/> -->
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyClassList } from '@/api/my'
import courseList from '@/components/extend/courseList'

export default {
  components: { courseList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyClassList(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->班圈简介
    goToClassDetails(classId) {
      this.$router.push('/classDetails/' + classId)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
.classring_list_time {
  font-size: 2.933vw;
  color: #909399;
  display: block;
  margin-top: 5vw;
}
.classring_list_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 4.267vw;
  line-height: 6.4vw;
  color: #303133;
}
.classring_list_right {
  width: 55vw;
  height: 22.133vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.classring_list_left {
  width: 32.333vw;
  height: 22.133vw;
  background-color: #c6c8cb;
  border-radius: 1.5vw;
}
.classring_list_left > img {
  width: 100%;
  height: 100%;
  border-radius: 1.5vw;
  object-fit: cover;
}
.classring_list {
  width: 90%;
  height: 28.267vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edeff2;
}
</style>
