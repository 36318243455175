<template>
  <div class="page">
    <topheader :title="title" />
    <!-- 题号 -->
    <div class="question_header">
      <div class="question_number">
        <!-- 得分状况 -->
        <div
          class="score_box"
          v-if="forbid2Change"
          v-text="`得分：${QSInfo.acscore}/${question.score}`"
        ></div>
        <!-- 公共的【上传附件】按钮 -->
        <!-- <van-uploader class="uploadFileBtn" v-else :before-read="uploadFile">
          <van-button type="info" size="small">上传附件</van-button>
        </van-uploader>-->
        <span class="premise">{{ questionIdx }}</span>
        <span class="topic">/{{ total }}题</span>
        <!-- 计时器 -->
        <div class="score_time" v-if="!forbid2Change">
          <van-icon name="clock-o" />
          <span v-text="countdown"></span>
        </div>
      </div>
    </div>
    <!-- 问题描述 -->
    <div class="question_box" id="question">
      <!-- 题型：题目 -->
      <div class="question_desc">
        <div class="question_type" v-text="questionTypeDesc"></div>
        <div class="question_title">{{ question.title }}</div>
      </div>
      <!-- 选择题 type: 1-->
      <div class="question_choice" v-if="questionType === 1 || questionType === 2">
        <div
          class="answer"
          :class="{
            'pitch': isPitchOn(item),
            'finish_right':forbid2Change && item.iscorrect,
            'finish_wrong':forbid2Change && isPitchOn(item) && !item.iscorrect
          }"
          v-for="(item, i) in QSInfo.optioninfo"
          :key="i"
          @click="select(item)"
        >
          <!-- <span v-text="`${item.optionid}.`"></span> -->
          <span v-text="item.optionname"></span>
          <template v-if="forbid2Change">
            <van-icon name="success" v-if="item.iscorrect" />
            <van-icon name="cross" v-else-if="isPitchOn(item) && !item.iscorrect" />
          </template>
        </div>
        <!-- 上传文件列表 -->
        <!-- <Files :list="filesList" ref="files" openFile isdelete @delete="deleteFile" /> -->
      </div>
      <!-- 填空题 type: 3-->
      <ul class="question_gap_filling" v-if="questionType === 3">
        <li v-for="(item, idx) in QSInfo.optioninfo" :key="idx">
          <label>填空题{{ idx + 1 }}</label>
          <div class="question_gap_filling_answer">
            <van-field
              v-model="temporary[idx]"
              rows="1"
              type="textarea"
              autosize
              placeholder="请输答案..."
              :class="{ 
                'active': answering === idx,
                'tk_right': forbid2Change && tkIsRight(item.optionname,temporary[idx]),
                'tk_wrong': forbid2Change && !tkIsRight(item.optionname,temporary[idx]),
              }"
              :right-icon="rightIcon(item.optionname,temporary[idx])"
              :disabled="forbid2Change"
              :error-message="forbid2Change ? tkErrorMsg(item.optionname, temporary[idx]) : ''"
              @focus="answering = idx"
              @blur="answering = undefined"
            />
          </div>
        </li>
        <!-- 上传文件列表 -->
        <!-- <Files :list="filesList" ref="files" openFile isdelete @delete="deleteFile" /> -->
      </ul>
      <!-- 简答题 type: 4-->
      <div class="question_short_answer" v-if="questionType === 4">
        <van-field
          autosize
          v-model="temporary[0].content"
          rows="4"
          type="textarea"
          placeholder="请输入答案..."
          :disabled="forbid2Change"
        >
          <div slot="right-icon">
            <template v-if="forbid2Change">
              <van-icon name="upgrade" />
            </template>
            <template v-else>
              <van-uploader :before-read="uploadFile">
                <van-icon name="upgrade" />
              </van-uploader>
            </template>
          </div>
        </van-field>

        <!-- 上传文件列表 -->
        <Files :list="filesList" ref="files" openFile isdelete @delete="deleteFile" />
      </div>
    </div>

    <!-- 操作按钮 -->
    <div class="buttons">
      <template>
        <template v-if="total == 1">
          <van-button
            v-if="isend"
            block
            type="primary"
            color="#247dff"
            :disabled="!QSInfo.cansave"
            @click="submit(2)"
          >查看结果</van-button>
          <van-button v-else block type="primary" color="#247dff" @click="submit(1)">提交</van-button>
        </template>

        <van-button
          v-else-if="questionIdx == 1 && total > 1"
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>

        <van-button
          v-else-if="QSInfo.pretqsid"
          block
          plain
          type="primary"
          color="#247dff"
          @click="last"
        >上一题</van-button>
      </template>

      <template>
        <div v-show="total == 1 || (total > 1 && questionIdx == 1)" class="empty"></div>
        <van-button
          v-if="
            total > 1 &&
              QSInfo.hasnext &&
              questionIdx != 1 &&
              questionIdx != total
          "
          block
          plain
          type="primary"
          color="#247dff"
          @click="next"
        >下一题</van-button>
        <template v-else-if="total != 1 && total == questionIdx">
          <van-button
            v-if="forbid2Change"
            block
            type="primary"
            color="#247dff"
            @click="checkResult"
          >查看结果</van-button>
          <van-button
            v-else
            block
            type="primary"
            color="#247dff"
            :disabled="!QSInfo.cansave"
            @click="submit(1)"
          >提交</van-button>
        </template>
      </template>
    </div>

    <!-- 查看分数 -->
    <van-overlay :show="showGrade">
      <div class="wrapper">
        <div class="box">
          <p class="title">答卷提交成功</p>
          <p class="grade" style="margin-top: 19vw;">
            <template v-if="ischeck">
              本次考试
              <span v-text="score"></span>分
            </template>
            <template v-else>等待老师阅卷</template>
          </p>
          <div class="button" @click="goBack">返回上一页</div>
          <div class="back" @click="goHome">返回首页</div>
        </div>
      </div>
    </van-overlay>

    <!-- 文件上传进度环 -->
    <van-dialog v-model="showLoading" :showConfirmButton="false">
      <van-circle
        v-model="currentRate"
        :rate="10"
        :speed="100"
        :text="rateTxt"
        :stroke-width="60"
        stroke-linecap="butt"
      />
      <p>上传进度</p>
    </van-dialog>
  </div>
</template>

<script>
import {
  getExamQuestion,
  submitExamAnswer,
  getExamRecord,
  uploadExamFiles
} from '@/api/classRing'
import * as qiniu from 'qiniu-js'
import { getQiniuToken } from '@/api/qiniuUpload'
import Files from '@/components/extend/files/index'
import eventBus from '@/util/eventBus'
export default {
  name: 'examQuastionDetail',
  components: { Files },
  data() {
    return {
      kind: this.$route.params.id, // 使用类型 1-作业 2-考试
      temporary: [], // 临时答案区
      showGrade: false, // 显示成绩
      score: 0, // 成绩
      answering: undefined, //填空题是否正在答题
      countdown: '00:00:00', //倒计时
      validTime: 0, // 有效时间(秒)
      filesList: [], //上传文件列表
      showLoading: false, //是否展示进度环
      currentRate: 0, // 进度环百分比
      uploadTimer: undefined, //上传文件的定时器
      id: this.$route.query.id, // 考试/作业 id
      recordid: this.$route.query.recordid, //学员记录id
      lastqsid: this.$route.query.lastqsid, //上一次提交答题id（要提交答案的答题id）
      total: this.$route.query.total, //试题总数
      questionIdx: 0, //当前答题下标
      pretqsid: 0, //上一题答题id 为0即没有
      nextqsid: 0, //下一题答题id 为0即没有
      title: this.$route.query.title, //动态标题（作业/考试 名称）
      isend: this.$route.query.isend, //是否已提交答卷
      overdue: this.$route.query.overdue, //答题是否已结束
      headmasterId: this.$route.query.headmasterId, //班主任userid
      supervisorId: this.$route.query.supervisorId, //导师userid
      QSInfo: {}, //整个题目信息
      question: {}, //题目信息
      ischeck: false //老师已阅卷
    }
  },
  computed: {
    // 答题类型
    questionType() {
      return this.question.mode
    },
    // 答题类型 文字描述
    questionTypeDesc() {
      switch (this.question.mode) {
        case 1:
          return '单选题'
          break
        case 2:
          return '多选题'
          break
        case 3:
          return '填空题'
          break
        case 4:
          return '简答题'
          break
      }
    },
    // 班主任 | 导师
    isTeach() {
      const teachGroup = [this.headmasterId]
      if (this.supervisorId) {
        teachGroup.push(this.supervisorId)
      }
      const userId = this.$store.state.user.userId
      return teachGroup.includes(Number(userId))
    },
    // 禁止修改
    forbid2Change() {
      return this.isend == 1 || this.overdue == 1 || this.isTeach
    },
    // 上传进度环 百分比
    rateTxt() {
      return this.currentRate.toFixed(0) + '%'
    }
  },
  methods: {
    // 获取答题信息
    getExamQuestion(explanid, recordid, qsid) {
      return getExamQuestion({ explanid, recordid, qsid }).then(res => {
        const mode = res.questioninfo[0].mode
        const answer = res.answer
        const option = res.optioninfo
        this.question = res.questioninfo[0]
        this.temporary = this.getAnswerList(mode, answer, option) //答案（临时区）
        this.QSInfo = res
        this.pretqsid = res.pretqsid //上一题答题id
        this.nextqsid = res.nextqsid //下一题答题id
        this.questionIdx = res.rows //题目序号
        this.filesList = res.file //附件
        // 未提交
        if (this.isend == 0) {
          clearInterval(this.countdownTimer)
          this.countdownTimer = undefined
          this.$nextTick(() => {
            this.validTime = Number(res.countdown) //有效时间
            if (this.validTime) {
              this.setCountdown() //设置倒计时
            }
          })
        }
        return res //返回数据到其他地方引用
      })
    },
    /**
     * @desc 转换答案格式
     * @params
     *     mode    Number 答题类型 1-单选 2-多选 3-填空 4-简答
     *     answer  String 数据库返回的答案字符串
     *     option  Array  接口返回的选项
     * @return Array
     */
    getAnswerList(mode, answer, option) {
      let list = []
      if (answer) {
        //后台返回的字符串格式拼接的答案内容
        // 单  选：1_答案A(前面的数字是optionid)
        // 多选题：1_答案A|2_答案B|C_答案C(前面的数字是optionid)
        // 填空题：1_空1内容|2_空2内容|C_空3内容(前面的数字是optionid)
        // 简答题：0_答案(简答没有选项，所以保存为0)
        answer.split('|').forEach(op => {
          const optId = op.split('_')[0]
          const content = op.split('_')[1]
          if ([1, 2].includes(mode)) {
            //选择题
            const opt = option.find(item => item.optionid == optId) //预防后台修改选项，之前的答案找不到
            if (opt) {
              list.push(opt)
            }
          } else if ([3].includes(mode)) {
            //填空题
            list.push(content)
          } else if ([4].includes(mode)) {
            // 简答题
            list.push({
              optionid: 0,
              content
            })
          }
        })
      } else {
        if (mode === 4) {
          //简答题
          list.push({
            optionid: 0,
            content: ''
          })
        }
      }
      return list
    },
    // （选择题）判断是否被选中
    isPitchOn(opt) {
      return this.temporary.includes(opt)
    },
    // 选择题点击选择框
    select(opt) {
      if (this.forbid2Change) return
      if (this.questionType === 1) {
        // 单选
        this.temporary = [opt]
      } else if (this.questionType === 2) {
        // 多选
        if (this.temporary.includes(opt)) {
          this.temporary = this.temporary.filter(
            it => it.optionid != opt.optionid
          )
        } else {
          this.temporary.push(opt)
        }
      }
    },
    // 将答案(临时区)转换成接口指定格式数据
    changeAnswerType(mode) {
      let list = []
      if ([1, 2].includes(mode)) {
        //选择题
        this.temporary.forEach(item => {
          list.push({
            optionid: item.optionid,
            content: item.optionname
          })
        })
      } else if (mode === 3) {
        //填空题
        this.temporary.forEach((item, idx) => {
          list.push({ optionid: idx, content: item.trim() })
        })
      } else if (mode === 4) {
        //简答题
        list = this.temporary
      }
      return list
    },
    // 下一题
    next() {
      let answer = this.changeAnswerType(this.questionType)
      if (answer.length === 0) {
        this.$toast('请先完成作答')
        return
      } else if (
        this.questionType === 3 &&
        answer.length != this.QSInfo.optioninfo.length
      ) {
        this.$toast('请先完成作答')
        return
      } else {
        if (this.forbid2Change) {
          // 提交成功，创建下一题答题id（若数据库已经创建过，则返回最新创建的）
          // const nextQuestionId = this.nextqsid != 0 ? this.nextqsid : res.lastqsid
          let nextQuestionId = 0
          if (this.nextqsid != 0 && this.QSInfo.hasnext) {
            nextQuestionId = this.nextqsid
          } else {
            this.$toast('这已经是您的所有答题记录')
            return
          }
          // 获取下一题
          this.getExamQuestion(this.id, this.recordid, nextQuestionId)
            .then(res => {
              // this.questionIdx = this.questionIdx + 1
              this.lastqsid = nextQuestionId
            })
            .catch(err => {
              this.$toast('获取失败')
            })
        } else {
          //有答案，允许提交
          submitExamAnswer({
            explanid: this.id, //维持不变
            recordid: this.recordid, //维持不变
            qsid: this.lastqsid, //当前题目id
            questionid: this.QSInfo.questionid, //题库id
            answer
          }).then(res => {
            // 提交成功，创建下一题答题id（若数据库已经创建过，则返回最新创建的）
            const nextQuestionId =
              this.nextqsid != 0 ? this.nextqsid : res.lastqsid
            if (res.msg) {
              this.$toast(res.msg)
              return
            } else {
              // 获取下一题
              this.getExamQuestion(this.id, this.recordid, nextQuestionId)
                .then(res => {
                  // this.questionIdx = this.questionIdx + 1
                  this.lastqsid = nextQuestionId
                })
                .catch(err => {
                  this.$toast('获取失败')
                })
            }
          })
        }
      }
    },
    // 上一题
    last() {
      let answer = this.changeAnswerType(this.questionType)
      if (answer.length === 0) {
        this.$toast('请先完成作答')
        return
      } else if (
        this.questionType === 3 &&
        answer.length != this.QSInfo.optioninfo.length
      ) {
        this.$toast('请先完成作答')
        return
      } else {
        if (this.forbid2Change) {
          //已交卷
          if (this.pretqsid != 0) {
            //还有下一题的 直接等于下一题
            this.lastqsid = this.pretqsid
          } else {
            //没有下一题的 应该就等于当前（之前获取的pretid）,即不用变更
          }
          this.getExamQuestion(this.id, this.recordid, this.pretqsid)
            .then(res => {
              // this.questionIdx = this.questionIdx - 1
            })
            .catch(err => {
              console.error('题目获取失败', err)
              this.$toast('题目获取失败')
            })
        } else {
          //有答案，允许提交
          submitExamAnswer({
            explanid: this.id, //维持不变
            recordid: this.recordid, //维持不变
            qsid: this.lastqsid, //当前题目id
            questionid: this.QSInfo.questionid, //题库id
            answer
          })
            .then(res => {
              if (res.msg) {
                this.$toast(res.msg)
                return
              } else {
                if (this.pretqsid != 0) {
                  //还有下一题的 直接等于下一题
                  this.lastqsid = this.pretqsid
                } else {
                  //没有下一题的 应该就等于当前（之前获取的pretid）,即不用变更
                }
                this.getExamQuestion(this.id, this.recordid, this.pretqsid)
                  .then(res => {
                    // this.questionIdx = this.questionIdx - 1
                  })
                  .catch(err => {
                    console.error('题目获取失败', err)
                    this.$toast('题目获取失败')
                  })
              }
            })
            .catch(err => {
              console.error('提交失败', err)
              this.$toast('提交失败')
            })
        }
      }
    },
    // 提交答案 type: 1-提交 2-查看分数
    submit(type) {
      if (type === 1) {
        if (this.QSInfo.cansave) {
          //允许保存
          let answer = this.changeAnswerType(this.questionType)
          if (answer.length === 0) {
            this.$toast('请先完成作答')
            return
          } else if (
            this.questionType === 3 &&
            answer.length != this.QSInfo.optioninfo.length
          ) {
            this.$toast('请先完成作答')
            return
          }
          submitExamAnswer({
            explanid: this.id, //维持不变
            recordid: this.recordid, //维持不变
            qsid: this.lastqsid, //当前题目id
            questionid: this.QSInfo.questionid, //题库id
            answer,
            isend: 1 //提交标识
          }).then(res => {
            this.$store.dispatch('user/forbid2Change', true)
            getExamRecord(this.recordid).then(res => {
              this.score = res.data[0].score
              this.ischeck = res.data[0].ischeck //老师已阅卷
              this.showGrade = true
            })
          })
        } else {
          this.$toast('不允许提交') //标签已添加disabled判断，所以这里可删除
        }
      } else if (type === 2) {
        getExamRecord(this.recordid).then(res => {
          this.score = res.data[0].score
          this.ischeck = res.data[0].ischeck //老师已阅卷
          this.showGrade = true
        })
      }
    },
    // 返回到测试练习列表
    goBack() {
      this.$router.go(-1)
    },
    // 回到首页
    goHome() {
      this.$router.push('/')
    },
    // 设置 倒计时
    setCountdown() {
      // 还在有效期内，可进行倒计时
      this.countdownTimer = setInterval(() => {
        if (this.validTime) {
          this.countdown = this.formatSeconds(this.validTime)
          this.validTime--
        } else {
          clearInterval(this.countdownTimer)
          this.countdownTimer = undefined
        }
      }, 1000)
    },
    // 计算剩余（时:分:秒）
    formatSeconds(value) {
      if (!value) {
        return '00:00:00'
      } else {
        let second = parseInt(value),
          minute = 0,
          hour = 0
        if (second > 60) {
          minute = parseInt(second / 60)
          second = parseInt(second % 60)
          if (minute > 60) {
            hour = parseInt(minute / 60)
            minute = parseInt(minute % 60)
          }
        }
        return `${getNumber(hour)}:${getNumber(minute)}:${getNumber(second)}`

        function getNumber(v) {
          return v >= 0 && v < 10 ? `0${v}` : v
        }
      }
    },
    // 上传附件
    uploadFile(file) {
      if (this.filesList.length >= 3) {
        this.$toast('最多上传不超过3个附件')
        return
      }
      this.showLoading = true
      // 获取七牛云token
      getQiniuToken()
        .then(res => {
          let that = this
          const baseUrl = res.data.url // 文件地址域名
          const token = res.data.token // 七牛云token
          const key = file.name // 文件名
          const putExtra = {
            fname: file.name,
            params: {}
            // mimeType: ['word', 'txt']
          }
          // 监听
          const observer = {
            // 上传进度
            next(res) {
              that.currentRate = res.total.percent
            },
            error(err) {
              // 错误处理
              that.showLoading = false
              console.error('上传失败：', err)
            },
            complete(res) {
              // 上传完成
              const params = {
                title: key,
                qsid: that.lastqsid,
                url: baseUrl + res.key,
                autoid: '', //当type=0必填
                type: 9 // 0-删除 9-新建
              }
              // 上传文件到服务器
              uploadExamFiles(params)
                .then(data => {
                  that.showLoading = false
                  that.filesList.push({
                    title: file.name,
                    lag: (file.size / 1024 / 1024).toFixed(2),
                    fileext: file.name.slice(file.name.lastIndexOf('.') + 1),
                    url: baseUrl + res.key,
                    autoid: data.autoid
                  })
                })
                .catch(err => {
                  that.showLoading = false
                  console.error('上传文件到服务器失败')
                })
            }
          }
          const config = { useCdnDomain: true }
          const observable = qiniu.upload(file, key, token, putExtra, config)
          const subscription = observable.subscribe(observer)
        })
        .catch(err => {
          this.showLoading = false
          console.error('获取七牛云token失败')
        })
    },
    // 删除附件
    deleteFile(info) {
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要删除：${info.title}`,
          confirmButtonColor: '#ff3b30',
          cancelButtonColor: '#007aff'
        })
        .then(() => {
          const params = {
            title: info.title,
            qsid: this.lastqsid,
            url: info.url,
            autoid: info.autoid, //附件id
            type: 0
          }
          uploadExamFiles(params)
            .then(data => {
              this.$toast('删除成功')
              this.showLoading = false
              this.filesList = this.filesList.filter(
                item => item.autoid != info.autoid
              )
            })
            .catch(err => {
              this.showLoading = false
              console.error('上传文件到服务器失败')
            })
        })
    },
    // 查看答题结果
    checkResult() {
      getExamRecord(this.recordid).then(res => {
        this.score = res.data[0].score
        this.ischeck = res.data[0].ischeck
        this.showGrade = true
      })
    },
    // 填空题右边图标
    rightIcon(str, answer) {
      if (this.forbid2Change) {
        return this.tkIsRight(str, answer) ? 'success' : 'cross'
      } else {
        return ''
      }
    },
    // 判断填空题答案是否正确
    tkIsRight(str = '', answer = '') {
      return str.split('|').includes(answer)
    },
    //填空题错误话术
    tkErrorMsg(str, answer) {
      return this.tkIsRight(str, answer)
        ? ''
        : `正确答案：${str.replace(/\|/g, '/')}`
    }
  },
  mounted() {
    this.getExamQuestion(this.id, this.recordid, this.lastqsid)
    this.$store.dispatch('user/forbid2Change', this.forbid2Change)
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer)
    clearInterval(this.uploadTimer)
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  position: relative;
  // 题号（序号+倒计时）
  .question_header {
    .question_number {
      position: relative;
      height: 14vw;
      line-height: 14vw;
      text-align: center;
      width: 100%;
      font-size: 4vw;
      .premise {
        color: $active-color;
      }
      .topic {
        color: #303133;
      }
      // 已提交的题目，查看得分情况 || 倒计时 || 上传附件 按钮
      .score_box,
      .score_time,
      .uploadFileBtn {
        position: absolute;
        top: 50%;
        right: 3vw;
        transform: translateY(-50%);
        color: $active-color;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          padding-left: 1vw;
        }
      }
      // 已提交的题目，查看得分情况 | 上传附件 按钮
      .score_box,
      .uploadFileBtn {
        right: auto;
        left: 3vw;
        font-size: 3.2vw;
        color: $font-gray;
      }
      // 上传附件 按钮
      .uploadFileBtn {
        color: #fff;
      }
    }
  }
  // 答卷
  .question_box {
    min-height: calc(100% - 13.333vw - 14vw - 32vw);
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;
    // 题目
    .question_desc {
      width: 93.6vw;
      margin: 7.2vw auto;
      display: flex;
      .question_type {
        width: 13.333vw;
        height: 5.333vw;
        background-color: #ffffff;
        border-radius: 2.667vw;
        border: solid 0.267vw #bfc2cc;
        font-size: 2.933vw;
        color: #909399;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .question_title {
        margin-left: 2vw;
        font-size: 4vw;
        color: #303133;
        flex: 1;
        p {
          width: 100%;
        }
        img {
          width: 100%;
        }
        a {
          text-decoration: none;
        }
      }
    }
    // 选择题
    .question_choice {
      width: 93.6vw;
      margin: 0 auto;
      .answer {
        width: 93.6vw;
        min-height: 11.733vw;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 1.067vw;
        border: solid 0.267vw #edeff2;
        margin: 0 auto;
        margin-top: 4vw;
        display: flex;
        align-items: center;

        font-size: 3.733vw;
        letter-spacing: -0.133vw;
        color: #303133;
        span {
          padding-left: 5.333vw;
        }
        span:nth-child(2) {
          flex: 1;
        }
        i {
          padding: 0 1vw;
          font-size: 1.5em;
        }
      }
      .pitch {
        border: solid 0.267vw #247dff;
      }
      .finish_right {
        i {
          color: $success-color;
        }
      }
      .finish_wrong {
        i {
          color: $warn-color;
        }
      }
    }
    // 填空题
    .question_gap_filling {
      padding: 0 3.2vw;
      li {
        @include fja(space-between, center);
        margin-bottom: 3.2vw;
        label {
          @include wh(13.333vw, 100%);
          @include font(3.2vw);
        }
        .question_gap_filling_answer {
          flex: 1;
          margin-left: 2.133vw;
          .van-cell {
            @include borderRadius(1vw);
            min-width: 36.667vw;
            width: auto;
            display: inline-block;
            border: 1px solid #ccc;
            overflow: hidden;
            input:disabled {
              background: $bg-light;
            }
          }
          .active {
            border-color: $active-color;
          }
          /deep/.tk_right {
            .van-cell__value {
              .van-field__body {
                .van-field__right-icon {
                  .van-icon {
                    color: $success-color;
                  }
                }
              }
            }
          }
          /deep/.tk_wrong {
            .van-cell__value {
              .van-field__body {
                .van-field__right-icon {
                  .van-icon {
                    color: $warn-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    // 简答题
    .question_short_answer {
      padding: 0 3vw;
      .van-field {
        border: 1px solid $border-gray;
        @include borderRadius(1vw);
        padding-right: 2.667vw;
        /deep/.van-cell__value {
          .van-field__body {
            align-items: flex-end;
            textarea {
            }
            .van-field__right-icon {
              font-size: 2vw;
            }
          }
        }
      }
      // .main {
      //   .datum {
      //     margin: 0;
      //   }
      // }
    }
  }
  // 操作按钮
  .buttons {
    width: 100%;
    padding: 2vw 3vw 5vw 3vw;
    box-sizing: border-box;
    .van-button {
      margin-bottom: 3vw;
      border-radius: 1vw;
    }
    .van-button:last-child {
      margin-bottom: 0;
    }
    .empty {
      height: 11.733vw;
    }
  }
  // 上传进度环
  .van-dialog {
    /deep/ .van-dialog__content {
      text-align: center;
      padding: 1em;
      .van-circle {
        display: inline-block;
      }
    }
  }
}

// 答题分数
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 84.533vw;
    height: 88.133vw;
    background-image: url('~@/assets/images/grade.jpg');
    background-size: 100%;
    border-radius: 6.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 10vw;
      font-size: 7.467vw;
      color: #fffefb;
    }
    .grade {
      font-size: 4.533vw;
      color: #303133;
      span {
        color: #247dff;
      }
    }
    .button {
      margin-top: 8vw;
      width: 63.867vw;
      height: 11.2vw;
      background-image: linear-gradient(90deg, #37c1ff 0%, #137bfe 100%);
      border-radius: 5.6vw;
      font-size: 4.267vw;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // .bton {}
    .back {
      margin-top: 6vw;
      font-size: 4.267vw;
      color: #249cff;
    }
  }
}
// vue 手机键盘把底部按钮顶上去 ??? 参考https://segmentfault.com/a/1190000014228563
</style>
