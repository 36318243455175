<!-- 学员进入班圈详情页-看到的导师列表头像 -->
<!-- @todo 建议封装一下 图片展示默认图的问题 -->
<!-- 需求改动，可以删除 -->
<template>
<div>
    <ul class="name_list" v-if="list.length > 0">
        <li class="name_list_li" v-for="(item, idx) in list" :key="idx">
            <div class="name_list_li_avatar">
            <img :src="item.pic" v-if="item.pic">
            <img src="@/assets/images/head_prole.png" v-else>
            </div>
            <p class="name_list_li_username" v-text="item.name"></p>
        </li>
    </ul>
    <div v-else>暂无信息</div>
</div>
</template>

<script>
export default {
    deata() {
        return {
        }
    },
    props: {
        list: Array
    },
    methods: {

    },
    mounted() {
        // console.log(this)
    }
}
</script>

<style lang="scss" scoped>
// 名单列表
.name_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: columns;
  .name_list_li {
    padding: 2vw;
    .name_list_li_avatar {
        width: 14vw;
        height: 14vw;
        border-radius: 50%;
        border: 1px solid rgba(104, 104, 104, .1);
        overflow: hidden;
        position: relative;
      img {//@todo 默认图未配置
        height: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .name_list_li_username {
      width: 14vw;
      padding: 2vw 0;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .name_list_li:nth-child(5n+1) {
    padding-left: 0;
  }
  .name_list_li:nth-child(5n) {
    padding-right: 0;
  }
}
</style>
