<template>
  <div class="set_homework">
    <topheader :title="headerText" />
    <!-- 所属班圈/课程信息 -->
    <div class="lesson_msg">基本信息</div>
    <van-form class="newHomework">
      <van-cell-group class="homework_info">
        <van-field
          label="所属班圈:"
          name="className"
          type="textarea"
          rows="1"
          label-width="5em"
          readonly
          clickable
          :value="className"
          autosize
        />
      </van-cell-group>
      <van-cell-group class="homework_info">
        <van-field
          label="所属课程:"
          name="lessonName"
          type="textarea"
          rows="1"
          label-width="5em"
          readonly
          clickable
          :value="lessonName"
          autosize
        />
      </van-cell-group>
      <van-cell-group class="homework_info mini">
        <van-field
          label="课程时间:"
          name="lessonName"
          label-width="5em"
          readonly
          clickable
          :value="`${lessonStartTime} - ${lessonEndTime}`"
          autosize
        />
      </van-cell-group>
    </van-form>

    <div class="lesson_msg">作业内容</div>

    <van-form ref="form" class="newHomework" validate-first @submit="onSubmit">
      <!-- 日期选择 -->
      <van-cell-group class="homework_time">
        <van-field
          v-model="startTime"
          label="开始时间:"
          name="startTime"
          placeholder="请选择..."
          type="textarea"
          rows="1"
          label-width="5em"
          autosize
          readonly
          disabled
          required
          :rules="[{ required: true, message: '请选择开始时间' }, {validator: beginTimeValid, message: ''}]"
          @click="showStartTimePicker"
        />
      </van-cell-group>
      <van-cell-group class="homework_time">
        <van-field
          v-model="endTime"
          label="结束时间:"
          name="endTime"
          placeholder="请选择..."
          type="textarea"
          rows="1"
          label-width="5em"
          readonly
          autosize
          disabled
          required
          :rules="[{ required: true, message: '请选择结束时间' },{ validator: endTimeValid, message: '开始时间不能大于等于结束时间' }]"
          @click="showEndTimePicker"
        />
      </van-cell-group>
      <!-- 作业信息 -->
      <van-cell-group class="homework_content">
        <van-field
          v-model="title"
          name="title"
          type="textarea"
          label="作业名称:"
          placeholder="请输入..."
          rows="1"
          label-width="5em"
          autosize
          required
          clearable
          :disabled="!(isNew || !began)"
          :rules="[{ required: true, message: '请输入作业名称' }]"
        />
        <van-field
          v-model="content"
          name="content"
          type="textarea"
          label="作业内容:"
          placeholder="请输入..."
          rows="3"
          label-width="5em"
          required
          autosize
          clearable
          :disabled="!(isNew || !began)"
          :rules="[{ required: true, message: '请输入作业内容' }]"
        >
          <template #input v-if="!(isNew || !began)">
            <div class="homework_content_readonly" v-html="content"></div>
          </template>
        </van-field>

        <div class="btn" v-if="isNew || !began">
          <van-button
            block
            type="info"
            native-type="submit"
            loading-text="正在保存..."
            :loading="loading"
            :disabled="loading"
          >保存</van-button>
        </div>
      </van-cell-group>
    </van-form>

    <!-- 开始时间选择器 -->
    <van-popup v-model="showStartTime" round position="bottom">
      <van-datetime-picker
        v-model="startT"
        type="date"
        title="选择开始时间"
        :min-date="startMinDate"
        :max-date="startMaxDate"
        @confirm="selectStartPicker"
        @cancel="cancelStartPicker"
      />
    </van-popup>
    <!-- 结束时间选择器 -->
    <van-popup v-model="showEndTime" round position="bottom">
      <van-datetime-picker
        v-model="endT"
        type="date"
        title="选择结束时间"
        :min-date="endMinDate"
        :max-date="endMaxDate"
        @confirm="selectEndPicker"
        @cancel="cancelEndPicker"
      />
    </van-popup>

    <!-- 成功弹窗 -->
    <SuccessDialog
      cancelText="返回上一页"
      confirmText="继续发布"
      :show="show"
      :title="isNew? undefined : '编辑成功'"
      :confirmBtn="isNew"
      @confirm="successCallback"
    />
  </div>
</template>

<script>
import { getHWPlanList } from '@/api/my'
import { editeHWPlaninfo } from '@/api/my'
import SuccessDialog from '@/components/extend/successDialog'
export default {
  components: { SuccessDialog },
  data() {
    return {
      userId: this.$store.state.user.userId,
      className: this.$route.query.className,
      lessonId: this.$route.query.lessonId,
      lessonName: this.$route.query.lessonName,
      isNew: this.$route.query.isNew == 1, //是否新建作业
      began: this.$route.query.began == 1, // 课程是否已经开始

      lessonStartTime: this.$route.query.beginDate.replace(/\-/g, '/'), //课程开始时间
      lessonEndTime: this.$route.query.endDate.replace(/\-/g, '/'), //课程结束时间

      startMinDate: new Date(),
      startMaxDate: undefined,
      endMinDate: new Date(),
      endMaxDate: undefined,
      startT: new Date(), //选择器的开始时间
      endT: '', //选择器的结束时间
      startTime: '', //开始时间
      endTime: '', //结束时间
      title: '', //作业名称
      content: '', //内容
      showStartTime: false, //是否展示开始时间的选择器
      showEndTime: false, //是否展示结束时间的选择器
      curHWInfo: {}, //当前选中的作业信息
      loading: false, //正在上传
      show: false //接口调用成功之后的弹窗提示
    }
  },
  filters: {
    serial(i) {
      return i <= 9 ? `0${i}` : i
    }
  },
  computed: {
    headerText() {
      return this.isNew ? '发布作业' : this.began ? '作业描述' : '作业编辑'
    }
  },
  methods: {
    // 展示开始时间选择器
    showStartTimePicker() {
      if (this.isNew || !this.began) {
        this.showStartTime = true
        this.startT = new Date(this.startTime.replace(/\-/g, '/'))
      }
    },
    // 展示结束时间选择器
    showEndTimePicker() {
      if (this.isNew || !this.began) {
        this.showEndTime = true
        this.endT = new Date(this.endTime.replace(/\-/g, '/'))
      }
    },
    // 选中开始时间
    selectStartPicker(v) {
      this.startTime = v && v.Format('yyyy-MM-dd')
      this.showStartTime = false
    },
    // 选中结束时间
    selectEndPicker(v) {
      this.endTime = v && v.Format('yyyy-MM-dd')
      this.showEndTime = false
    },
    // 开始时间校验
    beginTimeValid(v) {
      return true
    },
    // 结束时间校验
    endTimeValid(v) {
      // ui框架存在问题：如果第一次校验开始时间大于结束时间【校验不通过】，此时改小开始时间，再提交不会触发结束时间的校验，导致无法提交
      return v > this.startTime
    },
    // 取消开始时间
    cancelStartPicker() {
      this.showStartTime = false
    },
    // 取消结束时间
    cancelEndPicker() {
      this.showEndTime = false
    },
    initForm() {
      this.title = ''
      this.content = ''
      this.startTime = ''
      this.endTime = ''
    },
    dateValid() {
      return new Promise((resolve, reject) => {
        const hwStartTime = Date.parse(
          new Date(this.startTime.replace(/\-/g, '/'))
        )
        const hwEndTime = Date.parse(new Date(this.endTime.replace(/\-/g, '/')))
        const lessonStartTime = Date.parse(this.lessonStartTime)
        const lessonEndTime = Date.parse(this.lessonEndTime)

        // 结束时间大于课程结束时间
        if (hwEndTime > lessonEndTime) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '作业设置的结束时间大于课程结束时间，确定继续吗？'
            })
            .then(() => resolve(true))
            .catch(reject)
        } else if (hwStartTime < lessonStartTime) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '作业设置的开始时间小于课程开始时间，确定继续吗？'
            })
            .then(() => resolve(true))
            .catch(err => reject(false))
        } else {
          resolve(true)
        }
      })
    },
    async onSubmit() {
      const defaultParams = {
        courseid: this.lessonId,
        title: this.title,
        content: this.content,
        bd: this.startTime,
        ed: this.endTime,
        duration: false
      }
      let params = {}
      let valid = await this.dateValid() //时间校验
      if (valid) {
        if (this.isNew) {
          //新增
          params = Object.assign(defaultParams, {
            sqnum: 99, //序号默认99,暂时不让老师更新
            autoid: null,
            type: 9
          })
        } else {
          //编辑
          params = Object.assign(defaultParams, {
            sqnum: this.curHWInfo.sqnum,
            autoid: this.curHWInfo.autoid,
            type: 1
          })
        }
        // 先清除校验
        this.$refs.form.resetValidation()
        // 校验
        this.$refs.form.validate().then(() => {
          this.loading = true
          editeHWPlaninfo(params)
            .then(res => {
              this.show = true
              this.loading = false
            })
            .catch(err => {
              this.loading = false
            })
        })
      } else {
        return '时间校验不通过，用户主动取消'
      }
    },
    deleteHomework(item) {
      if (this.began) {
        const t =
          item.title.length > 10 ? item.title.slice(0, 8) + '...' : item.title
        this.$dialog.alert({
          title: '提示',
          message: `当前作业：“${t}”已开始答题，不支持删除！`
        })
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定要删除“${item.title}”作业吗？`
          })
          .then(() => {
            editeHWPlaninfo({
              courseid: this.lessonId,
              title: item.title, //可不传
              content: item.content, //可不传
              bd: item.bd, //可不传
              ed: item.ed, //可不传
              duration: false, //可不传
              sqnum: null, //可不传
              autoid: item.autoid,
              type: 0
            }).then(() => {
              const group = this.list
              this.list = []
              this.$nextTick(() => {
                this.list = group.filter(it => it.autoid !== item.autoid)
                this.$toast({
                  message: '删除成功',
                  position: 'bottom'
                })
              })
            })
          })
      }
    },
    // 弹窗成功回调
    successCallback() {
      this.initForm()
      this.show = false
    }
  },
  created() {
    const info = this.$route.query
    if (info.isNew == 0) {
      // this.startMinDate = new Date()
      // // this.startMaxDate = undefined
      // this.endMinDate = new Date()
      // // this.endMaxDate = undefined
      // this.startT = new Date() //选择器的开始时间
      // this.endT = '' //选择器的结束时间
      // this.startTime = '' //开始时间
      // this.endTime = '' //结束时间
      // this.title = '' //作业名称
      // this.content = '' //内容
      this.curHWInfo = info
      this.isNew = false
      this.title = info.title
      this.content = info.content
      this.startTime = info.startTime
      this.endTime = info.endTime
    }
  }
}
</script>

<style lang="scss" scoped>
.set_homework {
  @include isTop;
  // 作业
  .newHomework {
    padding: 0 3.2vw;
    /deep/.van-cell-group {
      padding-top: 2.667vw;
      .van-cell {
        margin: 0;
        font-size: 3.8vw;
      }
      .van-cell::after {
        border-bottom: 0 none;
      }
    }
    .van-cell-group::after {
      border-bottom: 0 none;
    }
    .homework_info,
    .homework_time,
    .homework_content {
      /deep/.van-cell {
        padding-left: 0.5em;
        box-sizing: border-box;
        .van-field__value {
          .van-field__body {
            .van-field__control {
              .homework_content_readonly {
                color: $font-gray;
                p {
                  color: inherit !important;
                  width: 100%;
                  img {
                    @include imgCover;
                  }
                }
                img {
                  @include imgCover;
                }
              }
            }
          }
        }
      }
      .van-cell::before {
        left: 0;
      }
      /deep/.van-cell:nth-child(2) {
        .van-cell__value {
          box-shadow: $box-shadow;
          padding: 0.5em;
        }
      }
    }
    //
    .mini {
      .van-cell {
        /deep/.van-cell__value {
          font-size: 3.2vw;
        }
      }
    }

    // 提交按钮
    .btn {
      // padding: 0.667vw 3.2vw;
      @include wh(100%, 13.333vw);
      // box-sizing: border-box;
    }
  }
  // 课程提示
  .lesson_msg {
    @include font(3.2vw, 6.4vw);
    text-align: center;
    background: $bg-gray;
    color: $active-color;
  }
}
</style>
