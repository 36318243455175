<template>
  <div class="page" @click.stop="hideEmoji">
    <topheader />
    <div class="Reply_head">
      <div style="height:3.333vw"></div>
      <div class="Reply_head_list">
        <div class="Reply_head_list_left">
          <img :src="question.headurl" alt />
        </div>
        <div class="Reply_head_list_right">
          <div class="name">{{ question.name }}</div>
          <div class="time">{{ question.crdate }}</div>
          <div class="details">{{ question.content }}</div>
        </div>
      </div>
    </div>

    <div class="replyList">
      <p>回复详情</p>
      <Review
        :list="replyList"
        @refresh="refresh"
        @action="answerTo"
        :price="answerPrice"
        ref="Review"
      >
        <!-- <van-icon
          slot="nameRight"
          name="ascending"
          class="toTop"
          slot-scope="data"
          @click="toTop(data)"
          v-if="
          isTeacher && ($refs.Review.list.length && $refs.Review.list[0].autoid != data.data.autoid)"
        />-->

        <span
          slot="nameRight"
          name="ascending"
          class="toTop"
          slot-scope="data"
          @click="toTop(data)"
          v-if="isTeacher && ($refs.Review.list.length && $refs.Review.list[0].autoid != data.data.autoid)"
        >置顶</span>
      </Review>
    </div>

    <SendMsgBox
      class
      ref="reply"
      :replyTo="replyTo"
      @afterAudioComment="afterAudioComment"
      @sendComment="sendComment"
      @cleanReplyTo="cleanReplyTo"
    />
  </div>
</template>

<script>
import audioBox from '../My_personal_center/widget/audioBox'
import Review from '@/components/extend/review/index'
import SendMsgBox from '@/components/extend/sendMsgBox/index'
import {
  getFAQAndReplyList,
  createFAQReply,
  toTopFAQReply
} from '@/api/questionsAndAnswers'
import { sort2top } from '@/assets/js/public'

export default {
  components: { audioBox, Review, SendMsgBox },
  data() {
    return {
      questionId: this.$route.query.questionId,
      expertUserId: this.$route.query.expertUserId,
      answerPrice: this.$route.query.answerPrice,
      userId: this.$store.state.user.userId,
      question: {},
      replyList: [], // 回复
      replyTo: {} //指定回复的对象
    }
  },
  computed: {
    isTeacher() {
      //暂时紧开放班主任有问题置顶功能
      return this.expertUserId == this.userId
    }
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
    // 修复iOS版微信HTML5 History兼容性问题（导致ios用户获取微信参数报错config: invalid signature）
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
    // 参考 https://www.cnblogs.com/songxuan/p/10826335.html
  },
  methods: {
    getReplyList() {
      getFAQAndReplyList(this.questionId, this.userId).then(res => {
        this.question = res.data[0]
        // this.replyList = res.list
        this.replyList = sort2top(res.list)
        this.replyList.sort((a, b) => {
          let num1 = Date.parse(a.crdate.replace(/-/g, '/')),
            num2 = Date.parse(b.crdate.replace(/-/g, '/'))
          return num2 - num1
        })
      })
    },
    payment() {
      this.$dialog
        .confirm({
          title: '提示',
          confirmButtonText: '去支付',
          confirmButtonColor: '#ff3b30',
          cancelButtonColor: '#007aff',
          message: '收听本则回答需支付￥1.12是否确认支付'
        })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    hideEmoji(e) {
      let tarList = e.target.classList
      let parentNode = e.target.parentNode
      let parentList = parentNode ? parentNode.classList : []
      let a = false,
        b = false //不带“emotion”
      // 自带“emotion”，不处理
      // 父级带“emotion”，不处理
      tarList.forEach(item => {
        if (item.includes('emotion')) a = true
      })
      parentList.forEach(item => {
        if (item.includes('emotion')) b = true
      })
      //自己 或者 父亲 都不带"emotion"的可隐藏表情包
      if (!a && !b) {
        this.$refs.reply.hideEmoji()
      }
    },
    // 发送音频评论
    afterAudioComment(res) {
      let params = {
        userid: this.userId,
        mautoid: this.questionId,
        replymode: '语音',
        sec: res.duration,
        mediaId: res.serverId //音频id
      }
      createFAQReply(params).then(res => {
        this.refresh()
      })
    },
    // 发送文本评论
    sendComment(info) {
      let params = {
        userid: this.userId,
        mautoid: this.questionId,
        content: info.content,
        replymode: '文本'
        // sec: ,
        // mediaId
      }
      createFAQReply(params).then(res => {
        this.refresh()
        this.$refs.reply.init() //清空评论输入框
        this.replyTo = {} //清除指定回复
      })
    },
    // 刷新数据
    refresh() {
      this.replyList = []
      this.getReplyList()
      this.$refs.Review.pullRefresh = false
    },
    // 想回复谁???
    answerTo(info) {
      this.replyTo = info
      this.$refs.reply.comment = '' //清空内容
      this.$refs.reply.commentPlaceholder = `回复${info.name}：`
    },
    cleanReplyTo() {
      this.replyTo = {}
    },
    // 置顶
    toTop(info) {
      let msg = '',
        content = ''
      let item = info.data
      if (item.replymode === '语音') {
        msg = `${item.name}的语音`
      } else {
        let textArr = item.content.split('{+_+}')
        content = textArr.length > 1 ? textArr[1] : textArr[0]
        if (item.content.length < 10) {
          msg = content
        } else {
          msg = content.slice(0, 10) + '...'
        }
      }
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要将“${msg}”置顶到当前页？`
        })
        .then(() => {
          setTimeout(() => {
            this.$toast({
              message: '置顶成功',
              position: 'bottom'
            })
          }, 300)
          toTopFAQReply(item.autoid).then(res => {
            this.refresh()
          })
        })
    }
  },
  mounted() {
    this.getReplyList()
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  padding-bottom: 13vw;
  .replyList {
    padding-left: 0.8em;
    p {
      padding-top: 2.667vw;
      font-size: 3.467vw;
      color: $font-black;
    }
    // 置顶
    .toTop {
      line-height: 3.467vw;
      color: $font-gray;
    }
  }
}
.jieshida {
  width: 8.533vw;
  height: 4.533vw;
  background-color: #ffffff;
  border-radius: 0.533vw;
  border: solid 0.233vw #ff8c40;
  font-size: 2.933vw;
  color: #ff8c40;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
  margin-left: 4vw;
}
.details_yiuing——flex {
  display: flex;
}
.miasohu {
  margin-left: 2.8vw;
  font-size: 3.733vw;
  color: #303133;
}
.bolan {
  width: 2.133vw;
  height: 4vw;
  margin-right: 3.333vw;
}
.details_yiuing {
  width: 46.667vw;
  height: 10.133vw;
  background-color: #f5f7fa;
  border-radius: 1.867vw;
  margin-top: 4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Reply_head_list_detilas_title {
  font-size: 3.467vw;
  color: #303133;
}
.Reply_head_list_detilas {
  width: 93.733vw;
  margin: 4vw auto;
}

.name {
  font-size: 3.467vw;
  color: #303133;
}
.time {
  font-size: 3.2vw;
  letter-spacing: -0.133vw;
  color: #909399;
  margin-top: 2vw;
}
.details {
  font-size: 3.733vw;
  line-height: 6.4vw;
  color: #303133;
  margin-top: 2vw;
}
.Reply_head_list_right {
  width: 81.733vw;
  margin-left: 2.667vw;
}
.Reply_head_list_left {
  width: 8.8vw;
  height: 8.8vw;
  background-color: #a2a6a7;
  border-radius: 100%;
}
.Reply_head_list_left > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.Reply_head_list {
  width: 93.733vw;
  display: flex;
  margin: 0 auto;
}
.Reply_head_lists {
  width: 93.733vw;
  display: flex;
  margin: 4vw auto;
  border-bottom: 0.233vw solid #edeff2;
  padding-bottom: 4vw;
}
.Reply_head {
  width: 100vw;
  border-bottom: 3vw solid #f5f7fa;
  padding-bottom: 4vw;
}
</style>
