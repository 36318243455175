<template>
  <div class="page">
    <topheader />
    <van-tabs v-model="active" color="#247dff" line-width="5.6vw" animated sticky offset-top="100">
      <van-tab name="1" title="我问">
        <AnswerList ref="myAsk" @onLoad="onLoad" @click="goToAnswerDetail" />
      </van-tab>
      <van-tab name="0" title="我听">
        <AnswerList ref="myListen" @onLoad="onLoad" @click="goToAnswerDetail" />
      </van-tab>
      <van-tab name="2" title="我答" v-if="isExpert">
        <AnswerList ref="myAnswer" ask="提问内容" @onLoad="onLoad" @click="goToAnswerDetail" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import audioBox from '../widget/audioBox'
import AnswerList from '../widget/answerList'
import { getMyFAQ } from '@/api/my'
import { getOneTeacherinfo } from '@/api/questionsAndAnswers'

export default {
  components: { AnswerList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      isExpert: this.$store.state.user.isExpert,
      asks: [], // 我问
      listens: [], // 我听
      answers: [], // 我答
      active: '1' // 0-我听 1-我问 2-我答
    }
  },
  methods: {
    // 底部加载
    onLoad() {
      getMyFAQ(this.userId, this.active, this.page)
        .then(res => {
          switch (this.active) {
            case '1':
              this.$refs.myAsk.setList(res.list)
              this.$refs.myAsk.success(res)
              break
            case '0':
              this.$refs.myListen.setList(res.list)
              this.$refs.myListen.success(res)
              break
            case '2':
              this.$refs.myAnswer.setList(res.list)
              this.$refs.myAnswer.success(res)
              break
          }
        })
        .catch(err => {
          switch (this.active) {
            case '1':
              this.$refs.myAsk.failure(err)
              break
            case '0':
              this.$refs.myListen.failure(err)
              break
            case '2':
              this.$refs.myAnswer.failure(err)
              break
          }
        })
    },

    // 跳转到问答详情页
    goToAnswerDetail(item) {
      this.$router.push({
        path: '/replyList',
        query: {
          questionId: item.autoid
          // answerPrice: 0 //老师语音回复单价（自己的提问 老师语音回答不用付费）
        }
      })
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  /deep/.van-tabs {
    div {
      .van-sticky.van-sticky--fixed {
        top: 13.333vw !important;
      }
    }
  }
}
</style>
