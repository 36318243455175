<!-- 公告 -->
<template>
  <div class="page">
    <topheader @beforeBack="beforeBack">
      <span v-if="hasRight" slot="rightTools" @click="goToTagGroup">分组</span>
    </topheader>
    <!-- 班主任 -->
    <div class="kind" v-text="'班主任'"></div>
    <Contact :list="headMaster" groupname="headMaster" @follow="followMan" />
    <!-- 导师 -->
    <template v-if="supervisor.length">
      <div class="kind" v-text="'导师'"></div>
      <Contact :list="supervisor" groupname="supervisor" @follow="followMan" />
    </template>
    <!-- 辅导员 -->
    <template v-if="assistant.length">
      <div class="kind" v-text="'辅导员'"></div>
      <Contact :list="assistant" groupname="assistant" @follow="followMan" />
    </template>
    <!-- 学员 -->
    <template v-for="(item,idx) in students">
      <div class="kind student_group" :key="item.name">
        <span class="group_name" v-text="item.name"></span>
        <span class="total_type">
          <span>类别：</span>
          <van-dropdown-menu
            :active-color="item.active === 0 ? '#ff976a' : '#1989fa'"
            :class="{ orange: item.active === 0, blue: item.active === 1 }"
            :direction="idx != students.length -1 || idx == students.length -1 && item.list.length !== 1 ? 'down' : 'up'"
          >
            <van-dropdown-item
              v-model="item.active"
              :options="[ { text: '作业', value: 0 }, { text: '考试', value: 1 } ]"
            />
          </van-dropdown-menu>
        </span>
        <span class="total_score">
          总分:
          <b
            :class="{
            orange: item.active === 0,
            blue: item.active === 1,
          }"
            v-text="item.active === 0 ? item.hwscoresum : item.exscoresum"
          ></b>
        </span>
      </div>
      <Contact
        groupname="students"
        ref="stu"
        :active="item.active"
        :list="item.list"
        :key="item.name + idx"
        @follow="followMan"
        @scoreAct="goToScoreList"
      />
    </template>
  </div>
</template>

<script>
import Contact from './widget/contact'
import eventBus from '@/util/eventBus'
import { getClassPeopleInfo, followMan } from '@/api/classRing'
export default {
  // props: {
  //   headMasterUserId: Number, //班主任userid
  //   assistantUserId: Number //辅导员userid
  // },
  components: { Contact },
  data() {
    return {
      userId: this.$store.state.user.userId,
      classId: this.$route.params.classId, //班圈id
      headMaster: [], //班主任
      supervisor: [], //导师
      assistant: [], //辅导员
      students: [],
      childs: [], //原有学员名单列表
      hasRight: false, //只有班主任才有分组权限
      groupArr: [] //现有学员分组名称集合
    }
  },
  // computed: {
  //   // 班主任 | 辅导员 才有权限 修改班圈名单分组
  //   hasRight() {
  //     return (
  //       this.$route.query.headMasterUserId == this.userId ||
  //       this.$route.query.assistantUserId == this.userId
  //     )
  //   }
  // },
  methods: {
    init() {
      getClassPeopleInfo(this.userId, this.classId).then(res => {
        this.hasRight = res.isclassowneruserid1 //是否当前班圈课程中的班主任
        this.headMaster = res.teacher1 //班主任
        this.supervisor = res.teacher2 //导师
        this.assistant = res.teacher3 //辅导员
        this.childs = res.stu //原有学员名单列表
        // this.groupArr = res.group //学员分组项 --- 这里有毒，存了之后会导致类型切换的时候，不会更新视图
        this.students = this.stuGroup(res.stu, res.group)
      })
    },
    // 处理学员分组
    stuGroup(childs, arr = []) {
      let group = arr
      group.forEach(item => {
        item.active = 0
        item.list = childs.filter(it => it.groupid === item.groupid)
      })
      const noGroup = childs.filter(it => it.groupid === null)
      if (noGroup.length) {
        let hwscoresum = 0
        let exscoresum = 0
        noGroup.forEach(el => {
          hwscoresum += el.hwscoresum ? el.hwscoresum : 0
          exscoresum += el.exscoresum ? el.exscoresum : 0
        })
        group.push({
          groupid: null,
          name: '未分组',
          hwscoresum,
          exscoresum,
          active: 0,
          list: noGroup
        })
      }
      return group.filter(c => c.list.length)
    },
    // 成员关注
    followMan(man) {
      const type = man.info.islike ? 0 : 1
      const msg = man.info.islike
        ? `确定要取消关注：${man.info.name} 老师吗？`
        : `确定关注：${man.info.name} 吗？`
      this.$dialog
        .confirm({
          title: '提示',
          message: msg
        })
        .then(() => {
          followMan(this.userId, man.info.userid, type).then(res => {
            switch (man.groupname) {
              case 'headMaster':
                this.headMaster.forEach(item => {
                  if (item.userid === man.info.userid)
                    item.islike = !item.islike
                })
                break
              case 'supervisor':
                this.supervisor.forEach(item => {
                  if (item.userid === man.info.userid)
                    item.islike = !item.islike
                })
                break
              case 'assistant':
                this.assistant.forEach(item => {
                  if (item.userid === man.info.userid)
                    item.islike = !item.islike
                })
                break
              case 'students':
                this.childs.forEach(item => {
                  if (item.userid === man.info.userid)
                    item.islike = !item.islike
                })
                // this.students = this.stuGroup(this.childs, this.groupArr)
                this.students = this.stuGroup(this.childs)
                break
            }
            const message = man.info.islike
              ? '您的支持是我前进的动力'
              : '感谢您的支持与关注'
            this.$toast({
              message,
              position: 'bottom'
            })
          })
        })
    },
    //
    goToScoreList(data) {
      const t = data.type == 0 ? '作业' : '考试'
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要查看 ${data.info.name} 的${t}记录`
        })
        .then(() => {
          this.$router.push({
            path: `/student_score_list/${data.info.stuid}`,
            query: { active: data.type }
          })
        })
    },
    beforeBack() {
      eventBus.$emit('courseHome', {
        active: this.$route.query.active,
        curLessonIdx: this.$route.query.curLessonIdx
      })
    },
    goToTagGroup() {
      this.$router.push({
        path: '/tagGroup',
        query: {
          classId: this.classId
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  .kind {
    // @include wh(100%, 8.8vw);
    width: 100%;
    min-height: 8.8vw;
    @include font(2.667vw, 8.8vw);
    box-sizing: border-box;
    padding: 0 4vw;
    color: $font-gray;
    background: #ededed;
  }
  .kind.student_group {
    display: flex;
    align-items: center;
    .group_name {
      width: 50%;
    }
    .total_type {
      width: 25%;
      display: flex;
      align-items: center;
      /deep/.van-dropdown-menu {
        background: transparent;
        height: 8.8vw;
        .van-dropdown-menu__item {
          .van-dropdown-menu__title {
            padding-left: 0;
            .van-ellipsis {
              font-size: 3.2vw;
              //作业 | 考试
            }
          }
        }
        /deep/div {
          .van-dropdown-item {
            .van-popup {
              // todo
              width: calc((100% - 8vw) / 4);
              left: 50%;
              .van-cell {
                padding: 1.333vw 2.133vw;
              }
            }
          }
        }
      }
      /deep/.van-dropdown-menu.orange {
        background: transparent;
        .van-dropdown-menu__item {
          .van-dropdown-menu__title {
            .van-ellipsis {
              color: #ff976a;
            }
          }
        }
      }
      /deep/.van-dropdown-menu.blue {
        background: transparent;
        .van-dropdown-menu__item {
          .van-dropdown-menu__title {
            .van-ellipsis {
              color: #1989fa;
            }
          }
        }
      }
    }
    .total_score {
      width: 25%;
      text-align: right;
      b {
        font-weight: normal;
      }
      .orange {
        color: #ff976a;
      }
      .blue {
        color: #1989fa;
      }
    }
  }
}
</style>
