import router from "@/router/index";
import store from "@/store";
import { Dialog } from "vant";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import setTitle from '@/util/title'
import { getOpenId } from '@/util/auth'

// 路由跳转进度条
NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
  //做题页面判断是否允许跳转
  if (from.name === 'choice_question' && !store.state.user.forbid2Change) {
    Dialog.confirm({
      title: '提示',
      message: '是否结束答题',
      confirmButtonColor: '#ff3b30',
      cancelButtonColor: '#1989fa',
    }).then(() => {
      next();
    }).catch(() => {
      next(false);
    })
    return;
  }

  // 进度开始
  NProgress.start();
  // 设定页面标题
  setTitle(to.meta.title || '和英商学');
  // 是否获取用户信息(电话判断)
  const hasInfo = store.getters.tel;

  if (hasInfo) {
    if (to.path === '/login' || to.path === '/register' || to.path === '/forget') {
      // 已登录状态不允许进行登录\注册\忘记操作,跳转到主页面
      next({ path: '/', replace: true });
      NProgress.done();
    } else {
      store.dispatch('user/loginByOpenId').then(() => {
        next();
        NProgress.done();
      }).catch(err => {// 获取用户信息失败
        console.error(err);
        store.dispatch('user/resetState').then(() => {
          if (from.name !== 'login') {
            Dialog.confirm({
              message: '用户信息获取失败,请重新登录',
              confirmButtonText: '前往登录', // right按钮文本
              cancelButtonText: '返回首页', // left按钮文本
              cancelButtonColor: '#1989fa', // left按钮文字颜色
              closeOnPopstate: true, // 返回时自动关闭
            }).then(() => {
              // 前往登录
              next(`/login?redirect=${to.path}`);
              NProgress.done();
            }).catch(() => {
              // 返回首页
              next({ path: '/class_ring', replace: true });
              NProgress.done();
            })
          }
        })
      })
    }
  } else { // 未登录
    if (getOpenId()) {//有 openid 先自动登录
      store.dispatch('user/loginByOpenId').then(() => {
        next();
        NProgress.done();
      }).catch(err => {
        _loginFail();
      })
    } else {
      _loginFail();
    }
  }
  function _loginFail() {
    if (to.meta.needLogin) {
      next(`/login?redirect=${to.fullPath}`);
      NProgress.done();
    } else {
      // 不需要登录验证
      next()
      NProgress.done();
    }
  }
});
