import Vue from 'vue'

import {
  Button, Tabbar, TabbarItem, Field, Tab, Tabs, Picker, Popup, NavBar,
  Toast, Search, Swipe, SwipeItem, Overlay, CheckboxGroup, Checkbox, Uploader,
  Sticky, List, PullRefresh, Icon, ImagePreview, Collapse, CollapseItem,
  Tag, Divider, Card, NumberKeyboard, Dialog, Row, Col, Cell, CellGroup,
  Circle, Stepper, Step, Steps, Loading, DatetimePicker, Form, IndexBar, IndexAnchor, SwipeCell, DropdownMenu, DropdownItem
} from 'vant'
import { Image as VanImage } from 'vant';

Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(Toast);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Uploader);
Vue.use(Sticky);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Icon);
Vue.use(ImagePreview);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Card);
Vue.use(NumberKeyboard);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Col);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Circle);
Vue.use(Stepper);//步进器
Vue.use(Step);//步骤条
Vue.use(Steps);//步骤条
Vue.use(Loading);
Vue.use(DatetimePicker);
Vue.use(Form);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(VanImage);
Vue.use(SwipeCell);//滑动单元格
Vue.use(DropdownMenu);//下拉菜单
Vue.use(DropdownItem);//下拉菜单
