<template>
  <!-- 学员签到 -->
  <div
    id="moveDiv"
    class="xuanfu"
    :style="{
      'background': canSign ? 'transparent' : ''
    }"
    @mousedown="down($event)"
    @touchstart="down($event)"
    @mousemove="move($event)"
    @touchmove="move($event)"
    @mouseup="end($event)"
    @touchend="end($event)"
  >
    <transition name="fade" mode="out-in">
      <!-- 签到按钮 -->
      <div class="sign_box" v-if="canSign" key="sign" @click="action">
        <!-- 学员 -->
        <div class="stu_sign">
          <img :src="bg" class="tea_sign_img" />
          <i>
            立即
            <span class="large">签</span>
            <span>到</span>
          </i>
        </div>
        <div class="countdown">
          <div>剩余：{{`${time.hour}:${time.minute}:${time.second}`}}</div>
        </div>
      </div>
    </transition>
    <van-dialog
      v-model="showDialog"
      class="dialog_box"
      show-cancel-button
      :title="dialogTitle"
      :before-close="stuInitiate"
    >
      <div class="dialog_content">是否确认签到？</div>
    </van-dialog>
  </div>
</template>

<script>
import { createSignal } from '@/api/classRing'
import { formatSeconds } from '@/assets/js/public'
// 烟花
// http://v.bootstrapmb.com/2019/4/6rbr4432/
// http://www.bootstrapmb.com/item/4432
export default {
  props: {
    //倒计时(秒)
    countDown: {
      type: Number,
      default: 0
    },
    lessonId: [Number, String], //课程id
    signId: [Number, String], //学员签到id
    stuId: [Number, String]
  },
  data() {
    return {
      flags: false,
      position: { x: 0, y: 0 },
      nx: '',
      ny: '',
      dx: '',
      dy: '',
      xPum: '',
      yPum: '',
      // --------------------------------------
      // {"error":"0","msg":"","AllowSign":true,"sec":26,"surplussec":34,"starsignid":57}
      // --------------------------------------
      userId: this.$store.state.user.userId,
      canSign: false, //是否允许学员签到
      bg: require('@/assets/images/sign.png'), //签到背景图
      second: 0, //剩余(秒)
      countdownTimer: null, //倒计时定时器
      time: {
        second: '00',
        minute: '00',
        hour: '00'
      },
      showDialog: false, //学员签到提示框
      signInterval: '1', //学员签到时限，默认1分钟
      showKeyboard: false

      // video: undefined,
      // videoOriginZindex: undefined //视频窗口
    }
    // left: 250px; top: 520px;
  },
  computed: {
    dialogTitle() {
      return `剩余签到时间： ${this.time.hour}:${this.time.minute}:${this.time.second}`
    }
  },
  watch: {
    countDown: {
      handler(nl, ol) {
        // 如果旧值为0，新增大于0 表示需要倒计时
        if (nl > 0 && !ol) {
          this.second = nl
          this.canSign = true
          this.setCountdown()
        } else if (nl === 0) {
          this.canSign = false
        }
      },
      immediate: true
    }
    // showDialog(n) {
    //   if (n === false) {
    //     try {
    //       this.video.style['z-index'] = this.videoOriginZindex
    //     } catch (e) {
    //       console.error(e)
    //     }
    //   }
    // }
  },
  methods: {
    // 实现移动端拖拽
    down(event) {
      this.flags = true
      var touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.position.x = touch.clientX
      this.position.y = touch.clientY
      this.dx = moveDiv.offsetLeft
      this.dy = moveDiv.offsetTop
    },
    move() {
      if (this.flags) {
        var touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        this.nx = touch.clientX - this.position.x
        this.ny = touch.clientY - this.position.y
        this.xPum = this.dx + this.nx
        this.yPum = this.dy + this.ny
        moveDiv.style.left = this.xPum + 'px'
        moveDiv.style.top = this.yPum + 'px'
        //阻止页面的滑动默认事件
        document.addEventListener(
          'touchmove',
          function() {
            // 1.2 如果碰到滑动问题，请注意是否获取到 touchmove
            event.preventDefault() //jq 阻止冒泡事件
            // event.stopPropagation(); // 如果没有引入jq 就用 stopPropagation()
          },
          false
        )
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false
    },
    //模拟键盘点击数字时触发的函数
    onClickInputKeyBoard(value) {
      this.signInterval += value
      // 限制只能输入一个小数点及两位小数
      this.signInterval =
        this.signInterval.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null
      if (this.signInterval > 60) {
        this.signInterval = this.signInterval.slice(0, -1)
      }
    },
    //模拟键盘删除时触发的函数
    onDeleteKeyBoard() {
      let flag = true
      if (flag) {
        // 删除掉字符串最后一个
        this.signInterval = this.signInterval.substring(
          0,
          this.signInterval.length - 1
        )
        if (this.signInterval.length === 0) {
          flag = false
          return false
        }
      }
    },
    // 设置 倒计时
    setCountdown() {
      // 还在有效期内，可进行倒计时
      this.countdownTimer = setInterval(() => {
        if (this.second) {
          this.time = formatSeconds(this.second)
          this.second--
        } else {
          this.canSign = true
          this.cleanCountdownTimer()
        }
      }, 1000)
    },
    // 清除倒计时定时器
    cleanCountdownTimer() {
      clearInterval(this.countdownTimer)
      this.countdownTimer = null
    },
    // 发起 / 签到
    action() {
      // this.video = document.getElementsByTagName('video')[0]
      // this.videoOriginZindex = this.video.style['z-index']
      // this.video.style['z-index'] = 3000
      this.showDialog = true
    },
    // 学员执行签到
    stuInitiate(action, done) {
      if (action == 'confirm') {
        // 发起“签到”请求
        createSignal(this.lessonId, this.stuId, this.signId)
          .then(() => {
            this.$emit('after', 2) // 签到 成功后通知父组件隐藏签到
          })
          .finally(done)
      } else if (action == 'cancel') {
        done()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.xuanfu {
  padding: 1.5vw;
  z-index: 999;
  position: fixed;
  top: 120vw;
  left: 74vw;
  border-radius: 0.8vw;
  background-color: rgba(0, 0, 0, 0.55);
  @include font(4.2vw, 4.2vw);
  .content {
    color: #ff8b07;
  }
  // 签到盒子
  .sign_box {
    color: #ff8b07;
    // 学员发起签到盒子
    .stu_sign {
      width: 24vw;
      position: relative;
      .tea_sign_img {
        @include imgCover;
      }
      i {
        position: absolute;
        left: 0;
        bottom: 2vw;
        right: 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding-right: 1.2vw;
        span {
          color: #ffe607;
        }
        span.large {
          padding-left: 0.6vw;
          font-size: 5.8vw;
        }
      }
    }
    // 倒计时
    .countdown {
      width: 100%;
      height: 3.2vw;
      position: relative;
      div {
        position: absolute;
        left: 50%;
        text-align: center;
        width: 150%;
        transform: translateX(-50%) scale(0.8);
      }
    }
  }
  //
  .dialog_box {
    z-index: 9999 !important; //默认2034+（安卓还是被播放器挡住）
    top: 93.333vw;
  }
  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
  .fade-enter-active {
    transition: all 0.1s ease;
  }
  .fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter,
  .fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .dialog_content {
    text-align: center;
    padding: 1em;
  }
}
</style>
