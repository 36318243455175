<template>
  <!-- 签到 -->
  <component
    ref="sign"
    v-bind:is="active"
    :countDown="countDown"
    :lessonId="lessonId"
    :signId="signId"
    :stuId="stuId"
    @after="afterSign"
  ></component>
</template>

<script>
import SignByTea from './signByTea'
import SignByStu from './signByStu'
export default {
  props: {
    //倒计时(秒)
    countDown: {
      type: Number,
      default: 0
    },
    lessonId: [Number, String], //课程id
    signId: [Number, String], //学员签到id
    isTeach: Boolean, //班主任/导师
    stuId: [String, Number] //学员id
  },
  components: {
    's-t': SignByTea,
    's-s': SignByStu
  },
  data() {
    return {}
    // left: 250px; top: 520px;
  },
  computed: {
    active() {
      return this.isTeach ? 's-t' : 's-s'
    }
  },
  methods: {
    // 通知 course_home.vue 再处理
    afterSign(v) {
      this.$emit('after', v)
    }
  }
}
</script>

<style lang="scss" scoped></style>
