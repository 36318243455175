<template>
  <!-- 专家栏目>>>课程 -->
  <div class="page">
    <topheader title="课程列表" />
    <van-search v-model="key" show-action label="课程" placeholder="请输入搜索关键词" @search="onRefresh">
      <template #action>
        <div @click="onRefresh">搜索</div>
      </template>
    </van-search>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 课程列表 -->
        <!-- <LessonList :list="list" @click="goToClassDetails" class="lessonBox" /> -->
        <div
          class="lessonList"
          v-for="(item, i) in list"
          :key="item.title + i"
          @click="goToClassDetails(item)"
        >
          <div class="lesson_left">
            <img v-if="item.pic" :src="item.pic" />
            <video v-else :src="item.videourl || item.liveurl"></video>
          </div>
          <div class="lesson_right">
            <div class="lesson_name">
              <van-tag plain :type="item.showmode == '直播' ? 'warning' : 'success'">{{item.showmode}}</van-tag>
              <span v-text="item.title"></span>
            </div>
            <!-- <div class="lesson_connent" v-text="item.content"></div> -->
            <div class="lesson_teacher">班圈：{{item.classname}}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import LessonList from '@/components/extend/lessonList'
import { getTealessonList, teaSearchLessonList } from '@/api/my'
export default {
  // components: { LessonList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      key: ''
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      // getTealessonList(this.userId, this.page)
      //   .then(res => {
      //     this.loading = false
      //     this.list.push(...res.list)
      //     this.pullRefresh = false
      //     if (this.page * 10 >= res.sum) {
      //       this.finished = true
      //     } else {
      //       this.page += 1
      //     }
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     this.pullRefresh = false
      //     this.error = true
      //   })

      teaSearchLessonList({
        keyword: this.key,
        userid: this.userId,
        size: 10,
        curpage: this.page,
        type: 1
      })
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page++
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 课程详情
    goToClassDetails(item) {
      this.$router.push({
        path: '/Course_home',
        query: {
          classId: item.classid,
          lessonId: item.autoid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;

  // 搜索框 -- 必须重新设置样式-避免换成大屏没有自适应
  .van-search {
    padding: 1.333vw 1.6vw;
    .van-search__content {
      padding-left: 2.133vw;
      .van-search__label {
        padding: 0 1.333vw;
        font-size: 3.733vw;
        line-height: 9.067vw;
      }
      /deep/.van-cell {
        padding: 1.333vw 2.133vw 1.333vw 0;
        font-size: 3.733vw;
        line-height: 6.4vw;
        .van-field__left-icon {
          margin-right: 1.333vw;
          .van-icon {
            font-size: 4.267vw;
          }
        }
      }
    }
    .van-search__action {
      padding: 0 2.133vw;
      font-size: 3.733vw;
      line-height: 9.067vw;
    }
  }

  /deep/.van-pull-refresh {
    height: calc(100% - 11.733vw);
    overflow-y: scroll;
    // 课程样式
    .lessonList {
      padding: 0 3.2vw 3.2vw 3.2vw;
      // padding: 0 0 3.2vw 0;
      height: 24.933vw;
      background: #fff;
      box-sizing: border-box;
      @include borderRadius(1.067vw);
      .lesson_left {
        @include wh(33.33vw, 22.133vw);
        box-shadow: $box-shadow;
        border-radius: 6%;
        overflow: hidden;
        float: left;
        background: black;
        img {
          @include imgCover;
        }
        video {
          @include wh(100%, 100%);
        }
      }
      .lesson_right {
        padding-left: 2vw;
        height: 22.133vw;
        font-size: 2.9vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .lesson_name {
          font-size: 4.2vw;
          @include dot2;
          .van-tag {
            margin-right: 0.667vw;
            margin-bottom: 1px;
          }
        }
        // .lesson_connent {
        //   flex: 1;
        //   padding-top: 1px;
        //   @include dot;
        // }
        .lesson_teacher {
          flex: 1;
          color: $font-gray;
          padding: 0.667vw 1.333vw;
        }
      }
    }
  }
}
</style>
