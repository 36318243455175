import { render, staticRenderFns } from "./portrait.vue?vue&type=template&id=910df27a&scoped=true&"
import script from "./portrait.vue?vue&type=script&lang=js&"
export * from "./portrait.vue?vue&type=script&lang=js&"
import style0 from "./portrait.vue?vue&type=style&index=0&id=910df27a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "910df27a",
  null
  
)

export default component.exports