<template>
  <div class="box">
    <div
      class="class_ring_type_list"
      v-for="(item, i) in list"
      :key="'CurriculumTable' + i"
      @click="act(item)"
    >
      <!-- left -->
      <div class="class_ring_type_list_left">
        <img :src="item.pic" alt />
      </div>
      <!-- right -->
      <div class="class_ring_type_list_rigrt">
        <div class="type-title">{{ item.title }}</div>
        <div class="type-time">{{ item.crdate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    // 班圈点击事件
    act(info) {
      this.$emit('click', info)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  .class_ring_type_list {
    @include wh(93.6vw, 28.8vw);
    margin: 0 auto;
    border-bottom: 0.233vw solid #edeff2;
    display: flex;
    align-items: center;
    .class_ring_type_list_left {
      @include wh(33.333vw, 22.133vw);
      border-radius: 6%;
      background-color: #c6c8cb;
      overflow: hidden;
      img {
        @include imgCover;
      }
    }
    .class_ring_type_list_rigrt {
      flex: 1;
      margin-left: 2vw;
      height: 80%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .type-title {
        font-size: 4.2vw;
        color: #303133;
      }
      .type-time {
        font-size: 2.9vw;
        color: #909399;
      }
    }
  }
  .class_ring_type_list:last-child {
    border-bottom: 0 none;
  }
}
</style>
