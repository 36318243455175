<template>
  <div class="page">
    <topheader />
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <ClassList :list="list" @click="showPlanDetail" />
      </van-list>
    </van-pull-refresh>
    <van-popup
      closeable
      close-on-popstate
      position="bottom"
      close-icon="cross"
      close-icon-position="top-right"
      :style="{ height: '95%', 'margin-left': '20%', width: '80%' }"
      v-model="show"
    >
      <div class="study_plan">
        <header class="study_plan_title" v-text="plan.className"></header>
        <!-- <van-divider dashed/> -->
        <van-steps
          direction="vertical"
          :active="stepsActive"
          :style="{height: stepsHeight}"
          v-if="plan.list.length"
        >
          <!-- inactive-icon="clock"
          active-icon="checked"-->
          <!-- inactive-icon="arrow-down" -->
          <van-step v-for="(item, i) in plan.list" :key="item.title + i">
            <van-icon slot="inactive-icon" v-if="isFinish(item)" name="checked" class="finished" />
            <van-icon slot="inactive-icon" v-else name="clock" />
            <div class="plan_name" :class="{finished: isFinish(item)}" @click="planClick(item)">
              <template>
                <van-tag plain type="primary" v-if="item.type === 1">课程</van-tag>
                <van-tag plain type="success" v-if="item.type === 2">作业</van-tag>
                <van-tag plain type="warning" v-if="item.type === 3">考试</van-tag>
              </template>
              <span>{{item.title | setName}}</span>
            </div>
            <div class="plan_time" :class="{finished: isFinish(item)}">
              <div class="begin_time">
                <span>{{item.bd | timeStamp | dateFormat('yyyy-MM-dd')}}</span>
                <span
                  v-if="!(item.bd.includes('00:00:00') && item.ed.includes('00:00:00'))"
                >{{item.bd | timeStamp | dateFormat('hh:mm:ss')}}</span>
              </div>
              <div class="emp">-</div>
              <div class="end_time">
                <span>{{item.ed | timeStamp | dateFormat('yyyy-MM-dd')}}</span>
                <span
                  v-if="!(item.bd.includes('00:00:00') && item.ed.includes('00:00:00'))"
                >{{item.ed | timeStamp | dateFormat('hh:mm:ss')}}</span>
              </div>
            </div>
          </van-step>
        </van-steps>
        <div v-else class="no-data">查无数据</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getMyClassList, getMyLearningPlan } from '@/api/my'
import { createHWkRecord, createExamRecord } from '@/api/classRing'
import ClassList from '@/components/extend/classList'
export default {
  components: {
    ClassList
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      show: false,
      plan: {
        list: []
      }, //学习计划
      stepsHeight: 0,
      stepsActive: -1,
      stuId: -1
    }
  },
  watch: {
    show(n) {
      if (n) {
        this.$nextTick(() => {
          const planBox_height = document.getElementsByClassName('van-popup')[0]
            .offsetHeight
          const planTitle_height = document.getElementsByClassName(
            'study_plan_title'
          )[0].offsetHeight
          const steps = document.getElementsByClassName('van-steps')[0]
          this.stepsHeight = planBox_height - planTitle_height + 'px'
        })
      }
    }
  },
  filters: {
    setName(v) {
      if (v) return v.replace(/\(.*\)/g, '')
      return v
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyClassList(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.page += 1
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    showPlanDetail(item) {
      this.stuId = item.stuid
      if (!item.studyPlan) {
        getMyLearningPlan(item.autoid, item.stuid).then((res, i) => {
          this.list.forEach(it => {
            if (it.autoid === item.autoid) {
              it.studyPlan = {
                className: res.classname,
                classId: String(item.autoid),
                list: res.list
              }
            }
            if (res.list.isstudy) {
              this.stepsActive = i
            }
          })
          this.plan = this.list.find(el => el.autoid === item.autoid)[
            'studyPlan'
          ]
          this.show = true
        })
      } else {
        this.plan = item.studyPlan
        this.show = true
      }
    },
    isFinish(item) {
      switch (item.type) {
        case 1: //课程
          if (item.isstudy) return true
        case 2: //作业
        case 3: //考试
          if (item.isend) return true
      }
      return false
    },
    // @toAsk 注意，免费试学下 没有开放收费课程或作业考试的
    planClick(item) {
      switch (item.type) {
        case 1:
          this.goToLesson(item)
          break
        case 2:
          this.goToHomework(item)
          break
        case 3:
          this.goToExam(item)
          break
      }
    },
    goToLesson(item) {
      this.$router.push({
        path: '/Course_home/',
        query: {
          classId: this.plan.classId,
          lessonId: item.autoid
        }
      })
    },
    goToHomework(info) {
      const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))
      if (overdue && !info.lastqsid) {
        this.$toast('答题结束，未作答，暂无权限查看') //因为现在是做一题再创建一题qsid所以，未答过题的是没有qsid
        return
      } else {
        //学员(有参与过答题)
        let query = {
          id: info.planid, //作业id
          recordid: info.autoid, //记录id(关系id)
          lastqsid: info.lastqsid, //上次答题id
          total: info.qscount, //试题总数
          title: info.title.replace(/\(.*\)/g, ''), //标题
          isend: info.isend, //是否已经提交
          overdue: false, //未过期
          beginTime: info.bd, //作业开始时间
          endTime: info.ed, //作业结束时间
          lessonName: info.coursename
        }
        if (info.autoid && info.lastqsid) {
          // 平时查看作业
          this.$router.push({ path: '/homeworkQuestionDetail', query })
        } else {
          // 创建答题记录（首次查看作业）
          createHWkRecord(info.planid, this.stuId).then(res => {
            query.lastqsid = res.lastqsid
            this.$router.push({ path: '/homeworkQuestionDetail', query })
          })
        }
      }
    },
    goToExam(info) {
      const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))
      let query = {
        id: info.planid, //作业id
        recordid: info.autoid, //记录id(关系id)
        lastqsid: info.lastqsid, //上次提交答题id
        total: info.qscount, //试题总数
        title: info.title.replace(/\(.*\)/g, ''), //标题
        isend: info.isend, //是否已经提交
        overdue, //是否过期
        beginTime: info.bd, //作业开始时间
        endTime: info.ed, //作业结束时间
        lessonName: info.coursename
      }
      if (overdue && !info.lastqsid) {
        this.$toast('答题结束，未作答，暂无权限查看')
        return
      } else {
        //学员(有参与过答题)
        if (info.autoid && info.lastqsid) {
          // 平时查看作业
          this.$router.push({ path: '/examQuestionDetail', query })
        } else {
          // 创建答题记录（首次查看考试）
          createExamRecord(info.planid, this.stuId).then(res => {
            query.lastqsid = res.lastqsid
            this.$router.push({ path: '/examQuestionDetail', query })
          })
        }
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  background: $bg-gray;
  font-size: 3.467vw;
  .study_plan {
    overflow: hidden;
    .study_plan_title {
      padding: 4.267vw 10.667vw 2.667vw 4.267vw;
      border-bottom: 1px dotted $border-light;
      @include dot2;
    }
    .van-steps {
      overflow: scroll;
      .plan_name {
        .van-tag {
          margin-right: 1.333vw;
        }
      }
      .plan_time {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .begin_time,
        .end_time {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 3.2vw;
        }
        .emp {
        }
      }
      .finished {
        color: $active-color;
      }
    }
    .no-data {
      padding: 1.333vw;
      text-align: center;
      color: $font-gray;
    }
  }
}
</style>
