<template>
  <div class="answer_page">
    <div class="answer_top">
      <div class="answer_top_left">Q</div>
      <div class="answer_top_bottom">
        <div class="name">{{info.work_name}}</div>
        <div class="details">{{info.work_content}}</div>
      </div>
    </div>
    <!-- 未回答 -->
    <div v-if="!info.reply_pic&&!info.point">
<!--    <div v-if="false">-->
      <div class="answer_bottom">
        <div style="height:3.733vw"></div>
        <div class="answer_top_left">A</div>
        <div class="answer_bottom_text">完成练习能巩固知识点哦</div>
      </div>
      <div class="uploading bton" @click="UploadAnswer">上传答案</div>
    </div>
    <!-- 未评价 -->
    <div v-if="info.reply_pic&&!info.point">
<!--    <div v-if="true">-->
      <div class="answer_bottom">
        <div style="height:3.733vw"></div>
        <div class="answer_top_left">A</div>
        <div class="answer_bottom_list">
          <div class="touxiang">
            <img v-if="info.student_headimg" :src="info.student_headimg" alt/>
            <img v-else src="@/assets/images/head_prole.png" alt/>
          </div>
          <div class="answer_bottom_list_right">
            <div style="flex: 1;">
              <div class="names">{{info.student_name||'用户名为空'}}</div>
              <div class="time">{{info.replytime | dateFormat('yyyy/MM/dd',true)}}</div>
              <div class="up">
                <img v-for="(imgUrl,i) in info.reply_pic"
                     :key="'imgUrl'+i"
                     :src="imgUrl" alt>
              </div>
            </div>
            <div class="anew bton" @click="anewSed">重新编辑</div>
          </div>
        </div>
        <div class="huose_s"></div>
        <div class="mentor_bb">
          <div class="mentor_bb_title">导师评价</div>
          <div class="mentor_bb_details">导师会尽快点评您的作业哦</div>
        </div>
      </div>
    </div>
    <!-- 已评价 -->
    <div v-if="info.reply_pic&&info.point">
<!--    <div v-if="false">-->
      <div class="answer_bottom">
        <div style="height:3.733vw"></div>
        <div class="answer_top_left">A</div>
        <div class="answer_bottom_list">
          <div class="touxiang">
            <img v-if="info.student_headimg" :src="info.student_headimg" alt/>
            <img v-else src="@/assets/images/head_prole.png" alt/>
          </div>
          <div class="answer_bottom_list_right">
            <div>
              <div class="names">{{info.student_name||'用户名为空'}}</div>
              <div class="time">{{info.commenttime | dateFormat('yyyy/MM/dd',true)}}</div>
              <div class="up">
                <img v-for="(imgUrl,i) in info.reply_pic"
                     :key="'imgUrl'+i"
                     :src="imgUrl" alt>
              </div>
            </div>
            <div class="anew-hide"></div>
          </div>
        </div>
        <div class="huose_s"></div>
        <div class="mentor_bbs">
          <div class="mentor_bb_title">导师评价</div>
          <div class="mentor_bb_nbmer">
            <div class="mentor_bb_nbmer_img">
              <img src="https://img.yzcdn.cn/vant/cat.jpeg" alt/>
            </div>
            <div class="mentor_right">
              <div class="names">{{info.teacher_name||'用户名为空'}}</div>
              <div class="time">{{info.replytime | dateFormat('yyyy/MM/dd',true)}}</div>
              <div class="detailss">{{info.comment}}</div>
            </div>
          </div>
          <div class="pingfen">
            <span class="one_name">导师评分:</span>
            <span class="two_nbmer">{{parseInt(info.point,10)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getWorkInfo} from "@/api/classRing";

  export default {
    data() {
      return {
        workId: this.$route.params.id, // 作业id
        info: {}, // 练习题信息
      }
    },
    created() {
      setTitle("阿米巴划分主观题练习");
    },
    methods: {
      init() {
        getWorkInfo(this.workId).then(res => {
          this.info = res.data;
          if (res.data.reply_pic) {
            res.data.reply_pic = res.data.reply_pic.split('|');
          }
        })
      },
      anewSed() {
        this.$dialog.confirm({
          title: "提示",
          confirmButtonText: "继续",
          confirmButtonColor: "red",
          cancelButtonColor: "#007aff",
          message: "重新编辑会删除已上传的作业是否继续操作?"
        })
          .then(() => {
            this.UploadAnswer();
          })
          .catch(() => {
            // on cancel
          });
      },
      // ->上传答案
      UploadAnswer() {this.$router.push('/upload_answer?workid='+this.info.id+'&wordrecordid='+this.info.word_record_id)},
    },
    mounted() {
      this.init();
    }
  };
</script>

<style scoped>
  .answer_page {
    width: 100vw;
    height: 100%;
  }

  .pingfen {
    float: right;
    margin-right: 1vw;
    margin-top: 2vw;
  }

  .two_nbmer {
    font-size: 5.6vw;
    letter-spacing: -0.133vw;
    color: #1fb9bf;
  }

  .one_name {
    font-size: 3.2vw;
    letter-spacing: -0.133vw;
    color: #909399;
  }

  .detailss {
    text-align: justify;
    font-size: 3.733vw;
    line-height: 6.4vw;
    letter-spacing: 0px;
    color: #303133;
  }

  .mentor_right {
    width: 81.733vw;
    margin-left: 2.533vw;
  }

  .mentor_bb_nbmer_img {
    width: 8.8vw;
    height: 8.8vw;
    background-color: #303133;
  }

  .mentor_bb_nbmer_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }

  .mentor_bb_nbmer {
    width: 93.6vw;
    margin: 0 auto;
    display: flex;
    margin-top: 5.333vw;
  }

  .mentor_bb_details {
    font-size: 3.733vw;
    color: #bfc2cc;
    text-align: center;
    margin-top: 13.333vw;
  }

  .mentor_bb_title {
    font-size: 3.467vw;
    color: #303133;
    margin-top: 4.4vw;
  }

  .mentor_bb {
    width: 93.6vw;
    height: 29.467vw;
    border-bottom: 0.233vw solid #edeff2;
    margin-top: 10vw;
  }

  .mentor_bbs {
    width: 93.6vw;
  }

  .huose_s {
    width: 100vw;
    height: 2.133vw;
    background-color: #f5f7fa;
    margin-top: 4vw;
    position: relative;
    left: -4vw;
  }

  .up {
    margin-top: 4.533vw;
    display: flex;
    flex-wrap: wrap;
  }

  .up > img {
    margin-right: 2.2vw;
    width: 14.6vw;
    height: 14.6vw;
    object-fit: cover;
    margin-bottom: 3.5vw;
    background: #c6c8cb;
  }

  .time {
    font-size: 3.2vw;
    color: #909399;
    margin-top: 2.667vw;
  }

  .names {
    font-size: 3.467vw;
    color: #303133;
  }

  .anew {
    width: 14.133vw;
    height: 5.867vw;
    background-color: #ffffff;
    border-radius: 2.933vw;
    border: solid 0.233vw #edeff2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.933vw;
    color: #909399;
  }

  .anew-hide {
    width: 14.133vw;
    height: 5.867vw;
  }

  .answer_bottom_list_right {
    display: flex;
    justify-content: space-between;
    width: 83vw;
    margin-left: 2.4vw;
  }

  .touxiang {
    width: 8.8vw;
    height: 8.8vw;
  }

  .touxiang > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }

  .answer_bottom_list {
    width: 93.6vw;
    display: flex;
    margin-top: 4vw;
  }

  .answer_bottom_text {
    font-size: 3.733vw;
    color: #bfc2cc;
    text-align: center;
    margin-top: 13.333vw;
  }

  .answer_bottom {
    width: 93.6vw;
    height: 32.4vw;
    margin: 0 auto;
  }

  .answer_top_bottom {
    margin-left: 3.467vw;
    width: 80vw;
  }

  .name {
    font-size: 4.267vw;
    color: #303133;
  }

  .details {
    font-size: 3.467vw;
    color: #909399;
    margin-top: 2.933vw;
  }

  .answer_top_left {
    width: 6.333vw;
    height: 4vw;
    background-image: linear-gradient(-55deg, #f0b860 0%, #ffe7c1 100%),
    linear-gradient(#ffcd05, #ffcd05);
    background-blend-mode: normal, normal;
    border-radius: 0vw 1.067vw 1.067vw 1.067vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.467vw;
    color: #ffffff;
  }

  .answer_top {
    width: 93.6vw;
    height: 23.6vw;
    margin: 0 auto;
    border-bottom: 0.233vw solid #edeff2;
    display: flex;
    align-items: center;
  }

  .uploading {
    width: 92vw;
    height: 12.533vw;
    background-color: #247dff;
    border-radius: 6.267vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8vw;
    letter-spacing: 0.267vw;
    color: #ffffff;
  }
</style>
