<template>
  <div class="comment">
    <van-icon
      :class="{ talkDisable: talkDisable }"
      :name="!isTalk ? talkClose : talkActive"
      @click="talkChange"
    />
    <!-- 语音 -->
    <div class="hold_talk" v-if="isTalk" @touchstart.prevent="startRecord" @touchend="stopRecord">
      <span class="anzhu">按住 说话</span>
    </div>
    <!-- 文本 -->
    <template v-else>
      <div class="inputBox">
        <!-- 
        <input
          v-model="comment"
          type="text"
          :placeholder="commentPlaceholder"
          @focus="showEmotion = false"
        />
        -->

        <van-field
          v-model="comment"
          rows="1"
          autosize
          type="textarea"
          :placeholder="commentPlaceholder"
          show-word-limit
          @focus="showEmotion = false"
        />
        <van-icon name="close" v-show="showCloseBtn" @click="clean" />
      </div>
      <van-icon :name="face" @click="showEmotion = !showEmotion" class="emotion" />
      <div @click="commentSend" class="sendBtn">
        <span>发送</span>
      </div>
    </template>
    <!-- 表情包 -->
    <Emotion v-if="showEmotion" class="emoji" :height="200" @emotion="handleEmotion"></Emotion>
  </div>
</template>
<script>
import Emotion from '@/components/extend/emoji/emotion/index'
import { getAuthority } from '@/api/pay'
export default {
  components: { Emotion },
  props: {
    replyTo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isTalk: false,
      talkClose: require('@/assets/images/yuyin.png'),
      talkActive: require('@/assets/images/ddd.png'),
      face: require('@/assets/images/face.png'),
      showEmotion: false,
      sec: 0, //录音时长（录音时长，返回给后台，以便显示）
      recTimer: null, //定时器
      comment: '',
      commentPlaceholder: '我来说几句'
    }
  },
  computed: {
    talkDisable() {
      return this.replyTo.userid
    },
    showCloseBtn() {
      return this.comment || (!this.comment && this.replyTo.userid)
    }
  },
  watch: {
    talkDisable(n) {
      if (n) this.isTalk = false //仅支持文本回复
    }
  },
  methods: {
    init() {
      this.comment = ''
      this.commentPlaceholder = '我来说几句'
    },
    talkChange() {
      if (this.talkDisable) return
      this.isTalk = !this.isTalk
    },
    // 开始录音
    startRecord() {
      this.endRecord = false //是否已执行touchend事件
      this.stopRecordSuccess = false //是否成功执行stopRecord事件
      this.clearTimer()
      this.$nextTick(() => {
        wx.startRecord()
        this.recTimer = setInterval(() => {
          this.sec += 0.1
        }, 100)
        this.$toast({ message: '录音中', duration: 0 })
      })
      // let t = 0 //防止录音太短,没有触发stopRecord(),可控制在1s后执行
      // const timer = setInterval(() => {
      //   if (t >= 10) {
      //     clearInterval(timer)
      //     if (this.endRecord && !this.stopRecordSuccess) {
      //       this.stopRecord() //防止录音时间过短，没有调用停止录音功能
      //     }
      //   } else {
      //     t++
      //     if (this.endRecord) {
      //       this.$toast('录音太短')
      //     }
      //   }
      // }, 100)
      // 参考:https://www.cnblogs.com/zhongxia/p/5554324.html
    },
    // 终止并发送录音
    stopRecord() {
      this.endRecord = true
      let dura = this.sec
      this.$toast().clear()
      this.clearTimer()
      // 停止录音
      wx.stopRecord({
        success: res => {
          this.localId = res.localId
          this.stopRecordSuccess = true
          // 上传录音到微信
          wx.uploadVoice({
            localId: this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: res => {
              res.duration = Number(dura).toFixed(1) //保留一位小数（小数相加会生成如:2.00000000001这样的格式）
              this.$emit('afterAudioComment', res)
            },
            fail(e) {
              console.error('上传录音到微信=失败', e)
            }
          })
        },
        fail(e) {
          // 苹果手机 stopRecord:the permission value is offline verifying
          this.stopRecordSuccess = true
          console.error('停止录音=失败', e)
        }
      })
    },
    // 发送评论
    commentSend() {
      let comment = this.comment.trim()
      let text = ''
      if (comment) {
        if (this.replyTo.autoid && this.replyTo.replymode === '文本') {
          // 有 评论id 的需添加 回复引用
          const arr = this.replyTo.content.split('{+_+}')
          text = `「回复 ${this.replyTo.name}：${
            arr[arr.length - 1]
          }」{+_+}${comment}`
        } else {
          text = comment
        }
        let params = {
          content: text, //文本输入
          replymode: '1' //评论类型 [1-文本,2-语音]
        }
        this.$emit('sendComment', params)
      } else {
        this.$toast('评论不能为空')
      }
    },
    // 选中表情包
    handleEmotion(i) {
      this.comment += i
      this.showEmotion = false
    },
    // 隐藏表情包
    hideEmoji() {
      this.showEmotion = false
    },
    clean() {
      this.init()
      if (this.replyTo.userid) {
        this.$emit('cleanReplyTo')
      }
    },
    // 清除计算录音时长定时器
    clearTimer() {
      clearInterval(this.recTimer)
      this.recTimer = null
      this.sec = 0
    }
  },
  mounted() {
    getAuthority() //获取微信参数
  }
}
</script>
<style lang="scss" scoped>
// 发送评论
.comment {
  width: 100vw;
  min-height: 13vw;
  background: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #edeff2;
  transition: all 300ms;
  .van-icon {
    font-size: 7vw;
    padding: 0 2vw;
  }
  .talkDisable::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border: 1px solid #ccc;
    width: 7vw;
  }
  // 语音
  .hold_talk {
    flex: 1;
    position: relative;
    height: 100%;
    margin-right: 2vw;
    .anzhu {
      @include center;
      @include wh(94%, 8vw);
      @include borderRadius(4vw);
      border: 1px solid $border-light;
      @include font(4.267vw, 8vw);
      text-align: center;
      color: #303133;
    }
  }
  .inputBox {
    flex: 1;
    position: relative;
    box-sizing: border-box;
    margin: auto 0;
    // input {
    //   box-sizing: border-box;
    //   line-height: 9vw;
    //   font-size: 4.267vw;
    //   border: 1px solid $font-light;
    //   padding: 0 5vw 0 3vw;
    //   border-radius: 6.5vw;
    //   color: $font-gray;
    //   width: 100%;
    // }
    /deep/.van-cell {
      border: 1px solid $font-light;
      border-radius: 6.5vw;
      padding-left: 2vw;
      padding-right: 4vw;
      .van-cell__value {
        textarea {
        }
      }
    }
    .van-icon {
      position: absolute;
      right: 0vw;
      top: 50%;
      transform: translateY(-50%);
      font-size: 4vw;
      padding-right: 1vw;
      color: #969799;
    }
  }
  .sendBtn {
    height: 13vw;
    width: 17vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #247dff;
    span {
      color: #ffffff;
      font-size: 4.267vw;
    }
  }
  .emoji {
    position: fixed;
    bottom: 13vw;
    left: 0;
    width: 100%;
  }
}
</style>
