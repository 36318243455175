<template>
  <div>
    <div class="succeed_img">
      <img src="@/assets/images/succend.png" alt />
    </div>
    <div class="reminder">恭喜你发布成功</div>
    <div class="beizhu">导师会尽快回复你哦</div>
    <!-- <router-link :to="`/replyList/${questionId}`"> -->
    <div class="check" @click="goToReplyList">查看我的疑问</div>
    <!-- </router-link> -->
    <div class="back" @click="back">返回导师主页</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questionId: this.$route.params.id
    }
  },
  methods: {
    goToReplyList() {
      this.$router.push({
        path: '/replyList',
        query: {
          questionId: this.questionId
          // answerPrice: 0//自己的提问可以免费收听老师的语音回复
        }
      })
    },
    back() {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped>
.beizhu {
  font-size: 3.733vw;
  color: #909399;
  text-align: center;
}
.back {
  font-size: 4.8vw;
  width: 92vw;
  height: 12.533vw;
  letter-spacing: 0.267vw;
  color: #303133;
  text-align: center;
  border: solid 0.233vw #edeff2;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check {
  width: 92vw;
  height: 12.533vw;
  background-color: #247dff;
  border-radius: 1.333vw;
  margin: 6.667vw auto;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reminder {
  font-size: 5.333vw;
  line-height: 6.133vw;
  color: #303133;
  text-align: center;
}
.succeed_img img {
  display: block;
  width: 100%;
  height: 100%;
}
.succeed_img {
  width: 41.067vw;
  height: 38.933vw;
  margin: 20vw auto;
  margin-top: 20vw;
}
</style>
