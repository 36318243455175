<!-- 资料 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
      >
        <!-- @load="onLoad" -->
        <template v-if="from === 'discover'">
        <template v-for="(item,idx) in list">
          <div class="kind" v-text="item.groupName" :key="item.groupName"></div>
          <Files :list="item.list" openFile :key="item.groupName + idx" />
        </template>
        </template>
        <template v-else>
          <Files :list="list" openFile/>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { ImagePreview } from 'vant'
import { getFileList } from '@/api/classRing'

import Files from '@/components/extend/files/index'

export default {
  name: 'material',
  props: {
    classId: String,
    from: String //哪里调用 （discover-发现资料，undefined-其他）
  },
  data() {
    return {
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      imagePreview: undefined //图片预览
    }
  },
  components: { Files },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
      this.getFileList()
    },
    getFileList() {
      getFileList(this.classId)
        .then(res => {
          this.loading = false
          this.finished = true
          if (this.from === 'discover') {
            const classGroup = []
            const classArr = []
            res.list.forEach(item => {
              if (!classGroup.includes(item.classname))
                classGroup.push(item.classname)
            })
            classGroup.forEach((el, i) => {
              res.list.forEach(it => {
                if (el === it.classname) {
                  if (!classArr[i]) {
                    classArr[i] = {
                      groupName: el,
                      list: []
                    }
                  }
                  classArr[i].list.push(it)
                }
              })
            })
            this.list = classArr
          } else {
            this.list = res.list
          }
          // console.log(123, this.list)
        })
        .catch(err => {
          this.error = true
        })
        .finally(() => {
          this.pullRefresh = false
        })
    },
    // 文件类型载入
    pushFileType(list) {
      return list.map(item => {
        item.fileext = item.url.substr(item.url.lastIndexOf('.') + 1)
        return item
      })
    }
    // // 打开
    // open(info) {
    //   // 图片
    //   const img = ['jpg','jpge','png'];
    //   // 微软办公三剑客
    //   const wep = ['doc','docx','xls','xlsx','ppt','pptx'];
    //   // pdf
    //   const pdf = ['pdf'];
    //   if (img.indexOf(info.fileext)!==-1) {
    //     this.imagePreview = ImagePreview({
    //       images: [info.url],
    //       showIndex:false,
    //       closeable: true,
    //     })
    //   }
    //   if (wep.indexOf(info.fileext)!==-1) {
    //     window.location.href = 'https://view.officeapps.live.com/op/view.aspx?src='+info.url;
    //   }
    //   if (pdf.indexOf(info.fileext)!==-1) {
    //     window.location.href = info.url;
    //   }
    // }
  },
  mounted() {
    this.getFileList()
    window.addEventListener('popstate', function(e) {
      // var i=confirm("你确认要退出吗");
      // if(i){
      //   window.history.back();
      // }else{
      //   pushHistory();
      //   //在history加入空连接
      //   function pushHistory(){
      if (this.imagePreview) {
        this.imagePreview.close()
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#')
      }
      //   };
      // }
    })
  }
}
</script>

<style lang="scss" scoped>
.kind {
  @include wh(100%, 8.8vw);
  @include font(2.667vw, 8.8vw);
  padding: 0 4vw;
  color: $font-gray;
  background: #ededed;
}
</style>
