<template>
  <div class="apply_page">
    <topheader />
    <div style="height:0.0001vw;"></div>
    <div class="apply_message">
      <div class="apply_message_title">公司信息</div>
      <div class="apply_message_input">
        <div class="apply_message_input_size">公司名称</div>
        <van-field v-model="companyName" placeholder="请输入公司名称" />
      </div>
      <div class="apply_message_input">
        <div class="apply_message_input_size">公司地址</div>
        <van-field v-model="companyAddr" placeholder="请输入公司地址" />
      </div>
    </div>

    <div class="apply_message">
      <div class="apply_message_title">学员信息</div>
      <div class="apply_message_input">
        <div class="apply_message_input_size">学员姓名</div>
        <van-field v-model="studentName" placeholder="请输入学员姓名" />
      </div>
      <div class="apply_message_input">
        <div class="apply_message_input_size">部门/职务</div>
        <van-field v-model="role" placeholder="请输入部门/职务" />
      </div>
      <div class="apply_message_input">
        <div class="apply_message_input_size">联系电话</div>
        <van-field v-model="studentTel" placeholder="请输入联系电话" disabled />
      </div>
    </div>
    <div class="nonsense">
      <van-checkbox v-model="checked" shape="square">
        <span class="nonsense_text">本人已阅读并认同参训协议</span>
      </van-checkbox>
    </div>
    <div class="payment">
      <template v-if="price != 0">
        <div class="payment_flet">
          <span class="total">合计:￥{{ price }}</span>
          <span class="original">￥{{ original }}</span>
        </div>
        <div :class="`payment_right ${payOn ? '' : 'payment-off'}`" @click="WeChatPay">微信支付</div>
      </template>
      <div v-else :class="`payment_right apply ${payOn ? '' : 'payment-off'}`" @click="apply">立即报名</div>
    </div>
  </div>
</template>

<script>
import { updateUserInfo } from '../../api/my'
import { telTest } from '../../util/verify'
import { createStuinfo, wxPayOrderBM } from '@/api/pay'
import { getOneClassinfo } from '@/api/classRing'

export default {
  data() {
    return {
      lastPage: sessionStorage.getItem('lastPage'), //上一页（vux里面存不到??）
      classId: this.$route.query.classId, // 课程id
      userId: this.$store.state.user.userId, // 用户id
      stuId: this.$route.query.stuId, //学员id
      checked: false, // 参训协议是否阅读
      price: this.$route.query.price.split('|')[0], // 现价
      original: this.$route.query.price.split('|')[1], // 原价
      companyName: '', // 公司名称
      companyAddr: '', // 公司地址
      studentName: '', // 学员姓名
      role: '', // 职务
      studentTel: '', // 联系电话
      recClassId: this.$store.state.user.recClassId, //推荐班圈（二维码分享）
      recommendA: this.$store.state.user.recommendA, //一级推荐人
      recommendB: this.$store.state.user.recommendB, //二级推荐人
      className: this.$route.query.className //班圈名称
    }
  },
  computed: {
    info() {
      return this.$store.getters.info
    },
    // 是否开启支付
    payOn() {
      return (
        this.companyName !== '' &&
        this.companyAddr !== '' &&
        this.studentName !== '' &&
        this.role !== '' &&
        this.studentTel !== '' &&
        this.checked
      )
    },
    // 是否跟二维码推荐id一样的班圈
    sameRecommendClass() {
      return this.recClassId == this.classId
    }
  },
  methods: {
    init() {
      this.companyName = this.info.company
      this.companyAddr = this.info.comaddress
      this.studentName = this.info.name
      this.role = this.info.job
      this.studentTel = this.info.tel
    },
    // 验证
    verify() {
      if (!telTest(this.studentTel)) {
        this.$toast('学员联系电话填写错误')
        return false
      }
      return true
    },
    // 检测信息是否被修改
    verifyChangeInfo() {
      return (
        this.companyName !== this.info.company ||
        this.companyAddr !== this.info.comaddress ||
        this.studentName !== this.info.name ||
        this.role !== this.info.job ||
        this.studentTel !== this.info.tel
      )
    },
    // 微信支付
    WeChatPay() {
      if (this.payOn) {
        if (!this.verify()) return
        if (this.verifyChangeInfo()) {
          // 保存用户信息
          updateUserInfo({
            type: 1, // 1-报名 2-更新 3-上传头像
            autoid: this.userId,
            name: this.studentName,
            company: this.companyName,
            comaddress: this.companyAddr,
            job: this.role
          })
            .then(res => {
              this.$store.dispatch('user/loginByOpenId') //更新用户信息到store
              this.apply()
            })
            .catch(() => {
              this.$toast('保存信息失败')
            })
        } else {
          this.apply()
        }
      }
    },
    // 报名
    apply() {
      if (this.stuId) {
        //上次已报名，未支付
        this.payment(this.stuId)
      } else {
        // 创建学员报名记录，并完成支付
        createStuinfo(
          this.classId,
          this.userId,
          this.sameRecommendClass ? this.recommendA : '',
          this.sameRecommendClass ? this.recommendB : ''
        )
          .then(res => {
            this.sutId = res.autoid
            if (Number(this.price) === 0) {
              //免费班圈
              this.$router.push({
                path: '/Course_home',
                query: {
                  classId: this.classId
                }
              })
            } else {
              // 收费班圈
              this.payment(this.sutId) //传入学员id
            }
          })
          .catch(err => {
            this.$toast('创建报名记录失败')
          })
      }
    },
    // 执行微信支付
    payment(_id = this.sutId) {
      // 获取微信支付参数
      wxPayOrderBM(_id)
        .then(data => {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {
              appId: data.appId, // 公众号名称，由商户传入
              timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
              nonceStr: data.nonceStr, // 随机串
              package: data.packageValue,
              paySign: data.paySign, // 微信签名
              signType: 'MD5' // 微信签名方式
            },
            res => {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                this.$toast('支付成功')
                this.$toast().clear()
                this.$router.go(-1)
              } else {
                this.$toast('支付失败')
              }
            }
          )
        })
        .catch(err => {
          console.error('获取微信支付信息失败', err)
          this.$toast('获取微信支付信息失败')
        })
    },
    // 跳入支付成功页面
    goToSuccess() {
      this.$router.push({
        path: '/classSucceed/1',
        query: {
          classId: this.classId,
          className: this.className
        }
      })
    }
  },
  created() {
    this.init()
    if (this.lastPage === 'login') {
      if (this.$store.state.user.isExpert) {
        this.$router.push({
          path: '/Course_home',
          query: {
            classId: this.classId
          }
        })
      } else {
        getOneClassinfo(this.classId).then(res => {
          //获取班圈信息(带报名情况)
          if (res.stu.length && res.stu[0].paystatus == 1) {
            //免费班圈也算收费了
            this.$store.dispatch('user/setSutId', res.stu[0].stuid)
            this.stuId = res.stu[0].stuid
            // @toAsk 后台推荐接口已经返回 recommendA 是否有必要前端再保存
            this.$store.dispatch('user/setRecommend', {
              type: 'A',
              data: res.stu[0].recommendA
            }) //一级推荐人
            this.$store.dispatch('user/setRecommend', {
              type: 'B',
              data: res.stu[0].recommendB
            }) //二级推荐人
            this.$router.push({
              path: '/Course_home',
              query: {
                classId: this.classId
              }
            })
          }
        })
      }
    } else if (this.lastPage === 'Course_home') {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.van-cell {
  padding: 0;
}

.van-checkbox__icon {
  background-color: #ffffff !important;
}

.nonsense_text {
  font-size: 3.467vw;
  color: #bfc2cc;
}

.nonsense {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.payment_flet {
  margin-left: 4vw;
}

.original {
  font-size: 2.933vw;
  letter-spacing: -0.133vw;
  color: #bfc2cc;
  text-decoration: line-through;
  margin-left: 1.333vw;
}

.total {
  font-size: 5.067vw;
  letter-spacing: -0.267vw;
  color: #fe4858;
  font-weight: bold;
}

.payment_right {
  width: 33.333vw;
  height: 13.067vw;
  background-image: linear-gradient(90deg, #f0b860 0%, #fddaa4 100%),
    linear-gradient(#fe4858, #fe4858);
  background-blend-mode: normal, normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.733vw;
  color: #ffffff;
  float: right;
}
.apply {
  width: 100%;
}

.payment-off {
  opacity: 0.6;
}

.payment {
  width: 100vw;
  height: 13.067vw;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apply_message_input_size {
  font-size: 3.733vw;
  color: #909399;
  width: 40vw;
}

.apply_message_input {
  width: 88.267vw;
  height: 11.733vw;
  margin: 0 auto;
  border-top: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
}

.apply_message_title {
  width: 88.267vw;
  height: 11.733vw;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 4vw;
  color: #303133;
  font-weight: bold;
}

.apply_message {
  width: 93.6vw;
  background-color: #ffffff;
  border-radius: 1.067vw;
  margin: 2.667vw auto;
}

.apply_page {
  width: 100%;
  height: 100vh;
  background-color: #f5f7fa;
  @include isTop;
}
</style>
