/**
 * 图像处理
 */

import Exif from "exif-js";

/**
 * @desc 获取图片信息，使用exif.js库，具体用法请在github中搜索
 * @param {Object} file 上传的图片文件
 * @param {String} tag 需要获取的信息 例如：'Orientation'旋转信息
 * @return {Promise} 读取是个异步操作，返回指定的图片信息
 */
export const getImageTag = (file, tag) => {
  if (!file) return 0;
  return new Promise((resolve, reject) => {
    // 箭头函数会修改this，所以这里不能用箭头函数
    Exif.getData(file, function () {
      const o = Exif.getTag(this, tag);
      resolve(o);
    });
  });
};

export async function pictureRotationCorrection(file,callback) {
  // 获取拍照方式
  const or = await getImageTag(file,'Orientation')
  console.log('or',or)
  if (or) {
    // 使用FileReader读取文件流，file为上传的文件流
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // 箭头函数会改变this，所以这里不能用肩头函数
    reader.onloadend = function () {
      // this.result就是转化后的结果
      const result = this.result;
      // 将base64添加到图片标签上
      const img = new Image();
      img.src = result;
      img.onload = function () {
        // 获取旋转后的图片
        const data = getRotateImg(img, or);
        // 如果上传接口不支持base64，则这里需要将base64转为文档流
        const f = dataURLtoFile(data);
        callback(f);
      };
    };
  } else {
    callback(file)
  }
}

/**
 * @desc 获取旋转后的图片
 * @param {Object} img 图片文件
 * @param {Number} or 旋转信息
 */
function getRotateImg(img, or=6) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // 图片原始大小
  const width = img.width;
  const height = img.height;
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  switch (or) {
    case 6: // 顺时针旋转90度
      return rotateImg(img, 'right', canvas);
      break;
    case 8: // 逆时针旋转90度
      return rotateImg(img, 'left', canvas);
      break;
    case 3: // 顺时针旋转180度
      return rotateImg(img, 'right', canvas, 2);
      break;
    default:
      return rotateImg(img, 'right', canvas, 4);
      break;
  }
}

/**
 * @desc 旋转canvas，会对源数据canvas进行修改
 * @param {Object} img 图片文件
 * @param {String} dir 方向 left逆时针|right顺时针
 * @param {Object} canvas 画布
 * @param {Number} s 向指定方向旋转几步，1步为90度
 */
const rotateImg = (img, dir = 'right', canvas, s = 1) => {
  const MIN_STEP = 0;
  const MAX_STEP = 3;

  const width = canvas.width || img.width;
  const height = canvas.height || img.height;
  let step = 0;

  if (dir === 'right') {
    step += s;
    step > MAX_STEP && (step = MIN_STEP);
  } else {
    step -= s;
    step < MIN_STEP && (step = MAX_STEP);
  }

  const degree = step * 90 * Math.PI / 180;
  const ctx = canvas.getContext('2d');

  switch (step) {
    case 1:
      canvas.width = height;
      canvas.height = width;
      ctx.rotate(degree);
      ctx.drawImage(img, 0, -height, width, height);
      break;
    case 2:
      canvas.width = width;
      canvas.height = height;
      ctx.rotate(degree);
      ctx.drawImage(img, -width, -height, width, height);
      break;
    case 3:
      canvas.width = height;
      canvas.height = width;
      ctx.rotate(degree);
      ctx.drawImage(img, -width, 0, width, height);
      break;
    default:
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      break;
  }
  return canvas.toDataURL("image/png")
};

/**
 * @desc 将base64的图片转为文件流
 * @param {String} dataUrl base64数据
 * @return {Object} 文件流
 */
export const dataURLtoFile = (dataUrl) => {
  const filename = `img${Date.now()}`;
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

