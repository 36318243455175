<template>
  <div class="page" @click.stop="hideEmoji">
    <topheader :title="title" @beforeBack="beforeBack">
      <van-button
        :type="lessonInfo.lbcode === 1 ? 'warning' : lessonInfo.lbcode === 0 ? 'primary' : 'info'"
        size="small"
        slot="rightTools"
        v-show="lessonInfo.showmode === '直播' && isTeach"
        :disabled="lessonInfo.lbcode === 1"
        @click="live2RecordDialog = true"
      >{{lessonInfo.lbcode === 1 ? '转换中' : lessonInfo.lbcode === 0 ? '已转换' : '转换'}}</van-button>
    </topheader>
    <div class="player">
      <Live
        ref="player"
        v-if="lessonInfo.showmode === '直播' && lessonInfo.lburl === null"
        :poster="lessonInfo.pic"
        :src="curVideoUrl"
        :beginTime="lessonInfo.bd"
        :lessonId="lessonInfo.autoid"
        :stuId="stuId"
        @onplay="onplay(lessonInfo)"
      />
      <Record
        ref="player"
        v-else
        :poster="lessonInfo.pic"
        :src="curVideoUrl"
        :lessonId="lessonInfo.autoid"
        :stuId="stuId"
        @onplay="onplay(lessonInfo)"
      />
    </div>

    <div class="course_introduce">
      <div class="course_introduce_title">
        <van-icon name="live" v-if="lessonInfo.liveurl && lessonInfo.lburl === null" />
        <van-icon name="video" v-else v-show="lessonInfo.title" />
        {{ lessonInfo.title ? lessonInfo.title : '课程名称' }}
        <!-- 播放类型 -->
        <span
          class="tag"
          :style="{color: lessonInfo.tag == '预习' ? '#ff976a' : 'green'}"
          v-text="lessonInfo.tag"
        ></span>
        <!-- 打赏按钮 -->
        <i class="gratuity" @click="showGratuity = !showGratuity">赏</i>
      </div>

      <!-- 简介 -->
      <van-collapse v-model="isShowIntroduct" class="course_introduce2">
        <van-collapse-item name="1">
          <template #title>
            <div>
              <span v-text="lessonInfo.classownername1" />
              <span>&nbsp;|&nbsp;</span>
              <span v-text="lessonInfo.bd" v-if="lessonInfo.bd"></span>
              发布
            </div>
          </template>
          {{ lessonInfo.content }}
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 课表 -->
    <div class="course_timetable">
      <h1>
        课表
        <span @click="goToRecommend">
          班圈推荐
          <van-icon name="good-job" />
        </span>
      </h1>
      <div class="course_timetable_content">
        <ul :class="lessonOver8 ? 'course_timetable_2line' : ''">
          <li
            v-for="(item, idx) in courseList"
            :key="idx"
            :class="{
              course_active: idx == curLessonIdx,
            }"
            @click="chooseLesson(item, idx, 1)"
          >{{ idx + 1 }}</li>
        </ul>
        <div class="course_timetable_showmore" v-if="courseList.length > 8">
          <span
            class="course_timetable_more_point"
            :class="lessonOver8 ? 'course_active' : ''"
            @click="lessonOver8 = !lessonOver8"
          >···</span>
          <span
            class="course_timetable_more_txt"
            v-if="courseList.length > 16 && lessonOver8"
            @click="lessonOver16 = !lessonOver16"
          >更多</span>
        </div>
      </div>
    </div>

    <!-- 当前课堂 -->
    <div class="classroom">
      <!-- 已购买显示 -->
      <van-tabs
        v-model="active"
        color="#247dff"
        line-width="4.533vw"
        animated
        swipeable
        sticky
        offset-top="261"
        @change="tabsChange"
      >
        <van-tab title="课堂">
          <Review
            :classId="classId"
            :isBuy="isBuy"
            :isClassTeacher="isClassTeacher"
            :activeTag="active"
            ref="comment"
            @action="reply"
          >
            <span
              slot="nameRight"
              v-text="'置顶'"
              class="toTop"
              slot-scope="data"
              @click="toTop(data)"
              v-if="isTeach && ($refs.comment.list.length && data.data.autoid !== $refs.comment.list[0].autoid)"
            />
            <!-- 置顶：班主任+导师 -->
          </Review>
        </van-tab>
        <van-tab title="作业">
          <HomeworkTest
            ref="homework"
            type="1"
            :classId="classId"
            :lessonId="lessonInfo.autoid"
            :stuId="stuId"
            :isClassTeacher="isClassTeacher"
            @setSession="setSession"
            :headmasterId="lessonInfo.classowneruserid1"
            :supervisorId="lessonInfo.classowneruserid2"
          />
        </van-tab>
        <van-tab title="考试">
          <ExamTest
            ref="exam"
            type="2"
            :classId="classId"
            :lessonId="lessonInfo.autoid"
            :stuId="stuId"
            :isClassTeacher="isClassTeacher"
            @setSession="setSession"
            :headmasterId="lessonInfo.classowneruserid1"
            :supervisorId="lessonInfo.classowneruserid2"
          />
        </van-tab>
        <van-tab title="公告">
          <Announcement
            ref="announcement"
            :classId="classId"
            :headMasterUserId="lessonInfo.classowneruserid1"
            :supervisorId="lessonInfo.classowneruserid2"
            :assistantUserId="lessonInfo.classowneruserid3"
            @setSession="setSession"
          />
        </van-tab>
      </van-tabs>

      <!-- 发送评论 -->
      <sendMsgBox
        v-show="active === 0"
        :class="{ 'comment-show': active === 0 }"
        ref="reply"
        :replyTo="replyTo"
        @afterAudioComment="afterAudioComment"
        @sendComment="sendTextComment"
        @cleanReplyTo="cleanReplyTo"
      />
    </div>

    <!-- 打赏页 -->
    <van-popup
      v-model="showGratuity"
      position="bottom"
      :closeable="true"
      close-icon="cross"
      close-icon-position="top-right"
      :style="{ height: '68%', width: '100%' }"
      @closed="resetPayment"
    >
      <Payment :lesson="lessonInfo" @rewarded="rewarded" ref="payment" />
    </van-popup>

    <!-- 发起/签到 - 作废 -->
    <!-- <Sign-in
      right="4"
      bottom="20"
      ref="signIn"
      :isTeach="isTeach"
      :lessonId="lessonInfo.autoid"
      :userId="userId"
      :stuId="stuId"
      :signId="signid"
      v-if="showSign && false"
      @after="afterSign"
    />-->

    <!-- 弹出层 课程表 -->
    <van-popup
      v-model="lessonOver16"
      position="bottom"
      :closeable="false"
      close-icon="cross"
      close-icon-position="top-right"
      :style="{ height: '95%', 'margin-left': '20%', width: '80%' }"
    >
      <div class="course_timetable_list">
        <div class="course_timetable_header">
          课程表
          <van-icon name="cross" @click="lessonOver16 = !lessonOver16" />
        </div>
        <ul class="course_timetable_ul">
          <li
            v-for="(course, index) in courseList"
            :key="index"
            class="course_timetable_li"
            @click="chooseLesson(course, index, 2)"
          >
            <div class="course_timetable_li_div">
              <van-icon name="notes-o" />
              <div class="course_timetable_li_right">
                <span>
                  {{ course.bd | timeStamp | dateFormat('MM月dd') }}
                  {{ course.bd | dateWeek }}
                </span>
                <span>
                  {{ course.bd | dateFormat('hh:mm') }} -
                  {{ course.ed | dateFormat('hh:mm') }}
                </span>
              </div>
            </div>
            <div class="course_timetable_li_div">
              <em>{{ index + 1 }}</em>
              <div class="course_timetable_li_right">
                <span>{{ `${course.title}--${course.classownername1}` }}</span>
                <div>
                  <van-tag
                    plain
                    :type="course.showmode == '直播' ? 'warning' : 'success'"
                  >{{ course.showmode }}</van-tag>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </van-popup>
    <!-- 签到 -->
    <Drag
      ref="drag"
      :countDown="signCountDown"
      :isTeach="isTeach"
      :lessonId="lessonInfo.autoid"
      :signId="signid"
      :stuId="stuId"
      v-if="showSign"
      @after="afterSign"
    />
    <!-- 直播转录播 按钮弹窗 -->
    <van-dialog
      v-model="live2RecordDialog"
      title="确定将直播视频转换成录播视频？"
      show-cancel-button
      class="dialog_box"
      :before-close="live2Record"
    >
      <van-form ref="l2rForm">
        <van-cell-group class="homework_time">
          <van-field
            v-model="startTime"
            label="开始时间:"
            name="startTime"
            placeholder="请选择..."
            type="textarea"
            rows="1"
            label-width="5em"
            autosize
            readonly
            disabled
            required
            :rules="[{ required: true, message: '请选择开始时间' }]"
            @click="showStartTimePicker"
          />
        </van-cell-group>
        <van-cell-group class="homework_time">
          <van-field
            v-model="endTime"
            label="结束时间:"
            name="endTime"
            placeholder="请选择..."
            type="textarea"
            rows="1"
            label-width="5em"
            readonly
            autosize
            disabled
            required
            :rules="[{ required: true, message: '请选择结束时间' },{ validator: endTimeValid, message: '开始时间不能大于等于结束时间' }]"
            @click="showEndTimePicker"
          />
        </van-cell-group>
      </van-form>
    </van-dialog>
    <!-- 开始时间选择器 -->
    <van-popup v-model="showStartTime" round position="bottom">
      <van-datetime-picker
        v-model="startT"
        type="datetime"
        title="选择开始时间"
        @confirm="selectStartPicker"
        @cancel="showStartTime = false"
      />
    </van-popup>
    <!-- 结束时间选择器 -->
    <van-popup v-model="showEndTime" round position="bottom">
      <van-datetime-picker
        v-model="endT"
        type="datetime"
        title="选择结束时间"
        @confirm="selectEndPicker"
        @cancel="showEndTime = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Review from './widget/courseHome/review'
import HomeworkTest from './widget/courseHome/homeworkTest'
import ExamTest from './widget/courseHome/examTest'
import SignIn from './widget/courseHome/signIn'
import Announcement from './widget/courseHome/announcement'
import Payment from './widget/courseHome/payment'
import Live from './widget/courseHome/live'
import Record from './widget/courseHome/record'
import sendMsgBox from '@/components/extend/sendMsgBox/index'
import Drag from './widget/courseHome/drag'
import 'videojs-contrib-hls'

import {
  getOneClassinfo,
  // getCourseDetails,
  getCourseList,
  allowStarSign, // (专家)查询是否可以再发起签到
  getSignal, // (学员)查询是否要签到
  toTopReply,
  saveRecord,
  greateCourseLog
} from '@/api/classRing'
import { sendComment, delReply } from '@/api/classRing'
import eventBus from '@/util/eventBus'
import { getAuthority } from '@/api/pay'

export default {
  components: {
    Review,
    HomeworkTest,
    ExamTest,
    Announcement,
    SignIn,
    Payment,
    Live,
    Record,
    sendMsgBox,
    Drag
  },
  data() {
    return {
      userId: this.$store.state.user.userId, //用户id
      comment: '', // 你要发送的评论
      classId: this.$route.query.classId, //班圈id
      classInfo: {}, // 获取班圈详情接口返回的所有数据
      // lessonIdHistory: [], //选中过的lessonId记录（页签刷新用）
      stuId: null, //学员id
      isBuy: false, // 是否购买班圈
      courseList: [], // 课程列表
      active: 0, //页签idx
      isShowIntroduct: [], //是否展示简介
      curLessonIdx: 0, //选中的课程
      lessonOver8: false, //是否展示更多课程 > 8
      lessonOver16: false, //是否展示更多课程 > 16
      showGratuity: false, //是否展示“打赏”模块
      talkClose: require('@/assets/images/yuyin.png'),
      talkActive: require('@/assets/images/ddd.png'),
      talk: false, //语音
      face: require('@/assets/images/face.png'),
      commentPlaceholder: '我来说几句', //回复placeholder
      replyTo: {}, //评论谁的
      commentTimer: null, //定时获取评论的timer
      showSign: false, //是否展示签到图标
      launchTimer: undefined, //定时器(专家查询)
      signTimer: undefined, //定时器(学员查询)
      signid: NaN, //签到记录id
      refresh: false, //需要刷新当前页面
      title: this.$route.meta.caption, //标题
      isClassTeacher: false, //是否当前班圈的课程班主任/导师/辅导员

      signCountDown: 0, //签到倒计时
      live2RecordDialog: false, //直播转录播的弹窗
      showStartTime: false, //是否展示开始时间的选择器
      showEndTime: false, //是否展示结束时间的选择器
      startTime: '', //直播转录播的开始时间
      endTime: '', //直播转录播的结束时间
      startT: '', //选择器的开始时间
      endT: '' //选择器的结束时间
    }
  },
  computed: {
    //当前课程信息
    lessonInfo() {
      if (this.courseList.length) {
        return this.courseList[this.curLessonIdx]
      } else {
        return {}
      }
    },
    // 是否班主任
    isHeadmaster() {
      return this.userId == this.lessonInfo.classowneruserid1
    },
    // 班主任|导师
    isTeach() {
      return (
        this.userId == this.lessonInfo.classowneruserid1 ||
        this.userId == this.lessonInfo.classowneruserid2
      )
    },
    // 当前课程的播放路径
    curVideoUrl() {
      if (this.lessonInfo.showmode === '直播') {
        let { liveurl, lburl } = this.lessonInfo
        return lburl ? lburl : liveurl
      } else {
        return this.lessonInfo.videourl
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== 'Course_home') {
        this.refresh = true // 默认下次进入刷新
        this.cleanSignTimer() // 清除定时器
      }
      // 清除 弹窗
      document.getElementsByClassName('van-dialog').forEach(item => {
        item.children[2].children[0].click()
      })
    },
    'lessonInfo.bd'(n) {
      if (this.lessonInfo.showmode === '直播') {
        this.startTime = n //直播转录播的开始时间
      }
    },
    'lessonInfo.ed'(n) {
      if (this.lessonInfo.showmode === '直播') {
        this.endTime = n //直播转录播的结束时间
      }
    }
  },
  filters: {
    dateWeek(v) {
      let date = new Date(v.replace(/-/g, '/')),
        week = date.getDay(),
        obj = {
          1: '星期一',
          2: '星期二',
          3: '星期三',
          4: '星期四',
          5: '星期五',
          6: '星期六',
          0: '星期日'
        }
      return obj[week] ? obj[week] : week
    }
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
    // 修复iOS版微信HTML5 History兼容性问题（导致ios用户获取微信参数报错config: invalid signature）
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
    // 参考 https://www.cnblogs.com/songxuan/p/10826335.html
  },
  methods: {
    // 查询 能否 发起/签到
    askForLaunchSign() {
      this.cleanSignTimer()
      this.$nextTick(() => {
        //DOM更新之后操作
        // 若课程为直播
        if (this.lessonInfo.showmode === '直播') {
          //班主任和导师
          if (this.isTeach) {
            this.teaGetSignInfo()
            // 先查询能否发起签到
            this.launchTimer = setInterval(this.teaGetSignInfo, 5000)
          } else if (this.stuId) {
            // 防止ios用户 导师账号会收到学员签到提示/ps:安卓用户不会的
            //学员
            this.stuGetSignInfo()
            // 先查询能否发起签到
            this.signTimer = setInterval(this.stuGetSignInfo, 5000)
          }
        }
      })
    },
    // 老师获取签到信息
    teaGetSignInfo() {
      allowStarSign(this.lessonInfo.autoid, this.userId)
        .then(res => {
          if (res.error == '0') {
            //立即可以发起签到，不用倒计时
            if (res.AllowStarSign) {
              this.showSign = res.AllowStarSign
              this.signCountDown = 0
            } else {
              // 还剩多少秒可以再次发起签到
              this.showSign = res.surplussec > 0 ? true : false // res.AllowStarSign
              this.signCountDown = res.surplussec
            }
          } else {
            this.showSign = res.AllowStarSign
            this.signCountDown = res.surplussec //剩余多少秒允许发起下一次签到
          }
        })
        .catch(err => {
          console.error(err)
          this.cleanSignTimer
        })
    },
    // 学员获取签到信息
    stuGetSignInfo() {
      getSignal(this.lessonInfo.autoid, this.stuId)
        .then(res => {
          // 只有正在观看直播才允许签到
          if (this.$refs.player.isPlay) {
            this.hasNotice = false // 置空通知过
            this.showSign = res.AllowSign
            this.signCountDown = res.surplussec //剩余签到时间
            this.signid = res.starsignid //签到记录id
            this.$nextTick(() => {
              this.$refs.drag.$refs.sign.showDialog = true // 先弹窗通知学员
            })
          } else {
            // 如果没有观看直播，但老师又发起签到，可以通知学员观看????
            if (res.AllowSign && !this.hasNotice) {
              this.$toast({
                message: '老师正在发起直播签到，请及时观看直播并签到。',
                position: 'bottom'
              })
              this.hasNotice = true //通知过
            } else if (!res.AllowSign) {
              this.hasNotice = false
            }
          }
        })
        .catch(this.cleanSignTimer)
    },
    // 清除
    cleanSignTimer() {
      clearInterval(this.launchTimer)
      clearInterval(this.signTimer)
      this.launchTimer = null
      this.signTimer = null
      this.showSign = false
    },
    // 选中课程
    chooseLesson(item, index, type) {
      //学员已付费 | 班圈免费 | 班圈班主任|导师|辅导员
      // ---> 因为 付费用户+专家 只能看付费课程，免费试学只能看免费课程。 获取课程接口已经区分好付费/免费 所以这里就不多做处理
      // if (
      //   (item.pricemode == '付费' && this.isBuy) ||
      //   item.pricemode == '免费' ||
      //   this.isClassTeacher
      // ) {
      this.curLessonIdx = index
      this.tabsChange(this.active) //切换课程，同时判断是否需要刷新 作业|考试 数据
      switch (this.lessonInfo.showmode) {
        case '直播':
          this.askForLaunchSign()
          break
        case '录播':
          this.cleanSignTimer()
          break
      }
      if (type === 1) {
        //外层选 课程
        this.isShowIntroduct = []
      } else if (type === 2) {
        //弹出层
        this.lessonOver16 = false
      }
      // }
    },
    // 获取课程列表
    getCourseList() {
      getCourseList(this.classId).then(res => {
        this.title = res.data[0].title //班圈名称
        this.isClassTeacher = res.isclasstea //是否当前班圈的课程班主任/导师/辅导员
        // 付费学员+班主任+辅导员+导师 --> 查看收费课程
        if (
          this.isClassTeacher ||
          (res.stu.length && res.stu[0].paystatus === 1)
        ) {
          this.courseList = res.list.filter(item => item.pricemode === '付费')
        } else {
          this.courseList = res.list.filter(item => item.pricemode === '免费')
        }
        // 如果是 我的学习计划 进入 需反显指定的课程
        let lessonId = this.$route.query.lessonId
        // 推荐 | 作业 | 考试 | 公告 | 班圈名单 | 班圈资料 >>> 反选上次打开的课程及标签
        const activeTag = sessionStorage.getItem('activeTag')
        const curLessonId = sessionStorage.getItem('curLessonId')
        if (lessonId) {
          this.resetCurLesson(lessonId)
        } else if (activeTag != undefined || curLessonId != undefined) {
          if (activeTag != undefined) this.active = Number(activeTag)
          if (curLessonId != undefined) {
            this.resetCurLesson(curLessonId)
          }
        } else {
          this.askForLaunchSign() //如果第一个课程刚好是直播的，也需要触发
        }
        this.shareSetting() //设置分享信息
        if (this.courseList.length == 0) return //没有配置课程
        if (res.stu.length) {
          this.stuId = res.stu[0].autoid
          this.isBuy = res.stu[0].paystatus === 1
        }
      })
    },
    // 重置当前课程信息
    resetCurLesson(lessonId) {
      let curLessonInfo = {},
        curLessonIdx = ''
      this.courseList.forEach((item, idx) => {
        if (item.autoid == lessonId) {
          curLessonInfo = item
          curLessonIdx = idx
        }
      })
      this.chooseLesson(curLessonInfo, curLessonIdx, 1)
    },
    // 点击评论之后要执行的事情（回复/删除）
    reply(info) {
      if (this.userId == info.userid || info.type == 'delete') {
        //用户自行删除 || 班主任|导师协助删除
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除此条评论？'
          })
          .then(() => {
            delReply(info.autoid)
              .then(res => {
                this.$refs.comment.onRefresh()
                this.$toast('删除成功')
              })
              .catch(err => {
                // this.$toast("失败")
              })
          })
      } else {
        //回复
        if (info.replymode === '语音') {
          return '@todo'
        } else {
          this.$refs.reply.commentPlaceholder = `回复${info.name}：`
          this.replyTo = info //评论谁
        }
      }
    },
    // 定时获取最新评论信息
    upDateCommon() {
      this.commentTimer = setInterval(() => {
        this.$refs.comment.refresh(false)
      }, 10000)
    },
    // ->班圈推荐
    goToRecommend() {
      this.setSession()
      this.$router.push({
        path: `/recommend/${this.classId}/${this.userId}`
      })
    },
    // 保存 课程id + 标签index 到sessionStorage，用户返回页面反选
    setSession() {
      sessionStorage.setItem('activeTag', this.active)
      sessionStorage.setItem('curLessonId', this.lessonInfo.autoid)
    },
    // 签到后隐藏“签到”图标
    afterSign(type) {
      switch (type) {
        case 1:
          this.$toast({
            message: '成功发起签到',
            position: 'bottom'
          })
          break
        case 2:
          this.$toast({
            message: '签到成功',
            position: 'bottom'
          })
          break
      }
      this.showSign = false
      this.hasNotice = false //签到之后 标识未通知
    },
    // 隐藏表情包
    hideEmoji(e) {
      let tarList = e.target.classList
      let parentNode = e.target.parentNode
      let parentList = parentNode ? parentNode.classList : []
      let a = false,
        b = false //不带“emotion”
      // 自带“emotion”，不处理
      // 父级带“emotion”，不处理
      tarList.forEach(item => {
        if (item.includes('emotion')) a = true
      })
      parentList.forEach(item => {
        if (item.includes('emotion')) b = true
      })
      if (!a && !b) this.$refs.reply.showEmotion = false //自己 或者 父亲 都不带"emotion"的可隐藏表情包
    },
    // 打赏成功后
    rewarded(data) {
      this.showGratuity = false
    },
    // 语音评论
    afterAudioComment(res) {
      sendComment({
        classid: this.classId, //班圈id
        userid: this.userId, //用户id
        replymode: '2', //评论类型 [1-文本,2-语音]
        sec: res.duration, //语音时长(秒) ---> 接口报错???  <---
        // sec: 10, //语音时长(秒)
        mediaId: res.serverId //音频id
      })
        .then(data => {
          this.$toast('发送成功')
          this.$refs.comment.onRefresh()
        })
        .catch(err => this.$toast('音频发送失败'))
    },
    // 文本评论
    sendTextComment(info) {
      let params = {
        classid: this.classId, //班圈id
        userid: this.userId, //用户id
        content: info.content, //文本输入
        replymode: '1' //评论类型 [1-文本,2-语音]
      }
      sendComment(params).then(res => {
        this.$refs.comment.onRefresh()
        this.$refs.reply.init()
        this.cleanReplyTo()
      })
    },
    cleanReplyTo() {
      this.replyTo = {}
    },
    resetPayment() {
      this.$refs.payment.reset()
    },
    // 评论置顶
    toTop(info) {
      let msg = '该评论', //默认
        content = '',
        textArr = [],
        item = info.data
      if (item.replymode === '语音') {
        msg = `${item.name}的语音`
      } else {
        textArr = item.content.split('{+_+}')
        content = textArr.length > 1 ? textArr[1] : textArr[0]
        if (item.content.length < 10) {
          msg = content
        } else {
          msg = content.slice(0, 10) + '...'
        }
      }
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要将“${msg}”置顶到当前页？`
        })
        .then(() => {
          setTimeout(() => {
            this.$toast({
              message: '置顶成功',
              position: 'bottom'
            })
          }, 300)
          toTopReply(item.autoid).then(res => {
            this.$refs.comment.onRefresh()
          })
        })
    },
    onplay(info) {
      // 只有 看直播 或 看视频 的情况下才记录
      const { showmode, liveurl, videourl, lbcode } = info
      if (
        showmode === '直播' && liveurl != null && lbcode == 0 ||
        showmode === '录播' && videourl != null
      ) {
        greateCourseLog(info.autoid, this.stuId).then(res => {})
      }
    },
    beforeBack() {
      sessionStorage.removeItem('activeTag')
      sessionStorage.removeItem('curLessonId')
      sessionStorage.setItem('lastPage', this.$route.name) //classApply用到
    },
    // 微信客户端 右上角分享设置
    async shareSetting() {
      try {
        this.classInfo = await getOneClassinfo(this.classId)
      } catch (e) {}
      const { name, recommendA, headImg } = this.$store.state.user
      let shareIcon = '' // 设置 分享图标
      let describe = '' // 分享副标题
      if (this.classInfo.data) {
        shareIcon = this.classInfo.data[0].pic
        describe = this.classInfo.data[0].describe
      } else {
        shareIcon = res.headurl
      }
      let params = {
        title: this.title,
        desc: describe ? describe : `${name} 邀您加入班圈`,
        link: `${location.origin}/classDetails/${this.classId}?state=${
          this.classId
        }_${this.userId}_${recommendA ? recommendA : ''}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareIcon,
        success: () => {
          console.error('成功分享：朋友/朋友圈', params)
        },
        fail: e => {
          console.error('分享失败：朋友/朋友圈', e)
        }
      }
      //需在用户可能点击分享按钮前就先调用
      wx.ready(() => {
        wx.updateAppMessageShareData(params) // 分享给朋友
        setTimeout(() => {
          wx.updateTimelineShareData(params) // 分享到朋友圈
        }, 300)
      })
    },
    // 切换tab触发 目前只针对 作业|考试 是根据课程来展示
    tabsChange(v) {
      const rs = { 1: 'homework', 2: 'exam' } //映射关系
      if ([1, 2].includes(v)) {
        this.$nextTick(() => {
          let r = this.$refs[rs[v]]
          if (r && this.lessonInfo.autoid !== r.lastRequestLessonId) {
            // @todo 遗留问题 加载页面之后 立即 切换作业|考试页签 会发起2次请求
            r.onRefresh()
          }
        })
      } else {
        return
      }
    },

    // 直播转成录播.mp4格式
    live2Record(action, done) {
      // console.log('live2Record', { action, done })
      // done()
      // return
      if (action === 'cancel') {
        done()
      } else if (action === 'confirm') {
        this.$refs.l2rForm.validate().then(res => {
          saveRecord({
            courseid: this.lessonInfo.autoid,
            start: this.startTime,
            end: this.endTime
          })
            .then(res => {
              this.lessonInfo.lbcode = 1
              this.courseList[this.curLessonIdx].lbcode = 1
              this.$toast({
                message: '请求成功, 请耐心等待!',
                position: 'bottom'
              })
            })
            .finally(done)
        })
      }
    },
    // 结束时间校验
    endTimeValid(v) {
      // ui框架存在问题：如果第一次校验开始时间大于结束时间【校验不通过】，此时改小开始时间，再提交不会触发结束时间的校验，导致无法提交
      return v > this.startTime
    },
    // 展示开始时间选择器
    showStartTimePicker() {
      this.showStartTime = true
      this.startT = new Date(this.startTime.replace(/\-/g, '/'))
    },
    // 展示结束时间选择器
    showEndTimePicker() {
      this.showEndTime = true
      this.endT = new Date(this.endTime.replace(/\-/g, '/'))
    },
    // 选中开始时间
    selectStartPicker(v) {
      this.startTime = v && v.Format('yyyy-MM-dd hh:mm:ss')
      this.showStartTime = false
    },
    // 选中结束时间
    selectEndPicker(v) {
      this.endTime = v && v.Format('yyyy-MM-dd hh:mm:ss')
      this.showEndTime = false
    }
  },
  created() {
    getAuthority()
  },
  mounted() {
    this.getCourseList()
    this.upDateCommon() //定时获取最新评论
  },
  beforeDestroy() {
    this.cleanSignTimer()
    clearInterval(this.commentTimer)
    this.commentTimer = undefined
  }
}
</script>

<style lang="scss" scoped>
.free-comment > p {
  border-bottom: 1px solid #b2b4b7;
  padding-bottom: 2vw;
}
// // 公告样式
// .nopay {
//   opacity: 0.5;
// }
.stocks {
  width: 13.333vw;
  height: 5.333vw;
  background-image: linear-gradient(90deg, #17b3c1 0%, #47d6b6 100%),
    linear-gradient(#a2a6a7, #a2a6a7);
  background-blend-mode: normal, normal;
  border-radius: 0vw 2.667vw 0vw 2.667vw;
  font-size: 2.933vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;
}

.classroom_episode_flex {
  height: 12vw;
  padding: 0 2.5vw;
  background-color: #f5f7fa;
  border-radius: 1.067vw;
  display: inline-block;
  font-size: 3.733vw;
  margin-right: 2vw;
  line-height: 12vw;
  /*color: #247dff;*/
}
.classroom_episode_flex:last-child {
  margin-right: 0;
}

.lesson-on {
  color: $theme_color;
  box-sizing: border-box;
  border: 1px solid $theme_color;
}
.lesson-off {
  color: $active_color;
  box-sizing: border-box;
  border: 1px solid $active_color;
}

.classroom_episode {
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.classroom {
  // width: 93.6vw;
  // margin: 0 auto;
  .toTop {
    color: $font-gray;
  }
  .van-tabs {
    /deep/.van-sticky {
      .van-tabs__wrap {
        .van-tabs__nav {
          .van-tabs__line {
            z-index: 0;
          }
        }
      }
    }
  }
}

.course_introduce_box {
  border-bottom: 0.233vw solid #edeff2;
  padding-bottom: 7vw;
}

.extension {
  font-size: 3.467vw;
  color: #ff8c40;
  float: right;
  margin-top: -4vw;
}

.page {
  @include isTop;
  position: relative;
  padding-top: 69.6vw;
  @include wh(100vw, 100vh);
  .top_header {
    .right {
      .van-button {
        min-width: 4em;
        padding: 0;
      }
    }
  }
  .player {
    width: 100vw;
    height: 56.267vw;
    background-color: #c6c8cb;
    position: fixed;
    top: 13.333vw;
    left: 0;
    // z-index: 99;
    z-index: 1;
  }
  .classroom {
    /deep/.van-tabs {
      div {
        .van-sticky.van-sticky--fixed {
          top: 69.6vw !important;
        }
      }
    }
  }
  .dialog_box {
    top: 93.333vw;
  }
}
.page::-webkit-scrollbar {
  color: red;
  background: blue;
  // display: none;
}

// 简介
.course_introduce2 {
  .van-collapse-item {
    /deep/.van-cell {
      padding: 1vw 0;
      .van-cell__title {
        font-size: 3.467vw;
        color: #909399;
        flex: 1;
      }
      .van-cell__value {
        font-size: 3.467vw;
        flex: 0.5;
      }
    }
    /deep/.van-collapse-item__wrapper {
      .van-collapse-item__content {
        padding: 1vw 2vw;
        font-size: 3.2vw;
        letter-spacing: -0.133vw;
        color: #909399;
      }
    }
  }
}
/* 去除vant折叠面板的上下底边 */
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: 0 none;
}
// 课表
.course_timetable {
  padding: 0 4vw;
  padding-bottom: 3vw;
  h1 {
    @include font(4vw, 8vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      // display: flex;
      // align-items: center;
      color: orangered;
      font-size: 3.2vw;
      .van-icon {
        // padding-left: 0.667vw;
        // font-size: 3.6vw;
      }
    }
  }
  .course_timetable_content {
    display: flex;
    .course_timetable_2line {
      height: 20vw;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      height: 10vw;
      overflow: hidden;
      li {
        width: 8vw;
        height: 8vw;
        font-size: 3vw;
        line-height: 8vw;
        text-align: center;
        margin: 1vw;
        border-radius: 50%;
        background: #f2f2f2;
      }
      .course_active {
        background: #3f8eff;
        color: #fff;
      }
    }
    .course_timetable_showmore {
      width: 10vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .course_timetable_more_point,
      .course_timetable_more_txt {
        //··· && 更多
        display: block;
        width: 8vw;
        height: 8vw;
        margin: 1vw;
        line-height: 8vw;
        text-align: center;
        font-weight: bold;
        border-radius: 50%;
        background: #f2f2f2;
      }
      .course_timetable_more_txt {
        //更多
        background: #ffffff;
        font-weight: 400;
        font-size: 3vw;
      }
      .course_active {
        background: #3f8eff;
        color: #fff;
      }
    }
  }
}
// 弹出层 课程表
.course_timetable_list {
  padding-top: 6vw;
  margin-bottom: 3vw;
  height: calc(100% - 17vw);
  .course_timetable_header {
    height: 8vw;
    line-height: 8vw;
    font-size: 4vw;
    color: #ffffff;
    background: #3a96f2;
    text-align: center;
    position: relative;
    .van-icon {
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
  }
  .course_timetable_ul {
    padding: 3.5vw 3vw 0 4vw;
    height: 100%;
    overflow-y: scroll;
    .course_timetable_li {
      background: #f6f6f6;
      padding: 3vw;
      margin-bottom: 3vw;
      .course_timetable_li_div {
        color: #3a96f2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3vw;
        margin-bottom: 4vw;
        .van-icon,
        em {
          width: 4vw;
          height: 4vw;
          font-size: 4vw;
          margin-right: 2vw;
          font-style: normal;
        }
        .course_timetable_li_right {
          flex: 1;
        }
      }
      .course_timetable_li_div:first-child {
        .course_timetable_li_right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .course_timetable_li_div:last-child {
        margin-bottom: 0;
        color: #909399;
        .course_timetable_li_right {
          display: flex;
          justify-content: space-between;
          > span {
            flex: 1;
          }
        }
      }
    }
  }
}

.course_introduce_title {
  min-height: 5vw;
  padding: 0.267vw 0;
  @include font(4.533vw, 4.8vw);
  color: #303133;
  font-weight: bold;
  padding-right: 5vw;
  position: relative;
  .van-icon {
    font-size: 4vw;
    line-height: 4.8vw;
    // @include font(4.533vw, 4.8vw);
  }
  .tag {
    margin-left: 0.667vw;
    display: inline-block;
    font-size: 2.4vw;
    line-height: normal;
  }
  .gratuity {
    background-color: #ff7200;
    @include wh(5vw, 5vw);
    @include font(3vw, 5vw);
    text-align: center;
    font-style: normal;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    color: #fff;
  }
}
/* end -> by:yyz */

.course_introduce {
  width: 93.6vw;
  margin: 2vw auto;
  margin-bottom: 0;
  line-height: 6.4vw;
}
</style>
