<template>
  <div class="page">
    <topheader lucency title ref="head" />
    <div class="board" :class="{showBackground: finish}">
      <div class="class_type">
        <!-- <img :src="classUrl" alt /> -->
        <div class="classify">
          <span v-text="classify"></span>
        </div>
        <div class="classify_pinyin">
          <span></span>
          <span v-text="classifyPinyin"></span>
          <span></span>
        </div>
      </div>
      <div class="student_name" v-text="name"></div>
      <!-- 阿米巴经营系统班 -->
      <div class="content" v-if="type != 4">
        <p>
          祝贺你圆满完成“
          <span v-text="className"></span>”课程，并顺利通过结业考试，特发此证，以此鼓励！
        </p>
      </div>
      <!-- 复合咨询 -->
      <div class="content" v-else>
        <p>
          祝贺你圆满完成“「
          <span v-text="className"></span>」”课程，并顺利通过结业考试，特发此证，以此鼓励！
        </p>
      </div>
      <div class="graduation_date" v-text="graduationTime"></div>
    </div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import { getCertificate } from '@/api/my'
import { pinyin } from '@/assets/js/public'
export default {
  data() {
    return {
      stuId: this.$route.query.stuId,
      className: this.$route.query.className,
      name: this.$store.state.user.name,
      graduationTime: '',
      type: 0, // 0-阿米巴经营系统班 1-复合咨询
      classify: '', //班圈分类
      classifyPinyin: '',
      finish: false //请求成功
      // fhzx: require('@/assets/images/fhzx.png'),
      // xtb: require('@/assets/images/xtb.png')
    }
  },
  // computed: {
  //   classUrl() {
  //     switch (this.type) {
  //       case 0:
  //         return this.xtb
  //         break
  //       case 1:
  //         return this.fhzx
  //         break
  //     }
  //   }
  // },
  methods: {
    init() {
      getCertificate({ stuid: this.stuId }).then(res => {
        this.finish = true
        let master = res.master[0]
        if (master) {
          this.classify = master.groupname
          this.classifyPinyin = pinyin.getFullChars(this.classify).toUpperCase()
          this.classname = master.classname
          this.type = master.groupid
          this.graduationTime = new Date(
            master.crdate.replace(/-/g, '/')
          ).Format('yyyy年MM月dd日')
        } else {
          return
        }
      })
    },
    // 截屏1：1会不清晰，放大2倍又会超出视口导致截屏不全，先不用
    canvasDom() {
      const target = document.getElementsByClassName('page')[0],
        shareContent = document.getElementsByClassName('board')[0],
        head = this.$refs.head.$el,
        width = shareContent.offsetWidth,
        height = shareContent.offsetHeight,
        scale = 2,
        canvas = document.createElement('canvas')
      canvas.width = width * scale
      canvas.height = height * scale
      canvas.getContext('2d').scale(2, 2)
      html2canvas(target, {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        background: '#fff',
        scale: scale,
        width: width,
        height: height,
        canvas: canvas
      }).then(canv => {
        this.canvas = true
        const image = new Image()
        image.src = canv.toDataURL('image/png')
        target.innerHTML = ''
        this.$nextTick(() => {
          target.appendChild(head)
          target.appendChild(image)
        })
      })
    }
  },
  created() {
    this.init()
  },
  mounted() {
    // this.canvasDom()
    // 按比例缩放
    const ww = window.innerWidth
    const wh = window.innerHeight
    const bw = document.getElementsByClassName('board')[0].offsetWidth
    const bh = document.getElementsByClassName('board')[0].offsetHeight
    let ratio = 1,
      rH = wh / bh,
      rW = ww / bw
    ratio = rW < rH ? rW : rH
    document.getElementsByClassName(
      'board'
    )[0].style.transform = `translate(-50%, -50%) scale(${ratio})`
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include wh(100%, 100%);
  position: relative;
  /deep/img {
    width: 100%;
  }
  .showBackground {
    background: url('../../assets/images/graduation_bg.jpg') no-repeat;
  }
  .board {
    @include wh(375px, 603px);
    @include center;
    // background: url('../../assets/images/graduation_bg.jpg') no-repeat;
    background-size: 100% 100%;
    font-family: '思源黑体';
    .class_type {
      @include wh(135px, 30px);
      text-align: center;
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      // img {
      //   @include wh(100%, 100%);
      // }
      font-family: '庞门正道标题体';
      color: #666;
      position: relative;
      padding-top: 2px;
      box-sizing: border-box;
      // 班圈分类
      .classify {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          background: #fff;
          z-index: 1;
        }
      }
      // 班圈分类拼音
      .classify_pinyin {
        width: 100%;
        width: 150%;
        color: $font-gray;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%) scale(0.65);
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          flex: 1;
        }
        span:nth-child(2) {
          padding: 0 4px;
        }
        span:first-child,
        span:last-child {
          height: 12px;
          position: relative;
        }
        span:first-child::before,
        span:first-child::after,
        span:last-child::before,
        span:last-child::after {
          content: '';
          display: block;
          width: 120%;
          border: 1px solid #999;
          position: absolute;
        }
        span:first-child::before {
          bottom: 2px;
          right: 0;
        }
        span:first-child::after {
          top: 4px;
          right: 0;
          width: 60%;
        }
        span:last-child::before {
          bottom: 2px;
          left: 0;
        }
        span:last-child::after {
          top: 4px;
          left: 0;
          width: 60%;
        }
      }
    }
    .student_name {
      position: absolute;
      top: 226px;
      left: 130px;
      width: 70px;
      line-height: 16px;
      text-align: center;
    }
    .content {
      @include wh(100%, 100px);
      position: absolute;
      top: 250px;
      left: 0;
      p {
        width: 100%;
        padding: 0 40px;
        text-indent: 2em;
        box-sizing: border-box;
        @include center;
        @include font(16px, 30px);
        font-family: '思源黑体';
      }
    }
    .graduation_date {
      position: absolute;
      top: 495px;
      right: 65px;
      font-size: 12px;
      transform: scale(0.65);
      font-family: '思源黑体';
    }
  }
}
</style>