import request from '@/util/request';
// import { getOpenId } from "@/util/auth";
// import Store from "@/store"

// console.log("Store=", Store)//@toCheck

// 问答相关

// // 获取专家列表
// export function getExpertList(key,page) {
//   const isLogin = Store.state.user.userId ? 1 : 0;
//   const limit = 15;
//   return request.get('/professor/search',{key,limit,page,isLogin},false)
// }

// // 获取专家信息
// export function getExpertInfo(professorId) {
//   return request.get('/professor/introduction',{professor_id:professorId})
// }

// // 获取专家发布的课程
// export function getExpertCourse(professorId,page) {
//   const limit = 10;
//   const type = 4;
//   return request.get('/lesson/recordIndex',{professor_id:professorId,limit,page,type})
// }

// // 获取专家回答列表
// export function getExpertAnswer(key, professorId, page) {
//   const limit = 10;
//   return request.get('/Question/search', { key, professor_id: professorId, limit, page }, false)
// }

// // 用户提问
// export function quiz(professorId, message) {
//   return request.post('/Question/add', {
//     data: {
//       professor_id: professorId,
//       question_title: message
//     },
//     tips: '发布中'
//   })
// }

// // 获取问题详情
// export function questionInfo(questionId) {
//   return request.get('/question/detail', { question_id: questionId })
// }

// // 改变关注状态
// export function changeFocus(professorId) {
//   return request.post('/fans/changeFocus',{data:{professor_id:professorId}})
// }









/* ------------ 二次开发：start(by:yyz) ------------ */

// 获取专家列表
export function getTeacherList(userid, curpage) {
  return request.post('/Server/GetTeacherList', {
    data: { userid, curpage, size: 10 }
  })
}

// 学员收听专家/取消收听
export function changeFocus(userid, fouserid, type) {
  return request.post('/Server/FollowTeacher', {
    data: { userid, fouserid, type }
  })
}

// 获取专家信息
export function getOneTeacherinfo(userid, autoid) {
  return request.post('/Server/GetOneTeacherinfo', {
    data: { userid, autoid }
  })
}

// 获取专家发布的课程
export function getTeaClassList(autoid, curpage) {
  return request.post('/Server/GetTeaClassList', {
    data: { autoid, curpage, size: 10 }
  })
}

// 获取专家回答列表
export function getFAQList(params) {
  const { teaid, curpage, orderby } = { params }
  params.size = 10
  return request.get('/Server/GetFAQList', params, false)
}

// 用户提问
export function createFAQ(userid, teaid, content) {
  return request.post('/Server/CreateFAQ', {
    data: { userid, teaid, content }
  })
}

// 获取问答及其评论
export function getFAQAndReplyList(autoid, userid) {
  return request.post('/Server/GetFAQAndReplyList', {
    data: { autoid, userid }
  })
}

// 发表评论
export function createFAQReply(params) {
  let { userid, mautoid, content, replymode, sec, mediaId } = params;
  return request.post('/Server/CreateFAQReply', {
    data: { userid, mautoid, content, replymode, sec, mediaId }
  })
}

// 问题置顶
export function toTopFAQ(autoid) {
  return request.get('/Server/TopFAQ', { autoid })
}

// 问题评论置顶
export function toTopFAQReply(autoid) {
  return request.get('/Server/TopFAQReply', { autoid })
}

/* ------------ 二次开发：end(by:yyz)   ------------ */