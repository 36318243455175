<template>
  <div class="main">
    <div class="cell" v-for="(item, idx) in list" :key="idx" @click="act(item, idx)">
      <div class="cell_left">
        <div class="cell_left_box" v-if="item.icon">
          <van-icon name="bell" :style="{color: item.color}" v-if="item.type === 'iconfont'" />
          <img :src="item.icon" class="cell_left_img" v-else />
        </div>
        {{item.label}}
      </div>
      <div class="cell_right_box">
        <span v-if="item.value" :class="{dot: item.valueDot}" v-text="item.value"></span>
        <img :src="arrow_right" class="arrow_right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arrow_right: require('@/assets/images/rightarrows.png')
    }
  },
  props: {
    // 格式 {icon: '', label: '', route: '', value: '' }
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    act(info, idx) {
      //点击事件
      if (!info.route) {
        this.$emit('action', { info, idx })
      } else {
        this.$router.push({ path: info.route })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  color: #303133;
  .cell {
    width: 90vw;
    height: 13vw;
    padding: 0 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .cell_left {
      display: flex;
      align-items: center;
      .cell_left_box {
        width: 8vw;
        position: relative;
        .cell_left_img {
          width: 5.733vw;
          height: 5.867vw;
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-50%);
          object-fit: contain;
        }
        .van-icon {
          font-size: 5.333vw;
        }
      }
    }
    .cell_right_box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.933vw;
      color: $font-gray;
      .dot {
        position: relative;
        padding-left: 4vw;
      }
      .dot::after {
        content: '';
        display: block;
        @include wh(1.333vw, 1.333vw);
        @include borderRadius(50%);
        background: $warn-color;
        position: absolute;
        top: 50%;
        left: 1.333vw;
        transform: translateY(-50%);
      }
      .arrow_right {
        width: 1.333vw;
        height: 2.4vw;
        margin-left: 5.333vw;
      }
    }
  }
}
</style>
