<template>
  <div class="attention_page">
    <topheader />
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="attention" v-for="(item,i) in list" :key="'fans'+i">
          <div class="attention_left">
            <img v-if="item.headurl" :src="item.headurl" alt />
            <img v-else src="@/assets/images/head_prole.png" alt />
          </div>
          <div class="attention_right">
            <span class="attention_right_span">{{item.name}}</span>
            <span class="attention_right_daoshi">{{item.is_professor===0?'学员':'导师'}}</span>
          </div>
        </div>
        <div class="no-data" v-if="list.length===0">暂无粉丝</div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyFans } from '@/api/my'

export default {
  name: 'fans',
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyFans(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.pullRefresh = false
          this.error = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.attention_page {
  @include isTop;
  .attention {
    width: 90%;
    height: 23.733vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attention_left {
      width: 15.733vw;
      height: 15.733vw;
      border-radius: 100%;
      background-color: #c6c8cb;
      overflow: hidden;
      box-shadow: $box-shadow;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .attention_right {
      height: 23.733vw;
      width: 71.6vw;
      border-bottom: 0.133vw solid #edeff2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .attention_right_span {
        color: #303133;
      }
      .attention_right_daoshi {
        color: #bfc2cc;
        font-size: 3.6vw;
      }
    }
    .no-data {
      margin-top: 20vw;
      text-align: center;
      color: #909399;
    }
  }
}
</style>
