import request from '@/util/request';
import {getOpenId} from "@/util/auth";

// // 获取验证码
// export function getPIN(tel, type=2) {
//   return request.post('/member/sendSms',{
//     data:{mobile:tel,type},
//     isLoading:false,
//   })
// }

// // 注册
// export function register(tel, pw, pin, key) {
//   return request.post('/member/register',{
//     data:{
//       register_phone:tel,
//       password:pw,
//       code:pin,
//       key
//     }
//   })
// }

// // 忘记密码
// export function forget(pw,pin,key) {
//   return request.post('/Member/reset',{
//     data:{
//       code:pin,
//       key,
//       password:pw,
//     }
//   })
// }










/* ---------------- 二次开发：start(by:yyz) ---------------- */

  // 短信验证码
  export function getPIN(tel, type) { // type 1:注册，2，忘记密码
    return request.post('Server/BingingOfMessageCheck',{
      data:{tel, type},
      isLoading:false,
    })
  }

  // 注册
  export function register(name, tel, pwd, company = '', comaddress = '', job = '', sex = '') {
    return request.post('/Server/ReUser', {
      data:{
        name,                 // 姓名
        tel,                  // 电话
        openid: getOpenId(),  // 微信openid
        pwd,                  // 密码（6-11）位
        company,              // 公司名
        comaddress,           // 公司地址
        job,                  // 职务
        sex,                  // 性别
      }
    })
  }

  // 忘记密码
  export function updatePwd(oldpwd,newpwd,userid) {
    return request.post("/Server/UpdatePwd", {
      data: {
        oldpwd,
        newpwd,
        userid
      }
    })
  }

  // 重置密码
  export function resetPwd(tel,pwd) {
    return request.post("/Server/ResetPwd", {
      data: {
        tel,
        pwd
      }
    })
  }
/* ---------------- 二次开发：end(by:yyz)   ---------------- */ 