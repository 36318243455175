<template>
  <!-- 编辑公告 -->
  <div class="page">
    <topheader />
    <div class="newAnnoundance">
      <div class="class_name">
        <span>所属班圈：</span>
        <span v-text="className"></span>
      </div>
      <!-- <van-form scroll-to-error validate-first ref="form" @submit="onSubmit"> -->
      <van-form validate-first ref="form">
        <!-- 标题 -->
        <van-field
          v-model="newAnnouncement.title"
          name="title"
          rows="1"
          autosize
          label="标题："
          label-width="5em"
          placeholder="请输入..."
          type="textarea"
          required
          class="announdance_title"
          :disabled="loading"
          :rules="[{required: true, message: '请输入标题'}]"
        />
        <!-- 描述 -->
        <van-field
          v-model="newAnnouncement.describe"
          name="describe"
          rows="2"
          autosize
          label="描述："
          label-width="5em"
          placeholder="请输入..."
          type="textarea"
          required
          class="announdance_desc"
          :disabled="loading"
          :rules="[{required: true, message: '请输入描述'}]"
        />
        <!-- 封面 -->
        <van-field label="封面图片：" label-width="5em" name="coverImage" class="announdance_cover">
          <template #input>
            <div class="cover_box">
              <div class="announdance_cover_image">
                <template v-if="coverImage">
                  <img :src="coverImage" alt />
                  <van-icon name="clear" @click="clearCoverImage" />
                </template>
                <van-icon name="photograph" v-else />
                <input
                  class="update-img"
                  type="file"
                  accept="image/*"
                  :disabled="loading"
                  @change="uploadCoverImg"
                />
              </div>
            </div>
          </template>
        </van-field>
        <!-- 文本内容 -->
        <van-field
          v-model="newAnnouncement.contentText"
          name="contentText"
          rows="2"
          label="文本内容："
          label-width="5em"
          autosize
          type="textarea"
          placeholder="公告文字内容"
          class="announdance_content_text"
          :disabled="loading"
        />
        <!-- 文本附图 -->
        <van-field
          name="contentImage"
          label="附图："
          label-width="5em"
          class="announdance_content_image"
        >
          <template #input>
            <div class="content_image">
              <template v-if="contentImage">
                <img :src="contentImage" alt />
                <van-icon name="clear" @click="clearContentImage" />
              </template>
              <van-icon name="photograph" v-else />
              <input
                class="update-img"
                type="file"
                accept="image/*"
                :disabled="loading"
                @change="uploadContentImg"
              />
            </div>
          </template>
        </van-field>
        <!-- 作者 -->
        <van-field
          v-model="newAnnouncement.author"
          name="author"
          rows="2"
          label="作者:"
          label-width="5em"
          placeholder="请输入..."
          class="announdance_content_text"
          :disabled="loading"
        />
        <!-- <div class="announdance_author">
          <van-field v-model="newAnnouncement.author" placeholder="请输入..." label="作者:" />
        </div>-->
        <div class="btn">
          <van-button
            block
            type="info"
            native-type="submit"
            loading-text="正在发布..."
            :loading="loading"
            :disabled="loading"
            @click="onSubmit"
          >立即发布</van-button>
        </div>
      </van-form>
      <SuccessDialog
        title="发布成功"
        cancelText="返回上一页"
        confirmText="继续发布"
        :show="show"
        @confirm="successCallback"
      />
    </div>
  </div>
</template>
<script>
import { editeNoticeinfo } from '@/api/my'
import * as qiniu from 'qiniu-js'
import { getQiniuToken } from '@/api/qiniuUpload'
import SuccessDialog from '@/components/extend/successDialog'

export default {
  components: { SuccessDialog },
  data() {
    return {
      className: this.$route.query.className,
      coverImage: undefined, //封面图片（新增-暂存）
      contentImage: undefined, //内容图片（新增-暂存）
      newAnnouncement: {
        classid: this.$route.query.classId,
        type: 9, //新建
        sqnum: 99, //序号
        autoid: '', //公告记录id(新增不用)
        title: '', //标题
        pic: '', //封面图
        describe: '', //描述
        author: this.$store.state.user.name, //作者
        contentText: '', //文字内容
        contentImage: '', //图片内容
        content: '' // contentText + contentImage
      },
      show: false, //是否展示成功confirm
      loading: false //正在请求
    }
  },
  methods: {
    // 初始化公告内容
    initNewAnnouncement() {
      this.newAnnouncement = Object.assign(
        {},
        {
          classid: this.classId,
          type: 9, //新建
          sqnum: 99, //序号
          autoid: '', //公告记录id(新增不用)
          title: '', //标题
          pic: '', //封面图
          describe: '', //描述
          author: this.$store.state.user.name, //作者
          contentText: '', //文字内容
          contentImage: '', //图片内容
          content: '' // contentText + contentImage
        }
      )
      this.coverImage = undefined
      this.contentImage = undefined
    },
    // 赋值封面图
    async uploadCoverImg(e) {
      const file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      this.newAnnouncement.pic = file
      reader.onload = e => {
        this.coverImage = e.target.result
      }
    },
    // 赋值内容图片（data 顺带返回 base64 ）
    uploadContentImg(e) {
      const file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      this.newAnnouncement.contentImage = file
      reader.onload = e => {
        this.contentImage = e.target.result
      }
    },
    // 清楚封面图片
    clearCoverImage() {
      if (this.loading) {
        return '提交状态下禁止修改'
      } else {
        this.coverImage = undefined
      }
    },
    // 清除内容附图
    clearContentImage() {
      if (this.loading) {
        return '提交状态下禁止修改'
      } else {
        this.contentImage = undefined
      }
    },
    // 发起新增公告接口
    async add() {
      // 上传图片
      try {
        if (this.newAnnouncement.pic) {
          this.newAnnouncement.pic = await this.uploadFile(
            this.newAnnouncement.pic
          )
          // this.$dialog.alert({
          //   message: '封面图片上传成功'
          // })
        }
        if (this.newAnnouncement.contentImage) {
          this.newAnnouncement.contentImage = await this.uploadFile(
            this.newAnnouncement.contentImage
          )
          // this.$dialog.alert({
          //   message: '内容图片上传成功'
          // })
        }
      } catch (e) {
        console.error('公告图片上传失败', e)
        this.$toast({
          message: JSON.stringify(e),
          duration: 5000
        })
        this.loading = false
        return new Promise((resolve, reject) => {
          reject(e)
        })
      }
      // 上传数据
      const txt = this.newAnnouncement.contentText
      const image = this.newAnnouncement.contentImage
      this.newAnnouncement.content = `<p style="text-indent:2em;">${txt}</p>`
      if (image) {
        this.newAnnouncement.content += `<img src="${image}" style="width: 100%;"/>`
      }
      this.newAnnouncement.content = this.newAnnouncement.content
      return editeNoticeinfo(this.newAnnouncement)

      // //编辑器内容加盖
      // function htmlEncode(str) {
      //   let ele = document.createElement('span')
      //   ele.appendChild(document.createTextNode(str))
      //   return ele.innerHTML
      // }
      // //脱盖
      // function Escape2Html(str) {
      //   var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      //   return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
      //     return arrEntities[t]
      //   })
      // }
    },
    // 上传附件
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        // 获取七牛云token
        getQiniuToken()
          .then(res => {
            let that = this
            const baseUrl = res.data.url
            const token = res.data.token
            const stamp = Date.parse(new Date()) //加上时间戳 避免文件名字相同，导致无法再上传
            const key = file.name + stamp
            const putExtra = {
              fname: file.name + stamp,
              params: {}
              // mimeType: ['word', 'txt']
            }
            // 监听
            const observer = {
              next(res) {
                // that.currentRate = res.total.percent
              },
              error(err) {
                console.error('上传失败：', err)
                reject(err)
              },
              complete(res) {
                const url = baseUrl + res.key
                resolve(url)
              }
            }
            const config = { useCdnDomain: true }
            const observable = qiniu.upload(file, key, token, putExtra, config)
            const subscription = observable.subscribe(observer)
          })
          .catch(err => {
            console.error('获取七牛云token失败')
            reject(err)
          })
      })
    },
    //
    beforeSubmit() {
      if (!this.newAnnouncement.title) {
        this.$dialog.alert({
          title: '错误提示',
          message: '请补充公告标题'
        })
      } else if (!this.newAnnouncement.describe) {
        this.$dialog.alert({
          title: '错误提示',
          message: '请补充公告描述'
        })
      }
    },
    // 新建公告
    onSubmit() {
      this.loading = true
      this.$refs.form
        .validate()
        .then(res => {
          // if (res && res.length) {
          //   this.loading = false
          //   return '校验不通过，停留在当前页'
          // } else if (res === undefined) {
          //   this.add().then(res => {
          //     this.show = true
          //     this.loading = false
          //   })
          // }
          this.add().then(res => {
            this.show = true
            this.loading = false
          })
        })
        .catch(err => {
          this.beforeSubmit()
          this.loading = false
        })
    },
    successCallback() {
      this.initNewAnnouncement()
      this.show = false
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
@mixin image {
  @include wh(100%, 26.667vw);
  background: $bg-light;
  max-height: 40vw;
  border: 0.133vw solid $border-light;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  position: relative;
  .van-icon-photograph {
    @include center;
    font-size: 6vw;
    color: $font-gray;
  }
  img {
    @include wh(100%, 100%);
    object-fit: contain;
  }
  .van-icon-clear {
    position: absolute;
    top: -2vw;
    right: -2vw;
    font-size: 4vw;
    color: $font-gray;
  }
  .update-img {
    width: calc(100% - 0.5em);
    height: calc(100% - 0.5em);
    @include center;
    opacity: 0;
  }
}
.page {
  @include isTop;
  .newAnnoundance {
    border-top: 0.133vw solid $border-light;
    font-size: 3.2vw;
    box-sizing: border-box;
    overflow-y: scroll;
    .class_name {
      background: $bg-gray;
      @include font(3.2vw, 6.4vw);
      padding: 0 2.667vw;
      span:first-child {
        color: $active-color;
      }
      span:nth-child(2) {
        color: black;
      }
    }
    // 标题, 描述
    /deep/.announdance_title,
    /deep/.announdance_desc {
      border-bottom: $border-light;
      .van-cell__value {
        border: 0.133vw solid $border-light;
        padding: 0 0.8vw;
      }
    }

    // 封面
    .announdance_cover {
      margin-top: 1.333vw;
      .cover_box {
        @include wh(100%, 100%);
      }
      .announdance_cover_image {
        @include image;
      }
    }
    // 文本内容
    .announdance_content_text {
      /deep/.van-cell__value {
        border: 0.133vw solid $border-light;
        padding: 0 0.8vw;
      }
    }
    // 内容附图
    .announdance_content_image {
      .content_image {
        @include image;
      }
    }
    // 作者 -- 暂时作废
    .announdance_author {
      display: flex;
      justify-content: flex-end;
      /deep/.van-cell {
        padding: 1.333vw 0 0 0;
        width: 45%;
        border-bottom: 1px solid $border-light;
        font-size: 3.2vw;
        .van-cell__title {
          width: 3em;
          color: $font-gray;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              text-align: center;
            }
          }
        }
      }
    }
    // 提交按钮
    .btn {
      padding: 0.667vw 3.2vw;
      @include wh(100%, 13.333vw);
      box-sizing: border-box;
    }
  }
}
</style>