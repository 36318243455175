<!-- 课程详情>>>作业 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
      >
        <div class="practiceTests" v-for="(item, i) in list" :key="'practiceTest' + i">
          <div class="bigbox">
            <div class="tobecompleted" @click="goToQuestionDetail(item, $event)">
              <div class="tobecompleted_title">
                <span class="tag" v-text="item.coursename">专项突破 巩固夯基</span>
                <span class="deadline" v-if="item.ed">
                  {{
                  item.ed | timeStamp | dateFormat('yyyy-MM-dd hh:mm')
                  }}
                  截止答题
                </span>
              </div>

              <div class="tobecompleted_msg">
                <!-- <div class="tobecompleted_msg_title">
                  <span>{{ item.title }}</span>
                  <span>{{ item.panum }}人已练习</span>
                </div>-->
                <div class="tobecompleted_msg_box">
                  <div class="tobecompleted_msg_left">
                    <p class="tobecompleted_msg_headline">
                      <!-- <span v-html="item.content"></span> -->
                      <span>{{ item.title }}</span>
                    </p>
                    <div class="tobecompleted_teacher">
                      <span>{{ item.classownername2 || item.classownername1 }}</span>
                      <span v-if="item.ischeck" v-text="`得分：${item.score}/${item.totalscore}`"></span>
                    </div>
                  </div>

                  <div class="tobecompleted_msg_right">
                    <div class="tobecompleted_msg_title">
                      <span>{{ item.panum }}人已练习</span>
                    </div>

                    <template v-if="stuId">
                      <template>
                        <div v-if="item.asnum !== item.qscount && !item.isend" class="peugeot">待完成</div>
                        <div
                          v-if="item.asnum == item.qscount && !item.isend"
                          class="peugeot peugeot_submit"
                        >待提交</div>
                        <div v-else-if="item.isend && !item.ischeck" class="peugeot peugeot_on">待阅卷</div>
                        <div
                          v-else-if="item.isend && item.ischeck"
                          class="peugeot peugeot_scolling"
                        >已评分</div>
                      </template>

                      <div class="right_peugeot" v-if="!item.isend && inVaildDate(item.ed)">
                        <span class="peugeot_star">{{ item.asnum }}</span>
                        <span class="peugeot_star_two">/{{ item.qscount }}</span>
                      </div>

                      <div class="show_score" v-else @click.stop="showScore(item)">
                        {{
                        item.examination_done === item.examination_total &&
                        opentScoreList.includes(item.hwplanid)
                        ? '收起'
                        : '展开'
                        }}
                      </div>
                    </template>

                    <template v-else>
                      <div class="right_peugeot">
                        <span class="peugeot_star" v-text="item.flnum"></span>
                        <span class="peugeot_star_two" v-text="`/${total}`"></span>
                      </div>
                      <div class="show_score">
                        <span
                          class="toTop"
                          v-if="(userId == headmasterId || userId == supervisorId) && i != 0"
                          @click="hwToTop(item)"
                        >置顶 /</span>
                        <span @click.stop="showScore(item)">
                          {{
                          item.examination_done === item.examination_total &&
                          opentScoreList.includes(item.hwplanid)
                          ? '收起'
                          : '展开'
                          }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- 后期可以加个 动画过渡 -->
            <!-- 分数列表 -->
            <div class="score_list" v-if="opentScoreList.includes(item.hwplanid) && item.scoreList">
              <van-row class="score_list_head">
                <van-col span="6">排名</van-col>
                <van-col span="6">姓名</van-col>
                <van-col span="6">等级</van-col>
                <van-col span="6">分数</van-col>
              </van-row>
              <van-row class="score_list_body" v-for="(it, idx) in item.scoreList" :key="idx" @click.native="goToScoreList(it, item)">
                <van-col
                  span="6"
                  v-text="it.rank != null ? `NO.${it.rank < 10 ? '0' + it.rank : it.rank}` : '--'"
                ></van-col>
                <van-col span="6">{{ it.name }}</van-col>
                <van-col span="6" v-text="it.grade ? it.grade : '--'"></van-col>
                <!-- <van-col span="6" :class="scoreRank(it.grade)" @click="goToScoreList(it, item)"> -->
                <van-col span="6" :class="scoreRank(it.grade)">
                  {{ it.score != null ? `${it.score}分` : '--' }}
                  <van-icon name="arrow" v-if="item.recordid === it.recordid" />
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  getHomeworkList,
  createHWkRecord,
  getStuHWRecords,
  toTopHomework
} from '@/api/classRing'

export default {
  props: {
    type: String, //类型 1-作业 2-考试
    classId: String, //班圈id
    lessonId: Number, //课程id
    stuId: Number, //学员id
    headmasterId: Number, //班主任id
    supervisorId: Number, //导师id
    isClassTeacher: Boolean //是否当前班圈的课程班主任/导师
  },
  data() {
    return {
      list: [],
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      opentScoreList: [], // 已完成 作业/考试 并要展示分数列表的集合
      userId: this.$store.state.user.userId, //
      scoreList: [],
      total: 0, //班圈总人数
      lastRequestLessonId: null //上一次请求接口的课程id
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
        this.pullRefresh = true
        this.getHomeworkList()
      })
    },
    // 底部加载
    getHomeworkList() {
      getHomeworkList(this.classId, this.stuId, this.lessonId, false)
        .then(res => {
          this.loading = false
          this.list = res.list
          this.pullRefresh = false
          this.finished = true
          this.total = res.sumnum || 0 //只有班主任/导师查看才会返回班圈总人数
        })
        .catch(() => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
        .finally(() => {
          this.lastRequestLessonId = this.lessonId //记录上一次请求的课程id（用于父组件判断是否需要刷新数据）
        })
    },
    // 要展示分数列表的集合
    showScore(info) {
      if ((this.stuId && info.isend) || (!this.stuId && info.flnum)) {
        const id = info.hwplanid
        if (this.opentScoreList.includes(id)) {
          this.opentScoreList = this.opentScoreList.filter(item => item !== id)
        } else {
          if (!this.list.find(it => it.hwplanid == id).scoreList) {
            getStuHWRecords(id).then(res => {
              this.opentScoreList.push(id)
              this.list.forEach(item => {
                if (item.hwplanid == id) {
                  let arr = res.list.sort((a, b) => a.rank < b.rank) //排序
                  item.scoreList = arr
                }
              })
            })
          } else {
            this.opentScoreList.push(id)
          }
        }
      } else {
        this.$toast({
          message: '暂无学员提交记录',
          position: 'bottom'
        })
      }
    },
    // 判断有效日期是否大于当天
    inVaildDate(d) {
      let t = new Date(d.replace(/-/g, '/'))
      let dateTime = t.setDate(t.getDate() + 1)
      return new Date() <= dateTime
    },
    // 获取分数线对应的颜色
    scoreRank(grade) {
      switch (grade) {
        case '优':
          return 'score_green' //优
        case '良':
          return 'score_orange' //良
        case '中':
          return 'score_red' //中
        default:
          return 'score_gray' //4 - 差
      }
    },
    //
    goToScoreList(scoreInfo, homeworkInfo) {
      this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
      // 班主任 | 导师 | 辅导员 或者 自己的作业
      if (this.isClassTeacher || scoreInfo.recordid == homeworkInfo.recordid) {
        this.$router.push({
          path: '/homeworkQuestionDetail',
          query: {
            id: homeworkInfo.hwplanid, //作业id
            recordid: scoreInfo.recordid, //记录id(关系id)
            lastqsid: scoreInfo.lastqsid, //上次提交答题id
            total: homeworkInfo.qscount, //试题总数
            title: homeworkInfo.title, //标题
            active: this.active,
            curLessonIdx: this.curLessonIdx,
            isend: 1, //已经提交 (班主任可查看到已交卷的学员答题)
            overdue: 0, //不区分过期
            headmasterId: this.headmasterId, //班主任userid
            supervisorId: this.supervisorId, //导师userid
            isClassTeacher: this.isClassTeacher,
            studentName: scoreInfo.name, //学员姓名
            beginTime: homeworkInfo.bd, //作业开始时间
            endTime: homeworkInfo.ed, //作业结束时间
            lessonName: homeworkInfo.coursename
          }
        })
      }
    },
    // ->测试练习做题页面
    goToQuestionDetail(info, e) {
      console.log(123, JSON.parse(JSON.stringify(info)))
      const classNames = [...new Set(e.target.classList)]
      if (classNames.includes('toTop')) {
        return '作业置顶'
      } else {
        this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
        const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))
        // // 学员 @todo 后期需开放 逾期未参加 仍能查看题目的功能
        // if (overdue && !info.lastqsid) {
        //   this.$toast('答题结束，未作答，暂无权限查看')
        //   return
        // } else {
        //   if (this.stuId) {
        //     //学员(有参与过答题)
        //     if (info.recordid && info.lastqsid) {
        //       // 平时查看作业
        //       this.$router.push({
        //         path: '/homeworkQuestionDetail',
        //         query: {
        //           id: info.hwplanid, //作业id
        //           recordid: info.recordid, //记录id(关系id)
        //           lastqsid: info.lastqsid, //上次提交答题id
        //           total: info.qscount, //试题总数
        //           title: info.title, //标题
        //           active: this.active,
        //           curLessonIdx: this.curLessonIdx,
        //           isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
        //           overdue: overdue ? 1 : 0, //是否过期
        //           headmasterId: this.headmasterId, //班主任userid
        //           supervisorId: this.supervisorId, //导师userid
        //           beginTime: info.bd, //作业开始时间
        //           endTime: info.ed, //作业结束时间
        //           lessonName: info.coursename
        //         }
        //       })
        //     } else {
        //       // 创建答题记录（首次查看作业）
        //       createHWkRecord(info.hwplanid, this.stuId).then(res => {
        //         this.$router.push({
        //           path: '/homeworkQuestionDetail',
        //           query: {
        //             id: info.hwplanid, //作业id
        //             recordid: res.recordid, //记录id(关系id)
        //             lastqsid: res.lastqsid, //上次答题id
        //             total: info.qscount, //试题总数
        //             title: info.title, //标题
        //             active: this.active,
        //             curLessonIdx: this.curLessonIdx,
        //             isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
        //             overdue: 0, //未过期
        //             headmasterId: this.headmasterId, //班主任userid
        //             supervisorId: this.supervisorId, //导师userid
        //             beginTime: info.bd, //作业开始时间
        //             endTime: info.ed, //作业结束时间
        //             lessonName: info.coursename
        //           }
        //         })
        //       })
        //     }
        //   } else {
        //     this.showScore(info)
        //   }
        // }
        // -----------------------------------
        if (this.stuId) {
          let params = {
            id: info.hwplanid, //作业id
            recordid: info.recordid, //记录id(关系id)
            lastqsid: info.lastqsid, //上次提交答题id
            total: info.qscount, //试题总数
            title: info.title, //标题
            active: this.active,
            curLessonIdx: this.curLessonIdx,
            isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
            overdue: overdue ? 1 : 0, //是否过期
            headmasterId: this.headmasterId, //班主任userid
            supervisorId: this.supervisorId, //导师userid
            beginTime: info.bd, //作业开始时间
            endTime: info.ed, //作业结束时间
            lessonName: info.coursename
          }
          if (overdue) {
            // 过期未答题 || 过期且提交过
            if (info.lastqsid == 0 || info.isend) {
              params.lastqsid = info.firsitdetailid
            }
            this.$router.push({
              path: '/homeworkQuestionDetail2',
              query: params
            })
          } else {
            // 有效期内且已报名
            if (info.recordid && info.lastqsid) {
              this.$router.push({
                path: '/homeworkQuestionDetail',
                query: params
              })
            } else {
              //  有效期内但未报名
              createHWkRecord(info.hwplanid, this.stuId).then(res => {
                params.recordid = res.recordid //记录id(关系id)
                params.lastqsid = res.lastqsid //上次答题id
                this.$router.push({
                  path: '/homeworkQuestionDetail',
                  query: params
                })
              })
            }
          }
        } else {
          this.showScore(info)
        }
      }
    },
    // 置顶作业（班主任+导师）
    hwToTop(info) {
      if (this.isClassTeacher) {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定将“${info.title}”置顶到当前课程？`
          })
          .then(() => {
            toTopHomework(info.hwplanid).then(res => {
              this.$toast({
                message: '置顶成功',
                position: 'bottom',
                duration: 2000
              })
              this.onRefresh()
            })
          })
      } else {
        return
      }
    }
  },
  mounted() {
    this.getHomeworkList()
  }
}
</script>

<style lang="scss" scoped>
.van-pull-refresh {
  box-sizing: border-box;
  padding-bottom: 1px;
}
.practiceTests {
  width: 93.067vw;
  margin: 0 auto;
  .bigbox {
    .tobecompleted {
      width: 100%;
      box-sizing: border-box;
      // height: 31.2vw;
      background-color: #ffffff;
      border-radius: 1vw;
      border: solid 1px $font-light;
      margin: 2.667vw auto;
      // 作业/考试 标题
      .tobecompleted_title {
        font-size: 2.933vw;
        padding-right: 3vw;
        color: #303133;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tag {
          min-width: 30.933vw;
          padding: 0 0.5em;
          max-width: 50%;
          @include dot;
          height: 5.333vw;
          border-radius: 0.8vw 0vw 2.667vw 0vw;
          background-color: #ffcd05;
          text-align: center;
          line-height: 5.333vw;
        }
        .deadline {
          color: $font-gray;
        }
      }
      // 大盒子
      .tobecompleted_msg {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 0 3vw 3vw 3vw;
        box-sizing: border-box;
        height: calc(100% - 5.333vw);
        .tobecompleted_msg_box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .tobecompleted_msg_left {
            flex: 1;
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .tobecompleted_msg_headline {
            }
            .tobecompleted_teacher {
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                display: block;
                padding: 0 1em;
                font-size: 2.933vw;
                color: #909399;
              }
              span:first-child {
                border-radius: 2.667vw;
                border: solid 0.267vw #bfc2cc;
              }
            }
          }
          .tobecompleted_msg_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 100%;
            .tobecompleted_msg_title {
              font-size: 3.2vw;
              color: $font-gray;
            }
            .peugeot {
              width: 13.333vw;
              height: 5.333vw;
              background-image: linear-gradient(90deg, #37c1ff 0%, #137bfe 100%),
                linear-gradient(#a2a6a7, #a2a6a7);
              background-blend-mode: normal, normal;
              border-radius: 0vw 2.667vw 0vw 2.667vw;
              font-size: 2.933vw;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .peugeot_on {
              background-image: linear-gradient(
                90deg,
                #17b3c1 0%,
                #47d6b6 100%
              );
            }
            .peugeot_scolling {
              background-image: linear-gradient(
                90deg,
                #7ba563 0%,
                #26cf16 100%
              );
            }
            .peugeot_submit {
              background-image: linear-gradient(
                90deg,
                #c55252 0%,
                #f00a0a 100%
              );
            }
            .right_peugeot {
              // margin-left: 11vw;
              .peugeot_star {
                width: 8.933vw;
                height: 4.533vw;
                font-size: 5.6vw;
                color: #247dff;
              }
              .peugeot_star_two {
                font-size: 3.467vw;
                color: #303133;
              }
            }
            .show_score {
              color: #909399;
              font-size: 3.2vw;
              width: 100%;
              text-align: right;
              z-index: 100;
            }
          }
        }
      }
    }
    // 分数列表
    .score_list {
      margin-top: 2vw;
      background-color: #ffffff;
      border-radius: 1vw;
      border: solid 1px $font-light;
      // overflow: hidden;
      .van-row {
        .van-col {
          height: 8vw;
          line-height: 8vw;
          font-size: 3.5vw;
          text-align: center;
        }
        .van-col:first-child {
          padding-left: 6vw;
          text-align: left;
        }
        .van-col:last-child {
          padding-right: 6vw;
          text-align: right;
          position: relative;
          .van-icon {
            color: $font-gray;
            position: absolute;
            top: 46%;
            right: 2vw;
            transform: translateY(-50%);
          }
        }
      }
      .score_list_head {
        background: $success-color;
        color: #ffffff;
        border-radius: 1vw 1vw 0 0;
      }
      .score_green {
        color: $success-color;
      }
      .score_orange {
        color: $theme-color;
      }
      .score_red {
        color: $warn-color;
      }
      .score_gray {
        color: $font-gray;
      }
    }
  }
}
</style>
