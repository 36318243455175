<template>
  <div class="page">
    <topheader />
    <div class="classDetails_title">
      <span class="classDetails_title_ttiao"></span>
      {{info.crdate | timeStamp | dateFormat('yyyy年MM月dd日')}}
    </div>
    <div class="classDetails_title_big">{{info.title}}</div>
    <div class="classDetails_title_xiao">{{info.author}}</div>
    <div class="classDetails_center" v-html="info.content"></div>
  </div>
</template>

<script>
import {getOneCaseinfo} from "@/api/discover";

export default {
  name:'caseInfo',
  data() {
    return {
      caseId:this.$route.params.caseId,
      info:{
        crdate: '',
        title: '',
        author: '',
        content: '',
      },
    };
  },
  methods: {
    init() {
      getOneCaseinfo(this.caseId).then(res => {
        this.info = res.data[0];
      })
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
.quxioa {
  width: 50%;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.533vw;
  color: #007aff;
  font-weight: bold;
  border-top: 0.133vw solid #3f3f3f;
  border-right: 0.1vw solid #3f3f3f;
}
.baoming {
  width: 50%;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.533vw;
  color: #007aff;
  font-weight: bold;
  border-top: 0.133vw solid #3f3f3f;
}
.felx_quxiao {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.tooltip_cneter {
  width: 31.8vw;
  text-align: center;
  font-size: 3.467vw;
  color: #1e1e1e;
  margin: 0 auto;
}
.tooltip_title {
  font-size: 4.533vw;
  color: #1e1e1e;
  font-weight: bold;
  text-align: center;
  margin-top: 4.8vw;
}
.tooltip {
  width: 72vw;
  height: 37.333vw;
  background-color: #ffffff;
  border-radius: 3.733vw;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
}
.joins {
  width: 100vw;
  height: 13.067vw;
  background-image: linear-gradient(90deg, #f0b860 0%, #fddaa4 100%),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.yuanjia {
  font-size: 2.933vw;
  letter-spacing: -0.133vw;
  color: #bfc2cc;
  margin-left: 1.333vw;
  text-decoration: line-through;
}
.xianjia {
  font-size: 5.067vw;
  font-weight: normal;
  letter-spacing: -2px;
  color: #fe4858;
  font-weight: bold;
}
.join_flex_buttom {
  width: 41.6vw;
  height: 10.133vw;
  background-image: linear-gradient(90deg, #f0b860 0%, #fddaa4 100%),
    linear-gradient(#fe4858, #fe4858);
  background-blend-mode: normal, normal;
  border-radius: 5.067vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.733vw;
  color: #ffffff;
}
.join_flex {
  width: 93.733vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.join {
  width: 100vw;
  height: 14.067vw;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  border-top: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
}
.classDetails_center {
  width: 93.333vw;
  font-size: 4vw;
  line-height: 6vw;
  letter-spacing: 0vw;
  color: #303133;
  margin: 0 auto;
  /deep/p {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    span {
      img{
        width: 100%;
      }
    }
    img{
        width: 100%;
      }
  }
}
// .classDetails_center 
.classDetails_title_xiao {
  width: 93.333vw;
  margin: 4.533vw auto;
  font-size: 3.467vw;
  color: #7a8299;
}
.classDetails_title_ttiao {
  display: block;
  width: 0.8vw;
  height: 4vw;
  background-color: #247dff;
  margin-right: 2.133vw;
}
.classDetails_title_big {
  width: 93.333vw;
  margin: 4.533vw auto;
  font-size: 5.333vw;
  color: #303133;
  font-weight: bold;
}
.classDetails_title {
  width: 93.333vw;
  margin: 4.533vw auto;
  display: flex;
  align-items: center;
  font-size: 2.933vw;
  color: #909399;
}
</style>
