
<template>
  <div class="selectContact">
    <div class="contact_search">
      <div class="contact_search_imgs">
        <transition name="fade" v-for="(contact,idx) in checkedGroup" :key="'contact' + idx">
          <van-image :src="contact.headurl" @click="filterImg(contact)">
            <template v-slot:error>加载失败</template>
          </van-image>
        </transition>
      </div>
      <van-search v-model="key" placeholder="请输入搜索关键词" />
    </div>
    <!-- 索引栏 -->
    <van-checkbox-group v-model="checked" v-if="!key">
      <van-index-bar :index-list="idxList" :sticky="false" highlight-color="#000">
        <!-- @select="selectIndexBar" -->
        <div v-for="item in contacts" :key="item.letter">
          <van-index-anchor :index="item.letter" />
          <van-checkbox
            checked-color="#07c160"
            v-for="it in item.data"
            :key="it[keyword]"
            :name="it[keyword]"
          >
            <div class="name_list">
              <div class="name_list_li_avatar">
                <img :src="it.headurl" v-if="it.headurl" />
                <img src="@/assets/images/head_prole.png" v-else />
              </div>
              <div class="name_list_li_rightBox">
                <span class="username" v-text="it.name"></span>
                <span class="groupname" v-text="`归属：${it.groupname}`" v-show="it.groupname"></span>
              </div>
            </div>
          </van-checkbox>
        </div>
      </van-index-bar>
    </van-checkbox-group>
    <!-- 复选搜索列表 -->
    <van-checkbox-group v-model="checked" v-else>
      <div v-for="item in contactsBySearch" :key="item[keyword]">
        <van-checkbox checked-color="#07c160" :name="item[keyword]">
          <div class="name_list">
            <div class="name_list_li_avatar">
              <img :src="item.headurl" v-if="item.headurl" />
              <img src="@/assets/images/head_prole.png" v-else />
            </div>
            <div class="name_list_li_rightBox">
              <span class="username" v-text="item.name"></span>
              <span class="groupname" v-text="`归属：${item.groupname}`" v-show="item.groupname"></span>
            </div>
          </div>
        </van-checkbox>
      </div>
    </van-checkbox-group>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    keyword: {
      //关联关键字 (分组-stuid，通知-userid)
      type: String,
      default: 'stuid'
    }
  },
  data() {
    return {
      key: '',
      checked: [],
      idxList: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '*'
      ]
    }
  },
  computed: {
    contacts() {
      return this.pySegSort(this.list) || []
    },
    contactsBySearch() {
      return this.list.filter(item => item.name.includes(this.key))
    },
    checkedGroup() {
      return this.list.filter(item => this.checked.includes(item[this.keyword]))
    }
  },
  methods: {
    selectIndexBar(i) {
      console.log(11111, i)
      // @todo 跳转到对应锚点
    },
    filterImg(info) {
      this.checked = this.checked.filter(it => it != info[this.keyword])
    },
    // 按拼音分类
    pySegSort(arr, empty) {
      if (!String.prototype.localeCompare || arr.length === 0) return []

      let letters = '*abcdefghjklmnopqrstwxyz'.split('')
      let zh = '阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥扨它穵夕丫帀'.split('')

      let segs = []
      let curr
      letters.forEach((item, i) => {
        curr = { letter: item.toUpperCase(), data: [] }
        arr.forEach(it => {
          if (
            (!zh[i - 1] || zh[i - 1].localeCompare(it.name, 'zh') <= 0) &&
            it.name.localeCompare(zh[i], 'zh') == -1
          ) {
            curr.data.push(it)
          }
        })
        if (empty || curr.data.length) {
          segs.push(curr)
          curr.data.sort((a, b) => {
            return a.name.localeCompare(b.name, 'zh')
          })
        }
      })
      return JSON.parse(JSON.stringify(segs))
    }
  },
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.selectContact {
  .contact_search {
    padding: 0 3.2vw;
    display: flex;
    align-items: center;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.5);
    height: 13.333vw;
    .contact_search_imgs {
      overflow-x: scroll;
      height: 100%;
      white-space: nowrap;
      padding-top: calc((13.333vw - 9.067vw) / 2);
      box-sizing: border-box;
      //图片
      /deep/.van-image {
        @include wh(9.067vw, 9.067vw);
        @include borderRadius(1.333vw);
        overflow: hidden;
        margin-right: 0.667vw;
        img {
          @include imgCover;
        }
      }
      // 动画效果
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.15s;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
    }
    .van-search {
      // 搜索框
      height: 13.333vw;
      flex: 1;
      min-width: 8em;
    }
  }
  // 索引栏 复选列表
  /deep/.van-index-bar {
    // 索引栏
    /deep/.van-index-bar__sidebar {
      // 侧边栏
      height: calc(100% - 10% - 9.6vw - 13.333vw);
      top: auto;
      bottom: 0;
      transform: translateY(0);
      .van-index-bar__index {
        flex: 1;
      }
    }
    div:nth-child(n-1) {
      /deep/div:first-child {
        .van-index-anchor {
          @include wh(100%, 8.8vw);
          @include font(2.667vw, 8.8vw);
          color: $font-gray;
          background: #ededed;
        }
      }
      /deep/.van-checkbox {
        padding-left: 3.2vw;
        .van-checkbox__icon {
          .van-icon {
          }
        }
        .van-checkbox__label {
          width: 100%;
          .name_list {
            display: flex;
            align-items: center;
            .name_list_li_avatar {
              @include wh(9.6vw, 9.6vw);
              @include borderRadius(0.667vw);
              overflow: hidden;
              // box-shadow: $box-shadow;
              img {
                @include imgCover;
              }
            }
            .name_list_li_rightBox {
              @include font(4vw, 13.867vw);
              flex: 1;
              margin: 0 4.267vw;
              border-bottom: 0.133vw solid $font-light;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                flex: 1;
                @include dot;
              }
              span:nth-child(2) {
                font-size: 3.2vw;
                color: $font-gray;
              }
            }
          }
        }
      }
    }
  }
  // 复选搜索列表
  .van-checkbox-group {
    padding-left: 3.2vw;
    height: calc(100% - 13.333vw);
    overflow-y: scroll;
    div {
      /deep/.van-checkbox {
        .van-checkbox__label {
          width: 100%;
          .name_list {
            display: flex;
            align-items: center;
            .name_list_li_avatar {
              @include wh(9.6vw, 9.6vw);
              @include borderRadius(0.667vw);
              overflow: hidden;
              // box-shadow: $box-shadow;
              img {
                @include imgCover;
              }
            }
            .name_list_li_rightBox {
              @include font(4vw, 13.867vw);
              flex: 1;
              margin: 0 4.267vw;
              border-bottom: 0.133vw solid $font-light;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                flex: 1;
                @include dot;
              }
              span:nth-child(2) {
                font-size: 3.2vw;
                color: $font-gray;
              }
            }
          }
        }
      }
    }
  }
}
</style>