<template>
  <!-- 专家栏目>>>公告|学员信息|考勤记录 -->
  <div class="page">
    <topheader :title="titleText" />
    <van-search v-model="key" show-action label="班圈" placeholder="请输入搜索关键词" @search="onRefresh">
      <template #action>
        <div @click="onRefresh">搜索</div>
      </template>
    </van-search>
    <!-- <div class="tips" v-else>请先选择课程</div> -->
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 课程列表 -->
        <!-- <LessonList v-if="type == '7'" :list="list" @click="action" /> -->
        <ClassList :list="list" @click="action" />
      </van-list>
    </van-pull-refresh>
    <!-- 课程选择框 -->
    <van-popup
      close-on-popstate
      position="bottom"
      close-icon="cross"
      close-icon-position="top-right"
      :style="{ height: '90%', 'margin-left': '20%', width: '80%' }"
      v-model="show"
    >
      <div class="class_name" v-text="curClassInfo.title"></div>
      <div class="lesson_name">请选择课程</div>
      <ul class="lesson_list" v-if="lessons.length">
        <li
          v-for="(course, idx) in lessons"
          :key="idx"
          class="lesson_li"
          @click="goToOtherPage(course)"
        >
          <div class="lesson_box">
            <span class="lesson_idx" v-text="idx > 9 ? idx + 1 : `0${idx+1}`"></span>
            <p class="lesson_title">
              <van-tag
                plain
                :type="course.showmode == '直播' ? 'warning' : 'success'"
              >{{course.showmode}}</van-tag>
              {{course.title}}
            </p>
            <!-- <span
              class="spot"
              v-if="type == 2 && course.hashwcheck || type == 3 && course.hasexcheck"
            ></span>-->
            <van-icon
              name="warning"
              class="hasTodo"
              v-if="type == 2 && course.hashwcheck || type == 3 && course.hasexcheck"
            />
          </div>
        </li>
      </ul>
      <p class="no-data" v-else>暂无数据</p>
    </van-popup>
  </div>
</template>

<script>
import {
  getTeaClassListByRoot, // 作废
  getTeaSignlessonList,
  getTealessonListByRoot, // 作废
  teaSearchClassList,
  teaSearchLessonList
} from '@/api/my'
import { getCourseList } from '@/api/classRing'
import ClassList from '@/components/extend/classList'
import LessonList from '@/components/extend/lessonList'
export default {
  components: { ClassList, LessonList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      type: this.$route.params.type,
      key: '',
      show: false,
      lessons: [], //当前选中班圈的课程列表
      curClassInfo: {} //选中的班圈信息
    }
  },
  computed: {
    titleText() {
      switch (this.type) {
        case '2':
          return '作业'
        case '3':
          return '考试'
        case '4':
          return '公告'
        case '5':
          return '学员信息'
        case '6':
          return '考勤记录'
        case '7':
          return '设置作业'
        case '8':
          return '发布消息'
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true
      this.$nextTick(() => {
        this.finished = false //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      })
    },
    // 底部加载
    onLoad() {
      const publicParams = {
        keyword: this.key,
        userid: this.userId,
        curpage: this.page
      }
      // if (this.type == '7') {
      //   // 作业
      //   teaSearchLessonList(Object.assign(publicParams, { type: 2 }))
      //     .then(res => {
      //       this.success(res)
      //       this.list.push(...res.list)
      //     })
      //     .catch(this.failure)
      // } else {
      // 1：当前用户是班主任或导师或辅导员
      // 2：当前用户是班主任或导师
      // 3：当前用户是班主任
      // 4：当前用户是班主任或者辅导员
      let type = 1 //权限
      if (this.type == '4') {
        type = 3
      } else if (this.type == '5') {
        type = 1
      } else if (['2', '3'].includes(this.type)) {
        type = 1
      } else if (this.type === '6') {
        type = 1
      } else if (this.type === '7') {
        type = 2
      } else if (this.type === '8') {
        type = 3
      }
      teaSearchClassList(Object.assign(publicParams, { type }))
        .then(res => {
          this.success(res)
          this.list.push(...res.list)
        })
        .catch(this.failure)
      // }
    },
    // 成功回调
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 > res.sum) {
        this.finished = true
      } else {
        this.page++
      }
    },
    // 失败回调
    failure(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // 点击动作 （后期考虑添加loading加载数据的效果）
    action(info) {
      console.log(JSON.parse(JSON.stringify(info)))

      const classInfo = this.list.find(it => it.autoid === info.autoid)
      if (this.type === '2' || this.type === '3' || this.type === '7') {
        this.show = true
        if (classInfo && !classInfo.lessonInfo) {
          this.lessons = []
          teaSearchLessonList({
            classid: info.autoid,
            keyword: '',
            userid: this.userId,
            curpage: -1,
            type: 1
          }).then(res => {
            this.list.forEach(el => {
              if (el.autoid === info.autoid) {
                el.lessonInfo = {
                  lessonList: res.list,
                  sum: res.sum, //考虑是否做成上下加载
                  page: '' //考虑是否做成上下加载
                }
                this.lessons = res.list
                this.curClassInfo = el
              }
            })
          })
        } else {
          this.lessons = classInfo.lessonInfo.lessonList
        }
      } else if (this.type === '6') {
        //考勤记录
        this.show = true
        if (classInfo && !classInfo.lessonInfo) {
          this.lessons = []

          // getTeaSignlessonList({
          //   userid: this.userId,
          //   curpage: this.page,
          //   classid: classInfo.autoid,
          //   keyword: this.key
          // }).then(res => {
          //   this.list.forEach(el => {
          //     if (el.autoid === info.autoid) {
          //       el.lessonInfo = {
          //         lessonList: res.list,
          //         sum: res.sum, //考虑是否做成上下加载
          //         page: '' //考虑是否做成上下加载
          //       }
          //       this.lessons = res.list
          //       this.curClassInfo = el
          //     }
          //   })
          // })

          getCourseList(classInfo.autoid).then(res => {
            const courses = res.list.filter(item => item.pricemode === '付费')
            this.list.forEach(el => {
              if (el.autoid === info.autoid) {
                el.lessonInfo = {
                  lessonList: courses,
                  sum: res.sum, //考虑是否做成上下加载
                  page: '' //考虑是否做成上下加载
                }
                this.lessons = courses
                this.curClassInfo = el
              }
            })
          })
          // 接口返回有问题
          // getTealessonListByRoot({
          //   classid: classInfo.autoid,
          //   userid: this.$store.state.user.userId,
          //   type: 1
          // }).then(res => {
          //   this.list.forEach(el => {
          //     if (el.autoid === info.autoid) {
          //       el.lessonInfo = {
          //         lessonList: res.list,
          //         sum: res.sum, //考虑是否做成上下加载
          //         page: '' //考虑是否做成上下加载
          //       }
          //       this.lessons = res.list
          //       this.curClassInfo = el
          //     }
          //   })
          // })
        } else {
          this.lessons = classInfo.lessonInfo.lessonList
        }
      } else {
        this.goToDetails(info)
      }
    },
    goToDetails(item) {
      switch (this.type) {
        case '4': // 专家栏目 >>> 公告 >>>
          this.$router.push({
            path: '/experts_announcement',
            query: {
              classId: item.autoid,
              className: item.title
            }
          })
          break
        case '5': // 专家栏目 >>> 学员信息 >>>
          this.$router.push({
            path: '/student_list',
            query: {
              classId: item.autoid,
              className: item.title
            }
          })
          break
        case '6': // 专家栏目 >>> 考勤记录 >>>
          this.$router.push({
            path: '/attendance_detail',
            query: {
              classId: item.classid,
              lessonId: item.autoid,
              mode: item.showmode //视频类型
            }
          })
          break
        case '7': // 专家栏目 >>> 设置作业 >>>
          this.$router.push({
            path: '/set_homework', //是否考虑删除这个页面
            // path: '/publication_add/1', // 是否将该页面转换成组件
            query: {
              classId: item.classid,
              className: item.classname,
              lessonId: item.autoid,
              lessonName: item.title,
              beginDate: item.bd,
              endDate: item.ed
            }
          })
          break
        case '8': // 专家栏目 >>> 发布消息 >>>
          this.$router.push({
            path: `/class_news/${item.autoid}`,
            query: {
              className: item.title
            }
          })
          break
      }
    },
    goToOtherPage(data) {
      if (this.type === '6') {
        this.$router.push({
          path: '/attendance_detail',
          query: {
            classId: data.classid,
            lessonId: data.autoid,
            mode: data.showmode //视频类型
          }
        })
      } else if (['2', '3'].includes(this.type)) {
        this.goToShortAnswerList(data)
      } else if (this.type === '7') {
        this.goToDetails(data)
      }
    },
    goToShortAnswerList(item) {
      // const t =
      //   item.title.length > 10 ? item.title.slice(0, 8) + '...' : item.title
      // this.$dialog
      //   .confirm({
      //     title: '提示',
      //     message: `是否确认进入“${t}”的${this.titleText}简答题审批列表页？`
      //   })
      //   .then(() => {
      switch (this.type) {
        case '2': //作业
          this.$router.push({
            path: '/experts_questionList/1',
            query: {
              classId: this.curClassInfo.autoid,
              lessonId: item.autoid
            }
          })
          break
        case '3': //考试
          this.$router.push({
            path: '/experts_questionList/2',
            query: {
              classId: this.curClassInfo.autoid,
              lessonId: item.autoid
            }
          })
          break
      }
      // })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  background: #fff;
  .tips {
    background: $bg-gray;
    color: $active-color;
    @include font(3.2vw, 6.4vw);
    padding: 0 2.667vw;
  }
  // 搜索框 -- 必须重新设置样式-避免换成大屏没有自适应
  .van-search {
    padding: 1.333vw 1.6vw;
    .van-search__content {
      padding-left: 2.133vw;
      .van-search__label {
        padding: 0 1.333vw;
        font-size: 3.733vw;
        line-height: 9.067vw;
      }
      /deep/.van-cell {
        padding: 1.333vw 2.133vw 1.333vw 0;
        font-size: 3.733vw;
        line-height: 6.4vw;
        .van-field__left-icon {
          margin-right: 1.333vw;
          .van-icon {
            font-size: 4.267vw;
          }
        }
      }
    }
    .van-search__action {
      padding: 0 2.133vw;
      font-size: 3.733vw;
      line-height: 9.067vw;
    }
  }

  .van-pull-refresh {
    height: calc(100% - 11.733vw);
    overflow-y: scroll;
  }
  // 考勤的暂时没做搜索功能，所以需额外调整高度
  .van-pull-refresh.checkon {
    height: calc(100% - 6.4vw);
  }
  .classring_list,
  /deep/.van-pull-refresh .lessonList {
    .classring_list_left {
      width: 36.533vw;
      height: 24.267vw;
      border-radius: 1.067vw;
      overflow: hidden;
      img {
        @include wh(100%, 100%);
        object-fit: cover;
      }
    }
    .classring_list_right {
      flex: 1;
      height: 24.267vw;
      margin-left: 3.467vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 2.133vw;
      .classring_list_title {
        font-size: 4.267vw;
        line-height: 6.4vw;
        color: #303133;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .classring_list_bottom {
        font-size: 2.933vw;
        color: #909399;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          margin-top: 5vw;
        }
      }
    }
  }
  // 弹出层
  .van-popup {
    .class_name {
      padding: 3.2vw;
      text-align: center;
      @include font(3.6vw, 5vw);
      @include dot;
    }
    .lesson_name {
      background: $bg-gray;
      color: $active-color;
      @include font(3.2vw, 6.4vw);
      padding: 0 2.667vw;
    }
    // 课程列表
    .lesson_list {
      height: calc(100% - 6.4vw - 11.4vw);
      overflow-y: scroll;
      .lesson_li {
        padding: 3.2vw;
        border-bottom: 1px solid $bg-light;
        @include font(3.2vw, 5vw);
        .lesson_box {
          display: flex;
          align-items: center;
          .lesson_idx {
            width: 2em;
            color: $font-gray;
          }
          .lesson_title {
            flex: 1;
          }
          // 红点
          .spot {
            display: inline-block;
            @include wh(0.8vw, 0.8vw);
            border-radius: 50%;
            background: red;
          }
          .hasTodo {
            font-size: 1.6vw;
            color: $active-color;
          }
        }
      }
    }
    // 暂无数据
    .no-data {
      @include font(3.2vw, 9.6vw);
      text-align: center;
      color: $font-gray;
    }
  }
}
</style>
