<!-- 作业/考试 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
      >
        <div class="practiceTests" v-for="(item, i) in list" :key="'practiceTest' + i">
          <div class="bigbox">
            <div class="tobecompleted" @click.stop="goToQuestionDetail(item, $event)">
              <div class="tobecompleted_title">
                <span class="tag" v-text="item.coursename">专项突破 巩固夯基</span>
                <span class="deadline" v-if="item.ed">
                  {{
                  item.ed | timeStamp | dateFormat('yyyy-MM-dd hh:mm')
                  }}
                  截止答题
                </span>
              </div>

              <div class="tobecompleted_msg">
                <div class="tobecompleted_msg_title">
                  <span>{{ item.title }}</span>
                  <span>{{ item.panum }}人已练习</span>
                </div>
                <div class="tobecompleted_msg_box">
                  <div class="tobecompleted_msg_left">
                    <transition name="fade" mode="out-in">
                      <p
                        class="tobecompleted_synopsis"
                        :class="{noDot: open2synopsis.includes(item.explanid)}"
                        @click="synopsisAct(item)"
                      >
                        <span v-text="item.content"></span>
                        <transition
                          name="component-fade"
                          mode="out-in"
                          v-if="getLength(item.content) > 44"
                        >
                          <component
                            v-bind:is="open2synopsis.includes(item.explanid) ? 'a-u' : 'a-d'"
                          ></component>
                        </transition>
                      </p>
                    </transition>
                    <div class="tobecompleted_teacher">
                      <span>{{ item.classownername2 || item.classownername1 }}</span>
                      <!-- 逾期未参与考试的，得0分 -->
                      <span
                        v-if="item.ischeck && stuId"
                        v-text="`得分：${item.score || 0}/${item.totalscore}`"
                      ></span>
                    </div>
                  </div>

                  <div class="tobecompleted_msg_right">
                    <!-- 学员 -->
                    <template v-if="stuId">
                      <template>
                        <div v-if="item.asnum !== item.qscount && !item.isend" class="peugeot">待完成</div>
                        <div
                          v-if="item.asnum == item.qscount && !item.isend"
                          class="peugeot peugeot_submit"
                        >待提交</div>
                        <div v-else-if="item.isend && !item.ischeck" class="peugeot peugeot_on">待阅卷</div>
                        <div
                          v-else-if="item.isend && item.ischeck"
                          class="peugeot peugeot_scolling"
                        >已评分</div>
                      </template>

                      <div class="right_peugeot" v-if="!item.isend && inVaildDate(item.ed)">
                        <span class="peugeot_star">{{ item.asnum }}</span>
                        <span class="peugeot_star_two">/{{ item.qscount }}</span>
                      </div>

                      <div class="show_score" v-else @click.stop="showScore(item)">
                        {{
                        item.examination_done === item.examination_total &&
                        opentScoreList.includes(item.hwplanid)
                        ? '收起'
                        : '展开'
                        }}
                      </div>
                    </template>
                    <!-- 班主任/导师 -->
                    <template v-else>
                      <div class="right_peugeot">
                        <span class="peugeot_star" v-text="item.flnum"></span>
                        <span class="peugeot_star_two" v-text="`/${total}`"></span>
                      </div>
                      <div class="show_score">
                        <span
                          v-if="userId == headmasterId && !stuId && i != 0"
                          class="toTop"
                          @click="examToTop(item)"
                        >置顶 /</span>
                        <!-- <van-button
                          type="info"
                          plain
                          hairline
                          size="mini"
                          v-if="userId == headmasterId && !stuId && i != 0"
                          class="toTop"
                          @click="examToTop(item)"
                        >置顶</van-button>
                        <van-button
                          type="default"
                          plain
                          hairline
                          size="mini"
                          class="deleteBtn"
                          @click.stop="showScore(item)"
                        >
                          {{
                          item.examination_done === item.examination_total &&
                          opentScoreList.includes(item.explanid)
                          ? '收起'
                          : '展开'
                          }}
                        </van-button>-->
                        <span @click.stop="showScore(item)">
                          {{
                          item.examination_done === item.examination_total &&
                          opentScoreList.includes(item.explanid)
                          ? '收起'
                          : '展开'
                          }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- 后期可以加个 动画过渡 -->
            <!-- 分数列表 -->
            <div
              class="score_list"
              v-show="opentScoreList.includes(item.explanid) && item.scoreList"
            >
              <van-row class="score_list_head">
                <van-col span="6">排名</van-col>
                <van-col span="6">姓名</van-col>
                <van-col span="6">等级</van-col>
                <van-col span="6">分数</van-col>
              </van-row>
              <van-row
                class="score_list_body"
                v-for="(it, idx) in item.scoreList"
                :key="idx"
                @click="goToScoreList(it, item)"
              >
                <van-col
                  span="6"
                  v-text="it.rank != null ? `NO.${it.rank < 10 ? '0' + it.rank : it.rank}` : '--'"
                ></van-col>
                <van-col span="6" v-text="it.name"></van-col>
                <van-col span="6" v-text="it.grade ? it.grade : '--'"></van-col>
                <van-col span="6" :class="scoreRank(it.grade)">
                  {{ it.score != null ? `${it.score}分` : '--' }}
                  <van-icon name="arrow" v-if="item.recordid === it.recordid" />
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  getExamList,
  createExamRecord,
  getStuExamRecords,
  toTopExam
} from '@/api/classRing'

export default {
  props: {
    type: String, //类型 1-作业 2-考试
    classId: String, //班圈id
    lessonId: Number, //课程id
    stuId: Number, //学员id
    headmasterId: Number, // 班主任id
    supervisorId: Number, //导师id
    isClassTeacher: Boolean //是否当前班圈的课程班主任/导师
  },
  components: {
    'a-d': {
      template: '<van-icon name="arrow-down"/>'
    },
    'a-u': {
      template: '<van-icon name="arrow-up" />'
    }
  },
  data() {
    return {
      list: [],
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      opentScoreList: [], // 已完成 作业/考试 并要展示分数列表的集合
      userId: this.$store.state.user.userId, //
      scoreList: [],
      total: 0,
      open2synopsis: [], //正在展开的考试介绍列表
      lastRequestLessonId: null //上一次请求接口的课程id
    }
  },
  methods: {
    // 简介点击事件
    synopsisAct(info) {
      const open = this.open2synopsis
      if (open.find(item => item === info.explanid)) {
        this.open2synopsis = open.filter(it => it !== info.explanid)
      } else {
        this.open2synopsis.push(info.explanid)
      }
    },
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
        this.pullRefresh = true
        this.getExamList()
      })
    },
    // 底部加载
    getExamList() {
      getExamList(this.classId, this.stuId, this.lessonId, false)
        .then(res => {
          this.loading = false
          this.list = res.list
          this.total = res.sumnum
          this.pullRefresh = false
          this.finished = true
        })
        .catch(() => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
        .finally(() => {
          this.lastRequestLessonId = this.lessonId //记录上一次请求的课程id（用于父组件判断是否需要刷新数据）
        })
    },
    // ->测试练习做题页面
    goToQuestionDetail(info, e) {
      const childNodeClassList = [...new Set(e.target.classList)],
        parentNodeClassList = [...new Set(e.target.parentNode.classList)]
      if (
        childNodeClassList.includes('tobecompleted_synopsis') ||
        parentNodeClassList.includes('tobecompleted_synopsis')
      ) {
        return '考试简介展开'
      } else if (childNodeClassList.includes('toTop')) {
        return '考试置顶'
      } else {
        this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
        const overdue = new Date() > new Date(info.ed.replace(/-/g, '/'))
        // 学员 @todo 后期需开放 逾期未参加 仍能查看题目的功能
        // console.log('答题内容=', JSON.parse(JSON.stringify(info)))
        // if (overdue && !info.lastqsid) {
        //   this.$toast('答题结束，未作答，暂无权限查看')
        //   return
        // } else {
        //   if (this.stuId) {
        //     //学员(有参与过答题)
        //     if (info.recordid && info.lastqsid) {
        //       // 平时查看作业
        //       this.$router.push({
        //         path: '/examQuestionDetail',
        //         query: {
        //           id: info.explanid, //作业id
        //           recordid: info.recordid, //记录id(关系id)
        //           lastqsid: info.lastqsid, //上次提交答题id
        //           total: info.qscount, //试题总数
        //           finishCont: info.asnum, //答题数
        //           title: info.title, //标题
        //           active: this.active,
        //           curLessonIdx: this.curLessonIdx,
        //           isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
        //           overdue: overdue ? 1 : 0, //是否过期
        //           headmasterId: this.headmasterId, //班主任userid
        //           supervisorId: this.supervisorId //导师userid
        //         }
        //       })
        //     } else {
        //       // 创建答题记录（首次查看考试）
        //       createExamRecord(info.explanid, this.stuId).then(res => {
        //         this.$router.push({
        //           path: '/examQuestionDetail',
        //           query: {
        //             id: info.explanid, //作业id
        //             recordid: res.recordid, //记录id(关系id)
        //             lastqsid: res.lastqsid, //上次答题id
        //             total: info.qscount, //试题总数
        //             finishCont: info.asnum, //答题数
        //             title: info.title, //标题
        //             active: this.active,
        //             curLessonIdx: this.curLessonIdx,
        //             isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
        //             overdue: 0, //未过期
        //             headmasterId: this.headmasterId, //班主任userid
        //             supervisorId: this.supervisorId //导师userid
        //           }
        //         })
        //       })
        //     }
        //   } else {
        //     //专家/老师
        //     return
        //   }
        // }

        // -------------------
        if (this.stuId) {
          let params = {
            id: info.explanid, //作业id
            recordid: info.recordid, //记录id(关系id)
            lastqsid: info.lastqsid, //上次答题id
            total: info.qscount, //试题总数
            finishCont: info.asnum, //答题数
            title: info.title, //标题
            active: this.active,
            curLessonIdx: this.curLessonIdx,
            isend: Boolean(info.isend) ? 1 : 0, //是否已经提交
            overdue: overdue ? 1 : 0, //是否过期
            headmasterId: this.headmasterId, //班主任userid
            supervisorId: this.supervisorId //导师userid
          }
          if (overdue) {
            // 过期未答题 || 过期且提交过
            if (info.lastqsid == 0 || info.isend) {
              params.lastqsid = info.firsitdetailid
            }
            this.$router.push({
              path: '/examQuestionDetail2',
              query: params
            })
          } else {
            // 有效期内已报名
            if (info.recordid && info.lastqsid) {
              this.$router.push({
                path: '/examQuestionDetail',
                query: params
              })
            } else {
              // 有效期内但未报名
              createExamRecord(info.explanid, this.stuId).then(res => {
                params.recordid = res.recordid //记录id(关系id)
                params.lastqsid = res.lastqsid //上次答题id
                this.$router.push({
                  path: '/examQuestionDetail',
                  query: params
                })
              })
            }
          }
        } else {
          return
        }
      }
    },
    // 要展示分数列表的集合
    showScore(info) {
      if ((this.stuId && info.isend) || (!this.stuId && info.flnum)) {
        const id = info.explanid
        if (this.opentScoreList.includes(id)) {
          this.opentScoreList = this.opentScoreList.filter(item => item !== id)
        } else {
          if (!this.list.find(it => it.explanid == id).scoreList) {
            getStuExamRecords(id).then(res => {
              this.opentScoreList.push(id)
              this.list.forEach(item => {
                if (item.explanid == id) {
                  let arr = res.list.sort((a, b) => a.rank < b.rank) //排序
                  item.scoreList = arr
                }
              })
            })
          } else {
            this.opentScoreList.push(id)
          }
        }
      } else {
        this.$toast({
          message: '暂无学员提交记录',
          position: 'bottom'
        })
      }
    },
    // 判断有效日期是否大于当天
    inVaildDate(d) {
      let t = new Date(d.replace(/-/g, '/'))
      let dateTime = t.setDate(t.getDate() + 1)
      return new Date() <= dateTime
    },
    // 获取分数线对应的颜色
    scoreRank(grade) {
      switch (grade) {
        case '优':
          return 'score_green' //优
        case '良':
          return 'score_orange' //良
        case '中':
          return 'score_red' //中
        default:
          return 'score_gray' //4 - 差
      }
    },
    // 查看学员答题详情
    goToScoreList(scoreInfo, examInfo) {
      this.$emit('setSession') //保存课程详情页的课程idx、页签idx到sessonStorage
      // 班主任 | 导师 | 辅导员 或者 自己的考试
      if (this.isClassTeacher || examInfo.recordid == scoreInfo.recordid) {
        this.$router.push({
          path: '/examQuestionDetail',
          query: {
            id: examInfo.explanid, //考试id
            recordid: scoreInfo.recordid, //记录id(关系id)
            lastqsid: scoreInfo.lastqsid, //上次提交答题id
            total: examInfo.qscount, //试题总数
            title: examInfo.title, //标题
            active: this.active,
            curLessonIdx: this.curLessonIdx,
            isend: 1, //已经提交 (班主任可查看到已交卷的学员答题)
            overdue: false, //不区分过期
            headmasterId: this.headmasterId, //班主任userid
            supervisorId: this.supervisorId, //导师userid
            isClassTeacher: this.isClassTeacher,
            studentName: scoreInfo.name //学员姓名
          }
        })
      }
    },
    // 计算字符串的字节长度
    getLength(val) {
      var str = new String(val)
      var bytesCount = 0
      for (var i = 0, n = str.length; i < n; i++) {
        var c = str.charCodeAt(i)
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          bytesCount += 1
        } else {
          bytesCount += 2
        }
      }
      return bytesCount
    },
    // 置顶考试（班主任）
    examToTop(info) {
      if (this.userId == this.headmasterId) {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定将“${info.title}”置顶到当前课程？`
          })
          .then(() => {
            toTopExam(info.explanid).then(res => {
              this.$toast({
                message: '置顶成功',
                position: 'bottom',
                duration: 2000
              })
              this.onRefresh()
            })
          })
      } else {
        return
      }
    }
  },
  mounted() {
    this.getExamList()
  }
}
</script>

<style lang="scss" scoped>
.van-pull-refresh {
  box-sizing: border-box;
  padding-bottom: 1px;
}
.practiceTests {
  width: 93.067vw;
  margin: 0 auto;
  .bigbox {
    .tobecompleted {
      width: 100%;
      box-sizing: border-box;
      // min-height: 31.2vw;
      background-color: #ffffff;
      border-radius: 1vw;
      border: solid 1px $font-light;
      margin: 2.667vw auto;
      // 作业/考试 标题
      .tobecompleted_title {
        font-size: 2.933vw;
        padding-right: 3vw;
        color: #303133;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tag {
          min-width: 30.933vw;
          max-width: 50%;
          padding: 0 0.5em;
          @include dot;
          height: 5.333vw;
          border-radius: 0.8vw 0vw 2.667vw 0vw;
          background-color: #ffcd05;
          text-align: center;
          line-height: 5.333vw;
        }
        .deadline {
          color: $font-gray;
        }
      }
      // 大盒子
      .tobecompleted_msg {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 0 3vw 3vw 3vw;
        box-sizing: border-box;
        // height: calc(100% - 5.333vw);

        .tobecompleted_msg_title {
          @include font(4.533vw, 8vw);
          color: #303133;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:first-child {
            flex: 1;
            padding-right: 0.8vw;
            @include dot;
          }
          span:last-child {
            color: #909399;
            font-size: 3.2vw;
          }
        }

        .tobecompleted_msg_box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .tobecompleted_msg_left {
            width: calc(100% - 16vw);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .tobecompleted_synopsis {
              @include dot;
              text-indent: 1em;
              color: $font-gray;
              font-size: 3.2vw;
              padding-right: 2em;
              position: relative;
              box-sizing: border-box;
              animation: overflow 1s reverse;
              animation: white-space 1s reverse;
              animation: height 1s reverse;
              span {
                width: 100%;
              }
              .van-icon {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
              }
              .component-fade-enter-active,
              .component-fade-leave-active {
                transition: opacity 0.1s ease;
              }
              .component-fade-enter,
              .component-fade-leave-to {
                opacity: 0;
              }
            }
            .tobecompleted_synopsis.noDot {
              overflow: auto;
              white-space: normal;
            }

            .tobecompleted_teacher {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              span {
                display: block;
                padding: 0 0.8vw;
                font-size: 3.2vw;
                color: #909399;
              }
              span:first-child {
                border-radius: 2.667vw;
                border: solid 0.267vw #bfc2cc;
              }
            }
          }
          .tobecompleted_msg_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            // height: 100%;
            width: 16vw;
            .peugeot {
              width: 100%;
              height: 5.333vw;
              background-image: linear-gradient(90deg, #37c1ff 0%, #137bfe 100%),
                linear-gradient(#a2a6a7, #a2a6a7);
              background-blend-mode: normal, normal;
              border-radius: 0vw 2.667vw 0vw 2.667vw;
              font-size: 2.933vw;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .peugeot_on {
              background-image: linear-gradient(
                90deg,
                #17b3c1 0%,
                #47d6b6 100%
              );
            }
            .peugeot_scolling {
              background-image: linear-gradient(
                90deg,
                #7ba563 0%,
                #26cf16 100%
              );
            }
            .peugeot_submit {
              background-image: linear-gradient(
                90deg,
                #c55252 0%,
                #f00a0a 100%
              );
            }
            .right_peugeot {
              .peugeot_star {
                font-size: 5.6vw;
                color: #247dff;
              }
              .peugeot_star_two {
                font-size: 3.467vw;
                color: #303133;
              }
            }
            .show_score {
              color: #909399;
              font-size: 3.2vw;
              width: 100%;
              text-align: right;
              padding-top: 0.8vw;
              // display: flex;
              // align-items: center;
              .toTop {
              }
            }
          }
        }
      }
    }
    // 分数列表
    .score_list {
      margin-top: 2vw;
      background-color: #ffffff;
      border-radius: 1vw;
      border: solid 1px $font-light;
      // overflow: hidden;
      .van-row {
        .van-col {
          height: 8vw;
          line-height: 8vw;
          font-size: 3.5vw;
          text-align: center;
        }
        .van-col:first-child {
          padding-left: 6vw;
          text-align: left;
        }
        .van-col:last-child {
          padding-right: 6vw;
          text-align: right;
          position: relative;
          .van-icon {
            color: $font-gray;
            position: absolute;
            top: 46%;
            right: 2vw;
            transform: translateY(-50%);
          }
        }
      }
      .score_list_head {
        background: $success-color;
        color: #ffffff;
        border-radius: 1vw 1vw 0 0;
      }
      .score_green {
        color: $success-color;
      }
      .score_orange {
        color: $theme-color;
      }
      .score_red {
        color: $warn-color;
      }
      .score_gray {
        color: $font-gray;
      }
    }
  }
}
</style>
