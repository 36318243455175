var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('topheader',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"question_header"},[_c('div',{staticClass:"question_tip"},[_c('div',{staticClass:"question_tip_left"},[_c('div',{staticClass:"question_time"},[_vm._v(" 课题： "),_c('span',{domProps:{"textContent":_vm._s(("(" + (_vm.beginTime.replace(/\-/g,'/')) + " - " + (_vm.endTime.replace(/\-/g,'/')) + ")"))}})]),_c('div',{staticClass:"score_box",domProps:{"textContent":_vm._s(("得分：" + (_vm.QSInfo.acscore) + "/" + (_vm.question.score)))}})])]),_c('div',{staticClass:"lessonName",domProps:{"textContent":_vm._s(_vm.lessonName)}})]),_c('div',{staticClass:"question_box",attrs:{"id":"question"}},[_c('div',{staticClass:"question_desc"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"question_type",domProps:{"textContent":_vm._s(_vm.questionTypeDesc)}}),_c('div',{staticClass:"question_title"},[_c('div',{staticClass:"question_subject"},[_c('div',[_vm._v("题目:")]),_c('div',{domProps:{"textContent":_vm._s(_vm.question.title)}})]),_c('div',{staticClass:"question_content"},[_c('div',[_vm._v("说明:")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.QSInfo.plan ? _vm.QSInfo.plan[0].content : '')}})])])]),(_vm.questionType === 1 || _vm.questionType === 2)?_c('div',{staticClass:"question_choice"},_vm._l((_vm.QSInfo.optioninfo),function(item,i){return _c('div',{key:i,staticClass:"answer",class:{
          'pitch': _vm.isPitchOn(item),
          'finish_right':item.iscorrect,
          'finish_wrong':_vm.isPitchOn(item) && !item.iscorrect
        }},[_c('span',{domProps:{"textContent":_vm._s(item.optionname)}}),(item.iscorrect)?_c('van-icon',{attrs:{"name":"success"}}):(_vm.isPitchOn(item) && !item.iscorrect)?_c('van-icon',{attrs:{"name":"cross"}}):_vm._e()],1)}),0):_vm._e(),(_vm.questionType === 3)?_c('ul',{staticClass:"question_gap_filling"},_vm._l((_vm.QSInfo.optioninfo),function(item,idx){return _c('li',{key:idx},[_c('label',[_vm._v("填空题"+_vm._s(idx + 1))]),_c('div',{staticClass:"question_gap_filling_answer"},[_c('van-field',{class:{ 
              'active': _vm.answering === idx,
              'tk_right': _vm.tkIsRight(item.optionname,_vm.temporary[idx]),
              'tk_wrong': !_vm.tkIsRight(item.optionname,_vm.temporary[idx]),
            },attrs:{"rows":"1","type":"textarea","autosize":"","placeholder":"请输答案...","right-icon":_vm.rightIcon(item.optionname,_vm.temporary[idx]),"disabled":"","error-message":''},on:{"focus":function($event){_vm.answering = idx},"blur":function($event){_vm.answering = undefined}},model:{value:(_vm.temporary[idx]),callback:function ($$v) {_vm.$set(_vm.temporary, idx, $$v)},expression:"temporary[idx]"}})],1)])}),0):_vm._e(),(_vm.questionType === 4)?_c('div',{staticClass:"question_short_answer"},[_c('van-field',{attrs:{"autosize":"","rows":"4","type":"textarea","placeholder":"请输入答案...","disabled":""},model:{value:(_vm.temporary[0].content),callback:function ($$v) {_vm.$set(_vm.temporary[0], "content", $$v)},expression:"temporary[0].content"}},[_c('div',{attrs:{"slot":"right-icon"},slot:"right-icon"},[_c('van-icon',{attrs:{"name":"upgrade"}})],1)]),_c('Files',{ref:"files",attrs:{"list":_vm.filesList,"openFile":""}})],1):_vm._e()]),_c('div',{staticClass:"buttons"},[[(_vm.total == 1)?[_c('van-button',{attrs:{"block":"","type":"primary","color":"#247dff"},on:{"click":_vm.checkResult}},[_vm._v("查看结果")])]:(_vm.QSInfo.pretdetailid)?_c('van-button',{attrs:{"block":"","plain":"","type":"primary","color":"#247dff"},on:{"click":_vm.last}},[_vm._v("上一题")]):(_vm.QSInfo.nextdetailid || _vm.questionIdx == 1 && _vm.total > 1)?_c('van-button',{attrs:{"block":"","plain":"","type":"primary","color":"#247dff"},on:{"click":_vm.next}},[_vm._v("下一题")]):_vm._e()],[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.total == 1 || (_vm.total > 1 && _vm.questionIdx == 1)),expression:"total == 1 || (total > 1 && questionIdx == 1)"}],staticClass:"empty"}),(
          _vm.total > 1 &&
          _vm.QSInfo.hasnext &&
          _vm.questionIdx != 1 &&
          _vm.questionIdx != _vm.total
        )?_c('van-button',{attrs:{"block":"","plain":"","type":"primary","color":"#247dff"},on:{"click":_vm.next}},[_vm._v("下一题")]):(_vm.total != 1 && _vm.total == _vm.questionIdx)?[_c('van-button',{attrs:{"block":"","type":"primary","color":"#247dff"},on:{"click":_vm.checkResult}},[_vm._v("查看结果")])]:_vm._e()]],2),_c('van-overlay',{attrs:{"show":_vm.showGrade}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"box"},[(!_vm.result.isend && !_vm.result.ischeck && _vm.overdue)?[_c('p',{staticClass:"title"},[_vm._v("查询结果")]),_c('p',{staticClass:"grade",staticStyle:{"margin-top":"19vw"}},[_vm._v("答题结束,暂无成绩")])]:[_c('p',{staticClass:"title"},[_vm._v("答卷提交成功")]),_c('p',{staticClass:"grade",staticStyle:{"margin-top":"19vw"}},[(_vm.result.ischeck && _vm.result.score !== null)?[_vm._v("本次作业"+_vm._s(_vm.result.score)+"分")]:[_vm._v("等待老师阅卷")]],2)],_c('div',{staticClass:"button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回上一页")]),_c('div',{staticClass:"back",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("返回首页")])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }