// 安装: yarn add qiniu-js
import * as qiniu from 'qiniu-js';
import request from '@/util/request';
import { Toast } from "vant";

// 上传文件

export function getQiniuToken() {
  return request.get('/Server/GetUploadToken')
}

/**
 * 七六云上传
 * 返回一个上传实例
 * 可通过 unsubscribe() 取消上传
 * @param file 需要上传的文件
 * @param finish(url) 完成时回调 url为图片地址
 */
export default function (file, finish = () => { }) {
  // 上传
  getQiniuToken().then(res => {
    const baseUrl = res.data.url;  // 文件地址域名
    const token = res.data.token;  // 七牛云token
    const key = new Date().getTime() + file.name.substr(file.name.lastIndexOf('.')); // 文件名

    // 文件额外信息
    let putExtra = {
      fname: file.name,
      params: {},
    }
    // 配置
    let config = {
      useCdnDomain: true,
    }
    // 监听
    let observer = {
      // 上传进度
      // next(res) { next(res.total.percent) },
      next(res) {
        console.log('上传进度', res.total.percent)//@todo
      },
      // 错误处理
      error(err) { console.error('qiniu', err); },
      // 上传完成
      complete(res) {
        finish(baseUrl + res.key);
      }
    };
    let observable = qiniu.upload(file, key, token, putExtra, config);
    return observable.subscribe(observer);
  }).catch(err => {
    console.error(err)
    Toast('获取七六云token失败');
  })

}
