<template>
  <!-- 专家栏目>>>作业/考试>>>老师给学员打分 -->
  <div class="page">
    <topheader :title="titleText" />

    <!-- 问题描述 -->
    <div class="question_box">
      <div class="question_box_1st">
        <div class="icon_question"></div>
        <div class="question_title" v-text="questionInfo.caption"></div>
      </div>
      <div class="question_box_2nd" v-text="questionInfo.desc"></div>
    </div>

    <!-- 回答内容 -->
    <div class="answer_box">
      <div class="answer_box_1st">
        <div class="icon_answer"></div>
      </div>
      <div class="answer_box_2nd student_info">
        <div class="avatar">
          <img :src="studentInfo.headUrl" v-if="studentInfo.headUrl" />
          <img :src="defaultSrc" v-else />
        </div>
        <div class="infomation">
          <div class="name" v-text="studentInfo.name"></div>
          <div class="time">
            {{
            studentInfo.submitTime
            | timeStamp
            | dateFormat('yyyy-MM-dd hh:mm:ss')
            }}
          </div>
        </div>
        <div class="tag" v-if="ischeck">
          <van-tag plain type="warning">已评分</van-tag>
        </div>
      </div>
      <div
        class="answer_box_3th"
        :class="{gray: studentInfo.answer == '该学生未填写内容'}"
        v-text="studentInfo.answer"
      ></div>
    </div>

    <!-- 评分 -->
    <van-field
      class="grade"
      input-align="right"
      v-model="score"
      type="number"
      label="评分"
      placeholder="请输入分数"
    />
    <!-- :disabled="ischeck" -->

    <!-- 最高得分 -->
    <div class="hight_score" v-text="`最高得分 ${questionInfo.maxScore}`"></div>

    <!-- 附件 -->
    <div class="fileList">
      <p v-text="`学员附件： ${studentInfo.files.length}件`"></p>
      <Files :list="studentInfo.files" ref="files" openFile @delete="deleteFile" />
    </div>

    <!-- 按钮 -->
    <van-button round type="info" block class="submit" :class="{ hide: hideBtn }" @click="submit">提交</van-button>
    <!-- , opacity5: ischeck -->
    <!-- :disabled="ischeck" -->

    <!-- 打分成功弹出层 -->
    <van-dialog
      v-model="gradingSucc"
      title="评分成功"
      show-cancel-button
      cancel-button-text="停留当前页"
      confirm-button-text="返回上一页"
      @confirm="$router.back()"
    >
      <div class="successImg">
        <img src="@/assets/images/succend.png" />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import {
  teachGetHWQSInfo,
  hwMarking,
  teachGetExamQSInfo,
  ExamMarking
} from '@/api/classRing'
import Files from '@/components/extend/files/index'
export default {
  components: { Files },
  data() {
    return {
      userId: this.$store.state.user.userId,
      planId: this.$route.query.planId,
      recordId: this.$route.query.recordId,
      qsId: this.$route.query.qsId,
      defaultSrc: require('@/assets/images/head_prole.png'),

      score: '', //评分
      questionIcon: require('@/assets/images/yuyin.png'),
      answerIcon: require('@/assets/images/yuyin.png'),
      type: this.$route.params.type, // 1-作业 2-考试

      docmHeight: document.documentElement.clientHeight, //一开始的屏幕高度
      showHeight: document.documentElement.clientHeight, //一开始的屏幕高度
      hideBtn: false,

      questionInfo: {}, //问题描述
      studentInfo: {
        files: []
      }, //学员信息及答题内容
      gradingSucc: false,
      ischeck: false //是否已打分（允许已打分的题目重新评分）
    }
  },
  watch: {
    showHeight() {
      //检测屏幕高度变化
      if (!this.timer) {
        this.timer = true
        let that = this
        setTimeout(() => {
          if (that.docmHeight > that.showHeight) {
            //显示class
            this.hideBtn = true
          } else if (that.docmHeight <= that.showHeight) {
            //显示隐藏
            this.hideBtn = false
          }
          that.timer = false
        }, 20)
      }
    }
  },
  computed: {
    titleText() {
      return this.type === '1' ? '作业简答题评分' : '考试简答题评分'
    }
  },
  methods: {
    getAnswerDetail() {
      this.getData().then(res => {
        this.questionInfo = {
          caption: res.planname,
          desc: res.questioninfo[0].title,
          maxScore: res.questioninfo[0].score
        }
        this.studentInfo = {
          headUrl: res.headurl,
          name: res.name,
          submitTime: this.$route.query.submitTime,
          answer: this.setAnswer(res.answer),
          files: res.file //附件
        }
        if (res.acscore != null) this.score = res.acscore //存在0分
        this.ischeck = res.ischeck //是否已打分
      })
    },
    getData() {
      let params = {
        recordid: this.recordId,
        qsid: this.qsId,
        userid: this.userId
      }
      switch (this.type) {
        case '1': //作业
          params.hwplanid = this.planId
          return teachGetHWQSInfo(params)
        case '2': //考试
          params.explanid = this.planId
          return teachGetExamQSInfo(params)
      }
    },
    setAnswer(str) {
      const arr = str.split('_')
      if (arr.length > 1) {
        return arr[1] ? arr[1] : '该学生未填写内容'
      } else {
        return str ? str : '该学生未填写内容'
      }
    },
    deleteFile(item) {},
    // 打分
    grading() {
      const params = {
        recordid: this.recordId,
        qsid: this.qsId,
        userid: this.userId,
        score: this.score
      }
      switch (this.type) {
        case '1':
          params.hwplanid = this.planId
          return hwMarking(params)
        case '2':
          params.explanid = this.planId
          return ExamMarking(params)
      }
    },
    submit() {
      if (this.score === '') {
        this.$toast('还没给同学打分？')
      } else if (Number(this.score) == NaN) {
        this.$toast('请输入正确的数字格式')
      } else {
        if (this.score < 0 || this.score > this.questionInfo.maxScore) {
          this.$toast(`请输入0~${this.questionInfo.maxScore}范围内的分数`)
        } else {
          let t = this.ischeck ? '确定修改分数' : '确定提交'
          this.$dialog
            .confirm({
              title: t,
              message: `当前评分为 ${this.score} 分`
            })
            .then(() => {
              // 打分
              this.grading().then(res => {
                this.gradingSucc = true
              })
            })
        }
      }
    }
  },
  mounted() {
    this.getAnswerDetail()

    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight
        this.showHeight = window.screenHeight
      })()
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  position: relative;
  // 问题
  .question_box {
    padding: 4vw 3.2vw 4.267vw 3.2vw;
    position: relative;
    .question_box_1st {
      display: flex;
      align-items: center;
      .icon_question {
        @include wh(5.333vw, 4vw);
        position: relative;
        background-image: linear-gradient(90deg, #ffc037 0%, #feac13 100%),
          linear-gradient(#a2a6a7, #a2a6a7);
        border-radius: 0% 1vw 1vw 1vw;
      }
      .icon_question::after {
        content: 'Q';
        display: block;
        font-size: 3.2vw;
        @include center;
        color: #fff;
      }
      .question_title {
        margin-left: 3.2vw;
        font-size: 4vw;
      }
    }
    .question_box_2nd {
      @include font(3.2vw, 4.267vw);
      padding-top: 2.133vw;
      padding-left: 8.533vw;
      color: $font-gray;
    }
  }
  .question_box::after {
    content: '';
    display: block;
    width: calc(100% - 6.4vw);
    border-bottom: 1px solid $border-light;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  // 答案
  .answer_box {
    padding: 3.2vw;
    .answer_box_1st {
      height: 4vw;
      position: relative;
      padding-bottom: 1.333vw;
      .icon_answer {
        @include wh(5.333vw, 4vw);
        display: inline-block;
        position: relative;
        background-image: linear-gradient(90deg, #ffc037 0%, #feac13 100%),
          linear-gradient(#a2a6a7, #a2a6a7);
        border-radius: 0% 1vw 1vw 1vw;
      }
      .icon_answer::after {
        content: 'A';
        display: block;
        color: #fff;
        font-size: 3.2vw;
        @include center;
      }
    }
    .answer_box_2nd {
      display: flex;
      align-items: center;
      .avatar {
        @include wh(8.8vw, 8.8vw);
        @include borderRadius(50%);
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .infomation {
        margin-left: 2.133vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          font-size: 3.2vw;
        }
        .time {
          font-size: 2.933vw;
          color: $font-gray;
        }
      }
      .tag {
        flex: 1;
        .van-tag {
          float: right;
        }
      }
    }
    .answer_box_3th {
      padding-top: 2.4vw;
      padding-left: 10.933vw;
      @include font(3.2vw, 6.4vw);
    }
    .gray {
      color: $font-gray;
    }
  }
  // 打分
  .grade {
    border-top: 1.6vw solid $bg-light;
    border-bottom: 1.6vw solid $bg-light;
  }

  // 最高分
  .hight_score {
    padding-right: 3.2vw;
    @include font(3.733vw, 11.733vw);
    color: #65b529;
    text-align: right;
  }

  // 学员附件
  .fileList {
    > p {
      padding: 0 4.533vw;
      background: $bg-gray;
      @include font(3.2vw, 8.533vw);
    }
  }

  // 提交
  .submit {
    margin: 0 4vw;
    width: calc(100% - 8vw);
    position: fixed;
    bottom: 4vw;
  }
  .hide {
    position: static !important;
  }
  .opacity5 {
    opacity: 0.5;
  }
  .van-dialog {
    // width: 60%;
  }
  .successImg {
    @include wh(100%, 53.333vw);
    position: relative;
    img {
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
