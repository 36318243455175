import request from '@/util/request'
import Store from '@/store'

// 班圈相关

// // 获取班圈类别
// export function classRingType() {
//   return request.get('/classRound/index');
// }

// // 获取当前班圈课程列表
// export function getCurriculumTable(type,page) {
//   const limit = 10;
//   return request.get('/lesson/index',{
//     class_round: type,
//     limit,
//     page
//   }, false);
// }

// // 获取课程简介
// export function getCourseIntroduction(lessonId) {
//   return request.get('/lesson/introduction',{
//     lesson_id: lessonId
//   })
// }

// 判断课程购买状态
export function checkBuy(lessonId) {
  return request.get('/lesson/checkBuy', {
    lesson_id: lessonId,
  })
}

// 获取课程详细信息
export function getCourseDetails(lessonId) {
  return request.get('/lesson/detail', {
    lesson_id: lessonId,
  })
}

// // 获取评论列表
// export function getReviewList(lessonId,isBuy,page) {
//   const limit = 10;
//   if (isBuy) {
//     return request.get('/Comment/feeIndex',{
//       lesson_id: lessonId,
//       limit,
//       page
//     },false);
//   } else {
//     return request.get('/Comment/freeIndex',{
//       lesson_id: lessonId,
//       limit,
//       page
//     },false);
//   }
// }

// 发送评论
// export function sendComment(lessonId, content, isBay) {
//   if (isBay) {
//     return request.post('/Comment/addFee',{
//       data:{lesson_id:lessonId,content},
//       tips:'评论发送中'
//     })
//   } else {
//     return request.post('/Comment/addFree',{
//       data:{lesson_id:lessonId,content},
//       tips:'评论发送中'
//     })
//   }
// }

// // 获取课程列表
// export function getCourseList(lessonId,isBuy) {
//   if (isBuy) { // 买了
//     return request.get('/lesson/getFeeVideo',{lesson_id: lessonId});
//   } else { // 没买
//     return request.get('/lesson/getFreeVideo',{lesson_id: lessonId});
//   }
// }

// // 获取课堂作业列表
// export function getWorkList(lessonId,page) {
//   const limit = 10;
//   return request.get('/Work/index',{
//     lesson_id: lessonId,
//     limit,
//     page
//   });
// }

// 获取课堂作业详情
export function getWorkInfo(workId) {
  return request.get('/Work/workDetail', { work_id: workId })
}

// 获取测试练习列表
export function getPracticeTestList(lessonId, page) {
  const limit = 10
  return request.get('/test/index', { lesson_id: lessonId, limit, page }, false)
}

// // 获取题组
// export function getProblemSets(testId) {
//   return request.get('/test/detail',{test_id:testId});
// }

// // 上传答案
// export function uploadAnswer(examinationId,answerName) {
//   return request.post('/test/addExaminationRecord',{
//     data:{
//       examination_id:examinationId,
//       answer:answerName
//     },
//     isLoading:false
//   })
// }

// // 提交答案(用于计算分数)
// export function submit(testId) {
//   return request.post('/test/commit',{
//     data:{test_id:testId}
//   })
// }

// 获取测试分数列表
export function getTestScores(lessonId, page) {
  const limit = 20
  return request.get('/TestRecord/recordIndex', {
    lesson_id: lessonId,
    limit,
    page,
  })
}

// // 搜索(课程专场)
// export function searchCourses(key) {
//   const limit = 50;
//   const page = 1;
//   return request.get('/lesson/search',{key,limit,page})
// }

// // 搜索(专家解答)
// export function searchExpert(key) {
//   const limit = 50;
//   const page = 1;
//   return request.get('/professor/search',{key,limit,page})
// }

// // 搜索（专家）add -> 2020/3/24
// export function searchExperts(key) {
//   const limit = 50
//   const page = 1
//   return request.get('/professor/search', { key, limit, page })
// }
// // 搜索（班圈）add -> 2020/3/24
// export function searchClassRing(key) {
//   const limit = 50
//   const page = 1
//   return request.get('/lesson/search', { key, limit, page })
// }
// // 搜索（课程）add -> 2020/3/24 @todo 接口
// export function searchCourses(key) {
//   const limit = 50
//   const page = 1
//   return request.get('/professor/search', { key, limit, page })
// }
// // 搜索（案例）add -> 2020/3/24 @todo 接口
// export function searchCase(key) {
//   const limit = 50
//   const page = 1
//   return request.get('/professor/search', { key, limit, page })
// }

// 添加作业
export function addWork(workId, works) {
  return request.post('/workRecord/add', {
    data: { work_id: workId, reply_pic: works },
  })
}

// 更新作业(works:array转逗号拼接的字符串)
export function postWork(wordRecordId, works) {
  return request.post('/workRecord/update', {
    data: { work_record_id: wordRecordId, reply_pic: works },
  })
}

// // 获取轮播图
// export function getCarousel() {
//   return request.get('/Ads/index')
// }

/* ------------------------二次开发：start(by：yyz) ------------------------ */
// 获取轮播图
export function getCarousel() {
  return request.get('/Server/GetAllBannerList')
}
// 获取班圈分类
export function classRingType() {
  return request.get('/Server/GetAllClassGroupList')
}
// 获取1类班圈(当前班圈课程列表)
export function getCurriculumTable(params) {
  let { userid, autoid, page } = params
  return request.get(
    '/Server/GetClassList',
    {
      userid,
      class_round: autoid,
      limit: 10,
      page,
    },
    false
  )
}
// 获取班圈简介（获取一个班圈信息）
export function getOneClassinfo(autoid) {
  return request.get('/Server/GetOneClassinfo', {
    autoid,
    userid: Store.state.user.userId,
  })
}
// 获取课程列表
export function getCourseList(classid) {
  return request.post('/Server/GetLessonList', {
    data: {
      classid,
      size: -1,
      userid: Store.state.user.userId,
    },
  })
}
// 获取评论
export function getReviewList(classid, autoid = 0, isloading = true) {
  return request.get('/Server/GetReply', { classid, autoid }, isloading)
}
// 创建评论
export function sendComment(params) {
  let { classid, userid, content, replymode, sec, mediaId } = params
  return request.post('/Server/CreateReply', {
    data: {
      classid, //班圈id
      userid, //用户
      content, //文本输入
      replymode: replymode === '1' ? '文本' : '语音', //评论类型 [文本,语音]
      sec, //语音时长(秒)
      mediaId, //音频id
    },
    tips: '评论发送中',
  })
}
// 删除评论
export function delReply(autoid) {
  return request.post('Server/DelReply', {
    data: {
      autoid,
    },
  })
}
// 获取公告列表
export function getNoticeList(classid, curpage) {
  return request.get('/Server/GetNoticeList', {
    classid, //班圈autoid
    size: 10, //单页显示个数
    curpage, //页数
  })
}
// 获取公告详情
export function getOneNoticeinfo(autoid) {
  return request.get('/Server/GetOneNoticeinfo', {
    autoid, //公告id
  })
}

// (专家)查询是否可以再发起签到
export function allowStarSign(courseid, userid) {
  return request.get(
    '/Server/AllowStarSign',
    {
      courseid, //课程id,
      userid, //用户id
    },
    false
  )
}
// (专家)发起签到申请
export function createStarSign(params) {
  const { courseid, userid, signinterval } = params
  return request.get('/Server/CreateStarSign', {
    courseid, //课程id,
    userid, //用户id
    signinterval //学员签到时限
  })
}
// (学员)查询是否要签到
export function getSignal(courseid, stuid) {
  return request.get(
    '/Server/GetSignal',
    {
      courseid, //课程id,
      stuid, //用户id
    },
    false
  )
}
// (学员)执行直播签到
export function createSignal(courseid, stuid, starsignid) {
  return request.get('/Server/CreateSignal', {
    courseid, //课程id,
    stuid, //用户id
    starsignid, //发起签到记录id
  })
}

// 获取资料列表
export function getFileList(classid, size = -1, curpage = 10) {
  return request.get('/Server/GetFileList', {
    classid, //班圈id （不传返回全部）
    size, //页码
    curpage, //页数
  })
}
// 获取学员列表
export function getClassPeopleInfo(userid, classid) {
  return request.get('/Server/GetClassPeopleInfo', {
    userid, classid
  })
}

// 获取作业列表
export function getHomeworkList(classid, stuid, courseid, loading = true) {
  if (stuid) {
    //学员
    return request.get('/Server/Stu_GetHWPlanList', {
      classid, //班圈id
      stuid,
      courseid //课程id
    }, loading)
  } else {
    //专家
    return request.get('/Server/Tea_GetHWPlanList', {
      classid, //班圈id
      courseid //课程id
    }, loading)
  }
}

// 创建作业记录
export function createHWkRecord(hwplanid, stuid) {
  return request.post('/Server/Stu_InHWPlan', {
    data: { hwplanid, stuid },
  })
}

// 获取题目信息（学员）
export function geteHWQuestion(params) {
  const { hwplanid, recordid, qsid } = params;
  return request.post('/Server/Stu_GetHWQSInfo', {
    data: { hwplanid, recordid, qsid },
  })
}

// 学员提交答案
export function submitHWAnswer(params) {
  const { hwplanid, recordid, qsid, questionid, answer, isend = 0 } = params;
  return request.post('/Server/Stu_SendHWQSanswer', {
    data: {
      hwplanid,
      recordid,
      qsid,
      questionid,
      answer,
      isend
    },
    type: 'json',
  })
}

// 上传作业附件
export function uploadHWFiles(params) {
  const { title, qsid, url, autoid, type } = params
  return request.post("/Server/EditeHWFile", {
    data: { title, qsid, url, autoid, type }
  })
}

// 获取作业状态
export function getHWRecord(recordid) {
  return request.get("/Server/Stu_GetHWRecordInfo", {
    recordid
  })
}












// 获取考试列表
export function getExamList(classid, stuid, courseid, loading = true) {
  if (stuid) {//学员
    return request.get("/Server/Stu_GetEXPlanList", {
      classid, stuid, courseid
    }, loading)
  } else {//班主任/导师
    return request.get("/Server/Tea_GetEXPlanList", {
      classid, courseid
    }, loading)
  }
}

// 创建考试记录
export function createExamRecord(explanid, stuid) {
  return request.post("/Server/Stu_InEXPlan", {
    data: { explanid, stuid }
  })
}

// 获取考试题目信息
export function getExamQuestion(params) {
  const { explanid, recordid, qsid } = params
  return request.post("/Server/Stu_GetEXQSInfo", {
    data: { explanid, recordid, qsid }
  })
}

// 提交考试答题
export function submitExamAnswer(params) {
  const { explanid, recordid, qsid, questionid, answer, isend = 0 } = params;
  return request.post('/Server/Stu_SendEXQSanswer', {
    data: {
      explanid,
      recordid,
      qsid,
      questionid,
      answer,
      isend
    },
    type: 'json',
  })
}

// 学员获取1条题目信息（作业|禁止答题后通过detailid查询）
export function stuGetHWQSInfoFromDetail(params) {
  const { hwplanid, recordid, detailid } = params
  return request.get("/Server/Stu_GetHWQSInfoFromDetail", { hwplanid, recordid, detailid })
}

// 学员获取1条题目信息（考试|禁止答题后通过detailid查询）
export function stuGetEXQSInfoFromDetail(params) {
  const { explanid, recordid, detailid } = params
  return request.get("/Server/Stu_GetEXQSInfoFromDetail", { explanid, recordid, detailid })
}

// 上传考试附件
export function uploadExamFiles(params) {
  const { title, qsid, url, autoid, type } = params
  return request.post("/Server/EditeEXFile", {
    data: { title, qsid, url, autoid, type }
  })
}

// 获取考试状态
export function getExamRecord(recordid) {
  return request.get("/Server/Stu_GetEXRecordInfo", {
    recordid
  })
}

// 获取用户推荐二维码信息
export function getStuShareCode(userid, classid) {
  return request.get("/Server/GetStuShareCOde", {
    userid, classid
  })
}









// 专家获取作业提交列表
export function getStuHWRecords(hwplanid) {
  return request.get("/Server/Tea_GetHWRecordList", {
    hwplanid
  })
}

// 老师获取考试提交列表
export function getStuExamRecords(explanid) {
  return request.get("/Server/Tea_GetEXRecordList", {
    explanid
  })
}



// 老师获取作业方案某学员某题的答题情况
export function teachGetHWQSInfo(params) {
  // const { hwplanid, recordid, qsid, userid } = params
  return request.post("Server/Tea_GetHWQSInfo", {
    // data: { hwplanid, recordid, qsid, userid }
    data: params
  })
}

// 老师获取考试方案某学员某题的答题情况
export function teachGetExamQSInfo(params) {
  const { explanid, recordid, qsid, userid } = params
  return request.post("Server/Tea_GetEXQSInfo", {
    data: { explanid, recordid, qsid, userid }
  })
}



// 作业简答题老师打分
export function hwMarking(params) {
  const { hwplanid, recordid, qsid, userid, score } = params
  return request.post("/Server/Tea_HWQSScoring", {
    data: { hwplanid, recordid, qsid, userid, score }
  })
}

// 考试简答题老师打分
export function ExamMarking(params) {
  const { explanid, recordid, qsid, userid, score } = params
  return request.post("/Server/Tea_EXQSScoring", {
    data: { explanid, recordid, qsid, userid, score }
  })
}






// 班圈搜索
export function classSearch(keyword, curpage) {
  return request.get("/Server/SearchClassList", {
    keyword, curpage, size: 10
  })
}

// 专家搜索
export function expertSearch(keyword, curpage) {
  return request.get("/Server/SearchTeacherList", {
    keyword, curpage, size: 10
  })
}

// 课程搜索
export function lessonSearch(keyword, curpage) {
  return request.get("/Server/SearchLessonList", {
    keyword, curpage, size: 10
  })
}

// 案例搜索
export function caseSearch(keyword, curpage) {
  return request.get("/Server/SearchCaseList", {
    keyword, curpage, size: 10
  })
}

// 关注/取消关注用户
export function followMan(userid, fouserid, type) {
  return request.get("/Server/LikeUser", {
    userid, fouserid, type
  })
}

// 置顶班圈评论
export function toTopReply(autoid) {
  return request.get("/Server/TopReply", {
    autoid
  })
}

// 置顶公告
export function toTopNotice(autoid) {
  return request.get("/Server/TopNotice", {
    autoid
  })
}

// 课程学习记录
export function greateCourseLog(courseid, stuid) {
  return request.get("/Server/CreateCourseLog", {
    courseid, stuid
  }, false)
}






// 获取某个班圈的班圈分组列表
export function getAllStuGroupList(classid) {
  return request.get("/Server/GetAllStuGroupList", {
    classid
  })
}
// 编辑班圈分组
export function editeStuGroup(params) {
  const { classid, name, sqnum, autoid, type } = params
  return request.post('/Server/EditeStuGroup', {
    data: { classid, name, sqnum, autoid, type }
  })
}
// 获取某个班圈分组的所有成员
export function getStuListByGroup(groupid, classid) {
  return request.get('/Server/GetStuListByGroup', {
    groupid, classid
  })
}
// 添加某个班圈分组的成员
export function addGroupingStu(groupid, stuidlist) {
  return request.post('/Server/AddGroupingStu', {
    data: { groupid, stuidlist }
  })
}
// 删除某个班圈分组的成员(单个) -- 暂不适用
export function delGroupingStu(groupid, stuid) {
  return request.post('/Server/DelGroupingStu', {
    data: { groupid, stuid }
  })
}
// 批量设置某个班圈分组的成员列表
export function groupingStu(groupid, stuidlist) {
  return request.post('/Server/GroupingStu', {
    data: { groupid, stuidlist }
  })
}
// 课程详情页 - 作业置顶
export function toTopHomework(autoid) {
  return request.get('/Server/TopHWPlan', {
    autoid
  })
}
// 课程详情页 - 考试置顶
export function toTopExam(autoid) {
  return request.get('/Server/TopEXPlan', {
    autoid
  })
}

// 记录视频播放时间[直播/录播]
export function setVideoLogTime(params) {
  const { courseid, stuid, autoid, type } = params
  return request.get('/Server/LogWatchTime', {
    courseid, stuid, autoid, type
  }, false)
}

// 发起直播录制
export function saveRecord(params) {
  const { courseid, start, end } = params
  return request.get('/Server/SaveRecord', {
    courseid, start, end
  }, false)
}


/* ------------------------二次开发：end(by：yyz) ------------------------ */
