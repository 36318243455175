const getters = {
  isLogin: state => Boolean(state.user.userId),
  userId: state => state.user.userId,
  name: state => state.user.name,
  headImg: state => state.user.headImg,
  tel: state => state.user.tel,
  isExpert: state => state.user.isExpert,
  personalCenterInfo: state => state.user.personalCenterInfo,
  info: state => state.user.info,
}
export default getters;
