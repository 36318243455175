<template>
  <!-- 课程列表 组件：作废处理，已经改用lessonList组件 -->
  <div>
    <div v-for="(item, i) in list" :key="'classring' + i" class="classring_list" @click="act(item)">
      <!-- 图片 -->
      <div class="classring_list_left">
        <img :src="item.cover" alt />
      </div>
      <!-- 内容 -->
      <div class="classring_list_right">
        <span class="classring_list_title">{{ item.title }}</span>
        <p class="classring_list_bottom">
          <span v-text="item.teacher">xx老师</span>
          <span>{{ item.paytime | timeStamp | dateFormat("yyyy/MM/dd") }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    act(item) {
      //触发点击事件
      this.$emit('click', item)
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.classring_list {
  width: calc(100% - 6.4vw);
  height: 26.933vw;
  background: #fff;
  margin: 2.667vw 3.2vw 0 3.2vw;
  padding: 0 1.333vw;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @include borderRadius(1.067vw);
  .classring_list_left {
    width: 36.533vw;
    height: 24.267vw;
    border-radius: 1.067vw;
    overflow: hidden;
    // box-shadow: $box-shadow;
    img {
      @include wh(100%, 100%);
      object-fit: contain;
    }
  }
  .classring_list_right {
    flex: 1;
    height: 24.267vw;
    margin-left: 3.467vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 2.133vw;
    .classring_list_title {
      font-size: 4.267vw;
      line-height: 6.4vw;
      color: #303133;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .classring_list_bottom {
      font-size: 2.933vw;
      color: #909399;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-top: 5vw;
      }
    }
  }
}
</style>
