
import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant';




// 正在进行中的请求列表
let reqList = []

/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function (reqList, url, cancel, errorMessage) {
  const errorMsg = errorMessage || ''
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      cancel(errorMsg)
      return
    }
  }
  reqList.push(url)
}

/**
 * 允许某个请求可以继续进行
 * @param {array} reqList 全部请求列表
 * @param {string} url 请求地址
 */
const allowRequest = function (reqList, url) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      reqList.splice(i, 1)
      break
    }
  }
}


class Service {
  constructor() {
    this.service = this._setUpService()
  }

  /**
   * GET
   * @param url        传输地址
   * @param data      传输数据
   * @param isLoading 显示加载
   * @param tips      加载提示
   * @returns {*}
   */
  get(url = '', data, isLoading = true, tips = '加载中') {
    if (isLoading) {
      Toast.loading({
        message: tips,
        overlay: true, // 背景遮罩
        forbidClick: true, // 禁用背景点击
        duration: 0, // 手动取消
      });
    }
    const option = {
      url,
      params: data,
      method: 'get'
    };
    return this._request(option)
  }

  /**
   * POST
   * @param url       传输地址
   * @param data      传输数据
   * @param type      请求类型
   * @param isLoading 显示加载
   * @param tips      加载提示
   */
  post(url = '', { data = {}, type = 'x-www-form-urlencoded', isLoading = true, tips = '加载中' }) {
    if (isLoading) {
      Toast.loading({
        message: tips,
        overlay: true, // 背景遮罩
        forbidClick: true, // 禁用背景点击
        duration: 0, // 手动取消
      });
    }
    const option = {
      url,
      method: 'post',
    }
    if (type === 'json') {
      // 发送json
      option.headers = { 'Content-Type': 'application/json;charset=UTF-8' }
      option.data = data
    } else if (type === 'form-data') {
      // 文件发送
      option.headers = { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
      const form = new FormData()
      for (const key in data) {
        const value = data[key]
        form.append(key, value)
      }
      option.data = form
    } else {
      // 表单发送
      option.data = qs.stringify(data)
    }
    return this._request(option)
  }
  // 创建请求服务
  _setUpService() {
    const url = 'https://heying.hyclound.cn:8015/';
    const config = { baseURL: url, timeout: 30 * 1000 }

    const service = axios.create(config)

    service.interceptors.request.use(
      config => {

        // let cancel
        // // 设置cancelToken对象
        // config.cancelToken = new axios.CancelToken(function (c) {
        //   cancel = c
        // })
        // // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
        // stopRepeatRequest(reqList, config.url, cancel, `${config.url} 请求被中断`)

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
    service.interceptors.response.use(
      response => {
        // allowRequest(reqList, response.config.url)

        Toast.clear();
      const res = response.data;
        const port = response.config.url;
        // if (process.env.NODE_ENV !== 'production') {
        // console.warn(`接口${port.split('/')[port.split('/').length - 1]}：`, JSON.parse(JSON.stringify(res)))
        // }
        return new Promise((resolve, reject) => {
          switch (typeof res) {
            case "string": resolve(res); break;
            case "object": {
              // special
              //获取微信支付参数 | 获取JSSDK秘钥（微信授权参数）| 购买答案支付接口 | 打赏支付接口
              if (['/WxPay/WxPayOrderBM', '/Server/GetJSSDK', '/WxPay/WxPayOrderWD', '/WxPay/WxPayOrderDS'].includes(port)) {
                if (res.appId) resolve(res);
                else this._reject(reject, port, res);
              }
              // 查询课程签到相关 
              else if (['/AllowStarSign', '/GetSignal'].includes(port)) {
                resolve(res)
              }
              // normal 
              else if (res.error === '0') {
                resolve(res);
              } else {
                this._reject(reject, port, res);
              }
            }
          }
        })
      },
      error => {
        // if (axios.isCancel(thrown)) {
        //   console.log(thrown.message);
        // } else {
        //   // // 建议增加延迟，相同请求不得在短时间内重复发送
        //   // allowRequest(reqList, error.config.url)
        // }
        Toast.clear();
        return Promise.reject(error)
      }
    )
    return service
  }
  // 发送请求服务
  _request(option) {
    return this.service.request(option)
  }
  _reject(reject, port, res) {
    const msg = res.msg ? res.msg : "接口访问失败";
    Toast(msg);
    console.error(`接口“${port}”失败，错误提示：${msg}`)
    return reject(res)
  }
}

const service = new Service();
export default service
