<template>
  <div class="personals">
    <topheader :title="titleText" />
    <div class="personals_title">公司信息</div>
    <div class="company">
      <div class="company_group">
        <van-field v-model="company_name" label="公司名称" placeholder="请输入公司名称" />
      </div>
      <div class="company_group">
        <van-field v-model="company_address" label="公司地址" placeholder="请输入公司地址" />
      </div>
    </div>
    <div class="personals_title">学员信息</div>
    <div class="company">
      <div class="company_group">
        <van-field v-model="student_name" label="学员姓名" placeholder="请输入学员姓名" />
      </div>
      <div class="company_group">
        <van-popup v-model="showStatus" position="bottom">
          <van-picker
            :columns="columns"
            cancel-button-text=" "
            show-toolbar
            @confirm="selectStatus"
          />
        </van-popup>
        <div class="company_group">
          <van-field v-model="sex" label="性别" is-link @click="sexSelect" />
        </div>
      </div>
      <div class="company_group">
        <van-field v-model="section" label="部门/职务" placeholder="请输入部门/职务" />
      </div>
      <div class="company_group">
        <van-field v-model="student_tel" label="联系电话" placeholder="请输入联系电话" />
      </div>
    </div>
    <div :class="`affirm bton ${all ? 'lucency' : ''}`" @click="affirm">确认修改</div>
  </div>
</template>

<script>
import { telTest } from '@/util/verify'
import { updateUserInfo, getUserInfo } from '@/api/my'

export default {
  data() {
    // 注意 学员/用户 id
    let titleText = this.$route.query.studentUserId
      ? '修改学员信息'
      : '个人信息'
    return {
      studentUserId: this.$route.query.studentUserId,
      show: false,
      showStatus: false,
      userId: this.$store.state.user.userId,
      company_name: '',
      company_address: '',
      student_name: '',
      sex: '',
      student_tel: '',
      section: '',
      columns: ['不公开', '男', '女'],
      titleText //头部标题
    }
  },
  computed: {
    all() {
      return (
        this.company_name &&
        this.company_address &&
        this.student_name &&
        this.sex &&
        this.section &&
        this.student_tel
      )
    }
  },
  methods: {
    init() {
      // 老师要修改学员信息
      if (this.studentUserId) {
        getUserInfo(this.studentUserId).then(res => {
          this.company_name = res.company
          this.company_address = res.comaddress
          this.student_name = res.name
          this.sex = res.sex || '不公开'
          this.student_tel = res.tel
          this.section = res.job
        })
      } else {
        //自己修改自己的信息
        this.company_name = this.$store.getters.info.company
        this.company_address = this.$store.getters.info.comaddress
        this.student_name = this.$store.getters.info.name
        this.sex = this.$store.getters.info.sex || '不公开'
        this.student_tel = this.$store.getters.info.tel
        this.section = this.$store.getters.info.job
      }
    },
    // 选择性别
    sexSelect() {
      this.showStatus = true
    },
    selectStatus(value) {
      ;(this.sex = value), (this.showStatus = false)
    },
    // 确认修改
    affirm() {
      if (this.all) {
        if (!telTest(this.student_tel)) {
          this.$toast('学员联系电话填写错误')
          return
        }
        const userId = this.studentUserId ? this.studentUserId : this.userId
        updateUserInfo({
          type: 2,
          autoid: userId,
          company: this.company_name,
          comaddress: this.company_address,
          name: this.student_name,
          sex: this.sex,
          job: this.section,
          tel: this.student_tel
        })
          .then(res => {
            if (!this.studentUserId) {
              // 非老师修改学生信息的  要更新本地store数据
              this.$store.dispatch('user/loginByOpenId')
            }
            this.$toast('修改成功')
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.personals {
  @include isTop;
}
input::-webkit-input-placeholder {
  font-size: 4.267vw;
  color: #bfc2cc;
}
.lucency {
  opacity: 1 !important;
}
.affirm {
  width: 92vw;
  height: 12.533vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  background-color: #247dff;
  opacity: 0.4;
  border-radius: 1.333vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8vw;
}
.company_group_flex {
  display: flex;
  justify-content: space-between;
  width: 65vw;
}
.op {
  color: #000 !important;
}
.company_group_name {
  font-size: 4.267vw;
  color: #969799;
}
.van-cell {
  padding: 0;
}
.sex_title {
  /*font-size: 4.267vw;*/
  color: #303133;
  width: 90px;
}
.jiangtou {
  color: #bfc2cc;
}
.company_group {
  width: 93.6vw;
  height: 12.733vw;
  border-bottom: 0.133vw solid #edeff2;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.company_group:last-child {
  border-bottom: none;
}
.company {
  width: 100vw;
  background-color: #ffffff;
}
.personals_title {
  width: 100vw;
  height: 9.333vw;
  display: flex;
  align-items: center;
  text-indent: 3.467vw;
  font-size: 3.467vw;
  color: #909399;
}
.personals {
  width: 100vw;
  height: 100vh;
  background-color: #f5f7fa;
}
</style>
