<template>
  <div class="page">
    <div class="register_title">注册账号</div>
    <div class="register_title_welcome">欢迎加入我们</div>
    <div class="input_box">
      <div class="input_box_xia">
        <van-field v-model="username" type="text" placeholder="真实姓名" />
      </div>
      <div class="input_box_xia">
        <van-field v-model="tel" type="tel" placeholder="手机号码" />
      </div>
      <div class="input_box_xia">
        <van-field v-model="code" type="tel" placeholder="验证码" />
        <div
          class="code_gain"
          v-if="!showGetCode || countDown !== 0"
        >{{ countDown === 0 ? "获取验证码" : `${countDown}秒重新获取` }}</div>
        <div
          class="code_gain code_gain-on bton"
          v-if="showGetCode && countDown === 0"
          @click="getCode"
        >获取验证码</div>
      </div>
      <div class="input_box_xia">
        <van-field v-model="pwd" type="password" placeholder="设置6~8位登录密码" />
      </div>
      <div class="input_box_xia">
        <van-field v-model="pwds" type="password" placeholder="重复密码" />
      </div>
    </div>
    <div :class="`Sed bton ${filled ? 'op' : ''}`" @click="registerFn">确认</div>
  </div>
</template>

<script>
import { telTest } from '@/util/verify'
import { getPIN, register } from '@/api/register'

export default {
  data() {
    return {
      filled: false,
      username: '',
      tel: '',
      code: '',
      pwd: '',
      pwds: '',
      showGetCode: false, // 显示获取验证码
      countDown: 0, // 验证码倒计时
      key: '', // 验证码绑定的key
      redirect: '' // 重定向跳转的地址
    }
  },
  computed: {
    all() {
      const { username, tel, code, pwd, pwds } = this
      return { username, tel, code, pwd, pwds }
    }
  },
  watch: {
    all: {
      handler() {
        // 电话号码验证
        this.showGetCode = telTest(this.tel)
        if (
          this.username !== '' &&
          this.tel !== '' &&
          this.code !== '' &&
          this.pwd !== '' &&
          this.pwds !== ''
        ) {
          this.filled = true
        } else {
          this.filled = false
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取验证码
    getCode() {
      getPIN(this.tel, 1)
        .then(res => {
          this.$toast('发送成功')
          this.key = res.code
          this.countDown = 59
          let t = setInterval(() => {
            if (this.countDown > 0) {
              this.countDown--
            } else {
              clearInterval(t)
            }
          }, 1000)
        })
        .catch(err => {
          if (err.error != '1') {
            //非业务逻辑失败，则提示
            this.$toast('验证码获取失败')
          }
        })
    },
    // 注册
    registerFn() {
      if (!this.filled) return
      // 验证码输入不一致
      if (this.code !== this.key) {
        this.$toast('验证码输入有误')
        return
      }
      // 密码位数验证
      if (this.pwd.length < 6 || this.pwd.length > 11) {
        this.$toast('密码请在6-11位间')
        return
      }
      // 两次密码验证
      if (this.pwd !== this.pwds) {
        this.$toast('两次密码不等')
        return
      }
      register(this.username, this.tel, this.pwd).then(res => {
        this.$toast('注册成功')
        // 自动登录
        // setTimeout(() => {
        this.$store
          .dispatch('user/login', {
            username: this.username,
            tel: this.tel,
            password: this.pwd
          })
          .then(() => {
            if (this.redirect === '') {
              this.$router.go(-1)
            } else {
              this.$router.push(this.redirect)
            }
          })
          .catch(err => {
            this.$toast('登录失败,请手动登录')
            this.$router.push('/login')
          })
        // }, 1000);
      })
    }
  },
  mounted() {
    // 获取希望前去的页面
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect
      for (let key in this.$route.query) {
        if (key !== 'redirect') {
          this.redirect += `&${key}=${encodeURI(this.$route.query[key])}`
        }
      }
    }
  }
}
</script>

<style scoped>
.page {
  height: 100vh;
  overflow-y: scroll;
}

.op {
  opacity: 1 !important;
}

.Sed {
  width: 84vw;
  height: 12.533vw;
  /* margin: 0 auto; */
  margin: 5vw auto;
  background-color: #247dff;
  border-radius: 6.267vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  opacity: 0.4;
}

.code_gain {
  width: 35vw;
  height: 8vw;
  background-color: #edeff2;
  border-radius: 4vw;
  font-size: 3.467vw;
  color: #909399;
  display: flex;
  align-items: center;
  justify-content: center;
}
.code_gain-on {
  background-color: #008eed;
  color: #ffffff;
}

.input_box_xia {
  height: 11.333vw;
  border-bottom: 1px solid #edeff2;
}

.input_box_xia {
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
}

.van-field__control,
.van-cell {
  margin: 0;
  padding: 0;
  font-size: 4vw;
  color: #bfc2cc;
}

.input_box {
  width: 84vw;
  margin: 0 auto;
  margin-top: 13.333vw;
}

.register_title {
  font-size: 6.667vw;
  letter-spacing: 0.133vw;
  color: #303133;
  margin-left: 8vw;
  margin-top: 10.667vw;
}

.register_title_welcome {
  font-size: 3.733vw;
  letter-spacing: -0.133vw;
  color: #909399;
  margin-left: 8vw;
  margin-top: 5.067vw;
}
</style>
