<template>
  <div v-if="info" style="user-select: none;">
    <div class="doubt_list">
      <div class="doubt_list_portrait">
        <img :src="info.headimg" alt="">
      </div>
      <div class="doubt_list_right">
        <div class="doubt_list_right_top">
          <div class="top_felx">
            <span class="name">{{info.name}}</span>
          </div>
          <div style="color: #909399;font-size: 3.2vw;">{{info.createtime | dateFormat('yyyy/MM/dd',true)}}</div>
        </div>
        <div class="doubt_list_right_bottom">{{info.question_title}}</div>
      </div>
    </div>
    <div class="reply_box">
      <div class="reply_box_title">导师回复</div>
      <!-- 没有回复 -->
      <div class="reply_box_text" v-if="answer.length===0">学生等着您回复呢</div>
      <!-- 有回复 -->
      <div class="has-reply" v-if="answer.length!==0"
           v-for="(item,i) in answer" :key="'doubtExplain'+i"
      >
        <div class="reply-head">
          <img :src="item.headimg" alt="">
        </div>
        <div class="reply-context">
          <div class="reply-context-top">
            <p>{{item.name}}</p>
            <p>{{item.createtime | dateFormat('yyyy/MM/dd',true)}}</p>
          </div>
          <div class="reply-context-text">
            <!-- 语音回复 -->
            <div v-if="item.content_type===2">
              <div style="font-size: 3.467vw;">回复 <span style="color: #565D72;">{{info.name}}</span>：</div>
              <audioBox :url="item.content" :id="item.id" :isBuy="item.is_buy" :price="item.price"/>
            </div>
            <!-- 文本回复 -->
            <div v-if="item.content_type===1">
              <div class="reply-text">回复 <span style="color: #565D72;">{{info.name}}</span>：
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 13vw;"></div>
    <div class="operation_column">
      <div class="operation_column_center">
        <div class="operation_column_yuyin">
          <img src="@/assets/images/yuyin.png" v-if="!talk" @click="talk=true" />
          <img src="@/assets/images/ddd.png" v-else @click="talk=false" />
        </div>
        <!-- 语音 -->
        <div class="hold_talk" v-if="talk"
             @touchstart="touchstart"
             @touchend="touchend"
        >
          <span class="anzhu">按住 说话</span>
        </div>
        <!-- 文字 -->
        <div class="dddd" v-else>
          <div class="operation_column_shuru">
            <input
              type="text"
              class="text"
              placeholder="请输入内容"
              v-model="contentText"
            />
          </div>
          <div class="operation_column_yuyin" @click="sendText">
            <img src="@/assets/images/fasong.png" class="fasong" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import audioBox from "./widget/audioBox";
  import {getAuthority} from '../../api/pay'
  import {getIssuesInfo, sendaudioUrl, sendText, UploadRecording} from "../../api/my";
export default {
  components:{
    audioBox
  },
  data() {
    return {
      test:'',
      questionId:this.$route.params.id,
      info:{}, // 问题详情
      answer:[], // 回答
      audioUrl:'', // 语音
      contentText: "",
      voice: true,
      send: true,
      talk: false,
      localId:null, // 录音文件id
      serverId:null, // 服务端录音id
    };
  },
  methods: {
    init() {
      getAuthority();
      getIssuesInfo(this.questionId).then(res => {
        this.info = res.data;
        this.answer = res.data.questionInfo;
      })
    },
    // 发送文本
    sendText() {
      if (this.contentText!=='') {
        sendText(this.questionId,this.contentText).then(() => {
          this.$toast('发送成功');
          this.contentText = '';
          getIssuesInfo(this.questionId).then(res => {
            this.info = res.data;
            this.answer = res.data.questionInfo;
          })
        }).catch(() => {this.$toast('发送失败');})
      }
    },
    touchstart() {
      wx.startRecord();
      this.$toast({
        message:'录音中',
        duration:0,
      })
    },
    // 发送录音
    touchend() {
      this.$toast().clear();
      // 停止录音
      wx.stopRecord({
        success: (res) => {
          this.localId = res.localId;
          // 上传录音到微信
          wx.uploadVoice({
            localId: this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: (res) => {
              this.serverId = res.serverId; // 返回音频的服务器端ID
              // 上传录音到服务器
              UploadRecording(this.serverId).then(res => {
                // 保存录音地址到服务器
                sendaudioUrl(this.questionId,res.data).then(() => {
                  this.$toast('发送成功');
                  getIssuesInfo(this.questionId).then(res => {
                    this.info = res.data;
                    this.answer = res.data.questionInfo;
                  })
                }).catch(() => {this.$toast('音频发送失败');})
              }).catch(err => {this.$toast('音频发送失败')})
            }
          });
        }
      });
    },
  },
  mounted() {
    this.init();
    window.T = {};
    T.vm = this;
  }
};
</script>

<style scoped>
  .has-reply{
    margin-top: 2vw;
    display: flex;
    padding-bottom: 5.333vw;
    border-bottom: 1px solid #edeff2;
  }
  .reply-head{
    width: 8.8vw;
    height: 8.8vw;
    background: #c6c8cb;
    border-radius: 100%;
  }
  .reply-head>img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
  .reply-context{
    flex: 1;
    margin-left: 2.4vw;
  }
  .reply-context-top{
    height: 8.8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reply-context-top>p:first-child{
    font-size: 3.467vw;
    color: #474f66;
  }
  .reply-context-top>p:last-child{
    color: rgb(144, 147, 153);
    font-size: 3.2vw;
  }
  .reply-voice{
    margin-top: 2vw;
    width: 46.667vw;
    height: 10.133vw;
    background-color: #f5f7fa;
    border-radius: 1.867vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 2.8vw;
  }
  .reply-voice>p{
    font-size: 3.733vw;
    color: #303133;
  }
  .reply-voice>img{
    width: 2.133vw;
    height: 4vw;
  }
  .reply-text{
    font-size: 3.467vw;
    line-height:6vw;
  }

.dddd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 83vw;
}
.hold_talk {
  user-select: none;
  width: 84vw;
  height: 9.333vw;
  background-color: #ffffff;
  border-radius: 4.667vw;
  border: solid 0.233vw #edeff2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.anzhu {
  display: block;
  margin: 0 auto;
  font-size: 4.267vw;
  color: #303133;
}
.fasong {
  width: 5.867vw !important;
  height: 5.867vw !important;
}
input::-webkit-input-placeholder {
  font-size: 4.267vw;
  color: #bfc2cc;
}
.text {
  margin-left: 3vw;
  border: none;
  width: 66.8vw;
}
.operation_column_shuru {
  width: 72.667vw;
  height: 9.333vw;
  background-color: #ffffff;
  border-radius: 4.667vw;
  border: solid 0.233vw #edeff2;
  display: flex;
  align-items: center;
}
.operation_column_yuyin {
  width: 7.467vw;
  height: 7.467vw;
}
.operation_column_yuyin img {
  display: block;
  width: 100%;
  height: 100%;
}
.operation_column_center {
  width: 93.333vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operation_column {
  width: 100vw;
  height: 13vw;
  background-color: #ffffff;
  border-radius: 0.533vw;
  display: flex;
  align-items: center;
  border-top: 0.233vw solid #edeff2;
  position: fixed;
  bottom: 0;
}
.reply_box_text {
  font-size: 3.733vw;
  color: #bfc2cc;
  text-align: center;
  margin-top: 13.333vw;
}
.reply_box_title {
  font-size: 3.467vw;
  color: #303133;
  margin-top: 5.333vw;
}
.reply_box {
  width: 93.6vw;
  margin: 0 auto;
}
.reply {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.467vw;
  height: 4.533vw;
  background-color: #ffffff;
  border-radius: 0.533vw;
  border: solid 0.233vw #ff8c40;
  font-size: 2.933vw;
  color: #ff8c40;
  margin-left: 4vw;
}
.top_felx {
  display: flex;
  align-items: center;
}
.name {
  font-size: 3.467vw;
  color: #474f66;
}
.doubt_list_right_bottom {
  width: 81.6vw;
  font-size: 3.733vw;
  line-height: 6.4vw;
  margin-left: 2.4vw;
  color: #303133;
  margin-top: 4.933vw;
}
.doubt_list_right_top {
  width: 81.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2.4vw;
}
.doubt_list_right {
  margin-top: 5.333vw;
}
.doubt_list_portrait {
  width: 8.8vw;
  height: 8.8vw;
  background-color: #c6c8cb;
  border-radius: 100%;
  margin-top: 2.667vw;
}
  .doubt_list_portrait>img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
.doubt_list {
  width: 93.6vw;
  padding-bottom: 5vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
  display: flex;
}
</style>
