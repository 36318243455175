<template>
  <div class="main">
    <van-search v-model="key" placeholder="搜索他的回答" show-action shape="round">
      <div slot="action" @click="search">搜索</div>
    </van-search>
    <van-tabs v-model="orderby" color="rgb(36, 125, 255)">
      <van-tab title="按热点排序"></van-tab>
      <van-tab title="按回复量排序"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div style="height: 4vw;background-color: #ffffff;"></div>
        <div
          class="the_answer"
          v-for="(item, i) in list"
          :key="'answer' + i"
          @click="goToReplyDetails($event,item)"
        >
          <div class="the_answer_felx">
            <div class="the_answer_head">
              <img v-if="item.headurl" :src="item.headurl" />
              <img v-else :src="defaultSrc" />
            </div>
            <div class="the_answer_right">
              <div class="name">
                <span v-text="item.name"></span>
                <!-- <van-icon name="ascending" @click="toTop(item)" v-if="isTeacher && i !== 0" /> -->
                <span @click="toTop(item)" v-if="isTeacher && i !== 0" class="toTop">置顶</span>
              </div>
              <div class="time">
                <span v-text="item.crdate"></span>
                <span v-text="`回复量:${item.replynum}`"></span>
              </div>
              <div class="details">{{ item.content }}</div>

              <!-- 回复概况 -->
              <div class="reply" v-for="(reply, i) in item.reply" :key="i">
                <!-- 文本回复 -->
                <div v-if="reply.content_type === 1">
                  专家回复：
                  <span style="color: #4c4c4c">
                    {{
                    reply.content
                    }}
                  </span>
                </div>
                <!-- 语音回复 -->
                <div v-if="reply.content_type === 2">
                  专家回复：
                  <span style="color: #3e62c7">[语音]</span>
                </div>
              </div>
              <div class="uifu" v-if="item.reply_count > 2">点击查看更多回复</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import audioBox from '../../My_personal_center/widget/audioBox'
import { getFAQList, toTopFAQ } from '../../../api/questionsAndAnswers'

export default {
  name: 'answer',
  components: { audioBox },
  props: {
    expertId: String,
    teacher: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      key: '', // 搜索关键字
      page: 1, // 当前页数
      list: [], // 显示数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      expertUserId: this.$route.query.expertUserId,
      orderby: 0, //1-按热点 2-按回复量
      defaultSrc: require('@/assets/images/head_prole.png')
    }
  },
  watch: {
    orderby(n, o) {
      this.onRefresh()
    }
  },
  computed: {
    isTeacher() {
      //暂时紧开放班主任有问题置顶功能
      return [Number(this.expertUserId)].includes(Number(this.userId))
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      let params = {
        keyword: this.key,
        teaid: this.expertId,
        curpage: this.page,
        orderby: this.orderby + 1
      }
      getFAQList(params)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(() => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 搜索
    search() {
      this.onRefresh()
    },
    // 置顶
    toTop(info) {
      let msg = '',
        content = info.content
      if (info.content.length < 10) {
        msg = content
      } else {
        msg = content.slice(0, 10) + '...'
      }
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要将“${msg}”置顶到当前页？`
        })
        .then(() => {
          toTopFAQ(this.autoid).then(res => {
            this.$toast({
              message: '置顶成功',
              position: 'bottom'
            })
            this.onRefresh()
          })
        })
    },
    // ->回复详情
    goToReplyDetails(e, item) {
      if ([...new Set(e.target.classList)].includes('van-icon')) return
      this.$router.push({
        path: '/replyList',
        query: {
          questionId: item.autoid, //问题id
          expertUserId: this.expertUserId, //老师useid
          answerPrice: this.teacher.answerprice //老师语音回复单价
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .the_answer {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    .the_answer_felx {
      width: 93.6vw;
      margin: 0 auto;
      padding: 2vw 0;
      display: flex;
      border-bottom: 0.233vw solid #edeff2;
      .the_answer_head {
        width: 9.867vw;
        height: 9.867vw;
        background-color: #909399;
        border-radius: 100%;
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .the_answer_right {
        width: 81.6vw;
        margin-left: 2.667vw;
        .name {
          @include font(3.467vw, 4.533vw);
          color: #303133;
          .van-icon {
            float: right;
          }
          .toTop {
            float: right;
            color: $font-gray;
          }
        }
        .time {
          @include font(3.2vw, 3.733vw);
          letter-spacing: -0.133vw;
          color: #909399;
          margin-top: 2vw;
          span {
          }
          span:last-child {
            float: right;
          }
        }
        .details {
          font-size: 3.733vw;
          line-height: 6.4vw;
          color: #303133;
          margin-top: 1vw;
        }
        .reply {
          background: #f5f7fa;
          padding: 1vw;
          border-radius: 1vw;
          font-size: 3.4vw;
          margin-bottom: 1.5vw;
        }
        .uifu {
          margin-top: 2vw;
          font-size: 3.2vw;
          letter-spacing: -0.133vw;
          color: #3e62c7;
        }
      }
    }
  }
}
</style>
