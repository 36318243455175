import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VideoPlayer from 'vue-video-player'
// import VConsole from 'vconsole'
import '@/router/interceptor'
import store from './store'
import './publics/vant' // vant
import './util/dateFormat' // 日期格式化
import request from './util/request' // 请求工具
import setTitle from './util/title' // 改变title
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
// require('video.js/dist/video-js.css')


// 引入全局组件 -- 自定义的组件
import HeaderComponent from './components/extend/head/index.vue'
import CellGroup from './components/extend/cellGroup/index.vue'
Vue.component('topheader', HeaderComponent);
Vue.component('cellGroup', CellGroup);

// var vConsole = new VConsole();

Vue.config.productionTip = false
window.setTitle = setTitle
Vue.use(VideoPlayer);
Vue.prototype.$http = request;

// ① 解决二维码分享跳转到班圈详情页无法返回到首页的问题(利用window.history.length判断)
Vue.prototype.$setgoindex = function () {
  if (window.history.length <= 1) {
    if (location.href.indexOf('?') === -1) {
      window.location.href = location.href + '?goindex=true'
    } else if (location.href.indexOf('?') !== -1 && location.href.indexOf('goindex') === -1) {
      window.location.href = location.href + '&goindex=true'
    }
  }
}

// 过滤器
Vue.filter('dateFormat', function (val, format, difference = false) {
  if (typeof (val) === 'string') {
    val = parseInt(val, 10);
  }
  if (('' + val).length === 10) {
    val = val * 1000;
  }
  return new Date(val).Format(format, difference);
});
Vue.filter('timeStamp', function (val) {
  try {
    let stamp = Date.parse(new Date(val.replace(/-/g, '/')))
    return stamp ? stamp : val;
  } catch (e) {
    return val
  }
});
// 获取input焦点
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
});


import { init } from "@/init";
if (process.env.NODE_ENV === "production") {
  init(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  });
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}


