<!-- 1. 课程详情>>>作业>>>待完成>>>简答题（列表） -->
<!-- 2. 我的(专家)>>>设置作业>>>列表     （原有） -->
<template>
  <div>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div
          class="job_list"
          @click="revert(item.id)"
          v-for="(item,i) in list"
          :key="'homeworkList'+i"
        >
          <div class="job_list_lie">{{i+1}}</div>
          <div class="job_list_cneter">
            <div style="font-size: 4.267vw;color: #303133;">{{item.work_name}}</div>
            <div class="job_list_details">{{item.work_content}}</div>
          </div>
          <div class="job_list_right" v-if="item.new!==0">
            <span class="not"></span>
            <div class="answer">{{item.new}}条回答</div>
          </div>
          <div class="job_list_right" v-else></div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getWorkList } from '../../api/my'

export default {
  data() {
    return {
      lessonId: this.$route.params.id,
      page: 1, // 当前页数
      list: [
        {
          id: 1,
          lesson_id: 2,
          work_name: '测试',
          work_content: '爱是发送到发发',
          no: 1,
          status: 1,
          new: 8
        }
      ], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getWorkList(this.lessonId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.data.list)
          this.page += 1
          this.pullRefresh = false
          if (res.data.current_page >= res.data.total_page) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    revert(id) {
      this.$router.push('/homework_comment_list/' + id)
    }
  },
  mounted() {
    window.T = {}
    T.vm = this
  }
}
</script>

<style scoped>
.job_list_right {
  width: 18vw;
  display: flex;
  align-items: center;
  position: relative;
}
.answer {
  font-size: 2.933vw;
  color: #bfc2cc;
  margin-bottom: 5vw;
}
.not {
  display: block;
  width: 1.7vw;
  height: 1.7vw;
  background-color: #ff3b30;
  border-radius: 100%;
  margin-right: 0.8vw;
  margin-bottom: 5vw;
}
.job_list_cneter {
  position: relative;
  margin-top: 3.4vw;
}
.job_list_details {
  width: 63.733vw;
  font-size: 3.467vw;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delect {
  width: 4.267vw;
  height: 4.267vw;
  margin-left: 16vw;
  margin-top: 7.2vw;
}
.job_list {
  display: flex;
  justify-content: space-between;
  width: 95.067vw;
  height: 19.6vw;
  margin: 0 auto;
  border-bottom: 0.133vw solid #edeff2;
}
.job_list_lie {
  font-size: 3.733vw;
  letter-spacing: -0.133vw;
  color: #909399;
  position: relative;
  margin-top: 4.667vw;
}
</style>
