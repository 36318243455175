<template>
  <div>
    <div class="register_title">立即登录</div>
    <div class="register_title_welcome">欢迎加入我们</div>
    <div class="input_box">
      <div class="input_box_xia">
        <input v-model="tel" placeholder="手机号码" type="phone" />
      </div>
      <div class="input_box_xia">
        <input v-model="pwd" type="password" placeholder="密码" />
      </div>
    </div>
    <div :class="`Sed bton ${filled ? 'op' : ''}`" @click="login">确认</div>
    <div class="button_function">
      <div class="forget_password">
        <router-link to="/forget">忘记密码</router-link>
      </div>
      <div class="register_account">
        <router-link :to="`/register?redirect=${redirect}`">注册账号</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { createStuinfo } from '@/api/pay'
import { getOneClassinfo } from '@/api/classRing'
export default {
  name: 'register',
  data() {
    return {
      tel: '',
      pwd: '',
      redirect: '' // 重定向跳转的地址
    }
  },
  computed: {
    filled() {
      return this.tel !== '' && this.pwd !== ''
    }
  },
  methods: {
    login() {
      if (this.filled) {
        this.$store
          .dispatch('user/login', {
            tel: this.tel,
            password: this.pwd
          })
          .then(() => {
            this.applyClass().then(res => {
              if (this.redirect === '') {
                this.$router.go(-1)
              } else {
                this.$router.push(this.redirect)
              }
            })
          })
      }
    },
    // 申请班圈(班圈详情-->免费试学-->登录后需报名班圈)
    applyClass() {
      let classId = this.$route.query.classId, //班圈id
        apply = this.$route.query.apply, //是否需要报名班圈
        userId = this.$store.state.user.userId, //用户id
        recommendA = this.$store.state.user.recommendA, //一级推荐人
        recommendB = this.$store.state.user.recommendB //二级推荐人
      return new Promise((resolve, reject) => {
        if (apply) {
          // 班圈是否已报名
          getOneClassinfo(classId)
            .then(res => {
              if (res.stu.length) {
                //已报名
                resolve()
              } else {
                createStuinfo(classId, userId, recommendA, recommendB)
                  .then(resovle)
                  .catch(reject)
              }
            })
            .catch(err => {
              this.$router.go(-1)
            })
        } else {
          resolve()
        }
      })
    }
  },
  mounted() {
    sessionStorage.setItem('lastPage', this.$route.name) //classApply用到
    // 获取希望前去的页面
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect
      for (let key in this.$route.query) {
        if (key !== 'redirect') {
          this.redirect += `&${key}=${encodeURI(this.$route.query[key])}`
        }
      }
    }
    // 判断进入方式
    if (this.$route.query.type === 'logout') return
    // 尝试openId登录
    this.$store.dispatch('user/loginByOpenId').then(() => {
      this.applyClass().then(() => {
        if (this.redirect === '') {
          this.$router.go(-1)
        } else {
          this.$router.push(this.redirect)
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.button_function a {
  color: #909399;
}
.forget_password {
  border-right: 0.133vw solid #edeff2;
}
.register_account,
.forget_password {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 4vw;
  display: flex;
  align-items: center;
}
.button_function {
  width: 84vw;
  display: flex;
  margin: 0 auto;
  margin-top: 5.333vw;
  font-size: 3.733vw;
  color: #909399;
}
.op {
  opacity: 1 !important;
}
.Sed {
  width: 84vw;
  height: 12.533vw;
  margin: 0 auto;
  margin-top: 17.333vw;
  background-color: #247dff;
  border-radius: 6.267vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  opacity: 0.4;
}
.code_gain {
  width: 25.067vw;
  height: 8vw;
  background-color: #edeff2;
  border-radius: 4vw;
  font-size: 3.467vw;
  color: #909399;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_box_xia {
  border-bottom: 0.2vw solid #edeff2;
  margin-top: 8vw;
  height: 11.333vw;
  box-sizing: border-box;
  input {
    @include wh(100%, 100%);
    @include borderRadius(1.333vw);
    border: 0 none;
    padding-left: 0.5em;
  }
}
// .van-field__control,
// .van-cell {
//   margin: 0;
//   padding: 0;
//   font-size: 4vw;
//   color: #bfc2cc;
// }
.input_box {
  width: 84vw;
  margin: 0 auto;
  margin-top: 13.333vw;
}
.register_title {
  font-size: 6.667vw;
  letter-spacing: 0.133vw;
  color: #303133;
  margin-left: 8vw;
  margin-top: 10.667vw;
}
.register_title_welcome {
  font-size: 3.733vw;
  letter-spacing: -0.133vw;
  color: #909399;
  margin-left: 8vw;
  margin-top: 5.067vw;
}
</style>
