<template>
  <div class="page">
    <topheader />
    <cell-group :list="tools" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tools: [
        {
          icon: require('@/assets/images/couers.png'),
          label: '个人中心',
          route: '/personalInfo',
          value: ''
        },
        {
          icon: require('@/assets/images/key.png'),
          label: '修改密码',
          route: '/change',
          value: ''
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
$bgc: #f5f7fa;
@mixin font22 {
  font-size: 2.933vw;
}
@mixin boxlist {
  padding-left: 2.667vw;
  padding-right: 3.333vw;
  background: $bgc;
}
.page {
  @include isTop;
}
</style>
