// 解决 video-player 安卓手机 播放自动全屏显示的问题
export default () => {
    var ua = navigator.userAgent.toLocaleLowerCase();
    let video = document.getElementsByTagName('video')[0];
    if (video) {
        if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {//x5内核
            video.setAttribute('x-webkit-airplay', true)
            video.setAttribute('x5-playsinline', true)
            video.setAttribute('webkit-playsinline', true)
            video.setAttribute('playsinline', true)
        } else {//ios端
            video.setAttribute('webkit-playsinline', true)
            video.setAttribute('playsinline', true)
        }
    }
    // console.log('video, ',video)
}
// 参考：https://blog.csdn.net/qq_40669670/article/details/90169204