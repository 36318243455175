import { render, staticRenderFns } from "./createAnnouncement.vue?vue&type=template&id=379c6383&scoped=true&"
import script from "./createAnnouncement.vue?vue&type=script&lang=js&"
export * from "./createAnnouncement.vue?vue&type=script&lang=js&"
import style0 from "./createAnnouncement.vue?vue&type=style&index=0&id=379c6383&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379c6383",
  null
  
)

export default component.exports