import request from '@/util/request'

// 我的相关

// // 获取用户信息 ---> 作废
// export function personalCenterInfo() {
//   return request.get('/member/index')
// }

// // 上传头像
// export function uploadHeadImg(url) {
//   return request.post('/member/uploadHeadImg',{
//     data:{imgurl:url}
//   })
// }

// // 获取关注列表
// export function getAttentionList(page) {
//   const limit = 20;
//   return request.get('/fans/focusIndex', { limit, page }, false)
// }

// // 获取粉丝列表
// export function getFansList(page) {
//   const limit = 20;
//   return request.get('/fans/fansIndex', { limit, page }, false)
// }

// // 获取班圈课程记录 (type 1:用户买课记录 | 2:专家授课记录)
// export function getCourseRecord(page, type) {
//   const limit = 20;
//   return request.get('/lesson/recordIndex', { limit, page, type }, false)
// }

// // 获取测试记录列表
// export function getTestRecordList(status, page) {
//   const limit = 10;
//   return request.get('/TestRecord/index', { limit, page, status })
// }

// // 更新用户信息
// export function updateUserInfo({incName,incAddress,applicantName,applicantPhone,studentName,sex,role,studentPhone}) {
//   switch (sex) {
//     case '不公开':sex = 0;break;
//     case '男':sex = 1;break;
//     case '女':sex = 2;break;
//   }
//   return request.post('/member/updateInfo',{
//     data:{
//       inc_name:incName,
//       inc_address:incAddress,
//       applicant_name:applicantName,
//       applicant_phone:applicantPhone,
//       student_name:studentName,
//       sex:sex,
//       role:role,
//       student_phone:studentPhone,
//     }
//   })
// }

// 上传录音
export function UploadRecording(serverId) {
  return request.get('/Qiniu/downloadMedia', { serverId })
}

// 发送文本回答(解疑答惑)
export function sendText(questionId, content) {
  const contentType = 1; // 文字
  return request.post('/Question/reply', {
    data: { question_id: questionId, content, content_type: contentType }
  })
}

// 发送录音文件地址
export function sendaudioUrl(questionId, content) {
  const contentType = 2; // 语音文件url
  return request.post('/Question/reply', {
    data: { question_id: questionId, content, content_type: contentType }
  })
}

// 获取作业列表
export function getWorkList(lessonId, page) {
  const limit = 20;
  const type = 2;
  return request.get('/Work/index', {
    lesson_id: lessonId,
    limit, page, type
  })
}

// // 确定发布作业
// export function publishWork(lessonId, title, value) {
//   return request.post('/Work/add', {
//     data: {
//       lesson_id: lessonId,
//       work_name: title,
//       work_content: value,
//     }
//   })
// }

// 删除作业
export function deleteWork(id) {
  return request.get('/Work/delete', { ids: id })
}

// 修改作业
export function modifyWork(id, title, value) {
  return request.post('/Work/update', {
    data: {
      id,
      work_name: title,
      work_content: value,
    }
  })
}

// 获取作记录列表
export function getWorkRecordList(workId, page) {
  const limit = 20;
  return request.get('/WorkRecord/index', { work_id: workId, page, limit })
}

// 点评作业
export function commentWork(workRecordId, comment, point) {
  return request.post('/WorkRecord/comment', {
    data: {
      workrecord_id: workRecordId,
      comment, point,
    }
  })
}

// 获取问题列表
export function getIssuesList(page) {
  const limit = 20;
  const type = 2;
  return request.get('/Question/questionIndex', { limit, page, type })
}

// 获取问题详情
export function getIssuesInfo(questionId) {
  return request.get('/question/detail', { question_id: questionId })
}

// // 获取回答列表
// export function getAnswerList(page) {
//   const limit = 20;
//   return request.get('/questionInfo/index', { limit, page })
// }

// // 获取测试分数列表(总列表)
// export function getTestScores(page) {
//   const limit = 30;
//   return request.get('/TestRecord/recordIndex', { limit, page })
// }

// // 获取余额
// export function getBalance() {
//   return request.get('/member/balance')
// }

// // 获取提现记录
// export function getWithdrawalRecord() {
//   return request.get('/Cash/index')
// }



























/* ------------ 二次开发：start(by:yyz) ------------ */

/**
 * 更新用户信息
 * @params params = {
                      type,       //1-班圈报名 2-修改个人信息 3-修改头像
                      autoid,     //userid
                      name,       //姓名	Type=1,2时必填
                      company,    //公司	Type=1,2时必填
                      comaddress, //公司地址	Type=1,2时必填
                      job,        //部门职务	Type=1,2时必填
                      sex,        //性别	Type=2时必填
                      tel,        //电话	Type=2时必填
                      headurl,    //头像地址	Type=3时必填
                    } 
 * @return Promise()
 */
export function updateUserInfo(params) {
  let { type, autoid, name, company, comaddress, job, sex, tel, headurl } = params;
  return request.post("/Server/UpdateUserInfo", {
    data: { type, autoid, name, company, comaddress, job, sex, tel, headurl }
  })
}

// 获取 用户 关注/粉丝/班圈/回答 的数量
export function getMyNum(userid) {
  return request.get("/Server/GetMyNum", { userid }, false)
}


// 我的>>>我的关注
export function getMyTeacherList(userid, curpage) {
  return request.get("/Server/GetMyTeacherList", {
    userid,
    curpage,
    size: 10
  })
}
// 我的>>>获取班圈列表
export function getMyClassList(userid, curpage) {
  return request.get("/Server/GetMyClassList", {
    userid,
    curpage,
    size: 10
  })
}
// 我的>>>获取粉丝列表
export function getMyFans(userid, curpage) {
  return request.get("/Server/GetMyFans", {
    userid,
    curpage,
    size: 10
  })
}
// 我的>>>获取问答列表
export function getMyFAQ(userid, type, curpage) {
  return request.get("/Server/GetMyFAQ", {
    userid,
    type,
    curpage,
    size: 10
  })
}




// 获取我的推荐
export function getMyRecommend(userid) {
  return request.get("/Server/GetMyRecommend", {
    userid
  })
}

// 获取团队推荐
export function getTeamRecommend(userid, curpage) {
  return request.get("/Server/GetTeamRecommend", {
    userid,
    curpage,
    size: 10
  })
}


// 我的学习计划
export function getMylessonList(userid, curpage) {
  return request.get("/Server/GetMylessonList", {
    userid, curpage, size: 10
  })
}

// 获取我的新作业数量
export function getMyNewHWNum(userid) {
  return request.get("/Server/GetMyNewHWNum", {
    userid
  }, false)
}

// 获取我的新考试数量
export function getMyNewEXNum(userid) {
  return request.get("/Server/GetMyNewEXNum", {
    userid
  }, false)
}

// 获取我的作业列表 (type: 0-未完成 1-已完成)
export function getMyHWList(userid, type) {
  return request.get("/Server/GetMyHWList", {
    userid, type
  })
}

// 获取我的考试列表 (type: 0-未完成 1-已完成)
export function getMyEXList(userid, type) {
  return request.get("/Server/GetMyEXList", {
    userid, type
  })
}

// 获取我关注的用户（区分学生、老师） (type: 0-学生 1-老师)
export function getMyFollow(params) {
  const { userid, type, curpage } = params
  return request.get("/Server/GetMyLike", {
    userid, type, curpage, size: 10
  })
}

// 获取某个班圈的学习计划
export function getMyLearningPlan(classid, stuid) {
  return request.get("/Server/GetMyLearningPlan", {
    classid, stuid
  })
}

// 获取我的成绩
export function getMyClassAvgList(userid) {
  return request.get("/Server/GetMyClassAvgList", {
    userid
  })
}

// 我的成绩详细列表
export function getMyStuAvgList(stuid) {
  return request.get("/Server/GetMyStuAvgList", {
    stuid
  })
}

// 获取我的考勤记录
export function getMySignList(userid, courseid) {
  return request.get("/Server/GetMySignList", {
    userid, courseid
  })
}

// 获取我的所有需要签到的课程
export function getMySignlessonList(userid, curpage) {
  return request.get("/Server/GetMySignlessonList", {
    userid, curpage, size: 10
  })
}

// 我的余额
export function getMyBalance(userid) {
  return request.get("/Server/GetMyBalance", {
    userid
  })
}

// 账单流水
export function getMyAccountList(userid, curpage) {
  return request.get("/Server/GetMyAccountList", {
    userid, curpage, size: 10
  })
}








// 专家专栏>>>课程
export function getTealessonList(userid, curpage) {
  return request.get("/Server/GetTealessonList", {
    userid, curpage, size: 10
  })
}
// 专家专栏>>>作业数量
export function getTeaCanCheckHWTotal(userid) {
  return request.get("/Server/GetTeaCanCheckHWTotal", {
    userid
  })
}
// 专家专栏>>>考试数量
export function getTeaCanCheckEXTotal(userid) {
  return request.get("/Server/GetTeaCanCheckEXTotal", {
    userid
  })
}

// 专家专栏>>>获取作业题目（仅专家用）
export function getTeaCanCheckHWQSList(courseid, userid) {
  return request.get("/Server/GetTeaCanCheckHWQSList", {
    courseid, userid
  })
}
// 专家专栏>>>获取作业题目下所有同学的答案（仅专家用）
export function getTeaCanCheckHWAnswerList(params) {
  const { hwplanid, questionid, curpage, type } = params
  return request.post("/Server/GetTeaCanCheckHWAnswerList", {
    data: { hwplanid, questionid, size: 10, curpage, type }
  })
}

// 专家专栏>>>获取考试题目（仅专家用）
export function getTeaCanCheckEXQSList(courseid, userid) {
  return request.get("/Server/GetTeaCanCheckEXQSList", {
    courseid, userid
  })
}
// 专家专栏>>>获取考试题目下所有同学的答案（仅专家用）
export function getTeaCanCheckEXAnswerList(params) {
  const { explanid, questionid, curpage, type } = params
  return request.post("/Server/GetTeaCanCheckEXAnswerList", {
    data: { explanid, questionid, size: 10, curpage, type }
  })
}

// 专家专栏>>>获取专家参与讲课的班圈列表 -- 作废???  用teaSearchClassList替换
export function getTeaClassListByRoot(params) {
  const { userid, type = 1, curpage } = params
  return request.post("/Server/GetTeaClassListByRoot", {
    data: { userid, type, curpage, size: 10 }
  })
}

// 专家专栏>>>公告（新增/删除）
export function editeNoticeinfo(params) {
  const { classid, title, content, describe, author, sqnum, pic, autoid, type } = params
  return request.post("/Server/EditeNoticeinfo", {
    data: { classid, title, content, describe, author, sqnum, pic, autoid, type },
    type: 'json'
  })
}

// 专家专栏>>>专家获取某班圈的学员列表（包含结业信息）
export function getTeaClassPeopleList(classid) {
  return request.get("/Server/GetTeaClassPeopleList", { classid })
}

// 专家专栏>>>专家获取某班圈的学员作业成绩
export function teaGetStuHWRecordList(stuid) {
  return request.get("/Server/Tea_GetStuHWRecordList", { stuid })
}

// 专家专栏>>>专家获取某班圈的学员考试成绩
export function teaGetStuEXRecordList(stuid) {
  return request.get("/Server/Tea_GetStuEXRecordList", { stuid })
}

// 专家专栏>>>专家获取自己负责有签到计划的课程列表
export function getTeaSignlessonList(params) {
  const { userid, curpage, size = 10, classid, keyword } = params
  return request.get("/Server/GetTeaSignlessonList", {
    userid, curpage, size, classid, keyword
  })
}

// 专家专栏>>>专家获取某个签到计划的签到情况
export function getTeaSignCount(classid, autoid) {
  return request.get("/Server/GetTeaSignCount", { classid, autoid })
}

// 利用userid获取用户信息（@todo以后可以利用这个接口代替 intercaptor.js 来获取用户信息）
export function getUserInfo(userid) {
  return request.get("/Server/GetUserInfo", { userid })
}

// 编辑作业
export function editeHWPlaninfo(params) {
  const { courseid, title, content, bd, ed, duration, sqnum, autoid, type } = params
  return request.post("/Server/FrontEditeHWPlaninfo", {
    data: { courseid, title, content, bd, ed, duration, sqnum, autoid, type }
  })
}

// 获取某个课程的作业列表
export function getHWPlanList(courseid) {
  return request.get("/Server/GetHWPlanList", { courseid })
}

// 查询当前教师负责的课程 -- 作废??? 用teaSearchLessonList替换
export function getTealessonListByRoot(params) {
  const { classid, userid, size = 10, curpage = 1, type } = params
  return request.get("/Server/GetTealessonListByRoot", { classid, userid, size, curpage, type })
}


// 老师搜索班圈
export function teaSearchClassList(params) {
  const { keyword, userid, size = 10, curpage = 1, type } = params
  return request.get("/Server/teaSearchClassList", { keyword, userid, size, curpage, type })
}

// 老师搜索课程(可以优先查询指定班圈的课程)
export function teaSearchLessonList(params) {
  const { keyword, userid, size = 10, curpage = 1, type, classid } = params
  return request.get("/Server/teaSearchLessonList", { keyword, userid, size, curpage, type, classid })
}




// // 获取某个班圈该老师的推送记录
// export function getTeaMsgList(params) {
//   const { keyword, userid, size = 10, curpage = 1, classid } = params
//   return request.get("/Server/GetTeaWXSendList", { keyword, userid, size, curpage, classid })
// }

// // 删除推送信息
// export function deleteMsg(params) {
//   const { autoid } = params
//   return request.get("/Server/DelWXSend", { autoid })
// }

// // 获取一个推送消息详情
// export function getTeaMsgDetail(params) {
//   const { autoid } = params
//   return request.get("/Server/GetOneWXSend", { autoid })
// }

// // 新建班圈推送消息
// export function createNewMsg(params) {
//   const { userid, classid, title, content, stuid, groupid } = params
//   return request.get("/Server/CreateWXSendList", {
//     userid,
//     classid,
//     title,
//     content,
//     stuid,
//     groupid
//   })
// }




// 获取微信推送记录V2（userid）
export function getTeaMsgList(params) {
  const { userid, keyword, classid, size = 10, curpage } = params
  return request.get("/Server/GetTeaWXSendListV2", { userid, keyword, classid, size, curpage })
}
// 删除推送记录V2（userid）
export function deleteMsg(params) {
  const { autoid } = params
  return request.get("/Server/DelWXSendV2", { autoid })
}
// 获取一个推送记录详细信息（userid）
export function getTeaMsgDetail(params) {
  const { autoid } = params
  return request.get("/Server/GetOneWXSendV2", { autoid })
}
// 获取微信推送记录V2（改用userid）
export function createNewMsg(params) {
  const { userid, classid, title, content, useridlist, groupid } = params
  return request.get("/Server/CreateWXSendListV2", {
    userid,
    classid,
    title,
    content,
    useridlist,
    groupid
  })
}




// 获取学员的结业分数详细情况
export function getStuGraduationInfo(params) {
  const { stuid } = params
  return request.get("/Server/GetStuGraduationInfo", { stuid })
}
// 结业评分(老师打分)
export function graduationGrade(params) {
  const { autoid, score, stuid } = params
  return request.get("/Server/TeaScoreGraduation", { autoid, score, stuid })
}
// 获取学员的结业证书字段
export function getCertificate(params) {
  const { stuid } = params
  return request.get("/Server/GetStuCer", { stuid })
}

// 提现接口
export function cashout(params) {
  const { userid, chargemoney } = params
  return request.get("/Server/Cashout", { userid, chargemoney })
}

/* ------------ 二次开发：end(by:yyz)   ------------ */