import { login, loginByOpenId } from "@/api/login";

const initState = () => {
  return {
    userId: '',             // 用户id
    stuId: '',              // 学员id
    name: '',               // 用户名
    headImg: '',            // 头像
    tel: '',                // 手机号
    isExpert: '',           // 是否为专家

    recClassId: '',         // 推荐班圈id
    recommendA: null,       // 推荐人A用户id
    recommendB: null,       // 推荐人B用户id
    forbid2Change: false,   // 禁止修改答题（用于choice_question页返回禁止弹窗）
    lastPage: '',           //上一页（班圈申请页用到）
    // 个人中心
    personalCenterInfo: {
      focusCount: 0,     // 关注数
      classCount: 0,    // 班圈个数
      questionCount: 0,  // 回答数
      fansCount: 0,      // 粉丝数
      newQuestion: null,    // 新问题
      newAnswer: null,      // 新回答
      createtime: null,     // 创建时间
    },
    info: null, // 全部个人信息
  }
};

const state = initState();

const mutations = {
  // 重置用户
  RESET_STATE: (state) => {
    Object.assign(state, initState());
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_STUID: (state, stuId) => {
    state.stuId = stuId;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_HEAD_IMG: (state, headImg) => {
    state.headImg = headImg;
  },
  SET_TEL: (state, tel) => {
    state.tel = tel;
  },
  SET_IS_EXPERT: (state, isExpert) => {// 0||null:普通人 | 1:专家
    state.isExpert = isExpert === "1";
  },
  SET_RECOMMEND_A: (state, recommendA) => {// 一级推荐人
    state.recommendA = recommendA;
  },
  SET_RECOMMEND_B: (state, recommendB) => {// 二级推荐人
    state.recommendB = recommendB;
  },
  SET_CREATE_TIME: (state, time) => {//用户创建时间
    state.personalCenterInfo.createtime = time
  },
  SET_PERSONAL_CENTER_INFO: (state, info) => {
    state.personalCenterInfo = Object.assign(state.personalCenterInfo, {
      focusCount: info.focusCount,
      classCount: info.classCount,
      questionCount: info.questionCount,
      fansCount: info.fansCount,
    });
  },
  SET_PERSONAL_INFO: (state, info) => {
    state.info = info;
  },
  SET_FORBID2CHANGE: (state, forbidden) => {//禁止修改答题（用于choice_question页返回禁止弹窗）
    state.forbid2Change = forbidden;
  },
  // 二维码推荐班圈id
  SET_RECOMMEND_CLASSID: (state, id) => {
    state.recClassId = id
  },
  SET_LASTPAGE: (state, page) => {
    state.lastPage = page
  }
};


const actions = {
  // 账号密码 登录
  login({ commit }, userInfo) {
    const { tel, password } = userInfo;
    return new Promise((resolve, reject) => {
      login(tel, password).then(res => {
        if (res) {
          setPersonalInfo(commit, res);
          resolve()
        }

      }).catch(reject)
    })
  },
  // 登录(微信openId)
  loginByOpenId({ commit }) {
    return new Promise((resolve, reject) => {
      loginByOpenId().then(res => {
        if (res) { // 接口 “loginByOpenId” error："1" 也进入到成功回调??? res=undefined
          setPersonalInfo(commit, res);
          resolve()
        } else {
          reject();
        }
      }).catch(err => {
        reject()
      })
    })
  },
  // 登出
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('RESET_STATE');
      resolve();
    })
  },
  // 重置
  resetState({ commit }) {
    return new Promise(resolve => {
      commit('RESET_STATE');
      resolve();
    })
  },
  // 设置推荐人
  setRecommend({ commit }, res) {
    if (res.type === 'A') commit('SET_RECOMMEND_A', res.data)
    if (res.type === 'B') commit('SET_RECOMMEND_B', res.data)
  },
  // 保存学员id
  setSutId({ commit }, stuId) {
    return new Promise(resolve => {
      commit('SET_STUID', stuId);
      resolve()
    })
  },
  // 已完成的作业不需要弹窗
  forbid2Change({ commit }, res) {
    commit('SET_FORBID2CHANGE', res)
  },
  // 保存推荐班圈id
  setRecClassId({ commit }, id) {
    commit('SET_RECOMMEND_CLASSID', id)
  },
  // 保存我的头部参数（关注量|粉丝量|班圈量|回答量）
  setPersonalHeaderInfo({ commit }, info) {
    commit('SET_PERSONAL_CENTER_INFO', info)
  },
  setLastPage({ commit }, page) {
    commit('SET_LASTPAGE', page)
  }
};

// 保存个人信息
function setPersonalInfo(commit, info) {
  commit('SET_USERID', info.userid);
  commit('SET_NAME', info.name);
  commit('SET_HEAD_IMG', info.headurl);
  commit('SET_TEL', info.tel);
  commit('SET_IS_EXPERT', info.isexpert);
  commit('SET_CREATE_TIME', info.crdate);
  commit('SET_PERSONAL_INFO', info);
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
