<template>
    <div class="classList">
        <div
            class="ask_home"
            v-for="(item, i) in list"
            :key="'ask' + i"
            @click="goToExpertDetails(item)"
        >
            <div class="ask_home_flex">
                <div class="ask_home_flex_left">
                    <img :src="item.headurl" alt />
                </div>
                <div>
                    <div class="teacher_name">{{ item.name }}</div>
                    <div class="people">{{ item.follownum }}人收听</div>
                    <div class="ask_home_flex_zhong">
                        <span class="people">教师简介:</span>
                        <span class="synopsis">{{ item.describe }}</span>
                    </div>
                </div>
                <div v-if="isLogin">
                    <div
                        :class="{
                            ask_home_flex_rights: item.isfollow,
                            ask_home_flex_right: !item.isfollow,
                        }"
                        @click.stop="changeFocus(item.userid, item.isfollow, i)"
                        v-text="item.isfollow ? '已收听' : '未收听'"
                    ></div>
                </div>
                <div v-else style="width: 16vw;height: 8vw;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { changeFocus } from "@/api/questionsAndAnswers";

export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            userId: this.$store.state.user.userId,
        };
    },
    computed: {
        isLogin() {
            return this.$store.getters.isLogin;
        },
    },
    methods: {
        // ->专家详情
        goToExpertDetails(info) {
            this.$emit("click", info)
        },
        // 改变关注状态
        changeFocus(expertId, type, i) {
            const t = type ? 0 : 1;
            changeFocus(this.userId, expertId, t).then((res) => {
                this.list[i].isfollow = !this.list[i].isfollow;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.classList {
    .ask_home {
        width: 100vw;
        height: 27.467vw;
        border-bottom: 3vw solid #f5f7fa;
        display: flex;
        align-items: center;
        .ask_home_flex {
            width: 93.733vw;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ask_home_flex_left {
                width: 15.467vw;
                height: 15.467vw;
                background-color: #a2a6a7;
                border-radius: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
            div {
                .teacher_name {
                    font-size: 3.733vw;
                    color: #303133;
                }
                .people {
                    font-size: 3.467vw;
                    color: #909399;
                }
                .ask_home_flex_zhong {
                    display: flex;
                    align-items: center;
                    .people {
                        font-size: 3.467vw;
                        color: #909399;
                    }
                    .synopsis {
                        display: block;
                        font-size: 3.467vw;
                        color: #303133;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 40vw;
                    }
                }
            }
            div {
                .ask_home_flex_rights {
                    width: 16vw;
                    height: 8vw;
                    background-color: #f5f7fa;
                    border-radius: 4vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3.467vw;
                    color: #303133;
                    margin-bottom: 6vw;
                }
                .ask_home_flex_right {
                    width: 16vw;
                    height: 8vw;
                    background-color: #ffffff;
                    border-radius: 4vw;
                    border: solid 0.267vw #247dff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3.467vw;
                    color: #247dff;
                    margin-bottom: 6vw;
                }
            }
        }
    }
}
</style>
