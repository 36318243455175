<template>
  <div class="search_page">
    <div class="search_head">
      <div class="search_head_flex">
        <div class="search_head_img" @click="$router.go(-1)">
          <img src="@/assets/images/blck.png" />
        </div>
        <div class="search_input">
          <div class="search_input_left">
            <img src="@/assets/images/search.png" class="search_img" />
          </div>
          <div class="search_input_right">
            <input v-model="key" type="text" class="text_inout" v-focus placeholder="请输入课程标题" />
          </div>
        </div>
        <div class="hunt" @click="select">搜索</div>
      </div>
    </div>
    <div class="consequence_type">
      <van-tabs
        v-model="active"
        title-inactive-color="#909399"
        color="#247dff"
        line-width="4.533vw"
      >
        <van-tab title="专家">
          <div class="curriculum_list">
            <span class="consequence_type_title">搜索结果</span>
            <div v-if="experts.length === 0" class="no-data">没有搜索结果</div>
            <ExpertList :list="experts" @click="goToExpertDetails" />
          </div>
        </van-tab>

        <van-tab title="班圈">
          <div class="curriculum_list">
            <span class="consequence_type_title">搜索结果</span>
            <div v-if="classes.length === 0" class="no-data">没有搜索结果</div>
            <ClassList :list="classes" @click="goToClassDetails" />
          </div>
          <!-- </div> -->
        </van-tab>

        <van-tab title="课程">
          <div class="curriculum_list">
            <span class="consequence_type_title">搜索结果</span>
            <div v-if="lessons.length === 0" class="no-data">没有搜索结果</div>
            <!-- <div
                            v-else
                            class="lessonList"
                            v-for="item in lessons"
                            :key="item.title"
                            @click="goToLessonDetails(item)"
                        >
                            <div class="lesson_left">
                                <img
                                    v-if="item.pic"
                                    :src="item.pic"
                                />
                                <video v-else :src="item.videourl || item.liveurl"></video>
                            </div>
                            <div class="lesson_right">
                                <div class="lesson_name">
                                    <van-tag
                                        plain
                                        :type="
                                            item.showmode == '直播'
                                                ? 'warning'
                                                : 'success'
                                        "
                                        >{{
                                            item.showmode == "录播"
                                                ? "辅导"
                                                : "预习"
                                        }}</van-tag
                                    >
                                    <span v-text="item.title"></span>
                                </div>
                                <div
                                    class="lesson_connent"
                                    v-text="item.content"
                                ></div>
                                <div class="lesson_teacher">
                                    <span
                                        class="teacher_name"
                                        v-text="item.classownername1"
                                    ></span>
                                </div>
                            </div>
            </div>-->
            <LessonList v-else :list="lessons" @click="goToLessonDetails" />
          </div>
        </van-tab>

        <van-tab title="案例">
          <div class="curriculum_list">
            <span class="consequence_type_title">搜索结果</span>
            <div v-if="cases.length === 0" class="no-data">没有搜索结果</div>
            <div
              class="case_list_type"
              v-for="(item, i) in cases"
              :key="'case' + i"
              @click="goToCaseDetails(item)"
            >
              <div class="case_list_type_img">
                <img :src="item.pic" alt />
              </div>
              <div class="case_list_type_right">
                <div class="name_jinr">{{ item.title }}</div>
                <div class="name_time_jinr">{{ item.crdate }}</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {
  classSearch,
  expertSearch,
  lessonSearch,
  caseSearch,
  getOneClassinfo
} from '../../api/classRing'
import ExpertList from '@/components/extend/expertList'
import ClassList from '@/components/extend/classList'
import LessonList from '@/components/extend/lessonList'

export default {
  name: 'search',
  components: { ExpertList, ClassList, LessonList },
  data() {
    return {
      active: 0, // 当前下标(0:课程专场 | 1:专家解答)
      key: '', // 搜索关键字
      experts: [], //专家搜索结果
      classes: [], //班圈搜索结果
      lessons: [], //课程搜索结果
      cases: [], //案例搜索结果
      page: 1,
      classes: [] //查询结果
    }
  },
  watch: {
    active() {
      if (this.key.trim()) {
        this.select()
      }
    }
  },
  methods: {
    // 获取查询内容
    getData() {
      switch (this.active) {
        case 0: //专家
          return expertSearch(this.key, this.page)
          break
        case 1: //班圈
          return classSearch(this.key, this.page)
          break
        case 2: //课程
          return lessonSearch(this.key, this.page)
          break
        case 3: //案例
          return caseSearch(this.key, this.page)
          break
      }
    },
    // 初始化
    init() {
      this.experts = [] //专家搜索结果
      this.classes = [] //班圈搜索结果
      this.lessons = [] //课程搜索结果
      this.cases = [] //案例搜索结果
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 搜索
    select() {
      if (!this.key.trim()) {
        this.$toast('请先输入关键字')
        this.key = ''
        return
      } else {
        this.init()

        this.$nextTick(() => {
          this.getData()
            .then(res => {
              this.success(res)
              switch (this.active) {
                case 0:
                  this.experts.push(...res.list)
                  break
                case 1:
                  this.classes.push(...res.list)
                  break
                case 2:
                  this.lessons.push(...res.list)
                  break
                case 3:
                  this.cases.push(...res.list)
                  break
              }
            })
            .catch(err => {
              this.fail(err)
            })
        })
      }
    },
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 >= res.sum) {
        this.finished = true
      } else {
        this.page += 1
      }
    },
    fail(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // 专家详情
    goToExpertDetails(item) {
      this.$router.push({
        path: '/expertDetails/' + item.autoid,
        query: {
          expertUserId: item.userid //专家的用户id
        }
      })
    },
    // 班圈简介
    goToClassDetails(item) {
      this.$router.push('/classDetails/' + item.autoid)
    },
    // 案例详情
    goToCaseDetails(item) {
      this.$router.push('/discover_details/' + item.autoid)
    },
    // 课程详情
    goToLessonDetails(item) {
      // console.log(123, JSON.parse(JSON.stringify(item)))
      // return
      // 先判断是否报名？ 进入课程详情 ：进入班圈详情
      getOneClassinfo(item.classid).then(res => {
        if (res.isclasstea || (res.stu.length && res.stu[0].paystatus === 1)) {
          this.$router.push({
            path: '/Course_home/',
            query: {
              classId: String(item.classid),
              lessonId: item.autoid
            }
          })
        } else if (
          res.stu.length &&
          res.stu[0].paystatus === 0 &&
          item.pricemode != '免费'
        ) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '免费试学不能观看收费课程，可以先购买班圈后观看'
            })
            .then(() => {
              this.$router.push({
                path: '/classApply',
                query: {
                  // 价格 现价|原价
                  price: res.data[0].price + '|' + res.data[0].orprice,
                  className: res.data[0].title,
                  stuId: res.stu[0].stuid,
                  classId: String(item.classid)
                }
              })
            })
        } else {
          this.$dialog
            .confirm({
              title: '提示',
              message: '您还没有加入该课程所在班圈，可以先加入班圈再学习课程'
            })
            .then(() => {
              this.$router.push('/classDetails/' + String(item.classid))
            })
        }
      })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.search_page {
  // 搜索框
  .search_head {
    width: 100%;
    height: 13.333vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search_head_flex {
      width: 91.333vw;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search_head_img {
        width: 2.4vw;
        height: 4.267vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .search_input {
        width: 73.6vw;
        height: 9.6vw;
        background-color: #f5f7fa;
        border-radius: 2.667vw;
        display: flex;
        align-items: center;
        .search_input_left {
          .search_img {
            width: 4.4vw;
            height: 4.533vw;
            margin-left: 3.2vw;
          }
        }
        .search_input_right {
          input::-webkit-input-placeholder {
            font-size: 4.533vw;
            color: #838287;
          }
          .text_inout {
            border: none;
            background-color: #f5f7fa;
            height: 9.6vw;
            font-size: 4.533vw;
            margin-left: 2.4vw;
          }
        }
      }
      .hunt {
        font-size: 4.533vw;
        color: #303133;
      }
    }
  }
  .consequence_type {
    .curriculum_list {
      width: 93.6vw;
      margin: 0 auto;
      margin-top: 7.067vw;
      // 无数据
      .consequence_type_title {
        font-size: 3.467vw;
        color: #303133;
        font-weight: bold;
      }
      .no-data {
        margin-top: 10vw;
        text-align: center;
        color: #909399;
      }
      // 专家样式
      .classList {
        /deep/.ask_home {
          width: 100%;
          border-bottom: 1px solid $bg-light;
        }
      }
      // 课程样式
      .lessonList {
        margin: 3.333vw 0;
        .lesson_left {
          @include wh(33.33vw, 22.133vw);
          box-shadow: $box-shadow;
          border-radius: 6%;
          overflow: hidden;
          float: left;
          background: black;
          img {
            @include imgCover;
          }
          video {
            @include wh(100%, 100%);
          }
        }
        .lesson_right {
          height: 22.133vw;
          font-size: 2.9vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 2vw;
          .lesson_name {
            font-size: 4.2vw;
            @include dot2;
            .van-tag {
              margin-right: 0.667vw;
              margin-bottom: 1px;
            }
          }
          .lesson_connent {
            flex: 1;
            // padding: 1.333vw 0;
            padding-top: 1px;
            @include dot;
          }
          .lesson_teacher {
            span {
              min-width: 4em;
              text-align: center;
              display: inline-block;
              padding: 0.667vw 1.333vw;
              border: 1px solid $border-gray;
              border-radius: 1em;
              color: $font-gray;
            }
          }
        }
      }
      // 案例样式
      .case_list_type {
        width: 93.6vw;
        height: 28.8vw;
        border-bottom: 0.233vw solid #edeff2;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .case_list_type_img {
          width: 33.333vw;
          height: 22.133vw;
          border-radius: 4%;
          background-color: #c6c8cb;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4%;
            object-fit: cover;
          }
        }
        .case_list_type_right {
          width: 57.067vw;
          height: 22.133vw;
          margin-left: 2.4vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name_jinr {
            font-size: 4vw;
            color: #303133;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 6vw;
          }
          .name_time_jinr {
            font-size: 2.933vw;
            color: #909399;
          }
        }
      }
    }
  }
}
</style>
