<template>
  <div class="page">
    <topheader />
    <cell-group :list="list" />
  </div>
</template>

<script>
import { getMyNewHWNum, getMyNewEXNum } from '@/api/my'
export default {
  name: 'myGrow',
  data() {
    return {
      stamp: 0,
      userId: this.$store.state.user.userId,
      hwCount: 0, //新增作业量
      exCount: 0, //新增考试量
      list: [
        {
          icon: require('@/assets/images/study_plan.png'),
          label: '学习计划',
          route: '/my_studyplan',
          value: ''
        },
        {
          icon: require('@/assets/images/class.png'),
          label: '我的班圈',
          route: '/my_class',
          value: ''
        },
        {
          icon: require('@/assets/images/exam.png'),
          label: '我的考试',
          route: '/myExam',
          value: '',
          valueDot: false
        },
        {
          icon: require('@/assets/images/homework.png'),
          label: '我的作业',
          route: '/myHomework',
          value: '',
          valueDot: false
        },
        {
          icon: require('@/assets/images/score.png'),
          label: '我的成绩',
          route: '/myScore',
          value: ''
        },
        {
          icon: require('@/assets/images/my_checkin.png'),
          label: '我的考勤',
          route: '/my_attendance',
          value: ''
        }
      ] // 显示的数据
    }
  },
  methods: {},
  // beforeRouteEnter(to, from, next) {
  //   // 在渲染该组件的对应路由被 confirm 前调用
  //   // 不！能！获取组件实例 `this`
  //   // 因为当守卫执行前，组件实例还没被创建
  //   next(vm => {
  //     vm.stamp = Date.parse(new Date())
  //   })
  // },
  // beforeRouteLeave(to, from, next) {
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  //   const nowStamp = Date.parse(new Date())
  //   console.log(2222, {
  //     nowStamp,
  //     stamp: this.stamp,
  //     to,
  //     from
  //   })
  //   console.log(
  //     3333,
  //     to.name === 'My',
  //     from.name === 'myGrow',
  //     nowStamp - this.stamp > 5000
  //   )
  //   // 返回 我的
  //   if (to.name === 'My' && from.name === 'myGrow') {
  //     if (nowStamp - this.stamp > 5000) {
  //       next(false)
  //     } else {
  //       next()
  //     }
  //   } else {
  //     next()
  //   }
  // },
  mounted() {
    getMyNewHWNum(this.userId).then(res => {
      this.hwCount = res.newhwnum
      if (this.hwCount) {
        this.list.forEach(item => {
          if (item.label === '我的作业') {
            item.value = `${this.hwCount}条新作业`
            item.valueDot = true
          }
        })
      }
    })
    getMyNewEXNum(this.userId).then(res => {
      this.exCount = res.newexnum
      if (this.exCount) {
        this.list.forEach(item => {
          if (item.label === '我的考试') {
            item.value = `${this.exCount}条新考试`
            item.valueDot = true
          }
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}
</style>
