<!-- 公告详情 -->
<template>
  <div class="page">
    <topheader @beforeBack="beforeBack" />
    <!-- <p>@todo 待接口内容确定好再觉得布局</p> -->
    <template v-if="info.classid">
      <div class="classDetails_title">
        <span class="classDetails_title_ttiao"></span>
        {{ info.crdate }}
      </div>

      <div class="classDetails_title_big">{{ info.title }}</div>
      <div class="classDetails_title_xiao">{{ info.author || "教师名" }}</div>
      <div class="classDetails_center" v-html="info.content || '介绍'"></div>
    </template>

    <div v-else class="fail" @click="init">获取失败,请重新再试</div>
  </div>
</template>
<script>
import { getOneNoticeinfo } from '@/api/classRing'
import eventBus from '@/util/eventBus'
export default {
  data() {
    return {
      announcementId: this.$route.params.announcementId, //公告id
      info: {} //公告信息
    }
  },
  methods: {
    init() {
      getOneNoticeinfo(this.announcementId)
        .then(res => {
          this.info = res.data[0]
        })
        .catch(err => {
          this.$toast('公告详情获取失败,请重新再试')
        })
    },
    beforeBack() {
      eventBus.$emit('back2CourseHome', {
        active: this.$route.query.active,
        curLessonIdx: this.$route.query.curLessonIdx
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  .classDetails_title {
    width: 93.333vw;
    margin: 4.533vw auto;
    display: flex;
    align-items: center;
    font-size: 2.933vw;
    color: #909399;
    .classDetails_title_ttiao {
      display: block;
      width: 0.8vw;
      height: 4vw;
      background: $active-color;
      margin-right: 2.133vw;
    }
  }
  .classDetails_title_big {
    width: 93.333vw;
    margin: 4.533vw auto;
    font-size: 5.333vw;
    color: #303133;
    font-weight: bold;
  }
  .classDetails_title_xiao {
    width: 93.333vw;
    margin: 4.533vw auto;
    font-size: 3.467vw;
    color: #7a8299;
  }
  /deep/.classDetails_center {
    width: 93.333vw;
    font-size: 4vw;
    line-height: 6vw;
    color: #303133;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .fail {
    padding: 1em;
    text-align: center;
    color: $font-gray;
  }
}
</style>
