<template>
  <!-- 作废 -->
  <!-- 发起/签到 -->
  <div
    @click="action"
    :style="{
      top: top + 'vw',
      left: left + 'vw',
      right: right + 'vw',
      bottom: bottom + 'vw',
    }"
    class="signIn"
    :class="{ master: isTeach }"
  >
    <van-icon name="records"></van-icon>
    <span v-text="text"></span>
    <span v-text="time"></span>
  </div>
</template>

<script>
import { createStarSign, createSignal } from '@/api/classRing'
export default {
  props: {
    top: String,
    left: String,
    right: String,
    bottom: String,
    isTeach: Boolean, //班主任 或 导师
    lessonId: Number, //课程id
    signId: Number, //签到记录id
    userId: String, //用户id
    stuId: [String, Number] //学员id
  },
  data() {
    return {
      outSideTimer: undefined, //计时器
      confirmTimer: undefined, //计时器(弹窗)
      time: '' //当前时间
    }
  },
  computed: {
    text() {
      return this.isTeach ? '发起' : '签到'
    }
  },
  methods: {
    // 发起 / 签到
    action() {
      this.$dialog
        .confirm({
          title: this.setTime(),
          confirmButtonText: '确认',
          confirmButtonColor: '#2d4564',
          message: this.isTeach ? '是否发起移动签到？' : '是否确认签到？'
        })
        .then(() => {
          if (this.isTeach) {
            // 主动“发起”请求，通知所有学员
            createStarSign({
              courseid: this.lessonId,
              userid: this.userId,
              signinterval: 1
            }).then(() => {
              this.$emit('after', 1) // 发起 成功后通知父组件隐藏签到
            })
          } else {
            // 发起“签到”请求
            createSignal(this.lessonId, this.stuId, this.signId).then(() => {
              this.$emit('after', 2) // 签到 成功后通知父组件隐藏签到
            })
          }
        })
        .finally(() => clearInterval(this.confirmTimer))
      // 修改 confirm 标题
      setTimeout(() => {
        this.confirmTimer = setInterval(() => {
          let header = document.getElementsByClassName('van-dialog__header')[0]
          header.innerHTML = this.setTime()
        }, 1000)
      })
    },
    setTime() {
      let nowTime = new Date()
      const h = nowTime.getHours(),
        m = nowTime.getMinutes(),
        s = nowTime.getSeconds()
      const hour = h < 10 ? `0${h}` : h
      const minute = m < 10 ? `0${m}` : m
      const second = s < 10 ? `0${s}` : s
      return `${hour}:${minute}:${second}`
    }
  },
  mounted() {
    this.outSideTimer = setInterval(() => {
      this.time = this.setTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.outSideTimer)
    clearInterval(this.confirmTimer)
  }
}
</script>

<style lang="scss" scoped>
.signIn {
  width: 17.067vw;
  height: 17.067vw;
  border-radius: 50%;
  background: #7cd3fe;
  color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-size: 6vw;
  font-size: 2.667vw;
  z-index: 10;
  .van-icon {
    font-size: 5.333vw;
  }
  span:last-child {
    font-size: 2.667vw;
    // font-stretch: condensed;
  }
}
.master {
  background: #6bc02f;
}
</style>
