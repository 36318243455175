import request from '@/util/request';

//发现相关

// // 获取人脉列表
// export function getConnection(page) {
//   const limit = 20;
//   return request.get('/member/memberIndex',{limit,page},false)
// }

// // 获取案例列表
// export function getCaseList(page) {
//   const limit = 10;
//   return request.get('/Cases/index',{limit,page},false)
// }

// // 获取案例详情
// export function getCaseInfo(caseId) {
//   return request.get('/Cases/view',{cases_id:caseId})
// }

// // 获取资料列表
// export function getMaterialList(page) {
//   const limit = 20;
//   return request.get('/Data/index', { limit, page })
// }





/* ---------------- 二次开发：start(by:yyz) ---------------- */
// @todo ---> 接口数据有返回，但未在页面验证
// 获取案例列表
export function getCaseList(curpage) {
  return request.post('/Server/GetCaseList', {
    data: {
      curpage,
      size: 10
    }
  })
}
// 获取案例详情
export function getOneCaseinfo(autoid) {
  return request.post('/Server/GetOneCaseinfo', {
    data: { autoid }
  })
}
// 获取人脉列表
export function getConnections(userid, curpage) {
  return request.get('/Server/GetConnections', { userid, curpage, size: 10 }, false)
}

/* ---------------- 二次开发：end(by:yyz)   ---------------- */