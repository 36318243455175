<template>
  <div class="nav_top">
    <van-tabbar v-model="active">
      <van-tabbar-item>
        <span>班圈</span>
        <img slot="icon" slot-scope="props" :src="props.active ? circle.active : circle.inactive" />
      </van-tabbar-item>

      <van-tabbar-item>
        <span>问答</span>
        <img slot="icon" slot-scope="props" :src="props.active ? wen.active : wen.inactive" />
      </van-tabbar-item>

      <van-tabbar-item>
        <span>发现</span>
        <img slot="icon" slot-scope="props" :src="props.active ? find.active : find.inactive" />
      </van-tabbar-item>

      <van-tabbar-item>
        <span>我的</span>
        <img slot="icon" slot-scope="props" :src="props.active ? icon.active : icon.inactive" />
      </van-tabbar-item>
    </van-tabbar>

    <!-- 正文 -->
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigationBottom',
  data() {
    const path = location.pathname
    let idx = undefined
    switch (path) {
      case '/class_ring':
        idx = 0
        break
      case '/ask_home':
        idx = 1
        break
      case '/discover_home':
        idx = 2
        break
      case '/my':
        idx = 3
        break
    }
    return {
      active: idx,
      icon: {
        active: require('@/assets/images/buleInfo.png'),
        inactive: require('@/assets/images/bottomInfo.png')
      },
      circle: {
        active: require('@/assets/images/bottombanquanblue.png'),
        inactive: require('@/assets/images/bottombanquan.png')
      },
      wen: {
        active: require('@/assets/images/bottomwendablue.png'),
        inactive: require('@/assets/images/bottomwenda.png')
      },
      find: {
        active: require('@/assets/images/bottomfaxianblue.png'),
        inactive: require('@/assets/images/bottomfaxian.png')
      }
    }
  },
  watch: {
    active(newVal) {
      switch (newVal) {
        case 0: // 班圈
          this.$router.push('/class_ring')
          break
        case 1: // 问答
          this.$router.push('/ask_home')
          break
        case 2: // 发现
          this.$router.push('/discover_home')
          break
        case 3: // 我的
          this.$router.push('/my')
          break
        default:
        // console.log(new Error('??????'))
      }
    },
    $route(n) {
      // this.setActive(n.fullPath)
    }
  },
  methods: {
    setActive(str) {
      let idx = undefined
      if (str.includes('/class_ring')) {
        idx = 0
      } else if (str.includes('/ask_home')) {
        idx = 1
      } else if (str.includes('/discover_home')) {
        idx = 2
      } else if (str.includes('/my')) {
        idx = 3
      }
      this.active = idx
    }
  },
  mounted() {
    // this.setActive(location.pathname)
    // console.log(123, location.pathname)
  }
}
</script>

<style scoped>
.nav_top {
  height: 100%;
  overflow: scroll;
}
.nav_top::-webkit-scrollbar {
  display: none;
}
</style>
