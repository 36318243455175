<template>
  <div class="page">
    <topheader>
      <span slot="rightTools" @click="goTocreateNewTage">新增</span>
    </topheader>

    <div class="tagList">
      <ul class="tagList_ul">
        <li class="tagList_li" v-for="item in list" :key="item.autoid" @click="goToDetail(item)">
          <span v-text="`${item.name}（${item.stucount}）`"></span>
        </li>
      </ul>
    </div>
    <p v-if="list.length === 0" class="empty">暂无数据</p>
  </div>
</template>
<script>
import { getAllStuGroupList } from '@/api/classRing'
export default {
  data() {
    return {
      classId: this.$route.query.classId,
      list: [],
      tags: []
    }
  },
  methods: {
    init() {
      getAllStuGroupList(this.classId).then(res => {
        this.list = res.list
        this.tags = []
        this.list.forEach(item => {
          this.tags.push(item.name)
        })
      })
    },
    goTocreateNewTage() {
      this.$router.push({
        path: '/editTag',
        query: {
          classId: this.classId,
          isNew: true, //用于判断是否新建标签
          tags: this.tags.join()
        }
      })
    },
    goToDetail(item) {
      this.$router.push({
        path: '/editTag',
        query: {
          classId: this.classId,
          isNew: false, //用于判断是否新建标签
          tagId: item.autoid,
          sequence: item.sqnum, //序号（用于修改标签信息）
          tagName: item.name,
          tags: this.tags.join()
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  .tagList {
    .tagList_ul {
      .tagList_li {
        height: 13.867vw;
        padding: 0 3.2vw;
        font-size: 3.6vw;
        border-bottom: 1px solid $border-light;
        display: flex;
        align-items: center;
        span {
        }
      }
    }
  }
  .empty {
    @include font(3.2vw, 8vw);
    color: $font-gray;
    text-align: center;
  }
}
</style>