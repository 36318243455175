<template>
  <!-- 我的考勤 -->
  <div class="page">
    <topheader />
    <div class="tips">请先选择课程</div>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 课程列表 -->
        <LessonList :list="list" @click="showRecordList" />
      </van-list>
    </van-pull-refresh>

    <van-popup
      close-on-popstate
      position="bottom"
      close-icon="cross"
      close-icon-position="top-right"
      :style="{ height: '90%', 'margin-left': '20%', width: '80%' }"
      v-model="show"
    >
      <div class="attendance_lesson_name" v-text="records.lessonName"></div>
      <div class="attendance_title">
        <span>考勤次数</span>
        <span>考勤时间</span>
      </div>
      <div class="recordList">
        <div class="attendance_box" v-for="(item, i) in records.list" :key="'classring' + i">
          <van-icon name="checked" />
          <p>
            <span class="attendance_box_time">第{{item.rownumber}}次</span>
            <span class="attendance_box_date">{{item.crdate}}</span>
          </p>
        </div>
        <p class="no-data">暂无数据</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getMySignlessonList, getMySignList } from '@/api/my'
import LessonList from '@/components/extend/lessonList'
export default {
  components: { LessonList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据 todo 数据请求未处理的
      // list: ,
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false, // 加载出现错误
      type: this.$route.params.type, // 1-我的计划 2-我的考勤 3-考勤记录
      records: {},
      show: false
      // attendanceHeight: 0,
    }
  },
  watch: {},
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMySignlessonList(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.page += 1
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },

    showRecordList(item) {
      this.records = {}
      this.$nextTick(() => {
        if (!item.records) {
          const courseId = item.autoid
          getMySignList(this.userId, courseId).then(res => {
            this.list.forEach(it => {
              // @tocheck 课程id相等
              if (item.autoid === it.autoid) {
                item.records = {
                  lessonName: it.title,
                  list: res.list
                }
                this.records = item.records
              }
            })
            this.show = true
          })
        } else {
          this.records = this.list.find(el => item.autoid === el.autoid).records
          this.show = true
        }
      })
    },
    // 成功回调
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 > res.sum) {
        this.finished = true
      } else {
        this.page++
      }
    },
    // 失败回调
    fail(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    }
    // // 考勤列表
    // goToAttendance(item) {
    //     this.$router.push('/attendance_records/1')
    // }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  .tips {
    background: $bg-gray;
    color: $active-color;
    @include font(3.2vw, 6.4vw);
    padding: 0 2.667vw;
  }
  .van-pull-refresh {
    height: calc(100% - 6.4vw);
    overflow-y: scroll;
    /deep/.van-pull-refresh__track {
      .van-list {
        // padding: 0 3.2vw;
        .search_page {
          padding-top: 3.2vw;
        }
      }
    }
  }
  .lessonList {
    padding: 0.32vw;
    background: #fff;
  }
  .attendance_lesson_name {
    @include font(3.6vw, 8.6vw);
    text-align: center;
    @include dot;
  }
  .attendance_title {
    height: 9.867vw;
    background: $bg-light;
    color: $font-gray;
    padding: 0 3.2vw 0 6.667vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3.733vw;
    span {
    }
  }
  .recordList {
    height: calc(100% - 9.867vw - 8.6vw);
    overflow: scroll;
    .attendance_box {
      padding: 0 3.2vw;
      position: relative;
      height: 12.267vw;
      background: #fff;
      line-height: 12.267vw;
      font-size: 3.2vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .van-icon {
        font-size: 4.533vw;
        padding-left: 3.2vw;
        color: #00d2b2;
      }
      p {
        flex: 1;
        .attendance_box_time {
          margin-left: 4.8vw;
        }
        .attendance_box_date {
          float: right;
        }
      }
    }
    .attendance_box::after {
      content: '';
      display: block;
      width: calc(100% - 6.4vw);
      border-bottom: 1px solid $bg-gray;

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .no-data {
    @include font(3.2vw, 6.4vw);
    text-align: center;
    color: $font-gray;
  }
}
</style>
