<template>
  <div class="main">
    <!-- loading -->
    <div class="reply-voice" :style="{ width: audioWidth }" v-if="loading">
      <van-loading type="spinner" />
      <div class="right">
        <img src="@/assets/images/bolan.png" />
      </div>
    </div>
    <div class="reply-voice" :style="{ width: audioWidth }" @click="click()" v-else>
      <!-- 调用微信播放 -->
      <template v-if="useWxSDK">
        <p>{{ duration }}s</p>
        <div class="right">
          <div class="dian" v-show="isPlay"></div>
          <img src="@/assets/images/bolan.png" />
        </div>
      </template>
      <!-- 调用后台mp3播放 -->
      <template v-else>
        <audio ref="audio" :src="url" @canplay="canplay($event)" preload="auto"></audio>
        <p v-show="duration">{{ duration }}s</p>
        <div class="right">
          <div class="dian" v-if="isPlay"></div>
          <img src="@/assets/images/bolan.png" />
        </div>
      </template>
    </div>

    <div class="notBuy" v-if="!hasBuy" @click="click">
      <van-tag>未购买</van-tag>
    </div>
  </div>
</template>

<script>
import { wxPayOrderWD } from '../../../api/pay'

export default {
  name: 'audioBox',
  props: {
    id: Number, // 问题id
    url: String, // 音频地址
    isBuy: Boolean, // 是否购买
    price: [Number, String], // 价格
    createdTime: String, //创建时间
    mediaId: String, //音频id（微信）
    sec: [Number, String] // 录音时长
  },
  data() {
    return {
      audio: null, // audio实例
      duration: null, // 时长
      isPlay: false, // 是否在播放
      hasBuy: this.isBuy,
      allowToPlay: false, //父级判断是否允许播放
      timer: undefined,
      loading: true,

      useWxSDK: false, //是否先用微信提供的sdk来播放录音
      localId: null //如果录音后台转存中的话，直接用mediaId下载微信录音，获取本地的localId
    }
  },
  computed: {
    audioWidth() {
      let percent = 18 //百分比
      if (this.duration == 0) {
        percent = 18
      } else if (0 < this.duration && this.duration <= 20) {
        //一阶段
        percent = 18 + (40 * this.duration) / 20
      } else if (20 < this.duration && this.duration <= 120) {
        //二阶段
        percent = 58 + (30 * this.duration) / 120
      } else if (120 < this.duration && this.duration <= 180) {
        //三阶段
        percent = 85 + (10 * this.duration) / 180
      } else if (180 < this.duration) {
        //四阶段
        percent = 85
      }
      return percent + '%'
    }
  },
  methods: {
    init() {
      try {
        this.audio = this.$refs.audio
        this.audio.load()
        // 监听播放完成
        this.audio.addEventListener('ended', () => {
          this.isPlay = false
        })
      } catch (e) {
        console.error(e)
      }
    },
    // 准备好
    canplay(e) {
      let ua = navigator.userAgent.toLowerCase()
      if (!this.duration) {
        this.duration = e.target.duration.toFixed(1)
      }
      this.loading = false
    },
    // 点击
    click(e) {
      if (!this.hasBuy) {
        // 未购
        this.$dialog
          .confirm({
            title: '提示',
            message: `收听本则回答需支付￥${this.price}\n是否确认支付`
          })
          .then(() => {
            this.buyAudio()
          })
          .catch(() => {})
      } else {
        // 买了
        if (this.useWxSDK) {
          this.wxAudioPlay()
        } else {
          this.play()
        }
      }
    },
    // 播放
    play() {
      this.$emit('beforePlay', this) //父级需要判断是否同一个音频播放,若是则停止(allowToPlay = false)
      if (this.allowToPlay) {
        this.isPlay = true
        this.audio.play()
      }
    },
    stop(t) {
      if (this.useWxSDK) {
        this.wxAudioStop()
      } else {
        this.audio.pause() // 先暂停
        this.isPlay = false
        this.audio.currentTime = 0
      }
    },
    // 购买语音
    buyAudio() {
      // 问答>>>听答>>>回复列表>>>购买班主任回复语音（提问者除外）
      const userId = this.$store.state.user.userId
      const replyid = this.id
      wxPayOrderWD(userId, replyid)
        .then(res => {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {
              appId: res.appId, // 公众号名称，由商户传入
              timeStamp: res.timeStamp, // 时间戳，自1970年以来的秒数
              nonceStr: res.nonceStr, // 随机串
              package: res.packageValue,
              paySign: res.paySign, // 微信签名
              signType: 'MD5' // 微信签名方式
            },
            res => {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                // 支付成功
                this.hasBuy = true
                // this.play();
              } else {
                //支付失败
                this.$toast('支付失败')
              }
            }
          )
        })
        .catch(err => {})
    },
    // 微信语音播放
    wxAudioPlay() {
      this.$emit('beforePlay', this) //父级需要判断是否同一个音频播放,若是则停止(allowToPlay = false)
      if (this.allowToPlay) {
        if (this.localId) {
          //已经下载到本地
          wx.playVoice({ localId: this.localId })
          this.isPlay = true
        } else {
          this.loading = true
          wx.downloadVoice({
            serverId: this.mediaId,
            isShowProgressTips: 0, // 默认为1，显示进度提示
            success: res => {
              this.loading = false
              this.localId = res.localId //返回音频的bendingId
              wx.playVoice({ localId: this.localId })
              this.isPlay = true
            },
            fail(err) {
              clearInterval(this.timer)
              this.timer = undefined
              this.$nextTick(this.iosGetAudioHistory)
            }
          })
        }
      }
    },
    wxAudioStop() {
      wx.stopVoice({ localId: this.localId })
      // @todo 问题：如果来回切换 wx录音 上一段录音立即结束，下一段播放从：总时长-上一段已播放时长 开始???
      this.isPlay = false
    },
    //android用户 获取音频
    androidGetAudio() {
      if (this.mediaId && process.env.NODE_ENV == 'production') {
        // 优先判断后台是否已经转存录音mp3格式文件
        let audio = new Audio()
        audio.src = this.url //audio链接
        audio.addEventListener('loadedmetadata', () => {
          this.duration = audio.duration.toFixed(1)
          this.loading = false
          this.$nextTick(this.init)
        })
        // 后台仍在转存中的先用微信临时录音
        audio.onerror = () => {
          this.useWxSDK = true
          this.duration = this.sec
          this.loading = false
          // wx.downloadVoice({
          //   serverId: this.mediaId,
          //   isShowProgressTips: 0,
          //   success: res => {
          //     this.loading = false
          //     this.localId = res.localId //返回音频的bendingId
          //   }
          // })
        }
      } else {
        // 兼容旧版本录音（没返回mediaId）
        this.timer = setInterval(() => {
          let audio = new Audio()
          audio.src = this.url //audio链接
          audio.addEventListener('loadedmetadata', () => {
            this.duration = audio.duration.toFixed(1)
            this.loading = false
            clearInterval(this.timer)
            this.timer = undefined
            audio.removeEventListener('loadedmetadata', () => {})
            this.$nextTick(this.init)
          })
        }, 1000)
      }
    },
    // ios用户 获取音频
    iosGetAudio() {
      if (this.mediaId && process.env.NODE_ENV == 'production') {
        this.$http
          .get(this.url, {}, false)
          .then(() => {
            this.loading = false
            this.duration = this.sec
            this.$nextTick(this.init)
          })
          .catch(err => {
            this.useWxSDK = true
            this.duration = this.sec
            this.loading = false
            // wx.downloadVoice({
            //   serverId: this.mediaId,
            //   isShowProgressTips: 1,
            //   success: res => {
            //     this.loading = false
            //     this.localId = res.localId //返回音频的bendingId
            //   },
            //   fail(err) {
            //     this.iosGetAudioHistory()
            //   }
            // })
          })
      } else {
        this.iosGetAudioHistory()
      }
    },
    // 兼容ios获取音频（旧版本，没mediaId）
    iosGetAudioHistory() {
      const nowTime = Date.parse(new Date()),
        createTime = Date.parse(new Date(this.createdTime.replace(/\-/g, '/')))
      if (nowTime - createTime < 1000 * 60 * 3) {
        this.timer = setInterval(() => {
          this.$http.get(this.url, {}, false).then(() => {
            this.duration = this.sec
            this.loading = false
            clearInterval(this.timer)
            this.timer = undefined
            this.$nextTick(this.init)
          })
        }, 5000)
      } else {
        this.loading = false
        this.$nextTick(this.init)
      }
    }
  },
  created() {
    let that = this
    wx.onVoicePlayEnd({
      success: function(res) {
        // 这里要.bind()，要$emit，要isPlay=false
        let ua = navigator.userAgent.toLowerCase()
        if (/iphone|ipad|ipod/.test(ua)) {
          // 因为 多个audioBox 组件同时运行，所以ios最终监听到的是最后一个生成的audioBox，导致录音结束都不能this.isPlay = false
          that.$emit('beforePlay')
        } else {
          that.$emit('beforePlay', that)
          this.isPlay = false
        }
      }.bind(this),
      fail(err) {}
    })
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase()
    if (/iphone|ipad|ipod/.test(ua)) {
      this.iosGetAudio()
    } else {
      this.androidGetAudio()
    }
  }
}
// // ios 不允许自动获取video方法
// const fnList = {
//   loadstart: '浏览器开始请求媒介；',
//   durationchange: '媒介时长（duration属性）改变；',
//   loadedmetadata: '浏览器获取完媒介资源的时长和尺寸',
//   loadeddata: '已加载当前播放位置的媒介数据；',
//   progress: '浏览器正在获取媒介；',
//   canplay:
//     '浏览器能够开始媒介播放，但估计以当前速率播放不能直接将媒介播放完（播放期间需要缓冲）；',
//   canplaythrough:
//     '浏览器估计以当前速率直接播放可以直接播放完整个媒介资源（期间不需要缓冲）；',
//   timeupdate: '当前播放位置（currentTime属性）改变；',
//   ended: '播放由于媒介结束而停止；',
//   ratechange:
//     '默认播放速率（defaultPlaybackRate属性）改变或播放速率（playbackRate属性）改变；',
//   volumechange: '音量（volume属性）改变或静音（muted属性）'
// }
/**
 * 难点：ios微信用户新发送的语音不能点开播放，页面刷新/重新进入 有时又可以播放
 * 描述：因为新发的录音，需要前端上传完七牛云后后台再转换格式并下载，此时前端虽然刷新了评论列表（有返回录音地址），但后台仍未转换/下载完，如果用户此时点击播放录音，会报错（...mp3 404）,且用完不会播放完。
 *      在不刷新页面的前提下，尽管后台下载完前端也不能正常播放，因为Dom已经获取失败，不再重新触发请求。
 *      ios微信用户不能监听video的所有时间，因为ios内部决定不允许页面video自动播放，需要有一次用户行为。
 * 解决办法：针对最近3分钟内的录音，使用get请求判断后台是否下载完录音。没有，则加loading效果，因为每秒请求一次，容易出现多条重复请求，影响用户体验，所以需要在axios里额外处理：阻止重复请求。
 */
</script>
<style scoped>
.main {
  display: flex;
  align-items: flex-end;
  height: 10.133vw;
}
.notBuy {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 1.333vw;
}
.reply-voice {
  width: 46.667vw;
  height: 100%;
  background-color: #f5f7fa;
  border-radius: 1.867vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 2vw;
}
.reply-voice .van-loading .van-loading__spinner {
  width: 5vw;
  height: 5vw;
}
.reply-voice > p {
  font-size: 3.733vw;
  color: #303133;
}
.reply-voice img {
  width: 2.133vw;
  height: 4vw;
}
.right {
  display: flex;
  align-items: center;
  position: relative;
}
.dian {
  width: 2vw;
  height: 2vw;
  background: red;
  border-radius: 100%;
  position: absolute;
  right: 2.8vw;
  top: 50%;
  transform: translateY(-50%);
  animation: flicker 1.5s linear infinite;
}
@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
