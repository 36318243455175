<template>
  <div class="page">
    <topheader :title="headerTitle" @goBack="goBack">
      <van-button
        type="primary"
        size="small"
        slot="rightTools"
        :disabled="!isAll && isNew"
        @click="tagAction"
      >保存</van-button>
      <!-- :class="{gray: !isAll}" -->
    </topheader>

    <div class="info_box">
      <div class="kind" v-text="'分组名称'"></div>
      <van-field v-model="tagName" placeholder="例如地产、教育" :clearable="true" />
      <div class="kind">
        <span>成员</span>
        <span v-if="canDelete" @click="canDelete = false">关闭删除功能</span>
      </div>
      <!-- 成员列表 -->
      <div class="contacts_box">
        <ul class="contacts_ul">
          <li
            class="contacts_li"
            v-for="item in chooseContacts"
            :key="item.stuid"
            @click="delMember(item)"
          >
            <div class="contacts_info" :class="{canDelete}">
              <van-icon name="clear" v-if="canDelete" />
              <van-image :src="item.headurl" cover />
              <p class="contacts_name" v-text="item.name"></p>
            </div>
          </li>
          <!-- 添加 -->
          <li class="contacts_li" v-show="!canDelete">
            <div class="contacts_info" @click="addBtnClick">
              <div class="add_contacts">
                <van-icon name="plus" />
              </div>
              <p class="contacts_add" v-text="'add'"></p>
            </div>
          </li>
          <!-- 删除 -->
          <li class="contacts_li" v-show="!canDelete" @click="subBtnClick">
            <div class="contacts_info" @click="showDeleteBtn">
              <div class="sub_contacts">—</div>
              <p class="contacts_sub" v-text="'sub'"></p>
            </div>
          </li>
          <li class="contacts_li_default" v-if="canDelete" @click="canDelete = !canDelete">
            <div class="contacts_info">
              <div class="sub_contacts"></div>
              <p class="contacts_sub"></p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 删除按钮 -->
      <van-button
        type="primary"
        class="delete_btn"
        color="#F95350"
        v-if="!isNew"
        @click="deleteTag"
      >删除分组</van-button>
      <!-- v-show="!canDelete" -->
    </div>

    <!-- 选择联系人 -->
    <van-popup
      v-model="showPopup"
      position="bottom"
      close-icon-position="top-right"
      :style="{ height: 'calc(100% - 13.333vw)', width: '100%' }"
    >
      <div class="selectContact_box">
        <div class="selectContact_title">
          <div class="btn">
            <van-button type="primary" @click="choosedContact">确定</van-button>
          </div>
          <span class="txt">
            选择联系人
            <van-icon name="arrow" @click="showPopup = false" />
          </span>
        </div>
        <SelectContact :list="students" ref="selectContact" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getClassPeopleInfo,
  editeStuGroup,
  getStuListByGroup,
  groupingStu
} from '@/api/classRing'
// import SelectContact from './components/selectContact'
import SelectContact from '@/components/extend/selectContact'
export default {
  components: { SelectContact },
  data() {
    let usedTags = this.$route.query.tags
      ? this.$route.query.tags.split(',')
      : []
    let tagName = this.$route.query.isNew ? '' : this.$route.query.tagName
    return {
      userId: this.$store.state.user.userId,
      classId: this.$route.query.classId,
      isNew: this.$route.query.isNew, //是否新增标签
      tagId: this.$route.query.tagId,
      sequence: this.$route.query.sequence, //标签序号
      usedTags, //用到过的标签名
      students: [],
      showPopup: false,
      chooseContacts: [],
      defaultChooseContacts: [], //接口请求回来的标签成员
      tagName,
      canDelete: false //是否允许删除成员（样式）
    }
  },
  computed: {
    isAll() {
      return this.chooseContacts.length && this.tagName
    },
    headerTitle() {
      return this.isNew ? '新增分组' : '编辑分组'
    }
  },
  methods: {
    init() {
      getClassPeopleInfo(this.userId, this.classId).then(res => {
        this.students = res.stu
        // 筛选出 未分组或当前组内的成员
        // this.students = res.stu.filter(item => {
        //   return !item.groupname || item.groupname === this.tagName
        // })
      })
      if (!this.isNew) {
        getStuListByGroup(this.tagId, this.classId).then(res => {
          this.chooseContacts = res.list
          this.defaultChooseContacts = res.list
        })
      }
    },
    showDeleteBtn() {},
    addBtnClick() {
      this.showPopup = true
      let group = []
      this.$nextTick(() => {
        this.chooseContacts.forEach(item => {
          group.push(item.stuid)
        })
        this.$refs.selectContact.checked = group
      })
    },
    subBtnClick() {
      this.canDelete = true
    },
    choosedContact() {
      this.chooseContacts = this.$refs.selectContact.checkedGroup
      setTimeout(() => {
        this.showPopup = false
      }, 300)
    },
    delMember(item) {
      if (this.canDelete) {
        this.chooseContacts = this.chooseContacts.filter(
          it => it.stuid != item.stuid
        )
      } else {
        return
      }
    },
    beforeBack() {
      return new Promise((resolve, reject) => {
        if (this.isNew) {
          if (this.tagName || this.chooseContacts.length) {
            this.$dialog
              .confirm({
                title: '提示',
                message: '确定放弃新增分组？'
              })
              .then(resolve)
              .catch(reject)
          } else {
            resolve()
          }
        } else {
          let oldStuIds = [],
            newStuIds = []
          this.defaultChooseContacts.forEach(it => oldStuIds.push(it.stuid))
          this.chooseContacts.forEach(it => newStuIds.push(it.stuid))
          if (
            this.tagName != this.$route.query.tagName ||
            oldStuIds.sort().join() !== newStuIds.sort().join()
          ) {
            this.$dialog
              .confirm({
                title: '提示',
                message: '确定放弃编辑分组？'
              })
              .then(resolve)
              .catch(reject)
          } else {
            resolve()
          }
        }
      })
    },
    goBack() {
      this.beforeBack().then(() => {
        this.$router.back()
      })
    },
    tagAction() {
      if (this.isNew) {
        this.addTag()
      } else {
        this.editTag()
      }
    },
    addTag() {
      if (this.usedTags.includes(this.tagName)) {
        this.$dialog.alert({
          title: '提示',
          message: '分组名称已存在'
        })
        return
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定新增“${this.tagName}”分组吗？`
          })
          .then(() => {
            editeStuGroup({
              classid: this.classId,
              name: this.tagName,
              sqnum: 99,
              autoid: null,
              type: 9
            }).then(res => {
              let stuIdList = []
              this.chooseContacts.forEach(item => {
                stuIdList.push(item.stuid)
              })
              const stuIds = stuIdList.length ? stuIdList.join() : '0'
              groupingStu(res.autoid, stuIds)
                .then(data => {
                  this.$toast({
                    message: `新增成功`,
                    position: 'bottom'
                  })
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                })
                .catch(err => {
                  this.$toast('成员添加分组失败，请重新再试')
                })
            })
          })
      }
    },
    editTag() {
      if (
        this.usedTags.includes(this.tagName) &&
        this.tagName !== this.$route.query.tagName
      ) {
        this.$dialog.alert({
          title: '提示',
          message: '分组名称已存在'
        })
        return
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: `确定更新“${this.tagName}”分组信息吗？`
          })
          .then(() => {
            editeStuGroup({
              classid: this.classId,
              name: this.tagName,
              sqnum: this.sequence, //序号
              autoid: this.tagId,
              type: 1
            }).then(res => {
              let stuIdList = []
              this.chooseContacts.forEach(item => {
                stuIdList.push(item.stuid)
              })
              const stuIds = stuIdList.length ? stuIdList.join() : '0'
              groupingStu(res.autoid, stuIds)
                .then(data => {
                  this.$toast({
                    message: `更新成功`,
                    position: 'bottom'
                  })
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                })
                .catch(err => {
                  this.$toast('分组编辑失败，请重新再试')
                })
            })
          })
      }
    },
    deleteTag() {
      this.$dialog
        .confirm({
          title: '提示',
          message: `确定要删除“${this.tagName}”分组吗？`
        })
        .then(() => {
          const params = {
            classid: this.classId,
            name: this.tagName,
            sqnum: null,
            autoid: this.tagId,
            type: 0
          }
          editeStuGroup(params).then(res => {
            this.$toast('删除成功')
            setTimeout(() => {
              this.$router.back()
            }, 500)
          })
        })
    }
  },
  created() {
    if (this.isNew) {
      this.showPopup = true
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  .top_header {
    .right {
      .van-button {
        min-width: 4em;
        padding: 0;
        // @include borderRadius(1.333vw);
      }
    }
    .gray {
      color: $font-gray;
      background: #ededed;
      border-color: #ededed;
    }
  }
  // 信息
  .info_box {
    .kind {
      box-sizing: border-box;
      @include wh(100%, 8.8vw);
      @include font(2.667vw, 8.8vw);
      padding: 0 4vw;
      color: $font-gray;
      background: #ededed;
      span:nth-child(2) {
        float: right;
        color: #f95350;
      }
    }
    // 成员列表
    .contacts_box {
      padding: 1.333vw 2.4vw;
      box-sizing: border-box;
      border-bottom: 1px solid $border-light;
      .contacts_ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .contacts_li {
          width: 20%;
          // padding-top: 1.6vw;
          margin-bottom: 3.467vw;
          .contacts_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /deep/.van-image {
              @include wh(13.6vw, 13.6vw);
              @include borderRadius(1.333vw);
              overflow: hidden;
              img {
                @include imgCover;
              }
            }
            .contacts_name {
              @include font(3.2vw, 6.133vw);
              @include dot;
              color: $font-gray;
            }
          }
          // 增加
          // 减去
          .add_contacts,
          .sub_contacts {
            @include wh(13vw, 13vw);
            @include borderRadius(1.333vw);
            border: 0.3vw dashed $border-gray;
            margin-bottom: 0 none;
            text-align: center;
            font-size: 5.6vw;
            color: $font-gray;
            line-height: 13vw;
            .van-icon {
              line-height: 13.2vw;
            }
          }
          .contacts_add,
          .contacts_sub {
            @include font(3.2vw, 6.133vw);
            color: #fff;
            color: transparent;
          }
          // 允许删除成员
          .canDelete {
            position: relative;
            .van-icon-clear {
              position: absolute;
              top: -1vw;
              right: 1.6vw;
              font-size: 1.6vw;
              z-index: 1;
              color: #f95350;
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        // 占位元素 用于事件触发
        .contacts_li_default {
          flex: 1;
          // height: 19.867vw;
          // margin-bottom: 3.467vw;
          .contacts_info {
            .sub_contacts {
              @include wh(13.2vw, 13.2vw);
            }
            .contacts_sub {
              height: 6.133vw;
            }
          }
        }
      }
    }
    // 删除标签
    .delete_btn {
      width: calc(100% - 6.4vw);
      @include borderRadius(1.333vw);
      margin: 11.2vw 3.2vw 0 3.2vw;
    }
  }
  // proup弹出层
  .selectContact_box {
    height: 100%;
    .selectContact_title {
      font-size: 3.2vw;
      padding: 1.6vw 3.8vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-light;
      .btn {
        button {
          @include font(3.2vw, 3.8vw);
          height: 6.4vw;
          margin-left: 1.333vw;
        }
      }
      .txt {
        display: flex;
        align-items: center;
        .van-icon {
          padding-left: 1.333vw;
          font-size: 3.8vw;
        }
      }
    }
    .selectContact {
      height: calc(100% - 9.6vw - 0.267vw);
      overflow-y: hidden;
    }
  }
}
</style>