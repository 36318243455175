<template>
  <!-- <div class="page"> -->
  <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="加载失败，点击重新加载"
      @load="onLoad"
    >
      <div class="has-reply" v-for="(item, i) in list" :key="'doubtExplain' + i" @click="act(item)">
        <div class="reply-head">
          <!-- <img :src="item.headurl" alt /> -->
          <Portrait :src="item.headurl" />
        </div>
        <div class="reply-context">
          <div class="reply-context-top">
            <p>{{ item.name }}</p>
            <p>{{ item.crdate }}</p>
          </div>
          <div class="reply-context-text">
            <!-- 语音回复 -->
            <div v-if="item.content_type === 2">
              <div style="font-size: 3.467vw;">
                回复
                <span style="color: #565D72;">
                  {{
                  item.name
                  }}
                </span>：
              </div>
              <audioBox :url="item.content" :isBuy="item.is_buy" :price="item.price" :id="item.id" />
            </div>
            <!-- 文本回复 -->
            <div v-if="item.content_type === 1">
              <div class="reply-text">
                回复
                <span style="color: #565D72;">
                  {{
                  item.name
                  }}
                </span>
                ：
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="reply-context-question">{{ask ? ask : '我的提问'}}：{{ item.content }}</div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
  <!-- </div> -->
</template>

<script>
import audioBox from '../widget/audioBox'
import Portrait from '@/components/extend/portrait'
export default {
  components: { audioBox, Portrait },
  props: {
    ask: String
  },
  data() {
    return {
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      this.$emit('onLoad')
    },
    setList(data) {
      this.list = data
    },
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 >= res.sum) {
        this.finished = true
      } else {
        this.page += 1
      }
    },
    failure(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    // 跳转到问答详情页
    act(info) {
      this.$emit('click', info)
    }
  }
}
</script>

<style lang="scss" scoped>
.has-reply {
  margin: 2vw 2.8vw 0;
  display: flex;
  padding-bottom: 5.333vw;
  border-bottom: 1px solid #edeff2;
}
.reply-head {
  width: 8.8vw;
  height: 8.8vw;
  background: #c6c8cb;
  border-radius: 100%;
}
.reply-head > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.reply-context {
  flex: 1;
  margin-left: 2.4vw;
}
.reply-context-top {
  height: 8.8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reply-context-top > p:first-child {
  font-size: 3.467vw;
  color: #474f66;
}
.reply-context-top > p:last-child {
  color: rgb(144, 147, 153);
  font-size: 3.2vw;
}
.reply-voice > p {
  font-size: 3.733vw;
  color: #303133;
}
.reply-voice > img {
  width: 2.133vw;
  height: 4vw;
}
.reply-text {
  font-size: 3.467vw;
  line-height: 6vw;
}
.reply-context-question {
  font-size: 3.733vw;
  color: #606266;
  background-color: #f5f7fa;
  padding: 2.133vw;
  margin-top: 2.133vw;
}
.operation_column_yuyin img {
  display: block;
  width: 100%;
  height: 100%;
}
.doubt_list_portrait > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
</style>
