import request from '@/util/request'
import {getOpenId} from "@/util/auth";

// 登录相关

// 账号密码登录
// export function login(username,password) {
//   const type = 2; // 账号密码登陆
//   return request.post('/member/Login',{
//     data:{
//       username,
//       password,
//       type,
//       open_id:getOpenId(),
//     }
//   })
// }

// // openId登录
// export function loginByOpenId() {
//   const type = 1; // openId登陆
//   return request.post('/member/Login',{
//     data:{
//       type,
//       open_id:getOpenId(),
//     }
//   })
// }



/* ---------------- 二次开发：start(by:yyz) ---------------- */
// 账号密码登录
export function login(tel, pwd) {
    const openid = getOpenId();
    return request.post('/Server/LoginByMember', {data: {tel, pwd, openid}})
  }
  // openId登录
  export function loginByOpenId() {
    const openid = getOpenId();
    return request.post('/Server/LoginByOpenid', {
      data: {openid}
    })
  }
/* ---------------- 二次开发：end(by:yyz)   ---------------- */