<template>
  <div class="answers">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- 简答题列表 -->
        <div class="answer_box" v-for="(item, idx) in list" :key="idx" @click="act(item)">
          <!-- 学员信息 -->
          <div class="answer_box_student">
            <div class="avatar">
              <img :src="item.headurl" v-if="item.headurl" />
              <img :src="defaultSrc" v-else />
            </div>
            <div class="info">
              <div class="name">
                {{item.name}}
                <van-tag plain type="warning" class v-if="item.ischeck">已评分</van-tag>
              </div>
              <div class="time">{{item.ed | timeStamp | dateFormat('yyyy-MM-dd hh:mm:ss', true)}}</div>
            </div>
          </div>
          <!-- 答题内容 -->
          <div
            class="answer_box_desc"
            :class="{gray: setAnswer(item.answer) == '该学生未填写内容'}"
            v-text="setAnswer(item.answer)"
          ></div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultSrc: require('@/assets/images/head_prole.png'),
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  filters: {
    serial(i) {
      return i <= 9 ? `0${i}` : i
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      // 获取作业题目所有答题
      this.$emit('getData')
    },
    setList(res) {
      this.list.push(...res)
    },
    success(res) {
      this.loading = false
      this.pullRefresh = false
      if (this.page * 10 >= res.sum) {
        this.finished = true
      } else {
        this.page += 1
      }
    },
    failure(err) {
      this.loading = false
      this.pullRefresh = false
      this.error = true
    },
    setAnswer(str) {
      const arr = str.split('_')
      if (arr.length > 1) {
        return arr[1] ? arr[1] : '该学生未填写内容'
      } else {
        return str ? str : '该学生未填写内容'
      }
    },
    act(info) {
      this.$emit('goToDetail', info)
    }
  }
}
</script>
<style lang="scss" scoped>
.answers {
  .answer_box {
    padding-top: 2.667vw;
    padding-bottom: 4.533vw;
    border-bottom: 1px solid $border-light;
    .answer_box_student {
      display: flex;
      align-items: center;
      .avatar {
        @include wh(8.667vw, 8.667vw);
        @include borderRadius(50%);
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .info {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.2vw;
        padding-left: 2.133vw;
        .name {
          color: #465067;
          .van-tag {
            margin-left: 2.133vw;
          }
        }
        .time {
          color: #90949a;
        }
      }
    }
    .answer_box_desc {
      padding-left: 10.933vw;
      padding-right: 6.667vw;
      @include font(3.2vw, 5.867vw);
      overflow: hidden;
      @include dot2;
    }
    .gray {
      color: $font-gray;
    }
  }
}
</style>
