<template>
  <!-- 录播 -->
  <video-player
    class="video-player vjs-custom-skin"
    ref="videoPlayer"
    :playsinline="playsinline"
    :options="playerOptions"
    @canplay="onPlayerCanplay($event)"
    @play="onPlayerPlay($event)"
    @pause="onPlayerPause($event)"
    @ended="onPlayerEnded($event)"
  ></video-player>
</template>
<script>
import playCanplay from '@/util/playCanplay'
import { setVideoLogTime } from '@/api/classRing'
export default {
  props: {
    poster: String, //封面地址
    src: String, //录播地址
    lessonId: Number, //课程id
    stuId: Number //学员id
  },
  data() {
    return {
      playsinline: false,
      playerOptions: {},
      recordId: 0, //0-创建 其他-后台返回
      logTimer: null, //时间记录 定时器
      isPlay: false //是否正在播放视频
    }
  },
  watch: {
    src() {
      this.resetPlayerOpts()
      this.clearTimer()
    },
    poster() {
      this.resetPlayerOpts()
      this.clearTimer()
    }
  },
  methods: {
    init() {
      let ua = navigator.userAgent.toLocaleLowerCase()
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        this.playsinline = false
      } else {
        // ios端
        this.playsinline = true
      }
      this.resetPlayerOpts()
    },
    resetPlayerOpts() {
      this.playerOptions = {
        playbackRates: [1.0, 1.5, 1.75, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        },
        poster: this.poster, //你的封面地址
        sources: [
          {
            type: '', //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.src //url地址
          }
        ]
      }
    },
    //解决自动全屏
    onPlayerCanplay(player) {
      playCanplay()
    },
    onPlayerPlay(e) {
      this.$emit('onplay', e)
      this.isPlay = true
      this.createVideoPlayerLog()
      this.logTimer = setInterval(this.createVideoPlayerLog, 60000)
    },
    onPlayerPause(e) {
      this.isPlay = false
      this.createVideoPlayerLog()
      this.clearTimer()
      // console.log('onPlayerPause')
    },
    onPlayerEnded(e) {
      this.isPlay = false
      this.clearTimer()
      // 结束前会先执行 暂停onPlayerPause，以后可以针对结束做其他处理
      // console.log('onPlayerEnded')
    },
    createVideoPlayerLog() {
      if (this.stuId) {
        setVideoLogTime({
          courseid: this.lessonId,
          stuid: this.stuId,
          autoid: this.recordId,
          type: 2 //1：直播 2：录播
        })
          .then(res => {
            this.recordId = res.autoid
          })
          .catch(err => console.error(err))
      }
    },
    clearTimer() {
      clearInterval(this.logTimer)
      this.logTimer = null
      this.recordId = 0
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.clearTimer()
  }
}
</script>
<style lang="scss" scoped>
// //
// /deep/.video-player {
//   background: red;
//   /deep/.video-js {
//     video {
//       z-index: 1;
//     }
//   }
// }
</style>
