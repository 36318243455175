<template>
  <!-- 专家栏目>>>作业/考试>>>学员简答题答案 -->
  <div class="page">
    <topheader :title="titleText" />

    <van-tabs
      v-model="active"
      line-width="14vw"
      title-inactive-color="#247dff"
      title-active-color="#247dff"
      color="#247dff"
      animated
      swipeable
      sticky
      :offset-top="50"
    >
      <van-tab v-for="(tab,i) in tabsTitle" :title="tab" :key="tab + i">
        <Answerlist ref="answers" @getData="getData" @goToDetail="goToDetail" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {
  getTeaCanCheckHWAnswerList,
  getTeaCanCheckEXAnswerList
} from '@/api/my'
import Answerlist from './components/answers'
export default {
  components: {
    Answerlist
  },
  data() {
    return {
      type: this.$route.params.type, // 1-作业 2-考试
      planId: this.$route.query.planId, //作业/考试 id
      questionId: this.$route.query.questionId, // 问题id

      tabsTitle: ['待评分', '已评分'],
      active: 0
    }
  },
  computed: {
    titleText() {
      return this.type === '1' ? '作业简答题回答' : '考试简答题回答'
    }
  },
  methods: {
    getData() {
      let params = {
        questionid: this.questionId,
        curpage: this.page,
        type: this.active
      }
      if (this.type == '1') {
        params.hwplanid = this.planId //作业
        getTeaCanCheckHWAnswerList(params)
          .then(res => {
            this.$refs.answers[this.active].setList(res.list)
            this.$refs.answers[this.active].success(res)
          })
          .catch(err => {
            this.$refs.answers[this.active].failure(err)
          })
      } else if (this.type == '2') {
        params.explanid = this.planId //考试
        getTeaCanCheckEXAnswerList(params)
          .then(res => {
            this.$refs.answers[this.active].setList(res.list)
            this.$refs.answers[this.active].success(res)
          })
          .catch(err => {
            this.$refs.answers[this.active].failure(err)
          })
      }
    },
    // 进入 打分 页面
    goToDetail(item) {
      const query = {
        planId: this.planId,
        recordId: item.recordid,
        qsId: item.qsid,
        submitTime: item.ed
      }
      this.$router.push({
        path: `/experts_grading/${this.type}`,
        query
      })
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.page {
  @include isTop;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  /deep/.van-tabs {
    > div {
      .van-sticky {
        top: 13.333vw !important;
      }
    }
  }
}
</style>
