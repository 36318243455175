<template>
  <!-- 账单明细 -->
  <div class="page">
    <topheader />
    <div class="my_wallet_detaciis_title">
      <div>类型</div>
      <div>金额</div>
    </div>

    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="my_wallet_detaciis_list" v-for="(item,i) in list" :key="'myWallet'+i">
          <div class="my_wallet_detaciis_left">
            <div class="deposit_title" v-text="incomeType(item.mtype)"></div>
            <div class="deposit_desc" v-text="item.remark"></div>
            <div class="deposit_time">{{item.crdate}}</div>
          </div>

          <div class="my_wallet_detaciis_right">
            <div class="my_wallet_detaciis_money">
              <!-- <span class="deposit_type" :class="{
                'green': item.acmoney >= 0,
                'red': item.acmoney < 0 
              }" v-text="item.type == 1? '+' : '-'"></span>-->
              <span
                class="deposit_number"
                :class="{
                'green': item.acmoney >= 0,
                'red': item.acmoney < 0 
              }"
                v-text="` ${item.acmoney}元`"
              ></span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyAccountList } from '@/api/my'
export default {
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getMyAccountList(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    incomeType(type) {
      switch (type) {
        case 10:
          return '购买班圈'
        case 11:
          return '推荐提成' //一级可不显示级别
        case 12:
          return '推荐提成' //二级可不显示级别
        case 20:
          return '购买问题'
        case 21:
          return '提问提成' //提问者，不显示
        case 22:
          return '答案提成' //专家，不显示
        case 30:
          return '打赏老师'
        case 31:
          return '班圈打赏'
        case 40:
          return '现金提现'
      }
    }
  },
  mounted() {
    // this.list = mockData
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
  .my_wallet_detaciis_title {
    @include wh(100%, 9.6vw);
    @include font(3.2vw, 9.6vw);
    box-sizing: border-box;
    padding: 0 4vw;
    color: $font-gray;
    background: $bg-light;
    display: flex;
    justify-content: space-between;
  }
  .my_wallet_detaciis_list {
    @include wh(100%, 28.667vw);
    box-sizing: border-box;
    padding: 4.533vw 4vw 2.933vw 4vw;
    display: flex;
    position: relative;
    .my_wallet_detaciis_left {
      flex: 1;
      display: flex;
      flex-direction: column;
      .deposit_title {
        font-size: 4vw;
      }
      .deposit_desc {
        flex: 1;
        @include font(2.933vw, 4vw);
        color: $font-gray;
        padding-top: 1.333vw;
      }
      .deposit_time {
        font-size: 2.933vw;
        color: $font-gray;
      }
    }
    .my_wallet_detaciis_right {
      width: 31.467vw;
      display: flex;
      align-items: center;
      .my_wallet_detaciis_money {
        width: 100%;
        text-align: right;
        font-size: 4vw;
        // .deposit_type {}
        .deposit_number {
        }

        .green {
          color: #7acc5d;
        }
        .red {
          color: $warn-color;
        }
      }
    }
  }
  .my_wallet_detaciis_list::after {
    content: '';
    display: block;
    border-bottom: 1px solid $bg-light;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 8vw);
  }
}
</style>
