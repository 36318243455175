<template>
  <!-- 直播 -->
  <div class="live_box">
    <!-- v-if="isBegan" -->
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="playsinline"
      :options="playerOptions"
      @canplay="onPlayerCanplay($event)"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
    ></video-player>
    <!-- <div class="count_down" v-else>
      <img :src="poster" v-if="poster" />
      <div class="mark">
        <div
          class="connent"
          v-if="
            countdown.day ||
              countdown.hour ||
              countdown.minute ||
              countdown.second
          "
        >
          倒计时
          <span v-if="countdown.day"> <b v-text="countdown.day"></b>天 </span>
          <span> <b v-text="countdown.hour"></b>时 </span>
          <span> <b v-text="countdown.minute"></b>分 </span>
          <span> <b v-text="countdown.second"></b>秒 </span>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
// import playCanplay from '@/util/playCanplay'
export default {
  props: {
    poster: String, //封面地址
    src: String, //录播地址
    lessonId: Number, //课程id
    stuId: Number, //学员id
    beginTime: String //直播预计开始时间
  },
  data() {
    // let validTime = 0;
    // if (this.beginTime) {
    //   const stamp =
    //     Date.parse(this.beginTime.replace(/\-/g, "/")) - Date.parse(new Date());
    //   if (stamp) {
    //     //当前时间 < 开始时间
    //     validTime = Math.floor(stamp / 1000);
    //   }
    // }
    // const isBegan =
    //   Date.parse(new Date()) > Date.parse(this.beginTime.replace(/\-/g, "/"));
    return {
      playsinline: false,
      playerOptions: {},
      countdown: {
        day: '00',
        hour: '00',
        minute: '00',
        second: '00'
      }, //倒计时
      countdownTimer: null,
      validTime: 30000, // 有效时间(秒)
      isBegan: false,
      recordId: 0, //0-创建 其他-后台返回
      logTimer: null //时间记录 定时器
    }
  },
  watch: {
    src() {
      this.resetPlayerOpts()
      this.cleanCountdownTimer()
      this.clearLogTimer()
    },
    poster() {
      this.resetPlayerOpts()
      this.cleanCountdownTimer()
      this.clearLogTimer()
    },
    beginTime(v) {
      this.cleanTimer()
      this.validTime = 0
      if (v) {
        this.isBegan =
          Date.parse(new Date()) >
          Date.parse(this.beginTime.replace(/\-/g, '/'))
        const stamp = Date.parse(v.replace(/\-/g, '/')) - Date.parse(new Date())
        if (stamp > 0) {
          //当前时间 < 开始时间
          this.validTime = Math.floor(stamp / 1000)
          this.$nextTick(() => {
            this.setCountdown()
          })
        } else {
          this.cleanTimer() //多清一次 无所谓
        }
      }
    }
  },
  methods: {
    init() {
      let ua = navigator.userAgent.toLocaleLowerCase()
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        this.playsinline = false
      } else {
        // ios端
        this.playsinline = true
      }
      this.resetPlayerOpts()
    },
    resetPlayerOpts() {
      this.playerOptions = {
        // playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        },
        // poster: this.poster, //你的封面地址
        poster: '', //你的封面地址
        sources: [
          {
            type: 'application/x-mpegURL', //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // src: this.src //url地址
            src: 'http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8' //url地址
          }
        ]
      }
    },
    //解决自动全屏
    onPlayerCanplay(player) {
      var ua = navigator.userAgent.toLocaleLowerCase()
      let video = document.getElementsByTagName('video')[0]
      if (video) {
        if (
          ua.match(/tencenttraveler/) != null ||
          ua.match(/qqbrowse/) != null
        ) {
          //x5内核
          video.setAttribute('x-webkit-airplay', true)
          video.setAttribute('x5-playsinline', true)
          video.setAttribute('webkit-playsinline', true)
          video.setAttribute('playsinline', true)
        } else {
          //ios端
          video.setAttribute('webkit-playsinline', true)
          video.setAttribute('playsinline', true)
        }
      }
    },
    // 播放
    onPlayerPlay(e) {
      this.$emit('onplay', e)
      this.createVideoPlayerLog()
      this.logTimer = setInterval(this.createVideoPlayerLog, 60000)
      console.log('onPlayerPlay')
    },
    // 暂停
    onPlayerPause(e) {
      this.clearLogTimer()
      console.log('onPlayerPause')
    },
    // 结束
    onPlayerEnded(e) {
      this.clearLogTimer()
      // 结束前会先执行 暂停onPlayerPause，以后可以针对结束做其他处理
      console.log('onPlayerEnded')
    },
    // 记录
    createVideoPlayerLog() {
      if (this.stuId) {
        setVideoLogTime({
          courseid: this.lessonId,
          stuid: this.stuId,
          autoid: this.recordId,
          type: 1 //1：直播 2：录播
        })
          .then(res => {
            this.recordId = res.autoid
          })
          .catch(err => console.error(err))
      }
    },
    // 清除log定时器
    clearLogTimer() {
      clearInterval(this.logTimer)
      this.logTimer = null
      this.recordId = 0
    },
    // 设置 倒计时
    setCountdown() {
      // 还在有效期内，可进行倒计时
      this.countdownTimer = setInterval(() => {
        if (this.validTime) {
          this.countdown = this.formatSeconds(this.validTime)
          this.validTime--
        } else {
          this.isBegan = true
          this.cleanTimer()
        }
      }, 1000)
    },
    // 清除倒计时定时器
    cleanCountdownTimer() {
      clearInterval(this.countdownTimer)
      this.countdownTimer = null
    },
    // 计算剩余（时:分:秒）
    formatSeconds(value) {
      if (!value) {
        return '00:00:00'
      } else {
        let second = parseInt(value),
          minute = 0,
          hour = 0,
          day = 0
        if (second > 60) {
          minute = parseInt(second / 60)
          second = parseInt(second % 60)
          if (minute > 60) {
            hour = parseInt(minute / 60)
            minute = parseInt(minute % 60)
          }
          if (hour > 24) {
            day = parseInt(hour / 24)
            hour = parseInt(hour % 24)
            // return `${day}天 ${getNumber(hour)}:${getNumber(
            //   minute
            // )}:${getNumber(second)}`
          }
        }
        // return `${getNumber(hour)}:${getNumber(minute)}:${getNumber(second)}`
        return {
          second: getNumber(second),
          minute: getNumber(minute),
          hour: getNumber(hour),
          day: getNumber(day)
        }

        function getNumber(v) {
          return v >= 0 && v < 10 ? `0${v}` : v
        }
      }
    }
  },
  created() {
    this.init()
    // if (!this.isBegan) {
    //   this.setCountdown();
    // }
  },
  beforeDestroy() {
    this.cleanCountdownTimer()
    this.clearLogTimer()
  }
}
</script>
<style scoped>
.live_box {
  /* height: 360px; */
  width: 100%;
  border: 1px solid #eee;
  background: #eee;
}
/* .live_box {
  @include wh(100%, 100%);
  background: black;
  .count_down {
    @include wh(100%, 100%);
    position: relative;
    > img {
      @include wh(100%, 100%);
      object-fit: contain;
    }
    .mark {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.75);
      .connent {
        @include wh(100%, 12vw);
        @include font(3.2vw, 12vw);
        padding: 0 4.6vw;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.7);
        span {
          b {
            @include wh(6.4vw, 6.4vw);
            border-radius: 0.8vw;
            margin: 0 0.8vw;
            border: 1px solid hsla(0, 0%, 100%, 0.5);
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
} */
</style>
